import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    isFetching: false,
    error: false,
    errorMessage: null,
    selectedCompany: null, // Add selectedCompany field
    selectedCompanyName: null,
    selectedCompanyId: null,
    selectedUserCompanyId : null,
    isTermAccepted: false,
    selectedCompanyRoleId : null,
    isRegistrationSuccess: false,
  },
  reducers: {
    loginStart: (state) => {
      state.isFetching = true;
    },
    loginSuccess: (state, action) => {
      state.isFetching = false;
      state.user = action.payload;
    },
    loginFailure: (state, action) => {
      state.isFetching = false;
      state.errorMessage = action.payload;
      state.error = true;
    },
    refreshTokenStart:(state) => {
      state.isFetching = true;
    },
    refreshTokenSuccess:(state,action) => {
      state.isFetching = false;
      state.user = action.payload;
    },
    refreshTokenFailed:(state,action) => {
      state.isFetching = false;
      state.error = true;
      state.errorMessage = action.payload
    },
    registerStart: (state) => {
      state.isFetching = true;
    },
    registerSuccess: (state, action) => {
      state.isFetching = false;
      state.user = action.payload;
      state.isRegistrationSuccess = true;
    },
    registerFailed: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    logout: (state) => {
      state.user = null;
      state.selectedCompany = null;
    },
    setSelectedCompany: (state, action) => {
      state.selectedCompany = action.payload;
    },
    setSelectedCompanyName: (state, action) => {
      state.selectedCompanyName = action.payload
    },
    setSelectedCompanyId: (state, action) => {
      state.selectedCompanyId = action.payload
    },
    setSelectUserCompanyId : (state, action)  => {
      state.selectedUserCompanyId = action.payload 
    },
    setTermAccepted: (state, action) => {
      state.isTermAccepted = action.payload; // Update isTermAccepted
    },
    setSelectedCompanyRoleId : (state, action) => {
      state.selectedCompanyRoleId = action.payload
    },
    updateTokens: (state, action) => {
      state.user = action.payload.user;
      state.selectedCompany = action.payload.selectedCompany;
      // Update other relevant fields as needed
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  registerStart,
  registerSuccess,
  registerFailed,
  refreshTokenStart,
  refreshTokenSuccess,
  refreshTokenFailed,
  logout,
  setSelectedCompany,
  setSelectedCompanyName,
  setSelectedCompanyId,
  setSelectUserCompanyId,
  setTermAccepted,
  setSelectedCompanyRoleId,
  setSelectedCompanyRoleName,
  updateTokens,
} = userSlice.actions;

export default userSlice.reducer;
