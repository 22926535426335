import React, { useState, useEffect, useRef } from "react";
import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import "../../css/registercss/UserProfile.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import axios from "axios";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { publicRequest } from "../../requestMethods";
import _ from "lodash";
import { ThinCalendar } from "../../fontAwesomeComponents/FaIcons";
import { FaAngleDown } from "react-icons/fa";

const UserProfile = ({ onNext, data }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [dob, setDob] = useState("");
  const [mobilePhone, setMobilePhone] = useState("");
  const [licenseNumber, setLicenseNumber] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isCalendarOpen, setCalendarOpen] = useState(false); // State for calendar visibility

  const [officePhone, setOfficePhone] = useState("");
  const [startNum, setStartNum] = useState("+61"); // Default sel
  const [startNum2, setStartNum2] = useState("+61");
  const [allFieldsFilled, setAllFieldsFilled] = useState(true);

  const [passwordMatch, setPasswordMatch] = useState(true); // State for password matching check
  const [ageError, setAgeError] = useState(false);

  const [emailFormatError, setEmailFormatError] = useState(false);

  const handleNumericInput = (e, setter) => {
    const inputValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    setter(inputValue);
  };

  const isValidName = (name) => {
    // Use a regular expression to allow only letters and a dot
    const nameRegex = /^[a-zA-Z.]+$/;
    return nameRegex.test(name);
  };

  const dobInputRef = useRef(null);

  // useEffect(() => {
  //   document.addEventListener("click", handleOutsideClick);
  //   return () => {
  //     document.removeEventListener("click", handleOutsideClick);
  //   };
  // }, []);

  const handleOutsideClick = (event) => {
    // Check if the click is outside the calendar and input box
    if (
      !isCalendarOpen &&
      dobInputRef.current &&
      !dobInputRef.current.contains(event.target) &&
      !event.target.classList.contains("react-calendar")
    ) {
      setCalendarOpen(false);
    }
  };

  const [emptyFields, setEmptyFields] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
    confirmPassword: false,
    dob: false,
    mobilePhone: false,

    licenseNumber: false,
  });

  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [mobilePhoneErrorMessage, setMobilePhoneErrorMessage] = useState("");
  const [officePhoneErrorMessage, setOfficePhoneErrorMessage] = useState("");
  const [licenseNumberErrorMessage, setLicenseNumberErrorMessage] =
    useState("");

  const passwordStrengthColors = [
    "#FF0000", // Weak
    "#FF9900", // Fair
    "#FFFF00", // Okay
    "#00FF00", // Good
    "#28A745", // Strong
  ];

  //for checking email.. change link later
  const [emailExists, setEmailExists] = useState(false);

  // Add a variable to store the latest typed email
  let latestTypedEmail = "";

  const isValidEmail = (email) => {
    // Use a regular expression to validate the email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const checkEmailExistence = async (emailToCheck) => {
    try {
      const response = await publicRequest.get(
        `/auth/validate?email=${emailToCheck}`
      );

      if (response.status === 200) {
        setEmailExists(false); // Set to false if email doesn't exist
      } else {
        // Check if the 'Email' property exists in errors and contains 'Invalid email format'
        if (
          response.data.errors &&
          response.data.errors.Email &&
          response.data.errors.Email.length > 0 &&
          response.data.errors.Email[0].includes("Invalid email format")
        ) {
          setEmailExists(false);
        } else {
          // Default to true if there's no specific error
          setEmailExists(true);
        }
      }
    } catch (error) {
      console.error("Error checking email existence:", error);
      setEmailExists(true);
    }
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail); // Update email state
    latestTypedEmail = newEmail; // Update the latest typed email

    if (!newEmail) {
      setEmailFormatError(false); // Reset email format error
      setEmailExists(false); // Reset email existence status if the input is empty
    } else if (isValidEmail(newEmail)) {
      debouncedCheckEmailExistence(newEmail); // Trigger email existence check for valid emails
      // Remove the following line to hide the error message when typing
      // setEmailFormatError(false); // Reset email format error
    } else {
      setEmailFormatError(true); // Set the email format error
      setEmailExists(false); // Reset email existence status
    }
  };

  // Use _.debounce to debounce the checkEmailExistence function
  const debouncedCheckEmailExistence = _.debounce(checkEmailExistence, 100);

  const [formError, setFormError] = useState(false);

  const isFormValid = () => {
    return (
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      password !== "" &&
      confirmPassword !== "" &&
      dob !== "" &&
      mobilePhone !== "" &&
      licenseNumber !== "" &&
      passwordMatch
    );
  };

  // State to track whether each input has been clicked and left empty
  const [inputStates, setInputStates] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
    confirmPassword: false,
    dob: false,
    mobilePhone: false,
    officePhone: false,
    licenseNumber: false,
  });

  const handleInputChange = (field, value, isValidEmail) => {
    if (!value) {
      setInputStates((prevInputStates) => ({
        ...prevInputStates,
        [field]: true,
      }));
      setEmptyFields((prevEmptyFields) => ({
        ...prevEmptyFields,
        [field]: true,
      }));
    } else {
      setInputStates((prevInputStates) => ({
        ...prevInputStates,
        [field]: false,
      }));
      setEmptyFields((prevEmptyFields) => ({
        ...prevEmptyFields,
        [field]: false,
      }));
    }
  };

  //email validator

  const handleDateClick = (date) => {
    setCalendarOpen(false);

    // Calculate the user's age based on the selected birthdate
    const selectedDate = new Date(date);
    const today = new Date();
    const age = today.getFullYear() - selectedDate.getFullYear();

    // Check if the user is less than 15 years old
    if (age < 15) {
      // Set a state variable to indicate that the user is too young
      setAgeError(true);
      setDob(""); // Clear the date of birth input
    } else {
      // If the user is 16 years or older, clear the age error state
      setAgeError(false);

      // Format the selected date and time
      const formattedDate = date.toISOString();
      setDob(formattedDate); // Set the selected date in the desired format
    }
  };

  const handleNext = () => {
    const combinedPhoneNumber = startNum + mobilePhone;
    const combinedOfficePhone = startNum2 + officePhone;

    let hasError = false;

    setEmptyFields({
      firstName: !firstName,
      lastName: !lastName,
      email: !email,
      password: !password,
      confirmPassword: !confirmPassword,
      dob: !dob,
      mobilePhone: !mobilePhone,
      //   officePhone: !officePhone,
      licenseNumber: !licenseNumber,
    });

    if (
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      password !== "" &&
      confirmPassword !== "" &&
      dob !== "" &&
      mobilePhone !== "" &&
      licenseNumber !== "" &&
      passwordMatch
    ) {
      // Password strength validation
      if (passwordStrength < 3) {
        setPasswordErrorMessage("Password doesn't meet the requirements");
        hasError = true;
      } else if (/\s/.test(password)) {
        // Check for whitespace in the password
        setPasswordErrorMessage("Password should not contain spaces");
        hasError = true;
      } else {
        setPasswordErrorMessage("");
      }
      // Email format validation
      if (!isValidEmail(email)) {
        setEmailErrorMessage("Please enter a valid email");
        hasError = true;
      } else {
        setEmailErrorMessage("");
      }

      // Mobile phone validation
      // if (mobilePhone.length !== 8 || !/^\d+$/.test(mobilePhone)) {
      //   setMobilePhoneErrorMessage("Please enter a valid Mobile Phone Number");
      //   hasError = true;
      // } else {
      //   setMobilePhoneErrorMessage("");
      // }

      if (
        (mobilePhone.length !== 9 && startNum == 61) ||
        !/^\d+$/.test(mobilePhone)
      ) {
        setMobilePhoneErrorMessage("Please enter a valid Mobile Phone Number");
        hasError = true;
      } else if (startNum == 678 && mobilePhone.length !== 7) {
        setMobilePhoneErrorMessage("Please enter a valid Mobile Phone Number");
        hasError = true;
      } else {
        setMobilePhoneErrorMessage("");
      }

      // Office phone validation
      if (officePhone !== "") {
        if (
          (officePhone.length !== 9 && startNum2 == 61) ||
          !/^\d+$/.test(officePhone)
        ) {
          setOfficePhoneErrorMessage(
            "Please enter a valid Office Phone Number"
          );
          hasError = true;
        } else if (startNum2 == 678 && officePhone.length !== 4) {
          setOfficePhoneErrorMessage(
            "Please enter a valid Office Phone Number"
          );
          hasError = true;
        } else if (startNum2 == 64 && ![6, 7].includes(officePhone.length)) {
          setOfficePhoneErrorMessage(
            "Please enter a valid Office Phone Number"
          );
          hasError = true;
        } else {
          setOfficePhoneErrorMessage("");
        }
      }

      //   License number validation
      // if (licenseNumber.length < 1) {
      //   setLicenseNumberErrorMessage(
      //     "License number must be 12-30 alpha numeric characters"
      //   );
      //   hasError = true;
      // } else {
      //   setLicenseNumberErrorMessage("");
      // }

      if (!hasError) {
        onNext({
          firstName,
          lastName,
          email,
          password,
          confirmPassword,
          dob,
          mobilePhone: combinedPhoneNumber,
          licenseNumber,
          officePhone: combinedOfficePhone,
          // officePhone,
          // mobilePhone
        });

        setAllFieldsFilled(true);
        isFormValid(true);
      }
    } else {
      setAllFieldsFilled(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordVisibility2 = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const toggleCalendar = () => {
    setCalendarOpen(!isCalendarOpen);
  };

  const handlePhoneNumberChange = (e) => {
    setStartNum(e.target.value);
  };

  const handlePhoneNumberChange2 = (e) => {
    setStartNum2(e.target.value);
  };

  const handleLicenseNumberChange = (e) => {
    const keyPressed = e.key;
    const isAlphanumeric = /^[a-zA-Z0-9]$/; // Regex for alphanumeric characters only
    const allowedKeys = ["Backspace", "Delete"]; // Allowed keys (backspace and delete)
  
    if (!isAlphanumeric.test(keyPressed) && !allowedKeys.includes(keyPressed)) {
      e.preventDefault(); // Prevent typing non-alphanumeric characters (except backspace and delete)
    }
  };
  
  
  const calculatePasswordStrength = (password) => {
    // The conditions for password strength:
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSymbol = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password);
    const isLongEnough = password.length >= 12;

    // Calculate strength
    let strength = 0;
    if (hasUppercase && hasLowercase) strength++;
    if (hasNumber && hasSymbol) strength++;
    if (isLongEnough) strength++;
    //if () strength++;
    if (password.length >= 14 && strength === 3) strength++; // Additional strength if password is 14 or more characters

    return strength;
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    const strength = calculatePasswordStrength(newPassword);
    setPasswordStrength(strength);
    setPassword(newPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    const confirmPasswordValue = e.target.value;
    setConfirmPassword(confirmPasswordValue);
    setPasswordMatch(confirmPasswordValue === password);
  };

  useEffect(() => {
    if (data) {
      const slicedMobilePhone = data.mobilePhone
        ? data.mobilePhone.slice(3)
        : "";
      const slicedOfficePhone = data.officePhone
        ? data.officePhone.slice(3)
        : "";
      setFirstName(data.firstName || "");
      setLastName(data.lastName || "");
      setEmail(data.email || "");
      setMobilePhone(slicedMobilePhone);
      setDob(data.dob || "");
      setOfficePhone(slicedOfficePhone);
      //   setPassword(data.password || "");
      //   setConfirmPassword(data.confirmPassword || "");
      // //  setPasswordStrength(data.passwordStrength || '')
      //   if (password === confirmPassword) {
      //     setPasswordMatch(true);
      //     setPasswordStrength(password);
      //   } else {
      //     setPasswordMatch(false);
      //   }
      setLicenseNumber(data.licenseNumber);
      // Update other input field states
    }
  }, [data]);

  return (
    <div className="finalDetails-container">
      <div className="finalTitleDiv">
        <h3
          style={{
            fontFamily: "futurabook",
            fontWeight: "bold",
            fontSize: "40px",
            color: "#141212",
          }}
        >
          Register an Account
        </h3>
        <p style={{ fontFamily: "futurabook" }}>
          Information required for the first user of new company registration
        </p>
      </div>
      <div className="profile-row">
        <div
          className={`profile-col ${emptyFields.firstName ? "red-border" : ""}`}
        >
          <input
            className="inputbox"
            type="text"
            placeholder="First Name*"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            onBlur={() => handleInputChange("firstName", firstName)}
            required
            maxLength={50}
          />
          {inputStates.firstName && !firstName && (
            <div>
              <p
                className="register-ErrorContainer"
                style={{
                  marginTop: "-20px",
                  opacity: "90%",
                  padding: "5px",
                  marginLeft: "30px",
                }}
              >
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  style={{ marginRight: "1px" }}
                />
                This field is required
              </p>
            </div>
          )}
        </div>
        <div
          className={`profile-col ${emptyFields.lastName ? "red-border" : ""}`}
        >
          <input
            type="text"
            placeholder="Last Name*"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            onBlur={() => handleInputChange("lastName", lastName)}
            required
            maxLength={50}
          />
          {inputStates.lastName && !lastName && (
            <div>
              <p
                className="register-ErrorContainer"
                style={{
                  marginTop: "-20px",
                  opacity: "90%",
                  padding: "5px",
                  marginLeft: "30px",
                }}
              >
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  style={{ marginRight: "1px" }}
                />
                This field is required
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="profile-row">
        <div
          className={`profile-col email-col  ${
            emptyFields.email || emailErrorMessage ? "red-border" : ""
          }`}
          colSpan={2}
        >
          <input
            type="email"
            placeholder="Email*"
            value={email}
            onChange={handleEmailChange}
            onBlur={() => handleInputChange("email", email)}
            required
            maxLength={60}
          />
          {inputStates.email && !email && (
            <div>
              <p
                className="register-ErrorContainer"
                style={{
                  marginTop: "-20px",
                  opacity: "90%",
                  padding: "5px",
                  marginLeft: "30px",
                }}
              >
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  style={{ marginRight: "1px" }}
                />
                This field is required
              </p>
            </div>
          )}

          {emailErrorMessage && emailFormatError && (
            <div>
              <p
                className="register-ErrorContainer"
                style={{
                  marginTop: "-20px",
                  opacity: "90%",
                  padding: "5px",
                  marginLeft: "30px",
                  fontSize: "16px",
                }}
              >
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  style={{ marginRight: "1px" }}
                />

                {emailErrorMessage}
              </p>
            </div>
          )}
          {emailExists && (
            <div>
              <p
                className="register-ErrorContainer"
                style={{
                  marginTop: "-20px",
                  opacity: "90%",
                  padding: "5px",
                  marginLeft: "30px",
                }}
              >
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  style={{ marginRight: "1px" }}
                />
                Email Already Exists
              </p>
            </div>
          )}

          {/* {emailFormatError && (
            <div>
              <p
                className="register-ErrorContainer"
                style={{
                  marginTop: "-20px",
                  opacity: "90%",
                  padding: "5px",
                  marginLeft: "30px",
                }}
              >
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  style={{ marginRight: "1px" }}
                />
                Please enter a valid email
              </p>
            </div>
          )} */}
        </div>
      </div>

      <div className="profile-row">
        <div
          className={`profile-col ${
            !passwordMatch || emptyFields.password || passwordErrorMessage
              ? "red-border"
              : ""
          }`}
          colSpan={2}
        >
          <input
            type={showPassword ? "text" : "password"}
            placeholder="Password*"
            value={password}
            onChange={handlePasswordChange}
            onBlur={() => handleInputChange("password", password)}
            required
          />

          {/* Eye icon to toggle password visibility */}
          <span onClick={togglePasswordVisibility}>
            {showPassword ? <RiEyeOffFill /> : <RiEyeFill />}
          </span>
          {inputStates.password && !password && (
            <div>
              <p
                className="register-ErrorContainer"
                style={{
                  marginTop: "-20px",
                  opacity: "90%",
                  padding: "5px",
                  marginLeft: "30px",
                }}
              >
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  style={{ marginRight: "1px" }}
                />
                This field is required
              </p>
            </div>
          )}
        </div>

        <div
          className={`profile-col ${
            !passwordMatch || emptyFields.password || passwordErrorMessage
              ? "red-border"
              : ""
          }`}
          colSpan={2}
        >
          <input
            type={showConfirmPassword ? "text" : "password"}
            placeholder="Confirm Password*"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            required
          />
          {/* Eye icon to toggle password visibility */}
          <span onClick={togglePasswordVisibility2}>
            {showConfirmPassword ? <RiEyeOffFill /> : <RiEyeFill />}
          </span>
        </div>

        {!passwordMatch && (
          <div>
            <p
              className="register-ErrorContainer"
              style={{ marginTop: "10px", opacity: "90%", padding: "5px" }}
            >
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                style={{ marginRight: "1px" }}
              />
              Passwords don't match
            </p>
          </div>
        )}
      </div>

      <div style={{ marginTop: "1px", width: "50%", paddingRight: "1px" }}>
        {passwordStrength >= 0 && (
          <div>
            {passwordStrengthColors.map((color, index) => (
              <span
                key={index}
                style={{
                  display: "inline-block",
                  width: "70px",
                  height: "7px",
                  backgroundColor:
                    index <= passwordStrength
                      ? passwordStrengthColors[passwordStrength]
                      : "#eeeeee",
                  margin: "0 2px",
                  borderRadius: "4px",
                }}
              ></span>
            ))}

            <div className="passwordRequirements">
              {passwordErrorMessage && passwordStrength < 3 && (
                <p style={{ color: "red", fontSize: "15px", margin: "4px 0" }}>
                  {passwordErrorMessage}
                </p>
              )}
              <p
                style={{
                  fontFamily: "futurabook",
                  color: "#707070",
                  fontSize: "12px",
                  margin: "4px 0",
                }}
              >
                Minimum of 12 characters
              </p>
              <p
                style={{
                  fontFamily: "futurabook",
                  color: "#707070",
                  fontSize: "12px",
                  margin: "4px 0",
                }}
              >
                A combination of uppercase letters, lowercase letters, numbers,
                and symbols.
              </p>
              <p
                style={{
                  fontFamily: "futurabook",
                  color: "#707070",
                  fontSize: "12px",
                  margin: "4px 0",
                }}
              >
                Not a word that can be found in a dictionary.
              </p>
            </div>
          </div>
        )}
      </div>

      {/* birthdate */}
      <div className="profile-row">
        <div
          className="icon-col"
          style={{
            fontSize: "25px",
            justifyContent: "center",
            height: "60px",
            minWidth: "73px",
            cursor: "pointer",
          }}
        >
          <ThinCalendar
            height="20px"
            width="20px"
            onClick={toggleCalendar}
            className="calendar-icon"
          />
          <FaAngleDown
            size={13}
            style={{ marginLeft: "5px", cursor: "pointer" }}
            onClick={toggleCalendar}
          />
          {isCalendarOpen && (
            <div
              className="calendar-popup"
              style={{
                width: "18rem",
                height: "220px",
                fontSize: "15px",
                fontFamily: "futurabook",
              }}
            >
              <Calendar
                onClickDay={handleDateClick}
                className="custom-calendar"
              />
            </div>
          )}
        </div>

        {/* calendar */}
        <div
          className={`profile-col ${
            emptyFields.dob && (!dob || ageError) ? "red-border" : ""
          }`}
        >
          <input
            ref={dobInputRef}
            className="inputbox"
            type="text"
            placeholder="Date Of Birth*"
            value={dob ? new Date(dob).toLocaleDateString() : ""}
            onChange={(e) => setDob(e.target.value)}
            onClick={toggleCalendar}
            readOnly
          />
          {ageError && (
            <div>
              <p
                className="register-ErrorContainer"
                style={{
                  marginTop: "-30px",
                  opacity: "90%",
                  padding: "5px",
                  marginLeft: "30px",

                  height: "60px",
                }}
              >
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  style={{ marginRight: "1px" }}
                />
                You must be 15 years old and above
              </p>
            </div>
          )}
        </div>
        <div className="icon-col" style={{ height: "60px" }}>
          {/* Dropdown for phone numbers */}
          <select
            value={startNum.replace("+", "")}
            onChange={handlePhoneNumberChange}
            style={{ border: "none", maxWidth: "100%", cursor: "pointer" }}
          >
            <option value={+61}>+61</option>
            <option value={+678}>+678</option>
            {/* Add more options for other phone number codes */}
          </select>
        </div>
        <div
          className={`profile-col ${
            (emptyFields.mobilePhone && !mobilePhone) || mobilePhoneErrorMessage
              ? "red-border"
              : ""
          }`}
        >
          <input
            type="tel"
            placeholder="Mobile Phone Number*"
            value={mobilePhone}
            onChange={(e) => handleNumericInput(e, setMobilePhone)}
            onBlur={() => handleInputChange("mobilePhone", mobilePhone)}
            required
            maxLength={9}
            pattern="[0-9]*"
          />

          {mobilePhoneErrorMessage && (
            <div>
              <p
                className="register-ErrorContainer"
                style={{
                  marginTop: "-20px",
                  opacity: "90%",
                  padding: "5px",
                  marginLeft: "30px",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  style={{ marginRight: "1px" }}
                />
                {mobilePhoneErrorMessage}
              </p>
            </div>
          )}

          {inputStates.mobilePhone && !mobilePhone && (
            <div>
              <p
                className="register-ErrorContainer"
                style={{
                  marginTop: "-20px",
                  opacity: "90%",
                  padding: "5px",
                  marginLeft: "30px",
                }}
              >
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  style={{ marginRight: "1px" }}
                />
                This field is required
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="profile-row">
        <div className="icon-col" style={{ height: "60px" }}>
          {/* Dropdown for phone numbers */}
          <select
            value={startNum2.replace("+", "")}
            onChange={handlePhoneNumberChange2}
            style={{ border: "none", maxWidth: "100%", cursor: "pointer" }}
          >
            <option value={+61}>+61</option>
            <option value={64}>+64</option>
            <option value={+678}>+678</option>
            {/* Add more options for other phone number codes */}
          </select>
        </div>
        <div
          className={`profile-col ${
            officePhoneErrorMessage ? "red-border" : ""
          }`}
        >
          <input
            type="tel"
            placeholder="Office Phone Number"
            value={officePhone}
            onChange={(e) => handleNumericInput(e, setOfficePhone)}
            onBlur={() => handleInputChange("officePhone", officePhone)}
            maxLength={9}
            pattern="[0-9]*"
          />

          {officePhoneErrorMessage && (
            <div>
              <p
                className="register-ErrorContainer"
                style={{
                  marginTop: "-20px",
                  opacity: "90%",
                  padding: "5px",
                  marginLeft: "30px",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  style={{ marginRight: "1px" }}
                />
                {officePhoneErrorMessage}
              </p>
            </div>
          )}
        </div>
        <div
          className={`profile-col ${
            emptyFields.licenseNumber && !licenseNumber ? "red-border" : ""
          }`}
          colSpan={2}
        >
          <input
            type="text"
            placeholder="Master/Electrician's License Number*"
            value={licenseNumber}
            onChange={(e) => setLicenseNumber(e.target.value)}
            onKeyDown={(e) => handleLicenseNumberChange(e)}
            onBlur={() => handleInputChange("licenseNumber", licenseNumber)}
            required
            maxLength={30}
          />

          {inputStates.licenseNumber && !licenseNumber && (
            <div>
              <p
                className="register-ErrorContainer"
                style={{
                  marginTop: "-20px",
                  opacity: "90%",
                  padding: "5px",
                  marginLeft: "30px",
                }}
              >
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  style={{ marginRight: "1px" }}
                />
                This field is required
              </p>
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
        }}
      >
        <div className="component-navigation">
          {!isFormValid() && !allFieldsFilled && (
            <p
              className=""
              style={{
                marginTop: "10px",
                opacity: "70%",
                padding: "20px",
                color: "red",
              }}
            >
              Please fill in all the required fields.
            </p>
          )}

          {/* {officePhoneErrorMessage && (
            <div>
              <p style={{ color: "red", fontSize: "12px", margin: "4px 0" }}>
                {officePhoneErrorMessage}
              </p>
            </div>
          )} */}

          {/* {licenseNumberErrorMessage && (
            <div>
              <p style={{ color: "red", fontSize: "12px", margin: "4px 0" }}>
                {licenseNumberErrorMessage}
              </p>
            </div>
          )} */}
          <span
            // className={`next-button ${isFormValid() ? '' : 'disabled'}`}
            className="next-button"
            // onClick={isFormValid() ? handleNext : undefined}
            onClick={handleNext}
            style={{
              fontFamily: "futuraheavy",
              position: "relative",
            }}
          >
            Next
          </span>

          <div></div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
