import React, { useEffect, useState } from "react";
import { publicRequest } from "../../requestMethods";
import {
  ThinEditIcon,
  ThinArchive,
  RefreshIcon,
  DownloadIcon,
  ThinAddNote,
} from "../../fontAwesomeComponents/FaIcons";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import AddItemModal from "./AddItemModal";
import "../../css/Tables.css";
import AddItemNoteModal from "./AddItemNoteModal";
import EditItemModal from "./EditItemModal";
import ItemArchiveSuccessModal from "./ValidationModals/ItemArchiveSuccessModal";
import { useNavigate } from "react-router-dom";
import ItemsAttachmentsModal from "./ItemAttachmentsModal";
import ConfirmDeleteItemModal from "./ValidationModals/ConfirmDeleteItemModal";
import ItemAccessValidationModal from "./ValidationModals/ItemAccessValidationModal";
import LocationViewerModal from "../LocationListComponents/LocationViewerModal";

const ItemsTable = ({ searchQuery }) => {
  //states

  const navigate = useNavigate();

  const handleArchiveButtonClick = () => {
    navigate("/items/archive");
  };

  const [selectedItem, setSelectedItem] = useState(null);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [itemNoteList, setItemNoteList] = useState([]);
  const [openLocationViewer, setOpenLocationViewer] = useState(false);
  const [isRefreshHovered, setIsRefreshHovered] = useState(false);

  const token = useSelector((state) => state.user.token);
  const userCompanyId = useSelector((state) => state.selectedCompany);
  const id = useSelector((state) => state.user.id);

  const [itemNoteExpanded, setItemNoteExpanded] = useState({});
  const [itemDescriptionExpanded, setItemDescriptionExpanded] = useState({});

  const [tagDoesNotExistModal, setTagDoesNotExistModal] = useState(false);

  const [openAddItemNoteModal, setOpenAddItemNoteModal] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  //modals
  const [openEditItemModal, setOpenEditItemModal] = useState(false);
  const [openAddItemModal, setOpenAddItemModal] = useState(false);
  const [showArchiveSuccessModal, setShowArchiveSuccessModal] = useState(false);
  const [openItemAttachments, setOpenItemAttachments] = useState(false);
  const [isAddNoteHovered, setIsAddNoteHovered] = useState(false);
  const [isEditTagHovered, setIsEditTagHovered] = useState(false);
  const [isArchiveHovered, setIsArchivehovered] = useState(false);
  const [openConfirmArchiveModal, setOpenConfirmArchiveModal] = useState(false);

  const [hoveredTag, setHoveredTag] = useState(null);

  const [errorMessage, setErrorMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleOpenAddItemModal = () => {
    setOpenAddItemModal(true);
  };

  const handleOpenConfirmArchiveModal = (item) => {
    setSelectedItem(item);
    setOpenConfirmArchiveModal(true);
    setIsArchivehovered(item);
  };

  const handleCloseTagDoesNotExistModal = () => {
    setTagDoesNotExistModal(false);
  };

  const handleOpenItemAttachments = (item) => {
    setSelectedItem(item);
    setOpenItemAttachments(true);
  };

  const handleCloseItemAttachments = () => {
    setOpenItemAttachments(false);
  };

  const handleCloseAddItemModal = () => {
    setOpenAddItemModal(false);
  };

  const handleOpenAddItemNoteModal = (item) => {
    setSelectedItem(item);
    setOpenAddItemNoteModal(true);
  };

  const handleCloseAddItemNoteModal = () => {
    setOpenAddItemNoteModal(false);
  };

  const handleOpenEditItemModal = (item) => {
    setSelectedItem(item);
    setOpenEditItemModal(true);
    setIsEditTagHovered(item);
  };

  const handleCloseEditItemModal = () => {
    setOpenEditItemModal(false);
  };


  const handleOpenLocationViewer = (item) => {
    setSelectedItem(item);
    setOpenLocationViewer(true);
  };

  const handleCloseLocationViewer = () => {
    setOpenLocationViewer(false);
  };

  // const handleOpenArchiveModal = (item) => {
  //   setSelectedItem(item);
  //   ArchiveItem(item);
  //   setShowArchiveSuccessModal(true);
  // };

  const handleCloseArchiveModal = (item) => {
    setSelectedItem(item);
    //   ArchiveItem(item);
    setShowArchiveSuccessModal(false);
  };

  const handleCloseConfirmArchiveModal = () => {
    setOpenConfirmArchiveModal(false);
    setIsArchivehovered(null);
  };

  ///////////////////////

  const handleSelectAllChange = () => {
    setSelectAllChecked(!selectAllChecked);
    setSelectedRows(selectAllChecked ? [] : [...Array(itemList.length).keys()]);
  };

  const handleRowSelection = (index) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(index)
        ? prevSelectedRows.filter((item) => item !== index)
        : [...prevSelectedRows, index]
    );
  };

  const formatAndConvertDate = (dateString) => {
    if (!dateString) return "";
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      timeZone: userTimezone,
    };
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString(undefined, options);
    const [month, day, year] = formattedDate.split("/");
    return `${day}/${month}/${year}`;
  };

  const formatDate = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${day}/${month}/${year}`;
  };

  const formatTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
  };

  //getting items

  const queryParams = {
    LoggedUserId: id,
    LoggedUserCompanyId: userCompanyId,
  };

  const fetchData = async (url) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const queryString = new URLSearchParams(queryParams).toString();
      const fullUrl = url + (queryString ? `?${queryString}` : "");

      const response = await publicRequest({
        method: "GET",
        url: fullUrl,
        headers: headers,
      });

      return response.data;
    } catch (error) {
      console.log("error fetching items", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchItemData = async () => {
      try {
        setIsLoading(true);
        const getItemResponse = await fetchData("/item/list");
        if (getItemResponse) {
          // Convert date strings to Date objects
          getItemResponse.forEach((item) => {
            item.dateCreatedUtc = new Date(item.dateCreatedUtc);
          });

          // Sort itemsList by date in descending order
          getItemResponse.sort((a, b) => b.dateCreatedUtc - a.dateCreatedUtc);

          setItemList(getItemResponse);
        }

        getItemResponse.forEach((item) => {
          const itemNoteResponse = item.itemNotes;

          if (itemNoteResponse.length > 0) {
            const latestNote = itemNoteResponse[0];
            const customItemNote = `${
              latestNote.note}`;

            item.note = customItemNote;

            item.note1 = `${latestNote.latitude}`;
          } else {
            item.note = ""; // No notes, set to an empty string
          }

          console.log("Itemnotedata", itemNoteResponse);
          //   setItemData(itemNoteResponse);
          return itemNoteResponse;
        });

        // const itemNoteDataResponse = await Promise.all//(itemNotePromise);
        //    setItemNoteList(itemNoteDataResponse);
        //  console.log("this is itemnote data", //itemNoteDataResponse);
      } catch (error) {
        console.error("error fetching data", error);
      }
      finally {
        setIsLoading(false);
      }
    };

    fetchItemData();
  }, []);

  
  const fetchItemData = async () => {
    try {
      setIsLoading(true);
      const getItemResponse = await fetchData("/item/list");
      if (getItemResponse) {
        // Convert date strings to Date objects
        getItemResponse.forEach((item) => {
          item.dateCreatedUtc = new Date(item.dateCreatedUtc);
        });

        // Sort itemsList by date in descending order
        getItemResponse.sort((a, b) => b.dateCreatedUtc - a.dateCreatedUtc);

        setItemList(getItemResponse);
      }

      getItemResponse.forEach((item) => {
        const itemNoteResponse = item.itemNotes;

        if (itemNoteResponse.length > 0) {
          const latestNote = itemNoteResponse[0];
          const customItemNote = `${
            latestNote.note}`;

          item.note = customItemNote;

          item.note1 = `${latestNote.latitude}`;
        } else {
          item.note = ""; // No notes, set to an empty string
        }

        console.log("Itemnotedata", itemNoteResponse);
        //   setItemData(itemNoteResponse);
        return itemNoteResponse;
      });

      // const itemNoteDataResponse = await Promise.all//(itemNotePromise);
      //    setItemNoteList(itemNoteDataResponse);
      //  console.log("this is itemnote data", //itemNoteDataResponse);
    } catch (error) {
      console.error("error fetching data", error);
    }
    finally {
      setIsLoading(false);
    }
  };

  const refreshItemList = async () => {
    try {
      const getItemResponse = await fetchData("/item/list");
      if (getItemResponse) {
        // Convert date strings to Date objects
        getItemResponse.forEach((item) => {
          item.dateCreatedUtc = new Date(item.dateCreatedUtc);
        });

        // Sort itemsList by date in descending order
        getItemResponse.sort((a, b) => b.dateCreatedUtc - a.dateCreatedUtc);

        setItemList(getItemResponse);
      }

      getItemResponse.forEach((item) => {
        const itemNoteResponse = item.itemNotes;

        if (itemNoteResponse.length > 0) {
          const latestNote = itemNoteResponse[0];
          const customItemNote = `${
            latestNote.note
          }`;

          item.note = customItemNote;

          item.note1 = `${latestNote.latitude}`;
        } else {
          item.note = ""; // No notes, set to an empty string
        }

        console.log("Itemnotedata", itemNoteResponse);
        //   setItemData(itemNoteResponse);
        return itemNoteResponse;
      });

      // const itemNoteDataResponse = await Promise.all//(itemNotePromise);
      //    setItemNoteList(itemNoteDataResponse);
      //  console.log("this is itemnote data", //itemNoteDataResponse);
    } catch (error) {
      console.error("error fetching data", error);
    }
  };

  // const ArchiveItem = async (item) => {
  //   const ArchiveItemParams = {
  //     LoggedUserId: id,
  //     LoggedUserCompanyId: userCompanyId,
  //   };

  //   try {
  //     const headers = {
  //       Authorization: `Bearer ${token}`,
  //     };

  //     const requestConfigForArchiveItem = {
  //       method: "DELETE",
  //       headers: headers,
  //       params: ArchiveItemParams,
  //     };

  //     const archiveItemLink = await publicRequest(
  //       `/item/${item.id}/archive`,
  //       requestConfigForArchiveItem
  //     );

  //     if (archiveItemLink) {
  //       console.log("item removed");
  //       setShowArchiveSuccessModal(true);
  //     }
  //     refreshItemList();
  //     //modal
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  //pages

  const getTagBySerialNumber = async () => {
    console.log(searchQuery);
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const requestConfigForGettingTagBySerial = {
        method: "GET",
        headers: headers,
        params: queryParams,
      };

      const searchTag = await publicRequest(
        `/item/serialnumber/${searchQuery}`,
        requestConfigForGettingTagBySerial
      );

      //const searchTag = await publicRequest(`/tag/serialnumber/${searchQuery}`,queryParams);
      console.log(searchTag);
      if (searchTag) {
        console.log("this is searchtag", searchTag);
      }
    } catch (error) {
      console.log(isError);
      //  setIsError(true);
      console.error(error.response.status);
      if (error.response.status === 404) {
        setErrorMessage("This ITEM does not exist in the database.");
        setIsError(true);
        console.error("tag does not exist", error);
        setTagDoesNotExistModal(true);
      } else if (
        error.response.data.errors &&
        "Tag.InvalidTagPermission" in error.response.data.errors &&
        error.response.status === 400 &&
        filteredItems.length == 0
      ) {
        setIsError(true);
        setErrorMessage(
          "Your User access is not authorized to view this ITEM in the database, because it belongs to a different company."
        );
        setTagDoesNotExistModal(true);

        console.error(error);
      } else {
        // Handle other errors or provide a generic error message.
        console.error("Unhandled error:", error);
      }
    }
  };

  useEffect(() => {
    const filteredTags = itemList.filter((tag) => {
      // Customize the conditions for filtering based on your tag structure

      return (
        tag.serialNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tag.siteName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tag.createdBy.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tag.identifiersNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tag.locationName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tag.tagItemTypeName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tag.dateAppliedUtc.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tag.description.toLowerCase().includes(searchQuery.toLowerCase()) 
     

      );
    });

    setFilteredItems(filteredTags);
    setSelectAllChecked(false);
    setCurrentPage(1);

    getTagBySerialNumber();
  }, [searchQuery, itemList]);

  const [pagination, setPagination] = useState(1);
  const itemsPerPageOptions = [10, 20, 30];
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const renderPageButtons = () => {
    const pageButtons = [];
    const maxPagesToShow = 3; // Set the maximum number of pages to show
  
    const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
  
    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          onClick={() => handlePageClick(i)}
          className={currentPage === i ? "active-page" : ""}
          style={{
            color: "black",
            borderRadius: "5px",
            border: "none",
            marginLeft: "10px",
            backgroundColor: currentPage === i ? "#F6C90E" : "#3A475033",
            width: "30px",
            fontFamily: "futuramedium",
            cursor: "pointer",
          }}
        >
          {i}
        </button>
      );
    }
  
    return pageButtons;
  };

  const totalPages = Math.ceil(itemList.length / itemsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  
  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 3, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 3, 1));
  };


  return (
    <div  className="TablePageMainDiv"
      style={{
        marginLeft: "150px",
        marginRight: "150px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <div
          className="approvalTitle"
          style={{
            fontFamily: "futuramedium",
            color: "#141212",
            fontSize: "30px",
          }}
        >
          Items
        </div>
        <div
          className="save-button"
          style={{
            fontFamily: "futuramedium",
            color: "#141212",
            fontSize: "12px",
            height: "45px",
            fontWeight: "bold",
          }}
          onClick={handleOpenAddItemModal}
        >
          + Add Item
        </div>
        <Modal
          size="xl"
          show={openAddItemModal}
          //  onHide={handleCloseAddItemModal}
          centered
        >
          <Modal.Body>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={handleCloseAddItemModal}
                style={{
                  background: "none",
                  border: "none",
                  fontSize: "35px",
                  position: "absolute",
                  zIndex: "9999",
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <AddItemModal />
          </Modal.Body>
        </Modal>
      </div>

      <div
        className="minibar"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            fontFamily: "futuramedium",
          }}
        >
          <p>Show</p>
          <div
            style={{
              backgroundColor: "#e5e5e5",
              borderRadius: "10px",
              padding: "4px",
              cursor: "pointer",
              height: "30px",
              width: "50px",
              marginLeft: "1px",
              marginRight: "5px",
            }}
          >
            <select
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
              style={{
                border: "none",
                background: "none",
                cursor: "pointer",
              }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
            </select>
          </div>
          <p>items</p>
        </div>

        <div style={{ display: "flex" }}>
          {/* <p
            style={{
              color: "#3A4750",
              fontSize: "16px",
              fontFamily: "futurabook",
            }}
          >
            last refresh was 25mins ago
          </p> */}
           <div
            className="icon"
            onMouseEnter={() => setIsRefreshHovered(true)}
            onMouseLeave={() => setIsRefreshHovered(null)}
       
            style={{
              backgroundColor: "#3A475033",
              borderRadius: "50%",
              color: "#3A4750",
              width: "32px",
              height: "32px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
              cursor: "pointer",
              position:"relative"
            }}
            onClick={fetchItemData}
          >
            <RefreshIcon />
            
            {isRefreshHovered && (
                              <p
                                style={{
                                  position: "absolute",
                                  top: "100%",
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                  background: "#ffffff",
                                  padding: "2px",
                                  borderRadius: "10px",
                                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                  zIndex: 999,
                                 
                                  fontSize:"12px",
                                  width:"100px",
                                  textAlign:"center"
                                }}
                              
                              >
                                  Refresh the page
                              </p>
                            )}

          </div>
          <div
            style={{
              backgroundColor: "#3A475033",
              borderRadius: "10%",
              color: "#3A4750",
              width: "100px",
              height: "32px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
              fontFamily: "futurabook",
            }}
          >
            <button
              style={{
                border: "none",
                background: "none",
              }}
              onClick={handleArchiveButtonClick}
            >
              Show Archive
            </button>
          </div>
          {/* <div
            className="icon"
            style={{
              backgroundColor: "#3A475033",
              borderRadius: "20%", // Use 50% for a circle
              color: "#3A4750",
              width: "32px",
              height: "32px",
              display: "flex", // Use flex display
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
            }}
          >
            <DownloadIcon />
          </div> */}
        </div>
      </div>

      {/* ------------------------------------------------------ */}

      {isError && (
        <Modal centered show={tagDoesNotExistModal}>
          <Modal.Body>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={handleCloseTagDoesNotExistModal}
                style={{
                  background: "none",
                  border: "none",
                  fontSize: "35px",
                  position: "absolute",
                  zIndex: 999,
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <ItemAccessValidationModal
              onClose={handleCloseTagDoesNotExistModal}
              errorMessage={errorMessage}
            />
          </Modal.Body>
        </Modal>
      )}

 {isLoading ? (
        <p>Loading data...</p>
      ) : (
        <>
      <div className="approvalTable">
        <table className="custom-table">
          <div className="tHead">
            <thead>
              <tr>
                <th>
                  <div className="checkbox-dropdown">
                    <input
                      type="checkbox"
                      id="selectAll"
                      checked={selectAllChecked}
                      onChange={handleSelectAllChange}
                    />
                    <div className="dropdown">
                      <span className="dropdown-arrow">&#9662;</span>
                      <div className="dropdown-content">
                        <div onClick={() => handleSelectAllChange(true)}>
                          Select All
                        </div>
                        <div onClick={() => handleSelectAllChange(false)}>
                          Deselect All
                        </div>
                      </div>
                    </div>
                  </div>
                </th>
                <th
                  className=""
                  style={{
                    width: "200px",
                  }}
                >
                 Applied By
                </th>
                <th className="" style={{ width: "250px" }}>
                      Applied Date
                    </th>
             
                <th
                  className=""
                  style={{
                    width: "250px",
                  }}
                >
                 Item Number
                </th>
              
                <th className="" style={{ width: "250px" }}>
              Identifier Number
                </th>
                <th
                  className=""
                  style={{
                    width: "250px",
                  }}
                >
                  Identifier Type
                </th>
                <th
                  className=""
                  style={{
                    width: "270px",
                  }}
                >
                  Items Description
                </th>
                <th
                  className=""
                  style={{
                    width: "200px",
                  }}
                >
                  Site Name
                </th>
                <th
                  className=""
                  style={{
                    width: "200px",
                  }}
                >
                  Location Name
                </th>
                <th
                  className="tableHeaderBig"
                  style={{
                    width: "250px",
                  }}
                >
                  Item Note
                </th>
                <th
                  className=""
                  style={{
                    width: "150px",
                  }}
                >
                  Attachments
                </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="tBody">
              {currentItems.map((item, index) => (
                <tr
                  key={index}
                  className={selectedRows.includes(index) ? "selected" : ""}
                >
                  <td>
                    <input
                      type="checkbox"
                      id={`companyCheckbox ${index}`}
                      checked={selectedRows.includes(index)}
                      onChange={() => handleRowSelection(index)}
                    />
                    <label htmlFor={`companyCheckbox${index}`}></label>
                  </td>

                  <td>{item.createdBy}</td>
                  <td>{formatAndConvertDate(item.dateCreatedUtc)}</td> 
                  <td>{item.serialNumber}</td>
                  <td>{item.identifiersNumber}</td>
                  <td>{item.tagItemTypeName}</td>
                 
   
                  <td className="descriptionNoteColumn">
                    {item.description.length > 40 &&
                    !itemDescriptionExpanded[item.id] ? (
                      <>
                        {item.description.slice(0, 40)}{" "}
                        <span
                          onClick={() =>
                            setItemDescriptionExpanded({
                              ...itemDescriptionExpanded,
                              [item.id]: true,
                            })
                          }
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          ...
                        </span>
                      </>
                    ) : (
                      <>
                        {item.description}
                        {item.description.length > 40 && (
                          <span
                            onClick={() =>
                              setItemDescriptionExpanded({
                                ...itemDescriptionExpanded,
                                [item.id]: false,
                              })
                            }
                            style={{
                              cursor: "pointer",
                              color: "blue",
                            }}
                          >
                            &nbsp; See Less
                          </span>
                        )}
                      </>
                    )}
                  </td>
                  <td>{item.siteName}</td>
                  <td
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => handleOpenLocationViewer(item)}
                      >
                        {item.locationName}
                      </td>

                      {selectedItem === item && ( // Check if this row's modal should be open
                        <Modal size="xl" centered show={openLocationViewer}>
                          <Modal.Body>
                            <div className="d-flex justify-content-end">
                              <button
                                type="button"
                                className="close"
                                aria-label="Close"
                                onClick={handleCloseLocationViewer}
                                style={{
                                  background: "none",
                                  border: "none",
                                  fontSize: "35px",
                                  position: "absolute",
                                  zIndex: "999999",
                                }}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <LocationViewerModal item={selectedItem} />
                          </Modal.Body>
                        </Modal>
                      )}
                  <td className="descriptionNoteColumn">
                    {item.note &&
                    item.note.length > 40 &&
                    !itemNoteExpanded[item.id] ? (
                      <>
                        {item.note.slice(0, 40)}{" "}
                        <span
                          onClick={() =>
                            setItemNoteExpanded({
                              ...itemNoteExpanded,
                              [item.id]: true,
                            })
                          }
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          ...
                        </span>
                      </>
                    ) : (
                      <>
                        {item.note ? item.note : "-"}
                        {item.note && item.note.length > 40 && (
                          <span
                            onClick={() =>
                              setItemNoteExpanded({
                                ...itemNoteExpanded,
                                [item.id]: false,
                              })
                            }
                            style={{
                              cursor: "pointer",
                              color: "blue",
                            }}
                          >
                            &nbsp; See Less
                          </span>
                        )}
                      </>
                    )}
                  </td>
                  <td
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => handleOpenItemAttachments(item)}
                  >
                    view
                  </td>
                  {selectedItem === item && ( // Check if this row's modal should be open
                    <Modal
                      size="lg"
                      centered
                      show={openItemAttachments}
                      //  onHide={handleCloseItemAttachments}
                    >
                      <Modal.Body>
                        <div className="d-flex justify-content-end">
                          <button
                            type="button"
                            className="close"
                            aria-label="Close"
                            onClick={handleCloseItemAttachments}
                            style={{
                              background: "none",
                              border: "none",
                              fontSize: "35px",
                              position: "absolute",
                              zIndex: 999,
                            }}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <ItemsAttachmentsModal item={selectedItem} />
                      </Modal.Body>
                    </Modal>
                  )}
                  <td>
                    <div className="iconBoxes">
                      <div
                        className="iconBox"
                        style={{
                          cursor: "pointer",
                          position: "relative",
                        }}
                        onClick={() => handleOpenAddItemNoteModal(item)}
                        // onClick={() => handleOpenEditTagModal(tag)}
                        onMouseEnter={() => setIsAddNoteHovered(item)}
                        onMouseLeave={() => setIsAddNoteHovered(null)}
                      >
                        <ThinAddNote height="15px" width="15px" fill="#" />
                        {isAddNoteHovered === item && (
                          <div
                            style={{
                              position: "absolute",
                              top: "100%",
                              left: "50%",
                              transform: "translateX(-50%)",
                              background: "#ffffff",
                              padding: "2px",
                              borderRadius: "10px",
                              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                              zIndex: 999,
                              width: "70px",
                            }}
                          >
                            Add Note
                          </div>
                        )}
                      </div>
                      {selectedItem === item && (
                        <Modal
                          size="xl"
                          centered
                          show={openAddItemNoteModal}
                          //  onHide={handleCloseAddItemNoteModal}
                        >
                          <Modal.Body>
                            <div className="d-flex justify-content-end">
                              <button
                                type="button"
                                className="close"
                                aria-label="Close"
                                onClick={handleCloseAddItemNoteModal}
                                style={{
                                  background: "none",
                                  border: "none",
                                  fontSize: "35px",
                                  position: "absolute",
                                  zIndex: "999",
                                }}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <AddItemNoteModal item={selectedItem} />
                          </Modal.Body>
                        </Modal>
                      )}

                      <div
                        className="iconBox"
                        onMouseEnter={() => setIsEditTagHovered(item)}
                        onMouseLeave={() => setIsEditTagHovered(null)}
                        style={{
                          cursor: "pointer",
                          position: "relative",
                        }}
                        onClick={() => handleOpenEditItemModal(item)}
                      >
                        <ThinEditIcon height="15px" width="15px" fill="#" />
                        {isEditTagHovered === item && (
                          <div
                            style={{
                              position: "absolute",
                              top: "100%",
                              left: "50%",
                              transform: "translateX(-50%)",
                              background: "#ffffff",
                              padding: "2px",
                              borderRadius: "10px",
                              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                              zIndex: 999,
                              width: "70px",
                            }}
                          >
                            Edit Item
                          </div>
                        )}
                      </div>
                      {selectedItem === item && ( // Check if this row's modal should be open
                        <Modal
                          centered
                          size="xl"
                          show={openEditItemModal}
                          //   onHide={handleCloseEditItemModal}
                        >
                          <Modal.Body>
                            <div className="d-flex justify-content-end">
                              <button
                                type="button"
                                className="close"
                                aria-label="Close"
                                onClick={handleCloseEditItemModal}
                                style={{
                                  background: "none",
                                  border: "none",
                                  fontSize: "35px",
                                  position: "absolute",
                                  zIndex: 999,
                                }}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <EditItemModal item={selectedItem} />
                          </Modal.Body>
                        </Modal>
                      )}

                      <div
                        className="iconBox"
                        onMouseEnter={() => setIsArchivehovered(item)}
                        onMouseLeave={() => setIsArchivehovered(null)}
                        style={{
                          cursor: "pointer",
                          position: "relative",
                        }}
                        onClick={() => handleOpenConfirmArchiveModal(item)}
                      >
                        <ThinArchive height="15px" width="15px" fill="#" />
                        {isArchiveHovered === item && (
                          <div
                            style={{
                              position: "absolute",
                              top: "100%",
                              left: "50%",
                              transform: "translateX(-50%)",
                              background: "#ffffff",
                              padding: "2px",
                              borderRadius: "10px",
                              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                              zIndex: 999,
                              width: "85px",
                            }}
                          >
                            Archive Item
                          </div>
                        )}
                      </div>
                      {selectedItem === item && (
                        <Modal
                          centered
                          show={showArchiveSuccessModal}
                          onHide={() => setShowArchiveSuccessModal(false)}
                        >
                          <ItemArchiveSuccessModal
                            item={selectedItem}
                            onClose={handleCloseArchiveModal}
                          />
                        </Modal>
                      )}

                      {selectedItem === item && (
                        <Modal
                          centered
                          show={openConfirmArchiveModal}
                          //      onHide={() => setOpenConfirmArchiveModal(false)}
                        >
                          <Modal.Body>
                            <div className="d-flex justify-content-end">
                              <button
                                type="button"
                                className="close"
                                aria-label="Close"
                                onClick={handleCloseConfirmArchiveModal}
                                style={{
                                  background: "none",
                                  border: "none",
                                  fontSize: "35px",
                                  position: "absolute",
                                }}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <ConfirmDeleteItemModal
                              item={selectedItem}
                              onClose={handleCloseConfirmArchiveModal}
                              refreshItemList={refreshItemList}
                            />
                          </Modal.Body>
                        </Modal>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </div>
        </table>
      </div>

    
      <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <div>
              Showing {Math.min(indexOfLastItem, filteredItems.length)} of{" "}
              {filteredItems.length} items
            </div>
            <div
              style={{
                marginRight: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <button
                onClick={handlePreviousPage}
                style={{
                  color: "black",
                  borderRadius: "5px",
                  border: "none",
                  marginLeft: "10px",
                  backgroundColor: "#3A475033",
                  width: "80px",
                  fontFamily: "futuramedium",
                  cursor: "pointer",
                }}
              >
                Previous
              </button>

              {renderPageButtons()}

              <button
                onClick={handleNextPage}
                style={{
                  color: "black",
                  borderRadius: "5px",
                  border: "none",
                  marginLeft: "10px",
                  backgroundColor: "#3A475033",
                  width: "60px",
                  fontFamily: "futuramedium",
                  cursor: "pointer",
                }}
              >
                Next
              </button>
            </div>
          </div>
      </>
      )}
    </div>
  );
};

export default ItemsTable;
