import React from "react";
import {
  Telephone,
  ThinLocation,
  ThinEnvelope,
  QrCodeIcon,
} from "../../fontAwesomeComponents/FaIcons";

const DistributorDetails = () => {
  return (
    <div className="distributorDetailsMainDiv"
      style={{
        backgroundColor: "#303841",

        display: "flex",
      }}
    >
      <div className="distributorsHeaderDiv"
        style={{
          flex: 3,
          marginLeft: "150px", // Adjust the margin as needed
          marginRight: "25px", // Adjust the margin as needed
        }}
      >
        <h3 className="headerTextdistributor"
          style={{
            fontFamily: "futura",
            color: "#EEEEEE",
            fontSize: "50px",
            paddingTop: "50px",
          }}
        >
         Industrial Protective Products (WA)
        </h3>
        <a
         className="DistributorP"
         href="https://www.ippwa.com.au/"
         target="_blank"
         rel="noopener noreferrer"
          style={{
            fontFamily: "futurabook",
            color: "#EEEEEE",
            maxWidth: "700px",
            fontSize: "25px",
            paddingTop: "10px",
            textDecoration: "none",
          }}
        >
      ippwa.com.au
        </a>
        <div
          className="contactInfo"
          style={{
            display: "flex",
            gap: "60px",
            paddingTop: "30px",
          }}
        >
          <div className="infoTitle">
            <span
              className="contact-icon"
              style={{
                backgroundColor: "#F6C90E33",
                border: "none",
                marginRight: "15px",
              }}
            >
              <Telephone width="40px" height="30px" />
            </span>
            <div>
              <h4
                className="infoName"
                style={{
                  color: "#EEEEEE",
                }}
              >
                Telephone Number
              </h4>
              <p
                className="infoSubtitle"
                style={{
                  fontSize: "17px",
                  color: "#EEEEEE",
                }}
              >
            (08) 9330 6355
              </p>
            </div>
          </div>
          <div className="infoTitle">
            <span
              className="contact-icon"
              style={{
                backgroundColor: "#F6C90E33",
                border: "none",
                marginRight: "15px",
              }}
            >
              <ThinLocation width="40px" height="30px" />
            </span>
            <div>
              <h4
                className="infoName"
                style={{
                  color: "#EEEEEE",
                }}
              >
                Location
              </h4>
              <p
                className="infoSubtitle"
                style={{
                  fontSize: "17px",
                  color: "#EEEEEE",
                }}
              >
               39 Norma Rd, Myaree WA 6154
              </p>
            </div>
          </div>
          <div className="infoTitle">
            <span
              className="contact-icon"
              style={{
                backgroundColor: "#F6C90E33",
                border: "none",
                marginRight: "15px",
              }}
            >
              <ThinEnvelope width="40px" height="30px" />
            </span>
            <div>
              <h4
                className="infoName"
                style={{
                  color: "#EEEEEE",
                }}
              >
                Email Address
              </h4>
              <p
                className="infoSubtitle"
                style={{
                  fontSize: "17px",
                  color: "#EEEEEE",
                }}
              >
               sales@ippwa.com.au
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="distributorQrContainer"
        style={{
          flex: 1,
          height: "200px",
          width: "200px",
          alignSelf:"center"
          
        }}
      >
       
      </div>
    </div>
  );
};

export default DistributorDetails;
