import React from "react";
import { useNavigate } from "react-router-dom";
import { CircleExclamationMark } from "../../../fontAwesomeComponents/FaIcons";
const TagAccessValidationModal = ({ onClose, errorMessage }) => {
  const navigate = useNavigate();
  console.log("this is error message tag accessvalidation", errorMessage);

  const handleBackToProfile = () => {
    onClose();
  };

  return (
    <div
      style={{
        height: "37vh",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div
          style={{
            marginBottom: "20px",
            backgroundColor: "#EFC8C8",
            padding: "10px",
            borderRadius: "16px",
          }}
        >
          <CircleExclamationMark height="50px" width="50px" color="#DC3545" />
        </div>

        <h2
          style={{
            fontFamily: "futuraheavy",
            fontSize: "25px",
            marginBottom: "10px",
          }}
        >
          Oops!
        </h2>

        <p
          style={{
            fontFamily: "futurabook",
            fontSize: "17px",
            ///  marginBottom: "20px",
            textAlign: "center",
            padding: "0px 30px 0px 0px",
          }}
        >
          {errorMessage && errorMessage}
        </p>

        <button
          className="back-to-login-button"
          onClick={handleBackToProfile}
          style={{
            backgroundColor: "#3A475033",
            width: "80%",
            fontFamily: "futuramedium",
            color: "#3A4750",
            height: "50px",
            border: "none",
            borderRadius: "10px",
            fontWeight: "bold",
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default TagAccessValidationModal;
