import React from 'react'
import Navbar from '../components/NavBar'
import Login from '../components/Login'

const LoginPage = () => {
  return (
    <div
      style={{background:"#eeeeee", height:"100vh"}}
    >
        <Navbar/>
        <div
          style={{maxWidth:"100%", alignItems:"center", display:"flex", justifyContent:"center", marginLeft:"25%", marginRight:"25%", marginTop:"4%"}}
        >
        <Login/>
        </div>
        {/*  */}
    </div>
  )
}

export default LoginPage;