import React, { useEffect, useState } from "react";
import { DownloadIcon, RefreshIcon } from "../../fontAwesomeComponents/FaIcons";
import { useSelector } from "react-redux";
import { publicRequest } from "../../requestMethods";
import { FilterIcon } from "../../fontAwesomeComponents/FaIcons";
import { Dropdown } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone"; // Import Moment and Moment Timezone

const UserLogs = ({ searchQuery }) => {
  const MAX_LENGTH_TO_DISPLAY = 30;

  const [currentTimeZone, setCurrentTimeZone] = useState(
    moment.tz.guess() // Initialize current timezone using Moment Timezone
  );

  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [auditList, setAuditList] = useState([]);
  const [filteredAudit, setFilteredAudit] = useState([]);

  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedCompanyId, setSelectedCompanyId] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const initialDate = new Date();
  initialDate.setHours(0, 0, 0, 0);

  const [selectedStartDate, setSelectedStartDate] = useState(initialDate);
  const [selectedEndDate, setSelectedEndDate] = useState(initialDate);
  const [isPrintPreviewVisible, setIsPrintPreviewVisible] = useState(false);
  const [isRefreshHovered, setIsRefreshHovered] = useState(false);

  const [expandedRows, setExpandedRows] = useState([]);

  const [categories, setCategories] = useState([]);

  const [selectedFileFormat, setSelectedFileFormat] = useState("");

  const [fileFormats, setFileFormats] = useState([]);

  // const [selectedCategory, setSelectedCategory] = useState([])

  const handleSeeMoreClick = (index) => {
    setExpandedRows((prevExpandedRows) => {
      if (prevExpandedRows.includes(index)) {
        // If already expanded, collapse it
        return prevExpandedRows.filter((item) => item !== index);
      } else {
        // If not expanded, expand it
        return [...prevExpandedRows, index];
      }
    });
  };

  const truncateString = (str) => {
    // Truncate the string to a maximum length
    return str.length > MAX_LENGTH_TO_DISPLAY
      ? str.substring(0, MAX_LENGTH_TO_DISPLAY) + "..."
      : str;
  };

  //redux states

  const token = useSelector((state) => state.user.token);
  const userCompanyId = useSelector((state) => state.selectedCompany);
  const id = useSelector((state) => state.user.id);
  const userRole = useSelector((state) => state.selectedCompanyRoleId);
  const selectedCompanyName = useSelector((state) => state.selectedCompanyName);
  const CompanyId = useSelector((state) => state.selectedCompanyId);

  // const [currentTimeZone, setCurrentTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

  useEffect(() => {
    setCurrentTimeZone(moment.tz.guess()); // Update current timezone using Moment Timezone on component mount
  }, []);

  console.log(currentTimeZone);

  const queryParams = {
    LoggedUserId: id,
    LoggedUserCompanyId: userCompanyId,
    StartDateUtc: selectedStartDate
      ? selectedStartDate.toLocaleString("en-US", { timeZone: currentTimeZone })
      : null,
    EndDateUtc: selectedEndDate
      ? selectedEndDate.toLocaleString("en-US", { timeZone: currentTimeZone })
      : null,
    TimeZone: currentTimeZone,
    CategoryId: selectedCategoryId,
    CompanyId: userRole === 2 ? CompanyId : selectedCompanyId,
  };

  console.log(queryParams);

  const fetchData = async (url) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const queryString = new URLSearchParams(queryParams).toString();
      const fullUrl = url + (queryString ? `?${queryString}` : "");

      const response = await publicRequest({
        method: "GET",
        url: fullUrl,
        headers: headers,
      });

      return response.data;
    } catch (error) {
      console.error("error fetching data", error);
      return null;
    }
  };

  //getting history

  const refreshLogs = async () => {
    // try {
    //   const getHistoryResponse = await fetchData(
    //     "admin/auditlog",
    //     queryParams
    //   );

    //   if (getHistoryResponse) {
    //     setAuditList(getHistoryResponse);

    //     console.log(auditList);
    //   }
    // } catch (error) {
    //   console.error(error);
    // }

    try {
      setIsLoading(true); // Set loading state to true
      const getHistoryResponse = await fetchData("admin/userlog", queryParams);

      if (getHistoryResponse) {
        setAuditList(getHistoryResponse);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false); // Set loading state to false regardless of success or failure
    }
  };

  useEffect(() => {
    const fetchHistory = async () => {
      // try {
      //   const getHistoryResponse = await fetchData(
      //     "admin/auditlog",
      //     queryParams
      //   );

      //   if (getHistoryResponse) {
      //     setAuditList(getHistoryResponse);

      //     console.log(auditList);
      //   }
      // } catch (error) {
      //   console.error(error);
      // }

      try {
        setIsLoading(true); // Set loading state to true
        const getHistoryResponse = await fetchData(
          "admin/userlog",
          queryParams
        );

        if (getHistoryResponse) {
          setAuditList(getHistoryResponse);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false); // Set loading state to false regardless of success or failure
      }
    };

    fetchHistory();
  }, [
    selectedStartDate,
    selectedEndDate,
    selectedCompanyId,
    selectedCategoryId,
  ]);

  //   const fetchCompanies = async () => {
  //     try {
  //       const getCompanies = await fetchData("lookup/companies");

  //       if (getCompanies) {
  //         setCompanyList(getCompanies);
  //         // Select the first company by default
  //         //    setSelectedCompany(getCompanies.name);
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   const fetchCategories = async () => {
  //     try {
  //       const getCategories = await fetchData("lookup/categories");

  //       if (getCategories) {
  //         setCategories(getCategories);

  //         //   setSelectedCategory(getCategories.name);
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  const handleCompanySelect = (company) => {
    setSelectedCompany(company.name);
    setSelectedCompanyId(company.id);
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category.name);
    setSelectedCategoryId(category.id);
  };

  const handleStartDateChange = (date) => {
    // Set time to midnight
    date.setHours(0, 0, 0, 0);
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    // Set time to midnight
    date.setHours(0, 0, 0, 0);
    setSelectedEndDate(date);
  };

  const handleSelectAllChange = () => {
    setSelectAllChecked(!selectAllChecked);
    setSelectedRows(
      selectAllChecked ? [] : [...Array(auditList.length).keys()]
    );
  };

  const handleRowSelection = (index) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(index)
        ? prevSelectedRows.filter((item) => item !== index)
        : [...prevSelectedRows, index]
    );
  };

  useEffect(() => {
    const filteredUser = auditList.filter((user) => {
      // Customize the conditions for filtering based on your tag structure

      return (
        user.activity.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.userFullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.userName.toLowerCase().includes(searchQuery.toLowerCase())

        //user.addedB
      );
    });

    setFilteredAudit(filteredUser);
    setSelectAllChecked(false);
    setCurrentPage(1);

    //   getTagBySerialNumber();
  }, [searchQuery, auditList]);

  //pages
  const [pagination, setPagination] = useState(1);
  const itemsPerPageOptions = [10, 20, 30, 50, 100];
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredAudit.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 3, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 3, 1));
  };

  const renderPageButtons = () => {
    // Sort the companyData array based on the created date in descending order
    // const sortedCompanyData = locationData.sort(
    //   (a, b) =>
    //     new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    // );

    const pageButtons = [];
    const maxPagesToShow = 3; // Set the maximum number of pages to show

    const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          onClick={() => handlePageClick(i)}
          className={currentPage === i ? "active-page" : ""}
          style={{
            color: "black",
            borderRadius: "5px",
            border: "none",
            marginLeft: "10px",
            backgroundColor: currentPage === i ? "#F6C90E" : "#3A475033",
            width: "30px",
            fontFamily: "futuramedium",
            cursor: "pointer",
          }}
        >
          {i}
        </button>
      );
    }

    return pageButtons;
  };

  //   const handlePrintDoc = () => {
  //     const content = filteredAudit.map((tag) => ({
  //       Company: tag.company,
  //       "Serial Number": tag.serialNumber,
  //       Category: tag.history[0]?.category || "-",
  //       "Added By": tag.history[0] ? tag.history[0].logs[0].fullName : "-",
  //       "Original Data": tag.history[0]?.logs[0]?.originalData || "-",
  //       "New Data": tag.history[0]?.logs[0]?.newData || "-",
  //       "Date Created": tag.history[0]
  //         ? new Date(tag.history[0].logs[0].dateCreatedUtc).toLocaleDateString()
  //         : "-",
  //       "-----------------------------------": "",
  //     }));

  //     const docContent = content
  //       .map(
  //         (item) =>
  //           `Company: ${item.Company}
  //   Serial Number: ${item["Serial Number"]}
  //   Category: ${item.Category}
  //   Added By: ${item["Added By"]}
  //   Original Data: ${item["Original Data"]}
  //   New Data: ${item["New Data"]}
  //   Date Created: ${item["Date Created"]}
  //   -----------------------------------`
  //       )
  //       .join("\n");

  //     const file = new Blob([docContent], { type: "text/plain;charset=utf-8" });
  //     saveAs(file, "report.doc");
  //   };

  //   pdfMake.vfs = pdfFonts.pdfMake.vfs;

  //   const handlePrintXls = async () => {
  //     const workbook = new ExcelJS.Workbook();
  //     const worksheet = workbook.addWorksheet("Sheet 1");

  //     // Add headers
  //     const headers = [
  //       "Company",
  //       "Serial Number",
  //       "Category",
  //       "Added By",
  //       "Original Data",
  //       "New Data",
  //       "Date Created",
  //     ];
  //     worksheet.addRow(headers);

  //     // Add data
  //     filteredAudit.forEach((item) => {
  //       const row = [
  //         item.company,
  //         item.serialNumber,
  //         item.history[0]?.category || "-",
  //         item.history[0] ? item.history[0].logs[0].fullName : "-",
  //         item.history[0]?.logs[0]?.originalData || "-",
  //         item.history[0]?.logs[0]?.newData || "-",
  //         item.history[0]
  //           ? new Date(
  //               item.history[0].logs[0].dateCreatedUtc
  //             ).toLocaleDateString()
  //           : "-",
  //       ];
  //       worksheet.addRow(row);
  //     });

  //     // Generate Excel file
  //     const buffer = await workbook.xlsx.writeBuffer();
  //     saveAs(
  //       new Blob([buffer], {
  //         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //       }),
  //       "report.xlsx"
  //     );
  //   };

  //   const handlePrintPdf = () => {
  //     const content = filteredAudit.map((tag) => ({
  //       Company: tag.company,
  //       "Serial Number": tag.serialNumber,
  //       Category: tag.history[0]?.category || "-",
  //       "Added By": tag.history[0] ? tag.history[0].logs[0].fullName : "-",
  //       "Original Data": tag.history[0]?.logs[0]?.originalData || "-",
  //       "New Data": tag.history[0]?.logs[0]?.newData || "-",
  //       "Date Created": tag.history[0]
  //         ? new Date(tag.history[0].logs[0].dateCreatedUtc).toLocaleDateString()
  //         : "-",
  //       "-----------------------------------": "",
  //     }));

  //     const pdfDocDefinition = {
  //       content: [
  //         {
  //           table: {
  //             headerRows: 1,
  //             body: [
  //               Object.keys(content[0]),
  //               ...content.map((item) => Object.values(item)),
  //             ],
  //           },
  //         },
  //       ],
  //     };

  //     pdfMake.createPdf(pdfDocDefinition).download("report.pdf");
  //   };

  //   const handleWebPrint = () => {
  //     // Generate table content
  //     const tableContent = currentItems
  //       .map(
  //         (tag) => `
  //           <tr>
  //             <td>${tag.company}</td>
  //             <td>${tag.serialNumber}</td>
  //             <td>${tag.history[0]?.category || "-"}</td>
  //             <td>${tag.history[0] ? tag.history[0].logs[0].fullName : "-"}</td>
  //             <td>${tag.history[0]?.logs[0]?.originalData || "-"}</td>
  //             <td>${tag.history[0]?.logs[0]?.newData || "-"}</td>
  //             <td>${
  //               tag.history[0]
  //                 ? new Date(
  //                     tag.history[0].logs[0].dateCreatedUtc
  //                   ).toLocaleDateString()
  //                 : "-"
  //             }</td>
  //           </tr>
  //         `
  //       )
  //       .join("\n");

  //     // Create a table with headers and the content
  //     const content = `
  //       <html>
  //         <head>
  //           <title>Print Preview</title>
  //           <style>
  //             table {
  //               border-collapse: collapse;
  //               width: 100%;
  //             }
  //             th, td {
  //               border: 1px solid #dddddd;
  //               text-align: left;
  //               padding: 8px;
  //             }
  //           </style>
  //         </head>
  //         <body>
  //           <table>
  //             <thead>
  //               <tr>
  //                 <th>Company</th>
  //                 <th>Serial Number</th>
  //                 <th>Category</th>
  //                 <th>Added By</th>
  //                 <th>Original Data</th>
  //                 <th>New Data</th>
  //                 <th>Date Created</th>
  //               </tr>
  //             </thead>
  //             <tbody>
  //               ${tableContent}
  //             </tbody>
  //           </table>
  //         </body>
  //       </html>
  //     `;

  //     // Open a new window or modal for printing
  //     const printWindow = window.open("", "_blank");

  //     // Set the content of the new window or modal
  //     printWindow.document.write(content);

  //     // Trigger the print dialog
  //     printWindow.print();
  // };

  const totalPages = Math.ceil(auditList.length / itemsPerPage);

  console.log(auditList);

  return (
    <div
      className="TablePageMainDiv"
      style={{
        marginLeft: "150px",
        marginRight: "150px",
        marginTop: "25px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <div
          className="approvalTitle"
          style={{
            fontFamily: "futuramedium",
            color: "#141212",
            fontSize: "30px",
            marginBottom: "20px",
          }}
        >
          User Logs
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
          }}
        >
          <div
            className=""
            style={{
              fontFamily: "futurabook",
              color: "#141212",
              fontSize: "20px",
              marginBottom: "20px",
            }}
          >
            <FilterIcon /> Filter
          </div>

          <Dropdown className="">
            <Dropdown.Toggle
              // className="approval-dropdown"
              style={{
                textDecoration: "none",
                fontFamily: "futuramedium",
                color: "#3A4750",
                fontSize: "15px",
                marginRight: "10px",
                border: "1px solid #3A475033",
                backgroundColor: "#3A475033",
              }}
            >
              {selectedStartDate
                ? selectedStartDate.toLocaleDateString("en-GB") // Format as dd/mm/yyyy
                : "Date Start"}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <DatePicker
                placeholderText="Input or select a Date"
                selected={selectedStartDate}
                onChange={handleStartDateChange}
                dateFormat="dd/MM/yyyy"
                maxDate={new Date()} // Set max date to the current date
              />
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown className="">
            <Dropdown.Toggle
              // className="approval-dropdown"
              style={{
                textDecoration: "none",
                fontFamily: "futuramedium",
                color: "#3A4750",
                fontSize: "15px",
                marginRight: "10px",
                border: "1px solid #3A475033",
                backgroundColor: "#3A475033",
              }}
            >
              {selectedEndDate
                ? selectedEndDate.toLocaleDateString("en-GB") // Format as dd/mm/yyyy
                : "Date End"}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <DatePicker
                placeholderText="Input or select a Date"
                selected={selectedEndDate}
                onChange={handleEndDateChange}
                dateFormat="dd/MM/yyyy"
                maxDate={new Date()} // Set max date to the current date
              />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div
        className="minibar"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            fontFamily: "futuramedium",
          }}
        >
          <p>Show</p>
          <div
            style={{
              backgroundColor: "#e5e5e5",
              borderRadius: "10px",
              padding: "4px",
              cursor: "pointer",
              height: "30px",
              width: "50px",
              marginLeft: "1px",
              marginRight: "5px",
            }}
          >
            <select
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
              style={{
                border: "none",
                background: "none",
                cursor: "pointer",
              }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
          <p>items</p>
        </div>

        <div style={{ display: "flex" }}>
          <div
            className="icon"
            onMouseEnter={() => setIsRefreshHovered(true)}
            onMouseLeave={() => setIsRefreshHovered(null)}
            style={{
              backgroundColor: "#3A475033",
              borderRadius: "50%",
              color: "#3A4750",
              width: "32px",
              height: "32px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
              cursor: "pointer",
              position: "relative",
            }}
            onClick={refreshLogs}
          >
            <RefreshIcon />

            {isRefreshHovered && (
              <p
                style={{
                  position: "absolute",
                  top: "100%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  background: "#ffffff",
                  padding: "2px",
                  borderRadius: "10px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  zIndex: 999,

                  fontSize: "12px",
                  width: "100px",
                  textAlign: "center",
                }}
              >
                Refresh the page
              </p>
            )}
          </div>
          {/* <p
            style={{
              color: "#3A4750",
              fontSize: "16px",
              fontFamily: "futurabook",
            }}
          >
            last refresh was 25mins ago
          </p>
          <div
            className="icon"
            style={{
              backgroundColor: "#3A475033",
              borderRadius: "50%",
              color: "#3A4750",
              width: "32px",
              height: "32px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
            }}
          >
            <RefreshIcon />
          </div> */}

          {/* <Dropdown className="">
            <Dropdown.Toggle
              style={{
                textDecoration: "none",
                fontFamily: "futuramedium",
                color: "#3A4750",
                fontSize: "15px",
                marginRight: "10px",
                border: "1px solid #3A475033",
                backgroundColor: "#3A475033",
              }}
            >
              <DownloadIcon color="#3A4750" />
            </Dropdown.Toggle>

            <Dropdown.Menu style={{}}>
              <Dropdown.Item onClick={handlePrintXls}>
                Microsoft Excel (.xls)
              </Dropdown.Item>
              <Dropdown.Item onClick={handlePrintDoc}>
                Microsoft Word (.docs)
              </Dropdown.Item>
              <Dropdown.Item onClick={handlePrintPdf}>
                PDF Document (.pdf)
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}

          {/* <div
            className=""
            onClick={handleWebPrint}
            style={{
              backgroundColor: "#F6C90E",
              borderRadius: "20%",
              color: "black",
              width: "100px",
              height: "40px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
              cursor: "pointer",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center", // Center vertically
                justifyContent: "center", // Center horizontally
              }}
            >
              <PrintIcon />
              <span
                style={{
                  fontFamily: "futuramedium",
                  color: "black",
                  fontSize: "15px",
                }}
              >
                {" "}
                Print
              </span>
            </div>
          </div> */}
        </div>
      </div>
      {/* ------------------------------------------------------ */}
      {isLoading ? (
        <p>Loading data...</p>
      ) : (
        <>
          <div className="approvalTable">
            <table className="custon-table">
              <div className="tHead">
                <thead>
                  <tr>
                    {/* <th>
                      <div className="checkbox-dropdown">
                        <input
                          type="checkbox"
                          id="selectAll"
                          checked={selectAllChecked}
                          onChange={handleSelectAllChange}
                        />
                        <div className="dropdown">
                          <span className="dropdown-arrow">&#9662;</span>
                          <div className="dropdown-content">
                            <div onClick={() => handleSelectAllChange(true)}>
                              Select All
                            </div>
                            <div onClick={() => handleSelectAllChange(false)}>
                              Deselect All
                            </div>
                          </div>
                        </div>
                      </div>
                    </th> */}
                    <th
                      className=""
                      style={{
                        width: "700px",
                      }}
                    >
                      Activity
                    </th>
                    <th
                      className=""
                      style={{
                        width: "700px",
                      }}
                    >
                      Time & Date
                    </th>
                    <th className="" style={{ width: "700px" }}>
                      Full Name
                    </th>
                    <th className="" style={{ width: "700px" }}>
                      User Name
                    </th>
                    {/* <th className="" style={{ width: "250px" }}>
                 Category
                </th> */}

                    {/* <th>Location Name</th> */}
                  </tr>
                </thead>
                <tbody className="tBody">
                  {currentItems.map((tag, index) => (
                    <tr>
                      <td>{tag.activity}</td>

                      <td>
                        {new Date(tag.dateCreatedUtc).toLocaleString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                          hour12: true,
                        })}
                      </td>
                      <td>{tag.userFullName}</td>
                      <td>{tag.userName}</td>
                    </tr>
                  ))}
                </tbody>
              </div>
            </table>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <div>
              Showing {Math.min(indexOfLastItem, auditList.length)} of{" "}
              {auditList.length} items
            </div>
            <div
              style={{
                marginRight: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <button
                onClick={handlePreviousPage}
                style={{
                  color: "black",
                  borderRadius: "5px",
                  border: "none",
                  marginLeft: "10px",
                  backgroundColor: "#3A475033",
                  width: "80px",
                  fontFamily: "futuramedium",
                  cursor: "pointer",
                }}
              >
                Previous
              </button>

              {renderPageButtons()}

              <button
                onClick={handleNextPage}
                style={{
                  color: "black",
                  borderRadius: "5px",
                  border: "none",
                  marginLeft: "10px",
                  backgroundColor: "#3A475033",
                  width: "60px",
                  fontFamily: "futuramedium",
                  cursor: "pointer",
                }}
              >
                Next
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UserLogs;
