import React, { useState } from "react";
import Navbar from '../components/NavBar'
import UserApprovalBar from '../components/UserApproval/UserApprovalBar'
import SitesTable from '../components/SitesListComponents/SitesTable'

const Sites = () => {

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (query) => {
    // Update the search query state
    setSearchQuery(query);
  };

  return (
    <div
    style={{
      background:"#EEEEEE",
      height:"100%"
    }}
    >
        <Navbar/>
        <UserApprovalBar searchQuery={searchQuery} onSearch={handleSearch}/>
        <SitesTable searchQuery={searchQuery}/>
    </div>
  )
}

export default Sites