import React, { useState, useEffect } from "react";
import "../../css/userApprovalcss/userApproval.css";
import { Dropdown } from "react-bootstrap";
import { publicRequest } from "../../requestMethods";
import {
  ThinEditIcon,
  ThinArchive,
  RefreshIcon,
  DownloadIcon,
} from "../../fontAwesomeComponents/FaIcons";
import { useSelector } from "react-redux";
import "../../css/userListcss/userList.css";
import AddUserModal from "../../modals/AddUserModal";
import EditUserModal from "../../modals/EditUserModal";
import UserListEditSuccessModal from "../../modals/UserListEditSuccessModal";
import FailedEditUserModal from "../../modals/FailedEditUserModal";
import "../../css/modalcss/addUserModal.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal } from "react-bootstrap";

const UserListTable = ({ searchQuery }) => {
  const [selectedUserIndex, setSelectedUserIndex] = useState(null);
  const [isUserSelected, setIsUserSelected] = useState(false);
  const [isRefreshHovered, setIsRefreshHovered] = useState(false);

  const [filteredUsers, setFilteredUsers] = useState([]);

  const [isEditHovered, setIsEditHovered] = useState(false);

  const [isLoading, setIsLoading] = useState(false);


  const sortUserListAlphabetically = (users) => {
    return users.sort((a, b) => a.name.localeCompare(b.name));
  };


  const handleEditClick = (index) => {
    setSelectedUserIndex(index);
  };

  const handleCloseEditUserModal = () => {
    setSelectedUserIndex(null);
  };

  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showFailedEditUserModal, setShowFailedEditUserModal] = useState(false);

  const handleAddUserClick = () => {
    setShowAddUserModal(true);
  };

  const handleCloseAddUserModal = () => {
    setShowAddUserModal(false);
  };

  const handleOpenFailedModal = () => {
    setShowFailedEditUserModal(true);
  };

  const handleCloseFailedModal = () => {
    setShowFailedEditUserModal(false);
  };

  const [showEditUserModal, setShowEditUserModal] = useState(false);

  // const handleEditClick = (user) => {
  //   setSelectedUser(user);
  //   setShowEditUserModal(true);
  // };

  // const handleCloseEditUserModal = () => {
  //   setShowEditUserModal(false);
  // };

  //modalSuccess
  const [showEditSuccessModal, setShowEditSuccessModal] = useState(false);

  const handleCloseSuccessUserModal = () => {
    setShowEditSuccessModal(false);
    setSelectedRows([]);
    setSelectAllChecked(false);
    gettingUser(); // This is where you fetch the data, you don't need useEffect here
  };

  useEffect(() => {
    gettingUser(); // This will be called when the component mounts
  }, []);

  const [selectedUser, setSelectedUser] = useState(null);

  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [accessList, setUserList] = useState([]);

  const handleSelectAllChange = (isSelectAll) => {
    if (isSelectAll) {
      setSelectAllChecked(true);
      setSelectedRows([...Array(approvalList.length).keys()]);
    } else {
      setSelectAllChecked(false);
      setSelectedRows([]);
    }
  };

  const handleSelectAllChangeBox = () => {
    setSelectAllChecked(!selectAllChecked);

    if (!selectAllChecked) {
      setSelectedRows([...Array(approvalList.length).keys()]);
    } else {
      setSelectedRows([]);
    }
  };

  const [approvalList, setApprovalList] = useState([]); // State to hold approval list data

  const [selectedRowsByPage, setSelectedRowsByPage] = useState([]);

  const handleRowSelection = (index) => {
    const itemIndex = indexOfFirstItem + index;
    const newSelectedRows = [...selectedRows];

    if (newSelectedRows.includes(itemIndex)) {
      newSelectedRows.splice(newSelectedRows.indexOf(itemIndex), 1);
    } else {
      newSelectedRows.push(itemIndex);
    }

    setSelectedRows(newSelectedRows);

    setIsUserSelected(newSelectedRows.length > 0);

    setSelectedRowsByPage((prevSelectedRowsByPage) => {
      const updatedSelectedRows = [...prevSelectedRowsByPage];
      updatedSelectedRows[currentPage - 1] = newSelectedRows;
      return updatedSelectedRows;
    });
  };

  //toggling true or false

  const handleToggleChange = (index) => {
    setFilteredUsers((prevList) => {
      const newList = [...prevList];
      const itemIndex = indexOfFirstItem + index; // Calculate the index in the full list

      console.log("Previous approvalList:", prevList);
      console.log("Toggled item index:", itemIndex);

      newList[itemIndex] = {
        ...newList[itemIndex],
        isActive: !newList[itemIndex].isActive,
      };

      console.log("New approvalList:", newList);

      return newList;
    });
  };

  const token = useSelector((state) => state.user.token);
  const selectedCompany = useSelector((state) => state.selectedCompany);
  const id = useSelector((state) => state.user.id);

  //this is for getting

  const queryParams = {
    LoggedUserId: id,
    LoggedUserCompanyId: selectedCompany,
  };

  const gettingUser = async () => {
    const data = {
      userId: id,
      userCompanyId: selectedCompany,
    };
  
    const headers = {
      Authorization: `Bearer ${token}`,
    };
  
    const queryString = new URLSearchParams(queryParams).toString();
  
    const fullUrl = "/user/userlist" + (queryString ? `?${queryString}` : "");
  
    try {
      setIsLoading(true);
      const response = await publicRequest({
        method: "GET",
        url: fullUrl,
        headers: headers,
      });
  
      const sortedApprovalList = response.data.sort((a, b) => {
        // Assuming userName is the property to sort alphabetically
        return a.fullName.localeCompare(b.fullName);
      });
  
      setApprovalList(sortedApprovalList);
      console.log(sortedApprovalList);
    } catch (error) {
      console.error("Error while fetching users:", error);
    } finally {
      setIsLoading(false);
    }
  };
  




  const handleSaveClick = async () => {
    if (!isUserSelected) {
      alert("Select a user you want to update");
      return;
    }

    const selectedUsers = filteredUsers.filter((user, index) =>
      selectedRows.includes(index)
    );
    const updatedApprovalList = selectedUsers.map((user) => ({
      ...user,
      userId: user.id,
      userCompanyId: user.userCompanyId,
    }));

    updateUser(updatedApprovalList);
  };

  //update user in userlist

  const updateUser = async (updatedApprovalList) => {
    try {
      const accessList = updatedApprovalList.map((user) => ({
        userId: user.id,
        userCompanyId: user.userCompanyId,
        isActive: user.isActive,
      }));

      console.log(accessList);

      const data = {
        loggedUserId: id,
        loggedUserCompanyId: selectedCompany,
        accessList: accessList,
      };

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await publicRequest({
        method: "POST",
        url: "/user/userlist/update",
        data: data,
        headers: headers,
      });

      console.log("User list updated:", response.data);

      setShowEditSuccessModal(true);
    } catch (error) {
      console.error("Error updating user list", error);
      setShowFailedEditUserModal(true);
    }
  };

  useEffect(() => {
    gettingUser();
  }, []);

  useEffect(() => {
    const filteredUser = approvalList.filter((user) => {
      // Customize the conditions for filtering based on your tag structure

      return (
        user.fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.companyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.licenseNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.userName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.roleName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.mobilePhone.toLowerCase().includes(searchQuery.toLowerCase())

        // Add more conditions as needed
        // ...
      );
    });

    setFilteredUsers(filteredUser);
    setSelectAllChecked(false);
    setCurrentPage(1);

    //   getTagBySerialNumber();
  }, [searchQuery, approvalList]);

  //PAGES

  const [pagination, setPagination] = useState(1); // Current page number
  const itemsPerPageOptions = [10, 20, 30]; // Number of items per page options
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 3, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 3, 1));
  };

  const renderPageButtons = () => {
    const pageButtons = [];
    const maxPagesToShow = 3; // Set the maximum number of pages to show

    const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          onClick={() => handlePageClick(i)}
          className={currentPage === i ? "active-page" : ""}
          style={{
            color: "black",
            borderRadius: "5px",
            border: "none",
            marginLeft: "10px",
            backgroundColor: currentPage === i ? "#F6C90E" : "#3A475033",
            width: "30px",
            fontFamily: "futuramedium",
            cursor: "pointer",
          }}
        >
          {i}
        </button>
      );
    }

    return pageButtons;
  };

  // ... (previous code remains unchanged)

  const totalPages = Math.ceil(approvalList.length / itemsPerPage);

  return (
    <div  className="TablePageMainDiv"
    
    style={{ marginLeft: "150px", marginRight: "150px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <div
          className="approvalTitle"
          style={{
            fontFamily: "futuramedium",
            color: "#141212",
            fontSize: "30px",
          }}
        >
          User List
        </div>
        <div
          className="save-button"
          style={{
            fontFamily: "futuramedium",
            color: "#141212",
            fontSize: "15px",
            height: "45px",
            width:"100px"
          }}
          onClick={handleAddUserClick}
        >
          + Add User
        </div>
        <Modal
          size="xl"
          show={showAddUserModal}
          //   onHide={handleCloseAddUserModal}
          centered
        >
          <Modal.Body>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={handleCloseAddUserModal}
                style={{
                  background: "none",
                  border: "none",
                  fontSize: "35px",
                  position: "absolute",
                  zIndex: "9999",
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <AddUserModal />
          </Modal.Body>
        </Modal>
      </div>

      <div
        className="minibar"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            fontFamily: "futuramedium",
          }}
        >
          <p>Show</p>
          <div
            style={{
              backgroundColor: "#e5e5e5",
              borderRadius: "10px",
              padding: "4px",
              cursor: "pointer",
              height: "30px",
              width: "50px",
              marginLeft: "1px",
              marginRight: "5px",
            }}
          >
            <select
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
              style={{
                border: "none",
                background: "none",
                cursor: "pointer",
              }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
            </select>
          </div>
          <p>items</p>
        </div>

        <div style={{ display: "flex" }}>

        <div
            className="icon"
            onMouseEnter={() => setIsRefreshHovered(true)}
            onMouseLeave={() => setIsRefreshHovered(null)}
            style={{
              backgroundColor: "#3A475033",
              borderRadius: "50%",
              color: "#3A4750",
              width: "32px",
              height: "32px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
              cursor: "pointer",
              position: "relative",
            }}
            onClick={gettingUser}
          >
            <RefreshIcon />

            {isRefreshHovered && (
              <p
                style={{
                  position: "absolute",
                  top: "100%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  background: "#ffffff",
                  padding: "2px",
                  borderRadius: "10px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  zIndex: 999,
               
                  fontSize: "12px",
                  width: "100px",
                  textAlign: "center",
                }}
              >
                     Refresh the page
              </p>
            )}
          </div>

          {/* <p
            style={{
              color: "#3A4750",
              fontSize: "16px",
              fontFamily: "futurabook",
            }}
          >
            last refresh was 25mins ago
          </p>
          <div
            className="icon"
            style={{
              backgroundColor: "#3A475033",
              borderRadius: "50%",
              color: "#3A4750",
              width: "32px",
              height: "32px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
            }}
          >
            <RefreshIcon />
          </div> */}
          {/* <div
            className="icon"
            style={{
              backgroundColor: "#3A475033",
              borderRadius: "20%", // Use 50% for a circle
              color: "#3A4750",
              width: "32px",
              height: "32px",
              display: "flex", // Use flex display
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
            }}
          >
            <DownloadIcon />
          </div> */}
        </div>
      </div>

      {/* ________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________ */}
      {isLoading ? (
        <p>Loading data...</p>
      ) : (
        <>
      <div className="approvalTable">
        <table className="custom-table">
          <div className="tHead">
            <thead>
              <tr>
                <th>
                  <div className="checkbox-dropdown">
                    <input
                      type="checkbox"
                      id="selectAll"
                      checked={selectAllChecked}
                      onChange={handleSelectAllChangeBox}
                    />
                    <div className="dropdown">
                      <span className="dropdown-arrow">&#9662;</span>
                      <div className="dropdown-content">
                        <div onClick={() => handleSelectAllChange(true)}>
                          Select All
                        </div>
                        <div onClick={() => handleSelectAllChange(false)}>
                          Deselect All
                        </div>
                      </div>
                    </div>
                  </div>
                </th>

                <th
                  style={{
                    width: "350px",
                  }}
                >
                  Name
                </th>
                <th
                  style={{
                    width: "350px",
                  }}
                >
                  Email Address
                </th>
                <th
                  style={{
                    width: "350px",
                  }}
                >
                  License Number
                </th>
                <th
                  style={{
                    width: "350px",
                  }}
                >
                  Company
                </th>
                <th
                  style={{
                    width: "350px",
                  }}
                >
                  Role
                </th>

                <th
                  style={{
                    width: "350px",
                  }}
                >
                  Authorised
                </th>
                <th
                  style={{
           
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>

            <tbody className="tBody">
              {currentItems.map((user, index) => (
                <tr
                  key={index}
                  className={selectedRows.includes(index) ? "selected" : ""}
                >
                  <td>
                    <input
                      type="checkbox"
                      id={`userCheckbox${index}`}
                      checked={
                        selectedRowsByPage[currentPage - 1] &&
                        selectedRowsByPage[currentPage - 1].includes(
                          indexOfFirstItem + index
                        )
                      }
                      onChange={() => handleRowSelection(index)}
                    />
                    <label htmlFor={`userCheckbox${index}`}></label>
                  </td>
                  <td
                    style={{
                      minWidth: "150px",
                    }}
                  >
                    {user.fullName}
                  </td>
                  <td>{user.userName}</td>
                  <td>{user.licenseNumber}</td>
                  <td>{user.companyName}</td>

                  <td
                    className="functions"
                    style={{
                      minWidth: "220px",
                    }}
                  >
                    {user.roleName}
                    {/* authorized */}
                  </td>
                  <td>
                    <div className="toggle-container">
                      <label className="toggle">
                        <input
                          type="checkbox"
                          checked={user.isActive}
                          onChange={() =>
                            handleToggleChange(index, selectedRows)
                          }
                        />
                        <div className="slider">
                          <span
                            className={user.isActive ? "yes-text" : "no-text"}
                            style={{ fontFamily: "futurabook" }}
                          >
                            <p style={{ fontSize: "12px", marginTop: "2px" }}>
                              {user.isActive ? "YES" : "NO"}
                            </p>
                          </span>
                        </div>
                      </label>
                    </div>
                  </td>

                  <div
                    className="iconBoxes"
                    style={{
                      marginTop: "7px",
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: "0px",

                    }}
                  >
                    <div
                      className="iconBox"
                      onMouseEnter={() => setIsEditHovered(index)}
                      onMouseLeave={() => setIsEditHovered(null)}
                      style={{
                        cursor: "pointer",
                        position: "relative",
                        
                        
                      }}
                      onClick={() => handleEditClick(index)}
                    >
                      <ThinEditIcon height="15px" width="15px" fill="#3A4750" />
                      {isEditHovered === index && (
                        <div
                          style={{
                            position: "absolute",
                            top: "100%",
                            left: "50%",
                            transform: "translateX(-50%)",
                            background: "#ffffff",
                            padding: "2px",
                            borderRadius: "10px",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            zIndex: 999,
                            width: "70px",
                            fontSize: "12px",
                            
                          }}
                        >
                          Edit User
                        </div>
                      )}
                    </div>

                    {selectedUserIndex === index && ( // Check if this row's modal should be open
                      <Modal
                        size="xl"
                        show={true}
                        // onHide={handleCloseEditUserModal}
                        centered
                      >
                        <Modal.Body>
                          <div className="d-flex justify-content-end">
                            <button
                              type="button"
                              className="close"
                              aria-label="Close"
                              onClick={handleCloseEditUserModal}
                              style={{
                                background: "none",
                                border: "none",
                                fontSize: "35px",
                                position: "absolute",
                                zIndex: "9999",
                              }}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <EditUserModal user={user} />
                        </Modal.Body>
                      </Modal>
                    )}

                    {/* <div className="iconBox">
                      <ThinArchive height="15px" width="15px" />
                    </div> */}
                  </div>
                </tr>
              ))}
            </tbody>
          </div>
        </table>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <div>
          Showing {Math.min(indexOfLastItem, filteredUsers.length)} of{" "}
          {approvalList.length} items
        </div>
        <div
          style={{
            marginRight: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <button
            onClick={handlePreviousPage}
            style={{
              color: "black",
              borderRadius: "5px",
              border: "none",
              marginLeft: "10px",
              backgroundColor: "#3A475033",
              width: "80px",
              fontFamily: "futuramedium",
              cursor: "pointer",
            }}
          >
            Previous
          </button>

          {renderPageButtons()}

          <button
            onClick={handleNextPage}
            style={{
              color: "black",
              borderRadius: "5px",
              border: "none",
              marginLeft: "10px",
              backgroundColor: "#3A475033",
              width: "60px",
              fontFamily: "futuramedium",
              cursor: "pointer",
            }}
          >
            Next
          </button>
        </div>
      </div>

      <div
        style={{ justifyContent: "flex-end", width: "100%", display: "flex" }}
      >
        <button className="save-button" onClick={handleSaveClick}>
          Save
        </button>
        <Modal
          show={showEditSuccessModal}
          // onHide={handleCloseSuccessUserModal}
          centered
        >
          <Modal.Body>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={handleCloseSuccessUserModal}
                style={{
                  background: "none",
                  border: "none",
                  fontSize: "35px",
                  position: "absolute",
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <UserListEditSuccessModal onClose={handleCloseSuccessUserModal} />
          </Modal.Body>
        </Modal>

        <Modal
          show={showFailedEditUserModal}
          // onHide={handleCloseSuccessUserModal}
          centered
        >
          <Modal.Body>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={handleCloseFailedModal}
                style={{
                  background: "none",
                  border: "none",
                  fontSize: "35px",
                  position: "absolute",
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <FailedEditUserModal onClose={handleCloseFailedModal} />
          </Modal.Body>
        </Modal>
      </div>
      </>
      )}
    </div>
  );
};

export default UserListTable;
