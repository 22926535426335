import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { publicRequest } from "../requestMethods";
import "../css/modalcss/addUserModal.css";
import "bootstrap/dist/css/bootstrap.min.css"; // Make sure to import Bootstrap's CSS
import EditCompanySuccessModal from "./EditCompanySuccessModal";
import EditCompanyFailedModal from "./EditCompanyFailedModal";

import { Modal } from "react-bootstrap";

const EditCompanyModal = ({ company }) => {
  console.log(company);

  const [formSubmitted, setFormSubmitted] = useState(false);

  const [showEditCompanySuccessModal, setShowEditCompanySuccessModal] =
    useState(false);

  const handleCloseSuccessUserModal = () => {
    setShowEditCompanySuccessModal(false);
  };

  const [showFailedEditModal, setShowFailedEditModal] = useState(false);

  const handleCloseEditFailed = () => {
    setShowFailedEditModal(false);
  };

  const [companyNameErrorMessage, setCompanyNameErrorMessage] = useState("");

  const [countryPrefix, setCountryPrefix] = useState("");

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [stateList, setStateList] = useState([]);
  const [countryList, setCountryList] = useState([]);

  //////////////////
  const token = useSelector((state) => state.user.token);
  const userId = useSelector((state) => state.user.id);
  const selectedCompanyId = useSelector((state) => state.selectedCompanyId);
  const selectedUserCompanyId = useSelector((state) => state.selectedCompany);

  const [errorMessage, setErrorMessage] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isAddressLine1Error, setIsAddressLine1Error] = useState(false);
  const [isAddressLine2Error, setIsAddressLine2Error] = useState(false);

  const [userInput, setUserInput] = useState({
    name: company.name,
    addressLine1: company.addressLine1,
    addressLine2: company.addressLine2,
    city: company.city,
    postCode: company.postCode,
    stateId: company.stateId,
    countryId: company.countryId,
    abnAcnIdentifierNumber: company.abnAcnIdentifierNumber,
    officePhone: company.officePhone,
  });

  const fetchData = async (url) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const queryParams = {
        LoggedUserId: userId,
        LoggedUserCompanyId: selectedUserCompanyId,
      };

      const queryString = new URLSearchParams(queryParams).toString();
      const fullUrl = url + (queryString ? `?${queryString}` : "");

      const response = await publicRequest({
        method: "GET",
        url: fullUrl,
        headers: headers,
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching data", error);

      return null;
    }
  };

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        // Fetch countries
        const getCountryResponse = await fetchData("/lookup/countries");
        if (getCountryResponse) {
          setCountryList(getCountryResponse);

          // Set the initial selected country based on the user's countryId
          const initialSelectedCountry = getCountryResponse.find(
            (country) => country.id === company.countryId
          );

          if (initialSelectedCountry) {
            setSelectedCountry(initialSelectedCountry.id);
          }
        }
      } catch (error) {
        console.error(error);
      }

      try {
        // Fetch states based on the initial selected country
        const getStateResponse = await fetchData(
          `lookup/country/${selectedCountry}/states`
        );
        if (getStateResponse) {
          setStateList(getStateResponse);

          // Set the initial selected state based on the user's stateId
          const initialSelectedState = getStateResponse.find(
            (state) => state.id === company.stateId
          );

          if (initialSelectedState) {
            setSelectedState(initialSelectedState.id);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchLocations();
  }, [selectedCountry]); // Add selectedCountry as a dependency to fetch states when the country changes

  ///updating company

  const updateCompany = async () => {
    const data = {
      loggedUserId: userId,
      loggedUserCompanyId: selectedUserCompanyId,
      companyId: company.id,
      name: userInput.name,
      addressLine1: userInput.addressLine1,
      addressLine2: userInput.addressLine2,
      city: userInput.city,
      postCode: userInput.postCode,
      stateId: userInput.stateId,
      countryId: userInput.countryId,
      abnAcnIdentifierNumber: userInput.abnAcnIdentifierNumber,
      officePhone: userInput.officePhone,
    };

    console.log(data);

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await publicRequest({
        method: "POST",
        url: `/company/${company.id}/update`,
        data: data,
        headers: headers,
      });
      setShowEditCompanySuccessModal(true);
      console.log(response.data);
    } catch (error) {
      console.error(error);
      setShowFailedEditModal(true);
      console.error(error.response.data.errors);
      setErrorMessage(error.response.data.errors || error.response.data.title);
      setIsError(true);
    }
  };

  const isValidOfficeContactNumber = (mobilePhone) => {
    const trimmedNumber = mobilePhone.replace(/\D/g, ""); // Remove non-numeric characters
    if (trimmedNumber.startsWith("61") && trimmedNumber.length === 11) {
      return true;
    } else if (
      trimmedNumber.startsWith("64") &&
      [11].includes(trimmedNumber.length)
    ) {
      return true;
    } else if (trimmedNumber.startsWith("678") && trimmedNumber.length === 7) {
      return true;
    } else {
      return false;
    }
  };

  const handleInputChange = (field, value) => {
    if (field === "mobilePhone" || field === "officePhone") {
      // Remove non-numeric characters from the input
      value = value.replace(/\D/g, "");

      // Update the countryPrefix when changing the officePhone

      // const newCountryPrefix =
      //   selectedCountry === "1" ? "61" : selectedCountry === "2" ? "64" : "";
      // setCountryPrefix(newCountryPrefix);

      if (value && value.length > 0) {
        // Concatenate "+" with the input value
        value = "+" + value;
      }
    }

    // For ABN/ACN Identifier Number and Postal Code, allow only numerical input
    if (field === "abnAcnIdentifierNumber" || field === "postCode") {
      value = value.replace(/\D/g, "");
    }

    if (field === "officePhone") {
      // Concatenate plus sign with the office phone value
    }

    if (field === "name") {
      const alphanumericRegex = /^[a-zA-Z0-9\s]+$/;
      if (!alphanumericRegex.test(value)) {
        setCompanyNameErrorMessage({
          name: "Company Name must only contain alphanumeric characters.",
        });
        setIsError(true);
      } else {
        setCompanyNameErrorMessage("");
        setIsError(false);
      }
    }

    setUserInput((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const handleCountryChange = (value) => {
    const selectedCountryObj = countryList.find(
      (country) => country.id === value
    );
    setSelectedCountry(value);

    // Set the countryPrefix based on the selected countryId
    const newCountryPrefix = value === "1" ? "61" : value === "2" ? "64" : "";
    setCountryPrefix(newCountryPrefix);

    setUserInput((prevDetails) => ({
      ...prevDetails,
      countryId: value,
      country: selectedCountryObj ? selectedCountryObj.name : "",
    }));

    // Reset the selected state when changing the country
    setSelectedState("");
  };

  const handleStateChange = (value) => {
    const selectedStateObj = stateList.find((state) => state.id === value);
    setSelectedState(value);
    setUserInput((prevDetails) => ({
      ...prevDetails,
      stateId: value,
      state: selectedStateObj ? selectedStateObj.name : "",
    }));
  };

  // Validation messages for required fields
  const [validationMessages, setValidationMessages] = useState({
    name: "",
    addressLine1: "",
    addressLine2: "",
    country: "",
    state: "",
    city: "",
    postCode: "",
    officePhone: "",
    abnAcnIdentifierNumber: "",
  });

  // Validation function for each required field
  const validateFields = () => {
    const messages = {};

    // Validate Company Name
    if (!userInput.name.trim()) {
      messages.name = "Company Name is required";
    }

    // Validate Address Line 1
    if (!userInput.addressLine1.trim()) {
      messages.addressLine1 = "Address Line 1 is required";
    } else if (!/^[a-zA-Z0-9\s]*$/.test(userInput.addressLine1)) {
      setIsAddressLine1Error(true);
      messages.addressLine1 = "Only alphanumeric characters are allowed";
    } else {
      messages.addressLine1 = "";
      setIsAddressLine1Error(false);
    }

    // Validate Address Line 2
    if (
      userInput.addressLine2.trim() &&
      !/^[a-zA-Z0-9\s]*$/.test(userInput.addressLine2)
    ) {
      setIsAddressLine2Error(true);
      messages.addressLine2 = "Only alphanumeric characters are allowed";
    }

    // Validate Country
    if (!selectedCountry) {
      messages.country = "Select a Country";
    }

    // Validate State
    if (!selectedState) {
      messages.state = "Select a State/Province";
    }

    // Validate City
    if (!userInput.city.trim()) {
      messages.city = "City is required";
    }

    // Validate Postal Code
    if (!userInput.postCode.trim()) {
      messages.postCode = "Postal Code is required";
    }

    const officePhoneValue = userInput.officePhone.trim();
    if (
      officePhoneValue &&
      selectedCountry === "1" &&
      ((officePhoneValue.startsWith("61") && officePhoneValue.length !== 12) ||
        (officePhoneValue.startsWith("678") && officePhoneValue.length !== 4))
    ) {
      messages.officePhone = "Invalid office phone number";
    } else if (
      officePhoneValue &&
      selectedCountry === "2" &&
      (officePhoneValue.length < 8 || officePhoneValue.length > 9)
    ) {
      messages.officePhone = "Phone number must be 8-9 digits";
    } else if (officePhoneValue && selectedCountry === "") {
      messages.officePhone = "Please enter a valid phone number";
    }

    setValidationMessages(messages);

    // Validate ABN/ACN Identifier Number
    if (!userInput.abnAcnIdentifierNumber.trim()) {
      messages.abnAcnIdentifierNumber = "ABN/ACN Identifier Number is required";
    }

    if (
      userInput.abnAcnIdentifierNumber.trim().length > 0 &&
      userInput.abnAcnIdentifierNumber.trim().length < 11
    ) {
      messages.abnAcnIdentifierNumber =
        "ABN/ACN Identifier Number should be 11 digits";
    }

    if (
      userInput.postCode.trim().length > 0 &&
      userInput.postCode.trim().length < 4
    ) {
      messages.postCode = "Postal code should be 4 digits";
    }

    setValidationMessages(messages);

    // Check if any validation errors exist
    return Object.values(messages).every((msg) => !msg);
  };

  // Function to handle form submission
  const handleUpdateCompany = () => {
    setFormSubmitted(true);
    const isValid = validateFields();
    //   const isValid = validateFields();
    //   if (isValid) {
    // Perform your createCompany logic
    updateCompany();
    //   }
  };

  return (
    <div
      className="ModalChildDiv"
      style={{
        alignItems: "center",
        display: "flex",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <div
        className="modal-content custom-modal-content"
        style={{
          padding: "10px",
          paddingLeft: "20px",
          paddingRight: "20px",
          border: "none",
          background: "none",
        }}
      >
        <div className="row">
          <div
            className="finalTitleDiv"
            style={{
              alignItems: "flex-start",
            }}
          >
            <h3
              style={{
                fontFamily: "futuramedium",
                fontWeight: "bold",
                fontSize: "30px",
                marginTop: "15px",
              }}
            >
              Edit Company
            </h3>
          </div>
        </div>
        <div className="finish-row2">
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Company Name*:
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
              value={userInput.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
            />
            {userInput.name === "" && formSubmitted && (
              <div className="validation-message">
                {validationMessages.name}
              </div>
            )}

            {isError &&
              companyNameErrorMessage.name ===
                "Company Name must only contain alphanumeric characters." &&
              userInput.name !== "" && (
                <div
                  className="validation-message"
                  style={
                    {
                      /* Add your custom styles here */
                    }
                  }
                >
                  Company Name must only contain alphanumeric characters.
                </div>
              )}
          </div>
        </div>
        <div className="finish-row"></div>

        <div className="finish-row">
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Address Line 1*:
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
              value={userInput.addressLine1}
              onChange={(e) =>
                handleInputChange("addressLine1", e.target.value)
              }
              maxLength={30}
            />
            {userInput.addressLine1 === "" && formSubmitted && (
              <div className="validation-message">
                {validationMessages.addressLine1}
              </div>
            )}

            {isAddressLine1Error &&
              formSubmitted &&
              userInput.addressLine1 !== "" && (
                <div className="validation-message">
                  {validationMessages.addressLine1}
                </div>
              )}
          </div>
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Address Line 2:
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
              value={userInput.addressLine2}
              onChange={(e) =>
                handleInputChange("addressLine2", e.target.value)
              }
              maxLength={30}
            />

            {isAddressLine2Error && formSubmitted && (
              <div className="validation-message">
                {validationMessages.addressLine2}
              </div>
            )}
          </div>
        </div>

        <div className="finish-row">
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              City*:
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
              value={userInput.city}
              onChange={(e) => handleInputChange("city", e.target.value)}
            />
            {userInput.city === "" && formSubmitted && (
              <div className="validation-message">
                {validationMessages.city}
              </div>
            )}
          </div>

          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Postal Code*:
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
              value={userInput.postCode}
              onChange={(e) => handleInputChange("postCode", e.target.value)}
              maxLength={4}
            />
            {userInput.postCode === "" && formSubmitted && (
              <div className="validation-message">
                {validationMessages.postCode}
              </div>
            )}

            {userInput.postCode !== "" && formSubmitted && (
              <div className="validation-message">
                {validationMessages.postCode}
              </div>
            )}
          </div>
        </div>
        <div className="finish-row">
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "2px",
              }}
            >
              Country*:
            </p>
            <select
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
                fontWeight: "bold",
                height: "25px",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
              }}
              value={selectedCountry}
              onChange={(e) => handleCountryChange(e.target.value)}
            >
              <option value="">Select Country</option>
              {countryList.map((country) => (
                <option key={country.id} value={country.id}>
                  {country.name}
                </option>
              ))}
            </select>
            {selectedCountry === "" && formSubmitted && (
              <div className="validation-message">
                {validationMessages.country}
              </div>
            )}
          </div>

          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "2px",
              }}
            >
              State/Province*:
            </p>
            <select
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
                fontWeight: "bold",
                height: "25px",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
              }}
              value={selectedState}
              onChange={(e) => handleStateChange(e.target.value)}
            >
              <option value="">Select State</option>
              {stateList.map((state) => (
                <option key={state.id} value={state.id}>
                  {state.name}
                </option>
              ))}
            </select>
            {selectedState === "" && formSubmitted && (
              <div className="validation-message">
                {validationMessages.state}
              </div>
            )}
          </div>
        </div>
        <div className="finish-row">
          {/* <div className="finish-column wholeRow">
                        <p className='detailsTitle'
                            style={{
                                fontSize: "13px",
                                marginBottom: "5px"
                            }}
                        >Master License Number:</p>
                        <input type="text" className="addUserDetails"
                            style={{
                                fontFamily: "futurabook",
                                color: "#141212"
                            }}
                            value={""}
                            onChange={(e) => handleInputChange('', e.target.value)}
                        />
                    </div> */}
        </div>
        <div className="finish-row">
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Phone Number:
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
              value={userInput.officePhone}
              onChange={(e) => {
                const inputValue = e.target.value;
                const strippedValue = inputValue.replace("+", ""); // Remove the "+" character
                handleInputChange("officePhone", strippedValue);
              }}
              maxLength={12}
            />
            {formSubmitted &&
              userInput.officePhone !== "" &&
              !isValidOfficeContactNumber(userInput.officePhone) && (
                <div className="validation-message" style={{}}>
                  Please enter a valid Phone number
                </div>
              )}
          </div>
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              ABN/ACN Identifier Number:*
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
              value={userInput.abnAcnIdentifierNumber}
              onChange={(e) =>
                handleInputChange("abnAcnIdentifierNumber", e.target.value)
              }
              maxLength={11}
            />
            {userInput.abnAcnIdentifierNumber === "" && formSubmitted && (
              <div className="validation-message">
                {validationMessages.abnAcnIdentifierNumber}
              </div>
            )}

            {userInput.abnAcnIdentifierNumber !== "" && formSubmitted && (
              <div className="validation-message">
                {validationMessages.abnAcnIdentifierNumber}
              </div>
            )}
          </div>
          {/* <div className="finish-column">
                        <p className='detailsTitle'
                            style={{
                                fontSize: "13px",
                                marginBottom: "5px"
                            }}
                        >Mobile Phone Nummber:</p>
                        <input type="text" className="addUserDetails"
                            style={{
                                fontFamily: "futurabook",
                                color: "#141212"
                            }}
                            value = {userInput.mobilePhone}
                        />
                    </div> */}
        </div>
        {/* <div className="finish-row2">
                    <div className="finish-column">
                        <p className='detailsTitle'
                            style={{
                                fontSize: "13px",
                                marginBottom: "5px"
                            }}
                        >ABN/ACN Identifier Number:</p>
                        <input type="text" className="addUserDetails"
                            style={{
                                fontFamily: "futurabook",
                                color: "#141212"
                            }}
                            value={userInput.abnAcnIdentifierNumber}
                            onChange={(e) => handleInputChange('abnAcnIdentifierNumber', e.target.value)}
                        />
                    </div>
                </div> */}
        {/* <div className="finish-row2">
                    <div className="finish-column">
                        <p className='detailsTitle'
                            style={{
                                fontSize: "13px",
                                marginBottom: "5px"
                            }}
                        >Select Administrator</p>
                        <input type="text" className="addUserDetails"
                            style={{
                                fontFamily: "futurabook",
                                color: "#141212"
                            }}
                            value={"tbd"}
                            onChange={(e) => handleInputChange('email', e.target.value)}
                        />
                    </div>
                </div> */}
        {/* <div className="finish-row2">
                    <div className="finish-column">
                        <p className='detailsTitle'
                            style={{
                                fontSize: "13px",
                                marginBottom: "5px"
                            }}
                        >Company Email Address:</p>
                        <input type="text" className="addUserDetails"
                            style={{
                                fontFamily: "futurabook",
                                color: "#141212"
                            }}
                            value={userInput.email}
                            onChange={(e) => handleInputChange('email', e.target.value)}
                        />
                    </div>
                </div> */}
        <div
          style={{
            width: "100%",
            justifyContent: "flex-end",
            display: "flex",
            marginTop: "20px",
          }}
        >
          {/* {isError && errorMessage && (
            <div
              className="error-message"
              style={{
                color: "red",
                fontSize: "10px",
                marginTop: "15px",
                marginRight: "10px",
              }}
            >
              {typeof errorMessage === "string" ? (
                <p
                  style={{
                    fontSize: "13px",
                  }}
                >
                  {errorMessage}
                </p>
              ) : (
                Object.values(errorMessage).map((error, index) => (
                  <p
                    style={{
                      fontSize: "13px",
                    }}
                    key={index}
                  >
                    {error}
                  </p>
                ))
              )}
            </div>
          )} */}
          <button
            className="next-button"
            style={{
              fontSize: "13px",
              width: "18%",
            }}
            onClick={handleUpdateCompany}
          >
            Save
          </button>
          <Modal
            show={showEditCompanySuccessModal}
            //       onHide={handleCloseSuccessUserModal}
            centered
          >
            <Modal.Body>
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={handleCloseSuccessUserModal}
                  style={{
                    background: "none",
                    border: "none",
                    fontSize: "35px",
                    position: "absolute",
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <EditCompanySuccessModal onClose={handleCloseSuccessUserModal} />
            </Modal.Body>
          </Modal>

          <Modal
            show={showFailedEditModal}
            //    onHide={handleCloseEditFailed}
            centered
          >
            <Modal.Body>
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={handleCloseEditFailed}
                  style={{
                    background: "none",
                    border: "none",
                    fontSize: "35px",
                    position: "absolute",
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <EditCompanyFailedModal onClose={handleCloseEditFailed} />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default EditCompanyModal;
