import React, { useState } from "react";
import { useSelector } from "react-redux";
import { publicRequest } from "../../../requestMethods";
import { CircleX } from "../../../fontAwesomeComponents/FaIcons";
import { Modal } from "react-bootstrap";
import DeleteItemSuccessModal from "./DeleteItemSuccessModal";
import FailedDeleteItemModal from "./FailedDeleteItemModal";

const ConfirmDeleteItemModal = ({ onClose, item, refreshArchiveList }) => {
  console.log(item);

  const token = useSelector((state) => state.user.token);
  const userId = useSelector((state) => state.user.id);
  const selectedUserCompanyId = useSelector((state) => state.selectedCompany);

  //modal

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleOpenSuccessModal = () => {
    setShowSuccessModal(true);
    
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const toggleSuccessModal = () => {
    setShowSuccessModal(!showSuccessModal);
    handleBackToProfile();
  };

  const [showFailedModal, setShowFailedModal] = useState(false);

  const handleOpenFailedModal = () => {
    setShowFailedModal(true);
  };

  const handleCloseFailedModal = () => {
    setShowFailedModal(false);
  };

  const toggleFailedModal = () => {
    setShowFailedModal(!showFailedModal);
  };

  const handleBackToProfile = () => {
    onClose();
  };

  const DeleteTagFunction = async () => {
    const DeleteTagParams = {
      LoggedUserId: userId,
      LoggedUserCompanyId: selectedUserCompanyId,
    };

    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const requestConfigForDeleteTag = {
        method: "DELETE",
        headers: headers,
        params: DeleteTagParams,
      };

      const deleteTag = await publicRequest(
        `/item/${item.id}/delete`,
        requestConfigForDeleteTag
      );

      if (deleteTag) {
        console.log("tag deleted");
        refreshArchiveList();
        setShowSuccessModal(true);
      }

     
    } catch (error) {
      console.error(error);
      setShowFailedModal(true);
    }
  };

  return (
    <div
      style={{
        height: "37vh",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div
          style={{
            marginBottom: "20px",
            backgroundColor: "#EFC8C8",
            padding: "10px",
            borderRadius: "16px",
          }}
        >
          <CircleX height="50px" width="50px" color="#DC3545" />
        </div>

        <h2
          style={{
            fontFamily: "futuraheavy",
            fontSize: "25px",
            marginBottom: "20px",
            textAlign: "center",
            paddingRight: "20px",
            paddingLeft: "20px",
          }}
        >
          Delete Item?
        </h2>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "50%",
          }}
        >
          <button
            className="back-to-login-button"
            onClick={DeleteTagFunction}
            style={{
              backgroundColor: "#28A745",
              width: "100px",
              fontFamily: "futurabook",
              color: "#FFFFFF",
              height: "50px",
              border: "none",
              borderRadius: "10px",
            }}
          >
            Confirm
            <Modal
              centered
              show={showSuccessModal}
           //   onHide={handleCloseSuccessModal}
            >
                <Modal.Body>
                            <div className="d-flex justify-content-end">
                              <button
                                type="button"
                                className="close"
                                aria-label="Close"
                                onClick={handleCloseSuccessModal}
                                style={{
                                  background: "none",
                                  border: "none",
                                  fontSize: "35px",
                                  position: "absolute",
                                  zIndex: "999",
                                }}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
              <DeleteItemSuccessModal
                toggleSuccessModal={toggleSuccessModal}
                onClose={toggleSuccessModal}
              />
                             </Modal.Body>
            </Modal>

          
          </button>
          <Modal
              centered
              show={showFailedModal}
              onHide={handleCloseFailedModal}
            >
              
              <FailedDeleteItemModal toggleFailedModal={toggleFailedModal}
                onClose={toggleFailedModal}
              />

            </Modal>
          <button
            className="back-to-login-button"
            onClick={handleBackToProfile}
            style={{
              backgroundColor: "#eeeeee",
              width: "100px",
              fontFamily: "futurabook",
              color: "#303841",
              height: "50px",
              border: "none",
              borderRadius: "10px",
            }}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteItemModal;
