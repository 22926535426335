import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { publicRequest } from "../../requestMethods";
import AddCompanySuccess from "./ValidationModals/AddCompanySuccess";
import FailedAddCompany from "./ValidationModals/FailedAddCompany";
import "../../css/Validation.css";

const AddCompanyModal = () => {
  const user = useSelector((state) => state.user);
  const userId = useSelector((state) => state.user.id);
  const token = useSelector((state) => state.user.token);
  const userCompanyId = useSelector((state) => state.selectedCompany);
  const selectedUserCompanyId = useSelector((state) => state.selectedCompanyId);
  const [isAddressLine1Error, setIsAddressLine1Error] = useState(false);
  const [isAddressLine2Error, setIsAddressLine2Error] = useState(false);

  const [companyNameErrorDuplicate, setCompanyNameErrorDuplicate] = useState(
    []
  );

  const [companyNameErrorMessage, setCompanyNameErrorMessage] = useState("");

  const [formSubmitted, setFormSubmitted] = useState(false);

  // Validation messages for required fields
  const [validationMessages, setValidationMessages] = useState({
    name: "",
    addressLine1: "",
    addressLine2: "",
    country: "",
    state: "",
    city: "",
    postCode: "",
    //   officePhone: "",
    abnAcnIdentifierNumber: "",
  });

  // Validation function for each required field
  const validateFields = () => {
    const messages = {};

    // Validate Company Name
    if (!companyDetails.name.trim()) {
      messages.name = "Company Name is required";
    }

    // // Validate Address Line 1
    // if (!companyDetails.addressLine1.trim()) {
    //   messages.addressLine1 = "Address Line 1 is required";
    // }

    // Validate Address Line 1
    if (!companyDetails.addressLine1.trim()) {
      messages.addressLine1 = "Address Line 1 is required";
    } else if (!/^[a-zA-Z0-9\s]*$/.test(companyDetails.addressLine1)) {
      setIsAddressLine1Error(true);
      messages.addressLine1 =
        "Address Line 1 must only contain alphanumeric characters.";
    } else {
      messages.addressLine1 = "";
      setIsAddressLine1Error(false);
    }

    // Validate Address Line 2
    if (
      companyDetails.addressLine2.trim() &&
      !/^[a-zA-Z0-9\s]*$/.test(companyDetails.addressLine2)
    ) {
      setIsAddressLine2Error(true);
      messages.addressLine2 =
        "Address Line 2 must only contain alphanumeric characters.";
    }

    // Validate Country
    if (!selectedCountry) {
      messages.country = "Select a Country";
    }

    // Validate State
    if (!selectedState) {
      messages.state = "Select a State/Province";
    }

    // Validate City
    if (!companyDetails.city.trim()) {
      messages.city = "City is required";
    }

    // Validate Postal Code
    if (!companyDetails.postCode.trim()) {
      messages.postCode = "Postal Code is required";
    }

    const officePhoneValue = companyDetails.officePhone.trim();
    if (
      officePhoneValue &&
      selectedCountry === "1" &&
      !["+61", "+64", "+678", "+"].includes(officePhoneValue) &&
      ((officePhoneValue.startsWith("+61") && officePhoneValue.length !== 12) ||
        (officePhoneValue.startsWith("+678") && officePhoneValue.length !== 4))
    ) {
      messages.officePhone = "Invalid office phone number";
    } else if (
      officePhoneValue &&
      selectedCountry === "2" &&
      !["+61", "+64", "+678", "+"].includes(officePhoneValue) &&
      officePhoneValue.length !== 12
    ) {
      messages.officePhone = "Invalid office phone number";
    } else if (
      officePhoneValue &&
      selectedCountry === "" &&
      !["+61", "+64", "+678", "+"].includes(officePhoneValue)
    ) {
      messages.officePhone = "Please enter a valid phone number";
    }
    setValidationMessages(messages);

    // Validate ABN/ACN Identifier Number
    if (!companyDetails.abnAcnIdentifierNumber.trim()) {
      messages.abnAcnIdentifierNumber = "ABN/ACN Identifier Number is required";
    }

    if (
      companyDetails.abnAcnIdentifierNumber.trim().length > 0 &&
      companyDetails.abnAcnIdentifierNumber.trim().length !== 10
    ) {
      messages.abnAcnIdentifierNumber =
        "ABN/ACN Identifier Number should be 11 digits";
    }

    if (
      companyDetails.postCode.trim().length > 0 &&
      companyDetails.postCode.trim().length < 4
    ) {
      messages.postCode = "Postal code should be 4 digits";
    }

    setValidationMessages(messages);

    // Check if any validation errors exist
    return Object.values(messages).every((msg) => !msg);
  };

  // Function to handle form submission
  const handleAddCompany = () => {
    const isValid = validateFields();

    // Perform your createCompany logic
    createCompany();
  };

  //modals

  const [showAddSuccessModal, setShowAddSuccessModal] = useState(false);
  const [showFailedModal, setShowFailedModal] = useState(false);

  const handleCloseSuccessModal = () => {
    setShowAddSuccessModal(false);
  };

  const handleCloseFailedModal = () => {
    setShowFailedModal(false);
  };

  const [countryPrefix, setCountryPrefix] = useState("");

  const [companyDetails, setCompanyDetails] = useState({
    name: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    stateId: "",
    countryId: "",
    postCode: "",
    officePhone: "",
    masterElectricianNumber: "",
    abnAcnIdentifierNumber: "",
  });

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);

  const createCompany = async () => {
    setFormSubmitted(true);

    // Check office phone value and set to blank if it matches the conditions
    let officePhone = companyDetails.officePhone;
    if (
      officePhone === "+64" ||
      officePhone === "+61" ||
      officePhone === "+678" ||
      officePhone === "+"
    ) {
      officePhone = "";
    }

    const data = {
      loggedUserId: userId,
      loggedUserCompanyId: userCompanyId,
      companyId: selectedUserCompanyId,
      ...companyDetails,
      officePhone, // Override the officePhone field with the potentially blank value
    };

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await publicRequest({
        method: "POST",
        url: "/company/create",
        data,
        headers,
      });
      console.log(response.data);
      console.log("this is data", data);
      setShowAddSuccessModal(true);
      console.log(response);
    } catch (error) {
      setShowFailedModal(true);
      console.error(error);
      console.error(error.response.data.errors);
      setErrorMessage(error.response.data.errors);
      setIsError(true);
      if (error.response.status === 409) {
        setCompanyNameErrorDuplicate("Company Name already exists");
        setIsError(true);
      }
    }
  };

  const handleInputChange = (field, value) => {
    if (field === "mobilePhone" || field === "officePhone") {
      // Remove non-numeric characters from the input
      value = value.replace(/\D/g, "");

      // Update the countryPrefix when changing the officePhone

      // const newCountryPrefix =
      //   selectedCountry === "1" ? "61" : selectedCountry === "2" ? "64" : "";
      // setCountryPrefix(newCountryPrefix);
    }

    // For ABN/ACN Identifier Number and Postal Code, allow only numerical input
    if (field === "abnAcnIdentifierNumber" || field === "postCode") {
      value = value.replace(/\D/g, "");
    }

    if (field === "officePhone") {
      // Concatenate plus sign with the office phone value
      value = "+" + value;
    }

    if (field === "name") {
      const alphanumericRegex = /^[a-zA-Z0-9\s]+$/;
      if (!alphanumericRegex.test(value)) {
        setCompanyNameErrorMessage({
          name: "Company Name must only contain alphanumeric characters.",
        });
        setIsError(true);
      } else {
        setCompanyNameErrorMessage("");
        setIsError(false);
      }
    }

    setCompanyDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
    validateFields();
  };

  const [countryList, setCountryList] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState("");
  const [stateList, setStateList] = useState([]);
  const [selectedStateId, setSelectedStateId] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");

  const handleCountryChange = (value) => {
    const selectedCountryObj = countryList.find(
      (country) => country.id === value
    );
    setSelectedCountry(value);

    // Set the countryPrefix based on the selected countryId
    const newCountryPrefix = value === "1" ? "+61" : value === "2" ? "+64" : "";
    setCountryPrefix(newCountryPrefix);

    // Automatically update the officePhone field with the country prefix
    setCompanyDetails((prevDetails) => ({
      ...prevDetails,
      countryId: value,
      country: selectedCountryObj ? selectedCountryObj.name : "",
      officePhone: newCountryPrefix, // Update officePhone with the country prefix
    }));

    // Reset the selected state when changing the country
    setSelectedState("");
  };

  const handleStateChange = (value) => {
    const selectedStateObj = stateList.find((state) => state.id === value);
    setSelectedState(value);
    setCompanyDetails((prevDetails) => ({
      ...prevDetails,
      stateId: value,
      state: selectedStateObj ? selectedStateObj.name : "",
    }));
  };

  const fetchData = async (url) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const queryParams = {
        LoggedUserId: userId,
        LoggedUserCompanyId: userCompanyId,
      };

      const queryString = new URLSearchParams(queryParams).toString();
      const fullUrl = url + (queryString ? `?${queryString}` : "");

      const response = await publicRequest({
        method: "GET",
        url: fullUrl,
        headers: headers,
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching data", error);

      return null;
    }
  };

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const getCountryResponse = await fetchData("/lookup/countries");
        if (getCountryResponse) {
          setCountryList(getCountryResponse);
        }
      } catch (error) {
        console.error(error);
      }

      try {
        const getStateResponse = await fetchData(
          `lookup/country/${selectedCountry}/states`
        );
        if (getStateResponse) {
          setStateList(getStateResponse);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchLocations();
  }, [selectedCountry]);

  return (
    <div
      className="ModalChildDiv"
      style={{
        alignItems: "center",
        display: "flex",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <div
        className="modal-content custom-modal-content"
        style={{
          padding: "10px",
          paddingLeft: "20px",
          paddingRight: "20px",
          background: "none",
          border: "none",
        }}
      >
        <div className="row">
          <div
            className="finalTitleDiv"
            style={{
              alignItems: "flex-start",
            }}
          >
            <h3
              style={{
                fontFamily: "futuramedium",
                fontWeight: "bold",
                fontSize: "30px",
                marginTop: "15px",
              }}
            >
              Add Company
            </h3>
          </div>
        </div>
        <div className="finish-row">
          <div className="finish-column wholeRow">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Company Name*:
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
              value={companyDetails.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
            />
            {companyDetails.name === "" && formSubmitted && (
              <div className="validation-message">
                {validationMessages.name}
              </div>
            )}

            {isError &&
              companyNameErrorDuplicate === "Company Name already exists" && (
                <div
                  className="validation-message"
                  style={
                    {
                      /* Add your custom styles here */
                    }
                  }
                >
                  Company Name already exists
                </div>
              )}

            {isError &&
              companyNameErrorMessage.name ===
                "Company Name must only contain alphanumeric characters." &&  companyDetails.name !== "" &&(
                <div
                  className="validation-message"
                  style={
                    {
                      /* Add your custom styles here */
                    }
                  }
                >
                  Company Name must only contain alphanumeric characters.
                </div>
              )}
          </div>
        </div>

        <div className="finish-row">
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Address Line 1*:
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
              value={companyDetails.addressLine1}
              onChange={(e) =>
                handleInputChange("addressLine1", e.target.value)
              }
              maxLength={30}
            />
            {companyDetails.addressLine1 === "" && formSubmitted && (
              <div className="validation-message">
                {validationMessages.addressLine1}
              </div>
            )}

            {companyDetails.addressLine1 !== "" &&
              isAddressLine1Error &&
              formSubmitted && (
                <div className="validation-message">
                  {validationMessages.addressLine1}
                </div>
              )}
          </div>
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Address Line 2:
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
              value={companyDetails.addressLine2}
              onChange={(e) =>
                handleInputChange("addressLine2", e.target.value)
              }
              maxLength={30}
            />

            {isAddressLine2Error && formSubmitted && (
              <div className="validation-message">
                {validationMessages.addressLine2}
              </div>
            )}
          </div>
        </div>
        <div className="finish-row">
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              City*:
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
              value={companyDetails.city}
              onChange={(e) => handleInputChange("city", e.target.value)}
            />
            {companyDetails.city === "" && formSubmitted && (
              <div className="validation-message">
                {validationMessages.city}
              </div>
            )}
          </div>

          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Postal Code*:
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
              value={companyDetails.postCode}
              onChange={(e) => handleInputChange("postCode", e.target.value)}
              maxLength={4}
            />
            {companyDetails.postCode === "" && formSubmitted && (
              <div className="validation-message">
                {validationMessages.postCode}
              </div>
            )}

            {companyDetails.postCode.length !== 4 &&
              companyDetails.postCode.length > 0 &&
              formSubmitted && (
                <div className="validation-message">
                  {validationMessages.postCode}
                </div>
              )}
          </div>
        </div>
        <div className="finish-row">
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "2px",
              }}
            >
              Country*:
            </p>
            <select
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
                fontWeight: "bold",
                height: "25px",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
              }}
              value={selectedCountry}
              onChange={(e) => handleCountryChange(e.target.value)}
            >
              <option value="">Select Country</option>
              {countryList.map((country) => (
                <option key={country.id} value={country.id}>
                  {country.name}
                </option>
              ))}
            </select>
            {selectedCountry === "" && formSubmitted && (
              <div className="validation-message">
                {validationMessages.country}
              </div>
            )}
          </div>

          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "2px",
              }}
            >
              State/Province*:
            </p>
            <select
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
                fontWeight: "bold",
                height: "25px",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
              }}
              value={selectedState}
              onChange={(e) => handleStateChange(e.target.value)}
            >
              <option value="">Select State</option>
              {stateList.map((state) => (
                <option key={state.id} value={state.id}>
                  {state.name}
                </option>
              ))}
            </select>
            {selectedState === "" && formSubmitted && (
              <div className="validation-message">
                {validationMessages.state}
              </div>
            )}
          </div>
        </div>

        <div className="finish-row">
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Office Phone Number:
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
              value={companyDetails.officePhone}
              onChange={(e) => handleInputChange("officePhone", e.target.value)}
              maxLength={12}
            />
            {companyDetails.officePhone !== "" && formSubmitted && (
              <div className="validation-message">
                {validationMessages.officePhone}
              </div>
            )}
          </div>
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              ABN/ACN Identifier Number*:
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
              value={companyDetails.abnAcnIdentifierNumber}
              onChange={(e) =>
                handleInputChange("abnAcnIdentifierNumber", e.target.value)
              }
              maxLength={11}
            />
            {companyDetails.abnAcnIdentifierNumber === "" && formSubmitted && (
              <div className="validation-message">
                {validationMessages.abnAcnIdentifierNumber}
              </div>
            )}

            {companyDetails.abnAcnIdentifierNumber !== 11 &&
              companyDetails.abnAcnIdentifierNumber > 0 &&
              formSubmitted && (
                <div className="validation-message">
                  {validationMessages.abnAcnIdentifierNumber}
                </div>
              )}
          </div>
        </div>

        <div
          style={{
            width: "100%",
            justifyContent: "space-between",
            display: "flex",
            marginTop: "20px",
          }}
        >
          <p
            style={{
              fontSize: "11px",
              color: "#141212",
            }}
          >
            The (*) Star denotes that the question must be completed to SAVE the
            data
          </p>
          {/* {isError && errorMessage && (
            <div
              className="error-message"
              style={{
                color: "red",
                fontSize: "10px",
                marginTop: "15px",
                marginRight: "10px",
              }}
            >
              {typeof errorMessage === "string" ? (
                <p
                  style={{
                    fontSize: "13px",
                  }}
                >
                  {errorMessage}
                </p>
              ) : (
                Object.values(errorMessage).map((error, index) => (
                  <p
                    style={{
                      fontSize: "13px",
                    }}
                    key={index}
                  >
                    {error}
                  </p>
                ))
              )}
            </div>
          )} */}
          {/* {Object.keys(validationMessages).map((fieldName) => (
            <div key={fieldName} className="validation-message">
              {validationMessages[fieldName]}
            </div>
          ))} */}
          <button
            className="next-button"
            style={{
              fontSize: "13px",
              width: "18%",
              fontFamily: "futuraheavy",
              fontWeight: "bold",
            }}
            onClick={handleAddCompany}
          >
            Add New Company
          </button>
          <Modal show={showAddSuccessModal} centered>
            <Modal.Body>
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={handleCloseFailedModal}
                  style={{
                    background: "none",
                    border: "none",
                    fontSize: "35px",
                    position: "absolute",
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <AddCompanySuccess onClose={handleCloseSuccessModal} />
            </Modal.Body>
          </Modal>

          <Modal show={showFailedModal} centered>
            <Modal.Body>
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={handleCloseFailedModal}
                  style={{
                    background: "none",
                    border: "none",
                    fontSize: "35px",
                    position: "absolute",
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <FailedAddCompany onClose={handleCloseFailedModal} />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default AddCompanyModal;
