import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { publicRequest } from "../requestMethods";
import "../css/modalcss/addUserModal.css";
import "bootstrap/dist/css/bootstrap.min.css"; // Make sure to import Bootstrap's CSS
import EditCompanySuccessModal from "./EditCompanySuccessModal";
import EditCompanyFailedModal from "./EditCompanyFailedModal";
import { Modal } from "react-bootstrap";

const SingleEditCompanyModal = ({ company }) => {
  const [errorMessage, setErrorMessage] = useState([]);
  const [isError, setIsError] = useState(false);
  console.log(company);

  const [formSubmitted, setFormSubmitted] = useState(false);

  const [showEditCompanySuccessModal, setShowEditCompanySuccessModal] =
    useState(false);

  const handleCloseSuccessUserModal = () => {
    setShowEditCompanySuccessModal(false);
  };

  const [showFailedModal, setShowFailedModal] = useState(false);

  const handleCloseFailedModal = () => {
    setShowFailedModal(false);
  };

  const [countryList, setCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(company.countryId);

  const [stateList, setStateList] = useState([]);
  const [selectedState, setSelectedState] = useState(company.stateId || "");

  const [validationMessages, setValidationMessages] = useState({
    officePhone: "",
  });

  const validateFields = () => {
    const messages = {};

    const officePhoneValue = userInput.officePhone.trim();
    if (
      officePhoneValue &&
      selectedCountry === "1" &&
      ((officePhoneValue.startsWith("61") && officePhoneValue.length !== 11) ||
        (officePhoneValue.startsWith("678") && officePhoneValue.length !== 4))
    ) {
      messages.officePhone = "Please enter a valid phone number";
    } else if (
      officePhoneValue &&
      selectedCountry === "2" &&
      officePhoneValue.length !== 11
    ) {
      messages.officePhone = "Please enter a valid phone number";
    } else if (officePhoneValue && selectedCountry === "") {
      messages.officePhone = "Please enter a valid phone number";
    }

    const abnAcnValue = userInput.abnAcnIdentifierNumber.trim();
    if (abnAcnValue && abnAcnValue.length !== 11) {
      messages.abnAcnIdentifierNumber =
        "ABN/ACN Identifier Number should be 11 digits";
    }

    const postalCodeValue = userInput.postCode.trim();
    if (postalCodeValue && postalCodeValue.length !== 4) {
      messages.postCode = "Postal code must be 4 digits";
    }

    setValidationMessages(messages);
  };

  const fetchData = async (url) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await publicRequest({
        method: "GET",
        url: url,
        headers: headers,
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching data", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchStateCountryData = async () => {
      try {
        const countryResponse = await fetchData(`/lookup/countries`);
        const stateResponse = await fetchData(
          `lookup/country/${selectedCountry.id}/states`
        );

        if (countryResponse) {
          setCountryList(countryResponse);
        }

        if (stateResponse) {
          setStateList(stateResponse);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchStateCountryData();
  }, []);

  useEffect(() => {
    // Fetch states based on the selected country when the selectedCountry changes
    if (selectedCountry) {
      fetchStates(selectedCountry);
    }
  }, [selectedCountry]);

  //////////////////
  const token = useSelector((state) => state.user.token);
  const userId = useSelector((state) => state.user.id);
  const selectedCompanyId = useSelector((state) => state.selectedCompanyId);
  const selectedUserCompanyId = useSelector((state) => state.selectedCompany);

  const [userInput, setUserInput] = useState({
    name: company.name,
    addressLine1: company.addressLine1,
    addressLine2: company.addressLine2,
    city: company.city,
    postCode: company.postCode,
    stateId: company.stateId,
    countryId: company.countryId,
    abnAcnIdentifierNumber: company.abnAcnIdentifierNumber,
    officePhone: company.officePhone || "",
  });

  ///updating company

  const updateCompany = async () => {
    validateFields();
    setFormSubmitted(true);

    const data = {
      loggedUserId: userId,
      loggedUserCompanyId: selectedUserCompanyId,
      companyId: company.id,
      name: userInput.name,
      addressLine1: userInput.addressLine1,
      addressLine2: userInput.addressLine2,
      city: userInput.city,
      postCode: userInput.postCode,
      stateId: selectedState,
      countryId: selectedCountry,
      abnAcnIdentifierNumber: userInput.abnAcnIdentifierNumber,
      officePhone: userInput.officePhone,
    };

    console.log(data);

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await publicRequest({
        method: "POST",
        url: `/company/${company.id}/update`,
        data: data,
        headers: headers,
      });

      setShowEditCompanySuccessModal(true);
      console.log(response.data);
    } catch (error) {
      console.error(error);
      setShowFailedModal(true);
      console.error(error.response.data.errors);
      setErrorMessage(error.response.data.errors || error.response.data.title);
      setIsError(true);
    }
  };

  const isValidOfficeContactNumber = (mobilePhone) => {
    const trimmedNumber = mobilePhone.replace(/\D/g, ""); // Remove non-numeric characters
    if (trimmedNumber.startsWith("61") && trimmedNumber.length === 11) {
      return true;
    } else if (
      trimmedNumber.startsWith("64") &&
      [11].includes(trimmedNumber.length)
    ) {
      return true;
    } else if (trimmedNumber.startsWith("678") && trimmedNumber.length === 7) {
      return true;
    } else {
      return false;
    }
  };



  const handleCountryChange = (e) => {
    const selectedCountryId = e.target.value;
    setSelectedCountry(selectedCountryId);
    // Fetch states for the selected country based on the ID and update stateList
    fetchStates(selectedCountryId);
  };

  const handleStateChange = (e) => {
    const selectedStateId = e.target.value;
    setSelectedState(selectedStateId);
  };

  const fetchStates = async (countryId) => {
    try {
      const stateResponse = await fetchData(
        `lookup/country/${countryId}/states`
      );
      if (stateResponse) {
        setStateList(stateResponse);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (field, value) => {
    if (field === "officePhone") {
      // Allow only numbers in the office phone input
      value = value.replace(/[^0-9]/g, "");

      if (value && value.length > 0) {
        // Concatenate "+" with the input value
        value = "+" + value;
      }
    }

  

    if (field === "abnAcnIdentifierNumber") {
      // Allow only numbers in the ABN/ACN Identifier Number input
      value = value.replace(/\D/g, "");
    }

    setUserInput((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  return (
    <div className="ModalChildDiv"
      style={{
        alignItems: "center",
        display: "flex",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <div
        className="modal-content custom-modal-content"
        style={{
          padding: "10px",
          paddingLeft: "20px",
          paddingRight: "20px",
          background: "none",
          border: "none",
        }}
      >
        <div className="row">
          <div
            className="finalTitleDiv"
            style={{
              alignItems: "flex-start",
            }}
          >
            <h3
              style={{
                fontFamily: "futuramedium",
                fontWeight: "bold",
                fontSize: "30px",
                marginTop: "15px",
              }}
            >
              Edit Company
            </h3>
          </div>
        </div>
        <div className="finish-row2">
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Company*:
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
              value={userInput.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
            />
            {userInput.name === "" && formSubmitted && (
              <div className="validation-message" style={{}}>
                Company Name is required
              </div>
            )}
          </div>
        </div>
        <div className="finish-row"></div>

        <div className="finish-row">
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Company Address Line 1*:
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
              value={userInput.addressLine1}
              onChange={(e) =>
                handleInputChange("addressLine1", e.target.value)
              }
              maxLength={50}
            />
            {userInput.addressLine1 === "" && formSubmitted && (
              <div className="validation-message" style={{}}>
                Company Address Line 1 is required
              </div>
            )}
          </div>
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Company Address Line 2:
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
              value={userInput.addressLine2}
              onChange={(e) =>
                handleInputChange("addressLine2", e.target.value)
              }
              maxLength={50}
            />
          </div>
        </div>
        <div className="finish-row">
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              City*:
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
              value={userInput.city}
              onChange={(e) => handleInputChange("city", e.target.value)}
              maxLength={30}
            />

            {userInput.city === "" && formSubmitted && (
              <div className="validation-message" style={{}}>
                City is Required
              </div>
            )}
          </div>

          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Postal Code*:
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
              value={userInput.postCode}
              onChange={(e) => handleInputChange("postCode", e.target.value)}
              maxLength={4}
            />
            {userInput.postCode === "" && formSubmitted && (
              <div className="validation-message" style={{}}>
                Postal Code is Required
              </div>
            )}
            {validationMessages.postCode && (
              <div className="validation-message" style={{}}>
                {validationMessages.postCode}
              </div>
            )}
          </div>
        </div>
        <div className="finish-row">
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "3px",
              }}
            >
              Country*:
            </p>
            <select
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
                fontWeight: "bold",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                height: "25px",
              }}
              value={selectedCountry}
              onChange={handleCountryChange}
            >
              {/* Map over the countryList to create options */}
              {countryList.map((country) => (
                <option key={country.id} value={country.id}>
                  {country.name}
                </option>
              ))}
            </select>
            {selectedCountry === "" && formSubmitted && (
              <div className="validation-message" style={{}}>
                Please Select a Country
              </div>
            )}
          </div>
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "3px",
              }}
            >
              State/Province*:
            </p>
            <select
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
                fontWeight: "bold",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                height: "25px",
              }}
              value={selectedState}
              onChange={handleStateChange}
            >
              {/* Map over the stateList to create options */}
              {stateList.map((state) => (
                <option key={state.id} value={state.id}>
                  {state.name}
                </option>
              ))}
            </select>

            {selectedState === "" && formSubmitted && (
              <div className="validation-message" style={{}}>
                Please Select a State
              </div>
            )}
          </div>
        </div>

        <div className="finish-row">
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Office Phone Number:
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
              value={userInput.officePhone}
              onChange={(e) => {
                const inputValue = e.target.value;
                handleInputChange("officePhone", inputValue);
              }}
              maxLength={12}
            />

            {formSubmitted &&
              userInput.officePhone !== "" &&
              !isValidOfficeContactNumber(userInput.officePhone) && (
                <div className="validation-message" style={{}}>
                  Please enter a valid Phone number
                </div>
              )}
          </div>

          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              ABN/ACN Identifier Number*:
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
              value={userInput.abnAcnIdentifierNumber}
              onChange={(e) =>
                handleInputChange("abnAcnIdentifierNumber", e.target.value)
              }
              maxLength={11}
            />
            {validationMessages.abnAcnIdentifierNumber && (
              <div className="validation-message" style={{}}>
                {validationMessages.abnAcnIdentifierNumber}
              </div>
            )}
            {userInput.abnAcnIdentifierNumber === "" && formSubmitted && (
              <div className="validation-message" style={{}}>
                ABN/ACN Identifier Number is required
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            width: "100%",
            justifyContent: "flex-end",
            display: "flex",
            marginTop: "20px",
          }}
        >
          {/* {isError && errorMessage && (
            <div
              className="error-message"
              style={{
                color: "red",
                fontSize: "10px",
                marginTop: "15px",
                marginRight: "10px",
              }}
            >
              {typeof errorMessage === "string" ? (
                <p
                  style={{
                    fontSize: "13px",
                  }}
                >
                  {errorMessage}
                </p>
              ) : (
                Object.values(errorMessage).map((error, index) => (
                  <p
                    style={{
                      fontSize: "13px",
                    }}
                    key={index}
                  >
                    {error}
                  </p>
                ))
              )}
            </div>
          )} */}
          <button
            className="next-button"
            style={{
              fontSize: "13px",
              width: "18%",
            }}
            onClick={updateCompany}
          >
            Save
          </button>
          <Modal
            show={showEditCompanySuccessModal}
            //     onHide={handleCloseSuccessUserModal}
            centered
          >
            <Modal.Body>
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={handleCloseSuccessUserModal}
                  style={{
                    background: "none",
                    border: "none",
                    fontSize: "35px",
                    position: "absolute",
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <EditCompanySuccessModal />
            </Modal.Body>
          </Modal>

          <Modal
            show={showFailedModal}
            //    onHide={handleCloseFailedModal}
            centered
          >
            <Modal.Body>
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={handleCloseFailedModal}
                  style={{
                    background: "none",
                    border: "none",
                    fontSize: "35px",
                    position: "absolute",
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <EditCompanyFailedModal onClose={handleCloseFailedModal} />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default SingleEditCompanyModal;
