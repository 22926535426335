import React, { useEffect, useState } from "react";
import "../../css/userApprovalcss/userApproval.css";
import "../../css/Tables.css";
import { publicRequest, userRequest } from "../../requestMethods";
import {
  ThinEditIcon,
  ThinArchive,
  RefreshIcon,
  DownloadIcon,
  ThinAddNote,
} from "../../fontAwesomeComponents/FaIcons";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import AddTagsModal from "./AddTagsModal";
import EditTagsModal from "./EditTagsModal";
import AddTagNoteModal from "./AddTagNoteModal";
import { useNavigate } from "react-router-dom";
import TagsAttachmentsModal from "./TagsAttachmentsModal";
import { refreshTokenFunction } from "../../redux/apiCals";
import ConfirmDeleteTagModal from "../TagArchive/ValidationModals/ConfirmDeleteTagModal";
import TagAccessValidationModal from "./ValidationModals/TagAccessValidationModal";
import LocationViewerModal from "../LocationListComponents/LocationViewerModal";

const TagsTable = ({ searchQuery }) => {
  const dispatch = useDispatch();

  const [selectedTag, setSelectedTag] = useState(null);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [tagData, setTagData] = useState([]);
  const [tagNoteList, setTagNoteList] = useState([]);
  const [tagNote, setTagNote] = useState();
  const [tagNoteExpanded, setTagNoteExpanded] = useState({});
  const [tagDescriptionExpanded, setTagDescriptionExpanded] = useState({});

  const [filteredTags, setFilteredTags] = useState([]);

  const navigate = useNavigate();

  const handleArchiveButtonClick = () => {
    navigate("/tags/archive");
  };

  //modals
  const [openTagsModal, setOpenTagsModal] = useState(false);
  const [openEditTagModal, setOpenEditTagModal] = useState(false);
  const [openAddTagNoteModal, setOpenAddTagNoteModal] = useState(false);
  const [showArchiveSuccessModal, setShowArchiveSuccessModal] = useState(false);
  const [openTagAttachments, setOpenTagAttachments] = useState(false);
  const [openConfirmArchiveModal, setOpenConfirmArchiveModal] = useState(false);
  const [isAddNoteHovered, setIsAddNoteHovered] = useState(false);
  const [isEditTagHovered, setIsEditTagHovered] = useState(false);
  const [isArchiveHovered, setIsArchivehovered] = useState(false);
  const [openLocationViewer, setOpenLocationViewer] = useState(false);
  const [isRefreshHovered, setIsRefreshHovered] = useState(false);

  const [hoveredTag, setHoveredTag] = useState(null);

  const [tagDoesNotExistModal, setTagDoesNotExistModal] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  //

  const handleOpenTagDoesNotExistModal = () => {
    setTagDoesNotExistModal(true);
  };

  const handleCloseTagDoesNotExistModal = () => {
    setTagDoesNotExistModal(false);
  };

  const handleOpenConfirmArchiveModal = (tag) => {
    setSelectedTag(tag);
    setOpenConfirmArchiveModal(true);
    setIsArchivehovered(tag);
  };

  const handleCloseConfirmArchiveModal = () => {
    setOpenConfirmArchiveModal(false);
    setIsArchivehovered(null);
  };

  const handleOpenTagAttachments = (tag) => {
    setSelectedTag(tag);
    setOpenTagAttachments(true);
  };

  const handleOpenArchiveModal = (tag) => {
    setSelectedTag(tag);
    ArchiveTag(tag);
    setShowArchiveSuccessModal(true);
  };

  const handleCloseArchiveArchiveModal = (tag) => {
    setSelectedTag(tag);
    setShowArchiveSuccessModal(false);
  };

  const handleCloseTagAttachments = () => {
    setOpenTagAttachments(false);
  };

  const handleOpenAddTagNoteModal = (tag) => {
    setSelectedTag(tag);
    setOpenAddTagNoteModal(true);
    setHoveredTag(tag);
  };

  const handleCloseAddTagNoteModal = () => {
    setOpenAddTagNoteModal(false);
    setHoveredTag(null);
  };

  const handleOpenAddTagModal = () => {
    setOpenTagsModal(true);
  };

  const handleCloseAddTagModal = () => {
    setOpenTagsModal(false);
  };

  const handleOpenEditTagModal = (tag) => {
    setSelectedTag(tag);
    setOpenEditTagModal(true);
    setIsEditTagHovered(tag);
  };

  const handleCloseEditTagModal = () => {
    setOpenEditTagModal(false);
    setIsEditTagHovered(null);
  };

  const handleSelectAllChange = () => {
    setSelectAllChecked(!selectAllChecked);
    setSelectedRows(selectAllChecked ? [] : [...Array(tagsList.length).keys()]);
  };

  const handleOpenLocationViewer = (tag) => {
    setSelectedTag(tag);
    setOpenLocationViewer(true);
  };

  const handleCloseLocationViewer = () => {
    setOpenLocationViewer(false);
  };

  const handleRowSelection = (index) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(index)
        ? prevSelectedRows.filter((item) => item !== index)
        : [...prevSelectedRows, index]
    );
  };

  const token = useSelector((state) => state.user.token);
  const userCompanyId = useSelector((state) => state.selectedCompany);
  const id = useSelector((state) => state.user.id);
  const refreshToken = useSelector((state) => state.user.refreshToken);
  const userRole = useSelector((state) => state.selectedCompanyRoleId);

  // console.log(tokenRefresher);

  const formatAndConvertDate = (dateString) => {
    if (!dateString) return "";
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      timeZone: userTimezone,
    };
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString(undefined, options);
    const [month, day, year] = formattedDate.split("/");
    return `${day}/${month}/${year}`;
  };

  const formatDate = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${day}/${month}/${year}`;
  };

  const formatTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
  };

  //getting tags

  const queryParams = {
    LoggedUserId: id,
    LoggedUserCompanyId: userCompanyId,
  };

  const fetchData = async (url) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const queryString = new URLSearchParams(queryParams).toString();
      const fullUrl = url + (queryString ? `?${queryString}` : "");

      const response = await publicRequest({
        method: "GET",
        url: fullUrl,
        headers: headers,
      });

      return response.data;
    } catch (error) {
      const res = await refreshTokenFunction(dispatch, { token, refreshToken });
      console.log(res);
      console.error("Error fetching data", error);

      return null;
    }
  };

  const fetchTagData = async () => {
    try {
      setIsLoading(true);
      const getTagResponse = await fetchData("/tag/list");

      console.log(tagsList);
      if (getTagResponse) {
        const updatedTagsList = getTagResponse.map((tag) => {
          tag.dateCreatedUtc = new Date(tag.dateCreatedUtc);

          // Map tag notes and set the custom "Tag Note" string
          const tagNoteResponse = tag.tagNotes || [];

          // Check if there are notes
          if (tagNoteResponse.length > 0) {
            const latestNote = tagNoteResponse[0];
            const customTagNote = ` ${latestNote.note}`;

            tag.note = customTagNote;
            tag.note1 = latestNote.latitude;
          } else {
            tag.note = ""; // No notes, set to an empty string
          }

          return tag;
        });

        // Sort tagsList by date in descending order
        updatedTagsList.sort((a, b) => b.dateCreatedUtc - a.dateCreatedUtc);

        setTagsList(updatedTagsList);
      }
    } catch (error) {
      console.error("error fetching data", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getTagBySerialNumber = async () => {
    console.log(searchQuery);
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const requestConfigForGettingTagBySerial = {
        method: "GET",
        headers: headers,
        params: queryParams,
      };

      const searchTag = await publicRequest(
        `/tag/serialnumber/${searchQuery}`,
        requestConfigForGettingTagBySerial
      );

      //const searchTag = await publicRequest(`/tag/serialnumber/${searchQuery}`,queryParams);
      console.log(searchTag);
      if (searchTag) {
        console.log("this is searchtag", searchTag);
      }
    } catch (error) {
      console.log(isError);
      //  setIsError(true);
      console.error(error.response.status);
      if (error.response.status === 404) {
        setErrorMessage("This TAG does not exist in the database.");
        setIsError(true);
        console.error("tag does not exist", error);
        setTagDoesNotExistModal(true);
      } else if (
        error.response.data.errors &&
        "Tag.InvalidTagPermission" in error.response.data.errors &&
        error.response.status === 400 &&
        filteredTags.length == 0
      ) {
        setIsError(true);
        setErrorMessage(
          "Your User access is not authorized to view this TAG in the database, because it belongs to a different company."
        );
        setTagDoesNotExistModal(true);

        console.error(error);
      } else {
        // Handle other errors or provide a generic error message.
        console.error("Unhandled error:", error);
      }
    }
  };

  useEffect(() => {
    console.log("Error Message:", errorMessage);
  }, [errorMessage, searchQuery]);

  useEffect(() => {
    fetchTagData();
    // getTagBySerialNumber();
  }, []);

  useEffect(() => {
    const filteredTags = tagsList.filter((tag) => {
      // Customize the conditions for filtering based on your tag structure

      return (
        tag.serialNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tag.siteName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tag.createdBy.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tag.identifiersNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tag.locationName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tag.tagItemTypeName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tag.dateAppliedUtc.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tag.description.toLowerCase().includes(searchQuery.toLowerCase()) 
        // Add more conditions as needed
        // ...
      );
    });

    setFilteredTags(filteredTags);
    setSelectAllChecked(false);
    setCurrentPage(1);

    getTagBySerialNumber();
  }, [searchQuery, tagsList]);

  const ArchiveTag = async (tag) => {
    const ArchiveTagParams = {
      LoggedUserId: id,
      LoggedUserCompanyId: userCompanyId,
    };

    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const requestConfigForArchiveTag = {
        method: "DELETE",
        headers: headers,
        params: ArchiveTagParams,
      };

      const archiveTagLink = await publicRequest(
        `/tag/${tag.id}/archive`,
        requestConfigForArchiveTag
      );

      if (archiveTagLink) {
        console.log("tag removed");
        setShowArchiveSuccessModal(true);
      }
      refreshTagList();
      //modal
    } catch (error) {
      console.error(error);
    }
  };

  const refreshTagList = async () => {
    try {
      const getTagResponse = await fetchData("tag/list");

      if (getTagResponse) {
        getTagResponse.forEach((tag) => {
          tag.dateCreatedUtc = new Date(tag.dateCreatedUtc);
        });

        // Sort tagsList by date in descending order
        getTagResponse.sort((a, b) => b.dateCreatedUtc - a.dateCreatedUtc);

        // Map tag notes and set the custom "Tag Note" string
        getTagResponse.forEach((tag) => {
          const tagNoteResponse = tag.tagNotes; // Assuming tagNotes are already included in the tag object

          // Check if there are notes
          // if (tagNoteResponse.length > 0) {
          //   const latestNote = tagNoteResponse[0];
          //   const customTagNote = `Saved in Latitude: ${
          //     latestNote.latitude
          //   }, Longitude: ${latestNote.longitude} on the ${formatDate(
          //     latestNote.dateCreatedUtc
          //   )} at ${formatTime(latestNote.dateCreatedUtc)} By ${
          //     latestNote.createdBy
          //   }`;

          //   tag.note = customTagNote;
          //   tag.note1 = latestNote.latitude;
          // } else {

          if (tagNoteResponse.length > 0) {
            const latestNote = tagNoteResponse[0];
            const customTagNote = `${latestNote.note}`;

            tag.note = customTagNote;
            tag.note1 = latestNote.latitude;
          } else {
            tag.note = ""; // No notes, set to an empty string
          }
        });

        setTagsList(getTagResponse);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Fetch data for each tag

  //pages
  const [pagination, setPagination] = useState(1);
  const itemsPerPageOptions = [10, 20, 30];
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredTags.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 3, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 3, 1));
  };

  const renderPageButtons = () => {
    const pageButtons = [];
    const maxPagesToShow = 3; // Set the maximum number of pages to show
  
    const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
  
    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          onClick={() => handlePageClick(i)}
          className={currentPage === i ? "active-page" : ""}
          style={{
            color: "black",
            borderRadius: "5px",
            border: "none",
            marginLeft: "10px",
            backgroundColor: currentPage === i ? "#F6C90E" : "#3A475033",
            width: "30px",
            fontFamily: "futuramedium",
            cursor: "pointer",
          }}
        >
          {i}
        </button>
      );
    }
  
    return pageButtons;
  };
  

  const totalPages = Math.ceil(tagsList.length / itemsPerPage);

  return (
    <div  className="TablePageMainDiv"
      style={{
        marginLeft: "150px",
        marginRight: "150px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <div
          className="approvalTitle"
          style={{
            fontFamily: "futuramedium",
            color: "#141212",
            fontSize: "30px",
          }}
        >
          Tags
        </div>
        <div
          className="save-button"
          style={{
            fontFamily: "futuramedium",
            color: "#141212",
            fontSize: "12px",
            height: "45px",
            fontWeight: "bold",
          }}
          onClick={handleOpenAddTagModal}
        >
          + Add Tag
        </div>
        <Modal size="xl" show={openTagsModal} centered>
          <Modal.Body>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={handleCloseAddTagModal}
                style={{
                  background: "none",
                  border: "none",
                  fontSize: "35px",
                  position: "absolute",
                  zIndex: "9999",
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <AddTagsModal />
          </Modal.Body>
        </Modal>
      </div>
      <div
        className="minibar"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            fontFamily: "futuramedium",
          }}
        >
          <p>Show</p>
          <div
            style={{
              backgroundColor: "#e5e5e5",
              borderRadius: "10px",
              padding: "4px",
              cursor: "pointer",
              height: "30px",
              width: "50px",
              marginLeft: "1px",
              marginRight: "5px",
            }}
          >
            <select
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
              style={{
                border: "none",
                background: "none",
                cursor: "pointer",
              }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
            </select>
          </div>
          <p>items</p>
        </div>

        <div style={{ display: "flex" }}>
          <p
            style={{
              color: "#3A4750",
              fontSize: "16px",
              fontFamily: "futurabook",
            }}
          >
            {/* last refresh was 25mins ago */}
          </p>
          <div
            className="icon"
            onMouseEnter={() => setIsRefreshHovered(true)}
            onMouseLeave={() => setIsRefreshHovered(null)}
       
            style={{
              backgroundColor: "#3A475033",
              borderRadius: "50%",
              color: "#3A4750",
              width: "32px",
              height: "32px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
              cursor: "pointer",
              position:"relative"
            }}
            onClick={fetchTagData}
          >
            <RefreshIcon />
            
            {isRefreshHovered && (
                              <p
                                style={{
                                  position: "absolute",
                                  top: "100%",
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                  background: "#ffffff",
                                  padding: "2px",
                                  borderRadius: "10px",
                                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                  zIndex: 999,
                               
                                  fontSize:"12px",
                                  width:"100px",
                                  textAlign:"center"
                                }}
                              
                              >
                               Refresh the page
                              </p>
                            )}

          </div>
          <div
            style={{
              backgroundColor: "#3A475033",
              borderRadius: "10%",
              color: "#3A4750",
              width: "100px",
              height: "32px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
              fontFamily: "futurabook",
            }}
          >
            <button
              style={{
                border: "none",
                background: "none",
              }}
              onClick={handleArchiveButtonClick}
            >
              Show Archive
            </button>
          </div>
          {/* <div
            className="icon"
            style={{
              backgroundColor: "#3A475033",
              borderRadius: "20%", // Use 50% for a circle
              color: "#3A4750",
              width: "32px",
              height: "32px",
              display: "flex", // Use flex display
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
            }}
          >
            <DownloadIcon />
          </div> */}
        </div>
      </div>
      {/* ------------------------------------------------------ */}
      {isError && (
        <Modal centered show={tagDoesNotExistModal}>
          <Modal.Body>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={handleCloseTagDoesNotExistModal}
                style={{
                  background: "none",
                  border: "none",
                  fontSize: "35px",
                  position: "absolute",
                  zIndex: 999,
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <TagAccessValidationModal
              onClose={handleCloseTagDoesNotExistModal}
              errorMessage={errorMessage}
            />
          </Modal.Body>
        </Modal>
      )}
      {/* ------------------------------------------------------ */}
      {isLoading ? (
        <p>Loading data...</p>
      ) : (
        <>
          <div className="approvalTable">
            <table className="custon-table">
              <div className="tHead">
                <thead>
                  <tr>
                    <th>
                      <div className="checkbox-dropdown">
                        <input
                          type="checkbox"
                          id="selectAll"
                          checked={selectAllChecked}
                          onChange={handleSelectAllChange}
                        />
                        <div className="dropdown">
                          <span className="dropdown-arrow">&#9662;</span>
                          <div className="dropdown-content">
                            <div onClick={() => handleSelectAllChange(true)}>
                              Select All
                            </div>
                            <div onClick={() => handleSelectAllChange(false)}>
                              Deselect All
                            </div>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th
                      className=""
                      style={{
                        width: "250px",
                      }}
                    >
                      Applied By
                    </th>
                    <th className="" style={{ width: "250px" }}>
                      Applied Date
                    </th>
                    <th className="" style={{ width: "250px" }}>
                      Tag Number
                    </th>
                    <th className="" style={{ width: "250px" }}>
                      Identifier Number
                    </th>
                    <th className="" style={{ width: "250px" }}>
                      Identifier Type
                    </th>
                    <th className="" style={{ width: "350px" }}>
                      Tags Description
                    </th>
                    {/* <th>Location Name</th> */}
                    <th className="" style={{ width: "250px" }}>
                      Site Name
                    </th>

                    <th className="" style={{ width: "250px" }}>
                      Location Name
                    </th>
                    <th
                      className=""
                      style={{
                        width: "350px",
                      }}
                    >
                      Tag Note
                    </th>
                    <th
                      className=""
                      style={{
                        width: "250px",
                      }}
                    >
                      Attachments
                    </th>
                    <th className="" style={{ width: "100px" }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="tBody">
                  {currentItems.map((tag, index) => (
                    <tr
                      key={index}
                      className={selectedRows.includes(index) ? "selected" : ""}
                    >
                      <td>
                        <input
                          type="checkbox"
                          id={`companyCheckbox ${index}`}
                          checked={selectedRows.includes(index)}
                          onChange={() => handleRowSelection(index)}
                        />
                        <label htmlFor={`companyCheckbox${index}`}></label>
                      </td>

                      <td
                      // style={{
                      //   minWidth: "150px",
                      // }}
                      >
                        {tag ? tag.createdBy : "Loading..."}
                      </td>
                      <td>{formatAndConvertDate(tag.dateCreatedUtc)}</td>
                      <td>{tag.serialNumber}</td>
                      <td>{tag.identifiersNumber}</td>
                      <td>{tag.tagItemTypeName}</td>
                      <td className="descriptionNoteColumn">
                        {tag.description.length > 40 &&
                        !tagDescriptionExpanded[tag.id] ? (
                          <>
                            {tag.description.slice(0, 40)}{" "}
                            <span
                              onClick={() =>
                                setTagDescriptionExpanded({
                                  ...tagDescriptionExpanded,
                                  [tag.id]: true,
                                })
                              }
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              ...
                            </span>
                          </>
                        ) : (
                          <>
                            {tag.description}
                            {tag.description.length > 40 && (
                              <span
                                onClick={() =>
                                  setTagDescriptionExpanded({
                                    ...tagDescriptionExpanded,
                                    [tag.id]: false,
                                  })
                                }
                                style={{
                                  cursor: "pointer",
                                  color: "blue",
                                }}
                              >
                                &nbsp; See Less
                              </span>
                            )}
                          </>
                        )}
                      </td>
                      {/* <td>{tag.locationName}</td> */}
                      <td>{tag.siteName}</td>
                      <td
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => handleOpenLocationViewer(tag)}
                      >
                        {tag.locationName}
                      </td>

                      {selectedTag === tag && ( // Check if this row's modal should be open
                        <Modal size="xl" centered show={openLocationViewer}>
                          <Modal.Body>
                            <div className="d-flex justify-content-end">
                              <button
                                type="button"
                                className="close"
                                aria-label="Close"
                                onClick={handleCloseLocationViewer}
                                style={{
                                  background: "none",
                                  border: "none",
                                  fontSize: "35px",
                                  position: "absolute",
                                  zIndex: "999999",
                                }}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <LocationViewerModal tag={selectedTag} />
                          </Modal.Body>
                        </Modal>
                      )}

                      <td className="descriptionNoteColumn">
                        {tag.note &&
                        tag.note.length > 40 &&
                        !tagNoteExpanded[tag.id] ? (
                          <>
                            {tag.note.slice(0, 40)}{" "}
                            <span
                              onClick={() =>
                                setTagNoteExpanded({
                                  ...tagNoteExpanded,
                                  [tag.id]: true,
                                })
                              }
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              ...
                            </span>
                          </>
                        ) : (
                          <>
                            {tag.note ? tag.note : "-"}
                            {tag.note && tag.note.length > 40 && (
                              <span
                                onClick={() =>
                                  setTagNoteExpanded({
                                    ...tagNoteExpanded,
                                    [tag.id]: false,
                                  })
                                }
                                style={{
                                  cursor: "pointer",
                                  color: "blue",
                                }}
                              >
                                &nbsp; See Less
                              </span>
                            )}
                          </>
                        )}
                      </td>
                      <td
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => handleOpenTagAttachments(tag)}
                      >
                        view
                      </td>
                      {selectedTag === tag && ( // Check if this row's modal should be open
                        <Modal size="lg" centered show={openTagAttachments}>
                          <Modal.Body>
                            <div className="d-flex justify-content-end">
                              <button
                                type="button"
                                className="close"
                                aria-label="Close"
                                onClick={handleCloseTagAttachments}
                                style={{
                                  background: "none",
                                  border: "none",
                                  fontSize: "35px",
                                  position: "absolute",
                                }}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <TagsAttachmentsModal tag={selectedTag} />
                          </Modal.Body>
                        </Modal>
                      )}
                      <td>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <div
                            className="iconBox"
                            onMouseEnter={() => setHoveredTag(tag)}
                            onMouseLeave={() => setHoveredTag(null)}
                            style={{
                              cursor: "pointer",
                              position: "relative",
                            }}
                            onClick={() => handleOpenAddTagNoteModal(tag)}
                            // onClick={() => handleOpenEditTagModal(tag)}
                          >
                            <ThinAddNote height="15px" width="15px" fill="#" />
                            {hoveredTag === tag && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "100%",
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                  background: "#ffffff",
                                  padding: "2px",
                                  borderRadius: "10px",
                                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                  zIndex: 999,
                                  width: "70px",
                                }}
                              >
                                Add Note
                              </div>
                            )}
                          </div>
                          {selectedTag === tag && (
                            <Modal
                              size="xl"
                              centered
                              show={openAddTagNoteModal}
                              //   onHide={handleCloseAddTagNoteModal}
                            >
                              <Modal.Body>
                                <div className="d-flex justify-content-end">
                                  <button
                                    type="button"
                                    className="close"
                                    aria-label="Close"
                                    onClick={handleCloseAddTagNoteModal}
                                    style={{
                                      background: "none",
                                      border: "none",
                                      fontSize: "35px",
                                      position: "absolute",
                                      zIndex: "999",
                                    }}
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <AddTagNoteModal tag={selectedTag} />
                              </Modal.Body>
                            </Modal>
                          )}

                          <div
                            className="iconBox"
                            onMouseEnter={() => setIsEditTagHovered(tag)}
                            onMouseLeave={() => setIsEditTagHovered(null)}
                            style={{
                              cursor: "pointer",
                              position: "relative",
                            }}
                            onClick={() => handleOpenEditTagModal(tag)}
                          >
                            <ThinEditIcon height="15px" width="15px" fill="#" />
                            {isEditTagHovered === tag && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "100%",
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                  background: "#ffffff",
                                  padding: "2px",
                                  borderRadius: "10px",
                                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                  zIndex: 999,
                                  width: "70px",
                                }}
                              >
                                Edit Tag
                              </div>
                            )}
                          </div>
                          {selectedTag === tag && ( // Check if this row's modal should be open
                            <Modal
                              size="xl"
                              centered
                              show={openEditTagModal}
                              //  onHide={}
                            >
                              <Modal.Body>
                                <div className="d-flex justify-content-end">
                                  <button
                                    type="button"
                                    className="close"
                                    aria-label="Close"
                                    onClick={handleCloseEditTagModal}
                                    style={{
                                      background: "none",
                                      border: "none",
                                      fontSize: "35px",
                                      position: "absolute",
                                      zIndex: 999,
                                    }}
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <EditTagsModal tag={selectedTag} />
                              </Modal.Body>
                            </Modal>
                          )}

                          <div
                            className="iconBox"
                            onMouseEnter={() => setIsArchivehovered(tag)}
                            onMouseLeave={() => setIsArchivehovered(null)}
                            style={{
                              cursor: "pointer",
                              position: "relative",
                            }}
                            onClick={() => handleOpenConfirmArchiveModal(tag)}
                          >
                            <ThinArchive height="15px" width="15px" fill="#" />
                            {isArchiveHovered === tag && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "100%",
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                  background: "#ffffff",
                                  padding: "2px",
                                  borderRadius: "10px",
                                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                  zIndex: 999,
                                  width: "85px",
                                }}
                              >
                                Archive Tag
                              </div>
                            )}
                          </div>
                          {selectedTag === tag && (
                            <Modal
                              centered
                              show={openConfirmArchiveModal}
                              //      onHide={() => setOpenConfirmArchiveModal(false)}
                            >
                              <Modal.Body>
                                <div className="d-flex justify-content-end">
                                  <button
                                    type="button"
                                    className="close"
                                    aria-label="Close"
                                    onClick={handleCloseConfirmArchiveModal}
                                    style={{
                                      background: "none",
                                      border: "none",
                                      fontSize: "35px",
                                      position: "absolute",
                                    }}
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <ConfirmDeleteTagModal
                                  tag={selectedTag}
                                  onClose={handleCloseConfirmArchiveModal}
                                  refreshTagList={refreshTagList}
                                />
                              </Modal.Body>
                            </Modal>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </div>
            </table>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <div>
              Showing {Math.min(indexOfLastItem, filteredTags.length)} of{" "}
              {filteredTags.length} items
            </div>
            <div
              style={{
                marginRight: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <button
                onClick={handlePreviousPage}
                style={{
                  color: "black",
                  borderRadius: "5px",
                  border: "none",
                  marginLeft: "10px",
                  backgroundColor: "#3A475033",
                  width: "80px",
                  fontFamily: "futuramedium",
                  cursor: "pointer",
                }}
              >
                Previous
              </button>

              {renderPageButtons()}

              <button
                onClick={handleNextPage}
                style={{
                  color: "black",
                  borderRadius: "5px",
                  border: "none",
                  marginLeft: "10px",
                  backgroundColor: "#3A475033",
                  width: "60px",
                  fontFamily: "futuramedium",
                  cursor: "pointer",
                }}
              >
                Next
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TagsTable;
