import React, { useState, useEffect } from "react";
import "../css/modalcss/addUserModal.css";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css"; // Make sure to import Bootstrap's CSS
import { useSelector } from "react-redux";
import { publicRequest } from "../requestMethods";
import AddUserSuccessModal from "./AddUserSuccessModal";
import AddUserFailedModal from "./AddUserFailedModal";

const AddUserModal = () => {
  const user = useSelector((state) => state.user);
  const userId = useSelector((state) => state.user.id);
  const selectedCompanyId = useSelector((state) => state.selectedCompany);
  const selectedCompanyName = useSelector((state) => state.selectedCompanyName);
  const selectedUserCompanyId = useSelector((state) => state.selectedCompanyId);
  const userRole = useSelector((state) => state.selectedCompanyRoleId);

  const [currentUser, setUser] = useState();
  const [company, setCompany] = useState();
  const [country, setCountry] = useState();
  const [prefix, setPrefix] = useState();
  const [countries, setCountries] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [countryStates, setCountryStates] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [errorMessage, setErrorMessage] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isBirthdateError, setIsBirthdateError] = useState(false);

  //modal
  const [showAddSuccessModal, setShowAddSuccessModal] = useState(false);
  const [showAddUserFailedModal, setShowAddUserFailedModal] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [selectedCompanyIdFromDropdown, setSelectedCompanyIdFromDropdown] =
    useState("");
  const [companyList, setCompanyList] = useState([]);

  const [selectedCompanyNameFromDropdown, setSelectedCompanyNameFromDropdown] =
    useState("");

  const isValidContactNumber = (mobilePhone) => {
    const trimmedNumber = mobilePhone.replace(/\D/g, ""); 
    if (trimmedNumber.startsWith("61") && trimmedNumber.length === 11) {
      return true;
    } else if (trimmedNumber.startsWith("678") && trimmedNumber.length === 7) {
      return true;
    } else {
      return false;
    }
  };

  const isValidOfficeContactNumber = (officePhone) => {
    const trimmedNumber = officePhone.replace(/\D/g, "");
    if (trimmedNumber.startsWith("61") && trimmedNumber.length === 11) {
      return true;
    } else if (
      trimmedNumber.startsWith("64") &&
      [11].includes(trimmedNumber.length)
    ) {
      return true;
    } else if (trimmedNumber.startsWith("678") && trimmedNumber.length === 7) {
      return true;
    } else {
      return false;
    }
  };

  const handleOpenFailedModal = () => {
    setShowAddUserFailedModal(true);
  };

  const handleCloseFailedModal = () => {
    setShowAddUserFailedModal(false);
  };

  const handleCloseSuccessUserModal = () => {
    setShowAddSuccessModal(false);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const loggedUserCompany = user
    ? selectedCompanyId
      ? ""
      : user.userCompanyId
    : "";

  const [userDetails, setUserDetails] = useState({
    loggedUserId: userId,
    loggedUserCompanyId: selectedUserCompanyId,
    firstName: "",
    lastName: "",
    mobilePhone: "",
    officePhone: "",
    dob: "",
    email: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    licenseNumber: "",
    company: selectedCompanyName,
    currentLocation: "",
    currentSite: "",
    roleId: "",
    companyId: selectedCompanyId,
  });

  console.log(userDetails);

  const token = useSelector((state) => state.user.token);

  const loggedUserId = user ? user.id : "";

  const createUser = async () => {
    setFormSubmitted(true);

    // Check if mobilePhone starts with "61" or "678"
    if (
      userDetails.mobilePhone.startsWith("61") ||
      userDetails.mobilePhone.startsWith("678")
    ) {
      userDetails.mobilePhone = "+" + userDetails.mobilePhone;
    }

    if (
      userDetails.officePhone.startsWith("61") ||
      userDetails.officePhone.startsWith("678")
    ) {
      userDetails.officePhone = "+" + userDetails.officePhone;
    }


    const dobDate = new Date(userDetails.dob);
    dobDate.setDate(dobDate.getDate()); // Add 1 day

    const data = {
      loggedUserId: userId,
      companyId: selectedCompanyIdFromDropdown || selectedUserCompanyId,
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      email: userDetails.email,
      dob: dobDate.toISOString(),
      mobilePhone: userDetails.mobilePhone,
      licenseNumber: userDetails.licenseNumber,
      roleId: userDetails.roleId,
      officePhone: userDetails.officePhone,
      loggedUserCompanyId: selectedCompanyId,
    };

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    console.log("this is data", data);

    try {
      const response = await publicRequest({
        method: "POST",
        url: "/user/create",
        data: data,
        headers: headers,
      });
      console.log(response.data);
      setShowAddSuccessModal(true);
      console.log(response);
    } catch (error) {
      console.error(error);
      console.error(error.response.data.errors);
      if (error.response.status === 409) {
        setErrorMessage("Email already exists");
      } else {
        setErrorMessage(
          error.response.data.errors || error.response.data.title
        );
      }
      setIsError(true);
      setShowAddUserFailedModal(true);
    }
  };

  // {
  //   "loggedUserId": "0c6d207a-45f2-4491-be52-67812282cd90",
  //   "loggedUserCompanyId": 4,
  //   "companyId": 6,
  //   "firstName": "steven",
  //   "lastName": "salazar",
  //   "email": "test123456789@mail.com",
  //   "dob": "2023-08-29T09:43:26.782Z",
  //   "mobilePhone": "6188888888",
  //   "licenseNumber": "333333333",
  //   "roleId": 4,
  //   "officePhone": "6188888888"
  // }

  const fetchData = async (url) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await publicRequest({
        method: "GET",
        url: url,
        headers: headers,
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching data", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchUserAndCompanyData = async () => {
      const userResponse = await fetchData(`/user/profile/${userId}`);
      const companyResponse = await fetchData(
        `/company/${selectedUserCompanyId}`
      );
      const countryResponse = await fetchData("/lookup/countries");
      const roleDropdown = await fetchData("/lookup/roles");
      if (roleDropdown) {
        setRoles(roleDropdown);
      }

      if (userResponse) {
        setUser(userResponse);
      }

      if (companyResponse) {
        setCompany(companyResponse);
        setUserDetails((prevDetails) => ({
          ...prevDetails,
          country: companyResponse.countryId,
          state: companyResponse.stateId,
          city: companyResponse.city,
          postalCode: companyResponse.postCode,
          addressLine1: companyResponse.addressLine1,
          addressLine2: companyResponse.addressLine2,
        }));

        console.log(companyResponse);

        const companyCountry = countryResponse.find(
          (country) => country.id === companyResponse.countryId
        );
        if (companyCountry) {
          setUserDetails((prevDetails) => ({
            ...prevDetails,
            country: companyCountry.name,
            state: companyResponse.stateId,
          }));

          // setUserDetails({
          //   addressLine1: userDetails.addressLine1,
          //   addressLine2: userDetails.addressLine2,
          //   city: userDetails.city,
          // //  state: userDetails.state,
          //   // country: userDetails.country,
          //   postalCode: userDetails.postalCode,
          // //  licenseNumber: company.abnAcnIdentifierNumber,
          //   company: selectedCompanyName,
          //   roleId: 4,
          // })

          // Fetch the states for the selected country
          const statesResponse = await fetchData(
            `/lookup/country/${companyResponse.countryId}/states`
          );
          if (statesResponse) {
            setCountryStates(statesResponse);
            const selectedState = statesResponse.find(
              (state) => state.id === companyResponse.stateId
            );
            if (selectedState) {
              setUserDetails((prevDetails) => ({
                ...prevDetails,
                state: selectedState.name,
              }));
            }
          }
          setIsLoading(false); // Data fetching complete
        }
      }
    };

    fetchUserAndCompanyData();
  }, [token, userId, selectedUserCompanyId]);

  useEffect(() => {
    if (!userRole === 1 && !selectedCompanyIdFromDropdown) {
      return;
    }

    const fetchCompanies = async () => {
      const companyResponse = await fetchData("lookup/companies");

      if (companyResponse && userRole === 1) {
        setCompanyList(companyResponse);
        console.log(companyList);
      }
    };

    fetchCompanies(); // Fetch companies when the component mounts

    fetchCompanies(); // Fetch companies when the component mounts
  }, [selectedCompanyIdFromDropdown, userRole]);

  const handleRoleChange = (e) => {
    const selectedRoleId = e.target.value;
    setSelectedRole(selectedRoleId);

    setUserDetails((prevDetails) => ({
      ...prevDetails,
      roleId: selectedRoleId,
    }));
  };

  const handleInputChange = (field, value) => {
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
    if (field === "country") {
      const selectedCountry = countries.find(
        (country) => country.name === value
      );
      if (selectedCountry) {
        // Set the prefix without the '+'
        setPrefix(selectedCountry.code);
      }
    }

    if (field === "dob" && formSubmitted) {
      let dobDate = new Date(value);
      dobDate.setUTCHours(12, 0, 0, 0); // Set hours to 12 PM UTC to ensure consistency

      let today = new Date();
      today.setUTCHours(12, 0, 0, 0); // Set hours to 12 PM UTC to ensure consistency

      let age = today.getUTCFullYear() - dobDate.getUTCFullYear();

      // Check if the birthday hasn't occurred yet this year
      if (
        today.getUTCMonth() < dobDate.getUTCMonth() ||
        (today.getUTCMonth() === dobDate.getUTCMonth() &&
          today.getUTCDate() < dobDate.getUTCDate())
      ) {
        // If birthday hasn't occurred, reduce the age by 1
        age--;
      }

      if (age < 15) {
        setErrorMessage("User must be 15 years old and above");
        setIsBirthdateError(true);
        setIsError(true);
      } else {
        setErrorMessage([]);
        setIsError(false);
        setIsBirthdateError(false);
      }
    }

    if (field === "mobilePhone") {
      // Check if the value starts with '+'
      if (value.startsWith("+")) {
        // Include the '+' sign in the input
        setUserDetails((prevDetails) => ({
          ...prevDetails,
          [field]: value,
        }));
      } else {
        // If the value does not start with '+', add '+' to the value
        setUserDetails((prevDetails) => ({
          ...prevDetails,
          [field]: "+" + value,
        }));
      }
    }

    if (field === "officePhone") {
      // Check if the value starts with '+'
      if (value.startsWith("+")) {
        // Include the '+' sign in the input
        setUserDetails((prevDetails) => ({
          ...prevDetails,
          [field]: value,
        }));
      } else {
        // If the value does not start with '+', add '+' to the value
        setUserDetails((prevDetails) => ({
          ...prevDetails,
          [field]: "+" + value,
        }));
      }
    
  };



};

  return (
    <div className="ModalChildDiv"
      style={{
        alignItems: "center",
        display: "flex",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <div
        className="modal-content custom-modal-content"
        style={{
          padding: "10px",
          paddingLeft: "20px",
          paddingRight: "20px",
          background: "none",
          border: "none",
        }}
      >
        <div className="row">
          <div
            className="finalTitleDiv"
            style={{
              alignItems: "flex-start",
            }}
          >
            <h3
              style={{
                fontFamily: "futuramedium",
                fontWeight: "bold",
                fontSize: "30px",
                marginTop: "15px",
              }}
            >
              Add User
            </h3>
          </div>
        </div>
        <div className="finish-row">
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              First Name:*
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
                fontSize: "16.5px",
              }}
              maxLength={25}
              value={userDetails.firstName}
              onChange={(e) => handleInputChange("firstName", e.target.value)}
            />
            {userDetails.firstName === "" && formSubmitted && (
              <div className="validation-message" style={{}}>
                First Name is required
              </div>
            )}
          </div>
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Last Name:*
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
                fontSize: "16.5px",
              }}
              value={userDetails.lastName}
              onChange={(e) => handleInputChange("lastName", e.target.value)}
              maxLength={25}
            />
            {userDetails.lastName === "" && formSubmitted && (
              <div className="validation-message" style={{}}>
                Last Name is required
              </div>
            )}
          </div>
        </div>
        <div className="finish-row">
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Mobile Phone Number:*
            </p>
            <input
              type="tel"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
              value={userDetails.mobilePhone}
              onChange={(e) => {
                const inputValue = e.target.value;
                const strippedValue = inputValue.replace("+", ""); // Remove the "+" character
                handleInputChange("mobilePhone", strippedValue);
              }}
              maxLength={12}
            />

            {formSubmitted && userDetails.mobilePhone === "" && (
              <div className="validation-message" style={{ marginTop: "5px" }}>
                Mobile Phone number is required
              </div>
            )}
            {formSubmitted &&
              userDetails.mobilePhone !== "" &&
              !isValidContactNumber(userDetails.mobilePhone) && (
                <div className="validation-message" style={{}}>
                  Please enter a valid Mobile Phone number
                </div>
              )}
          </div>
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Office Phone Number:
            </p>
            <input
              type="tel"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
              value={userDetails.officePhone}
              onChange={(e) => {
                const inputValue = e.target.value;
                const strippedValue = inputValue.replace("+", ""); // Remove the "+" character
                handleInputChange("officePhone", strippedValue);
              }}
              maxLength={12}
            />
            {formSubmitted &&
              userDetails.officePhone !== "" &&
              !isValidOfficeContactNumber(userDetails.officePhone) && (
                <div className="validation-message" style={{}}>
                  Please enter a valid Office Phone number
                </div>
              )}
          </div>
        </div>
        <div className="finish-row">
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Date of Birth:*
            </p>
            <input
              type="date"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
              value={userDetails.dob}
              onChange={(e) => handleInputChange("dob", e.target.value)}
              max="9999-12-31" // Set max attribute to prevent future dates
              maxLength={10} // Set maxLength to 10 to restrict the length of input
            />
            {userDetails.dob === "" && formSubmitted && (
              <div className="validation-message" style={{}}>
                Date of Birth is required
              </div>
            )}
            {isBirthdateError && formSubmitted && isError && (
              <div
                className="validation-message"
                style={{
                  marginTop: "0.5px",
                }}
              >
                User must be 15 years old and above
              </div>
            )}
          </div>
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Email Address:*
            </p>
            <input
              type="email"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
              value={userDetails.email}
              onChange={(e) => handleInputChange("email", e.target.value)}
            />
            {userDetails.email === "" && formSubmitted && (
              <div className="validation-message" style={{}}>
                Email is required
              </div>
            )}
            {formSubmitted &&
              userDetails.email !== "" &&
              !isValidEmail(userDetails.email) && (
                <div className="validation-message" style={{}}>
                  Please enter a valid email
                </div>
              )}
            {isError && errorMessage === "Email already exists" && (
              <div className="validation-message" style={{}}>
                Email already exists
              </div>
            )}
          </div>
        </div>
        {userRole === 1 && (
        <div className="finish-row">
          <div className="finish-column wholeRow">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Company Name*:
            </p>
            <select
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futuramedium",
                color: "#141212",
                height: "40px",
                borderTop: "none",
                borderRight: "none",
                borderLeft: "none",
              }}
              value={
                selectedCompanyNameFromDropdown !== ""
                  ? selectedCompanyNameFromDropdown
                  : selectedCompanyName
              }
              onChange={(e) => {
                const selectedOption = e.target.value;
                if (selectedOption === "Select a Company") {
                  setSelectedCompanyNameFromDropdown("");
                  setSelectedCompanyIdFromDropdown("");
                } else {
                  setSelectedCompanyNameFromDropdown(selectedOption);
                  const selectedCompanyDropdown = companyList.find(
                    (company) => company.name === selectedOption
                  );
                  if (selectedCompanyDropdown) {
                    setSelectedCompanyIdFromDropdown(
                      selectedCompanyDropdown.id
                    );
                  }
                }
              }}
            >
              <option value="">Select a Company</option>
              {companyList.map((company) => (
                <option key={company.id} value={company.name}>
                  {company.name}
                </option>
              ))}
            </select>
            {/* {selectedCompanyIdFromDropdown === "" && formSubmitted && (
              <div className="validation-message">
                Please Select a Company
              </div>
            )} */}
          </div>
        </div>
      )}
        <div className="finish-row">
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Master License Number:*
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
                height: "30px",
              }}
              // value={userDetails.licenseNumber || company ? company.abnAcnIdentifierNumber : 'Loading...'}
              value={userDetails.licenseNumber}
              onChange={(e) =>
                handleInputChange("licenseNumber", e.target.value)
              }
              maxLength={30}
            />
            {userDetails.licenseNumber === "" && formSubmitted && (
              <div className="validation-message" style={{}}>
                Master License number is required
              </div>
            )}
          </div>
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{ fontSize: "13px", marginBottom: "5px" }}
            >
              Role:*
            </p>
            <select
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                fontWeight: "bold",
                color: "#141212",
                height: "30px",
                borderLeft: "none",
                borderRight: "none",
                borderTop: "none",
              }}
              value={selectedRole}
              onChange={handleRoleChange}
            >
              <option value="">Select a Role</option>
              {userRole === 1
                ? // Render all roles without slicing
                  roles.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))
                : // Render roles except the first one after slicing
                  roles.slice(1).map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
            </select>

            {selectedRole === "" && formSubmitted && (
              <div className="validation-message">Please Select a Role</div>
            )}
          </div>
        </div>

        <div
          style={{
            width: "100%",
            justifyContent: "space-between",
            display: "flex",
            marginTop: "20px",
          }}
        >
          <p
            style={{
              fontSize: "11px",
              color: "#141212",
            }}
          >
            The (*) Star denotes that the question must be completed to SAVE the
            data
          </p>
          {/* {isError && errorMessage && (
            <div
              className="error-message"
              style={{
                color: "red",
                fontSize: "10px",
                marginTop: "15px",
                marginRight: "10px",
              }}
            >
              {typeof errorMessage === "string" ? (
                <p
                  style={{
                    fontSize: "13px",
                  }}
                >
                  {errorMessage}
                </p>
              ) : (
                Object.values(errorMessage).map((error, index) => (
                  <p
                    style={{
                      fontSize: "13px",
                    }}
                    key={index}
                  >
                    {error}
                  </p>
                ))
              )}
            </div>
          )} */}
          <button
            className="next-button"
            style={{
              fontSize: "13px",
              width: "18%",
            }}
            onClick={createUser}
          >
            <Modal
              show={showAddSuccessModal}
              //   onHide={handleCloseSuccessUserModal}
              centered
            >
              <Modal.Body>
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={handleCloseSuccessUserModal}
                    style={{
                      background: "none",
                      border: "none",
                      fontSize: "35px",
                      position: "absolute",
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <AddUserSuccessModal />
              </Modal.Body>
            </Modal>
            Add New User
          </button>
          <Modal
            show={showAddUserFailedModal}
            //    onHide={handleCloseFailedModal}
            centered
          >
            <Modal.Body>
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={handleCloseFailedModal}
                  style={{
                    background: "none",
                    border: "none",
                    fontSize: "35px",
                    position: "absolute",
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <AddUserFailedModal onClose={handleCloseFailedModal} />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default AddUserModal;
