import React from 'react';
import { ThinCircleCheck } from '../../../fontAwesomeComponents/FaIcons';
import { useLocation } from 'react-router-dom';

const UndeleteTagSuccess = ({ onClose, refreshArchiveList }) => {
  const handleBackToProfile = () => {
    onClose();
    refreshArchiveList();
  };

  const location = useLocation();

  const isTagsPage = location.pathname === '/tags/deleted';
  const isItemsPage = location.pathname === '/items/deleted';

  const message = isTagsPage ? 'Tag Restored' : isItemsPage ? 'Item Restored' : '';

  return (
    <div style={{ height: '37vh' }}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <div
          style={{
            marginBottom: '20px',
            backgroundColor: '#D4EDDA',
            padding: '10px',
            borderRadius: '16px',
          }}
        >
          <ThinCircleCheck height="50px" width="50px" />
        </div>

        <h2
          style={{
            fontFamily: 'futuraheavy',
            fontSize: '25px',
            marginBottom: '20px',
          }}
        >
          {message}
        </h2>

        <button
          className="back-to-login-button"
          onClick={handleBackToProfile}
          style={{
            backgroundColor: '#28A745',
            width: '80%',
            fontFamily: 'futurabook',
            color: '#FFFFFF',
            height: '50px',
            border: 'none',
            borderRadius: '10px',
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default UndeleteTagSuccess;
