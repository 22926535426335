import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { publicRequest } from "../../requestMethods";
import EditLocationSuccess from "./EditLocationSuccess";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal } from "react-bootstrap";
import SelectSiteLocationModal from "../../modals/SelectSiteLocationModal";
import { FaLocationArrow } from "react-icons/fa";
import FailedEditLocationModal from "./FailedEditLocationModal";
import AddSiteInLocationModal from "./AddSiteInLocationModal";

const EditLocationModal = ({ location }) => {
  console.log(location);
  console.log(location.id);
  console.log(location.siteId);

  const [showEditSuccessModal, setShowEditSuccessModal] = useState(false);
  const [selectedCompanyIdFromDropdown, setSelectedCompanyIdFromDropdown] =
    useState("");
  // const handleCloseSuccessModal = () => {
  //   setShowEditSuccessModal(false);
  // };

  const handleCloseFailedModal = () => {
    setShowFailedModal(false);
  };

  const token = useSelector((state) => state.user.token);
  const userId = useSelector((state) => state.user.id);
  const selectedCompanyId = useSelector((state) => state.selectedCompanyId);
  const selectedUserCompanyId = useSelector((state) => state.selectedCompany);
  const userRole = useSelector((state) => state.selectedCompanyRoleId);
  const selectedCompanyName = useSelector((state) => state.selectedCompanyName);

  const [locationNameErrorMessage, setLocatioNameErrorMessage] = useState("");

  const [selectedCompanyNameFromDropdown, setSelectedCompanyNameFromDropdown] =
    useState("");
  const [companyList, setCompanyList] = useState([]);

  const [isSelectLocationHovered, setIsSelectLocationHovered] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [showSelectLocationModal, setShowSelectLocationModal] = useState(false);
  const [showAddSiteInLocationModal, setShowAddSiteInLocationModal] =
    useState(false);

  const handleCloseSelectLocationModal = () => {
    setShowSelectLocationModal(false);
  };

  const handleSelectLocationClick = () => {
    if (navigator.geolocation) {
      // If Geolocation API is supported by the browser
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // If the user allows location access
          setShowSelectLocationModal(true);
        },
        (error) => {
          // If the user denies location access or there's an error
          alert("You need to allow location access to proceed.");
        }
      );
    } else {
      // If Geolocation API is not supported by the browser
      alert("Geolocation is not supported by your browser.");
    }
  };

  const [showFailedModal, setShowFailedModal] = useState(false);
  const [showAddSiteModal, setShowAddSiteModal] = useState(false);
  const [sitesList, setSitesList] = useState([]);
  const [selectedSiteName, setSelectedSiteName] = useState("");
  const [selectedSiteId, setSelectedSiteId] = useState("");
  // const handleCloseFailedModal = () => {
  //   setShowFailedModal(false);
  // }
  function handleAddSiteModal(e) {
    const selectedOption = e.target.value;
    if (selectedOption === "Add Site") {
      setShowAddSiteModal(true);
    }
  }

  const handlecloseAddSiteModal = () => {
    setShowAddSiteModal(false);
  };

  const toggleAddSiteInLocationModal = () => {
    setShowAddSiteInLocationModal(!showAddSiteInLocationModal);
  };

  const fetchSiteList2 = async () => {
    if (userRole === 1 && !selectedCompanyIdFromDropdown) {
      // Don't fetch data if a company is not selected
      return;
    }

    const companyIdToUse =
      userRole === 1 ? selectedCompanyIdFromDropdown : selectedCompanyId;
    const siteResponse = await fetchData(
      `lookup/company/${companyIdToUse}/sites`
    );

    if (siteResponse) {
      setSitesList(siteResponse);
      console.log(sitesList);
    }
  };

  useEffect(() => {
    const fetchSiteList = async () => {
      if (userRole === 1 && !selectedCompanyIdFromDropdown) {
        // Don't fetch data if a company is not selected
        return;
      }

      const companyIdToUse =
        userRole === 1 ? selectedCompanyIdFromDropdown : selectedCompanyId;
      const siteResponse = await fetchData(
        `lookup/company/${companyIdToUse}/sites`
      );

      if (siteResponse) {
        setSitesList(siteResponse);
        console.log(sitesList);
      }
    };

    const fetchCompanies = async () => {
      const companyResponse = await fetchData("lookup/companies");

      if (companyResponse && userRole === 1) {
        setCompanyList(companyResponse);
        console.log(companyList);
      }
    };

    fetchCompanies(); // Fetch companies when the component mounts

    fetchSiteList();
  }, [selectedCompanyIdFromDropdown, userRole]);

  const fetchData = async (url) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await publicRequest({
        method: "GET",
        url: url,
        headers: headers,
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const [siteDetails, setSiteDetails] = useState({
    loggedUserId: userId,
    loggedUserCompanyId: selectedUserCompanyId,
    companyId:
      userRole === 1 ? location.companyId : selectedCompanyId, // if admin give dropdown of companies and select from those copanies, if company admin use company id from redux
    //   companyId: selectedCompanyId,
    name: "",
    company: selectedCompanyName,
    location: "",
    addressLine1: "",
    addressLine2: "",
    latitude: "",
    longitude: "",

    //input other fields here later
  });

  const [userInput, setUserInput] = useState({
    name: location.name,
    addressLine1: location.addressLine1,
    addressLine2: location.addressLine2,
    latitude: location.latitude !== null ? parseFloat(location.latitude) : null,
    longitude:
      location.longitude !== null ? parseFloat(location.longitude) : null,
    isActive: location.isActive,
    siteId: location.siteId,
  });

  console.log(userInput);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [addressLine1ErrorMessage, setAddressLine1ErrorMessage] = useState("");
  const [addressLine2ErrorMessage, setAddressLine2ErrorMessage] = useState("");

  const updateSite = async () => {
    setFormSubmitted(true);
  
    // if (!userInput.isActive) {
    //   // If isActive is false, show an error message
    //   setErrorMessage("Location must be activated to edit.");
    //   setIsError(true);
    //   return
    // }
  
    const data = {
      loggedUserId: userId,
      loggedUserCompanyId: selectedUserCompanyId,
      companyId: userRole === 1 ? location.companyId : selectedCompanyId,
      siteId: location.siteId,
      isActive: userInput.isActive,
      name: userInput.name,
      addressLine1: userInput.addressLine1,
      addressLine2: userInput.addressLine2,
      latitude: userInput.latitude || null,
      longitude: userInput.longitude || null,
      locationId: location.id,
    };
  
    console.log( "this is data" ,data);
  
    const headers = {
      Authorization: `Bearer ${token}`,
    };
  
    try {
      const response = await publicRequest({
        method: "POST",
        url: `location/${location.id}/update`,
        data: data,
        headers: headers,
      });
      setShowEditSuccessModal(true);
      console.log(response.data);
    } catch (error) {
      console.log(error.errors);
      setShowFailedModal(true);
      console.error(error);
      console.error(error.response.data.errors);
      setErrorMessage(error.response.data.errors);
      setIsError(true);
    }
  };
  

  const handleInputChange = (field, value) => {
    const alphanumericRegex = /^[a-zA-Z0-9\s]+$/;
    if (field === "name") {
      const alphanumericRegex = /^[a-zA-Z0-9\s]+$/;
      if (!alphanumericRegex.test(value)) {
        setLocatioNameErrorMessage({
          name: "Location Name must only contain alphanumeric characters.",
        });
        setIsError(true);
      } else {
        setLocatioNameErrorMessage("");
        setIsError(false);
      }
    }

    if (field === "addressLine1") {
      if (!alphanumericRegex.test(value)) {
        setAddressLine1ErrorMessage("Address Line 1 must only contain alphanumeric characters.");
        setIsError(true);
      } else {
        setAddressLine1ErrorMessage("");
        setIsError(false);
      }
    }

    if (field === "addressLine2") {
      if (!alphanumericRegex.test(value)) {
        setAddressLine2ErrorMessage("Address Line 2 must only contain alphanumeric characters.");
        setIsError(true);
      } else {
        setAddressLine2ErrorMessage("");
        setIsError(false);
      }
    }

    setUserInput((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const onSelectLocation = (lat, lng) => {
    setUserInput((prevDetails) => ({
      ...prevDetails,
      latitude: lat,
      longitude: lng,
    }));
  };

  return (
    <div className="ModalChildDiv"
      style={{
        alignItems: "center",
        display: "flex",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <div
        className="modal-content custom-modal-content"
        style={{
          padding: "10px",
          paddingLeft: "20px",
          paddingRight: "20px",
          background: "none",
          border: "none",
        }}
      >
        <div className="row">
          <div
            className="finalTitleDiv"
            style={{
              alignItems: "flex-start",
            }}
          >
            <h3
              style={{
                fontFamily: "futuramedium",
                fontWeight: "bold",
                fontSize: "30px",
                marginTop: "15px",
              }}
            >
              Edit Location
            </h3>
          </div>
          <div className="finish-row2">
            <div className="finish-column">
              <p
                className="detailsTitle"
                style={{
                  fontSize: "13px",
                  marginBottom: "5px",
                }}
              >
                Location Name*:
              </p>
              <input
                type="text"
                className="addUserDetails"
                style={{
                  fontFamily: "futurabook",
                  color: "#141212",
                }}
                value={userInput.name}
                onChange={(e) => handleInputChange("name", e.target.value)}
              />

              {userInput.name === "" && formSubmitted && (
                <div className="validation-message">
                  Location Name is Required
                </div>
              )}

              {locationNameErrorMessage.name && formSubmitted && (
                <div className="validation-message"
                  style={{
                    marginTop:"0px"
                  }}
                >
                  {locationNameErrorMessage.name}
                </div>
              )}
            </div>
            <div className="finish-row">
              <div className="finish-column">
                <p
                  className="detailsTitle"
                  style={{
                    fontSize: "13px",
                    marginBottom: "5px",
                  }}
                >
                  Location Address Line 1*:
                </p>
                <input
                  type="text"
                  className="addUserDetails"
                  style={{
                    fontFamily: "futurabook",
                    color: "#141212",
                  }}
                  value={userInput.addressLine1}
                  onChange={(e) =>
                    handleInputChange("addressLine1", e.target.value)
                  }
                  maxLength={30}
                />

                {userInput.addressLine1 === "" && formSubmitted && (
                  <div className="validation-message">
                    Location Address Line 1 is Required
                  </div>
                )}
                       {addressLine1ErrorMessage && formSubmitted && (
                <div className="validation-message">
                  {addressLine1ErrorMessage}
                </div>
              )}

              </div>
              <div className="finish-column">
                <p
                  className="detailsTitle"
                  style={{
                    fontSize: "13px",
                    marginBottom: "5px",
                  }}
                >
                  Location Address Line 2:
                </p>
                <input
                  type="text"
                  className="addUserDetails"
                  style={{
                    fontFamily: "futurabook",
                    color: "#141212",
                  }}
                  value={userInput.addressLine2}
                  onChange={(e) =>
                    handleInputChange("addressLine2", e.target.value)
                  }
                  maxLength={30}
                />
                        {addressLine2ErrorMessage && formSubmitted && (
                <div className="validation-message">
                  {addressLine2ErrorMessage}
                </div>
              )}
              </div>
            </div>
            {/* <div className="finish-row">
            <div className="finish-column wholeRow">
              <p
                className="detailsTitle"
                style={{
                  fontSize: "13px",
                  marginBottom: "5px",
                }}
              >
                Company:
              </p>
              {userRole === 2 ? (
                <div
                  className="addUserDetails"
                  style={{
                    fontFamily: "futuramedium",
                    color: "#141212",
                    height: "40px",
                    borderTop: "none",
                    borderRight: "none",
                    borderLeft: "none",
                    lineHeight: "40px", // Center vertically
                  }}
                >
                  {selectedCompanyName}
                </div>
              ) : (
                <select
                  type="text"
                  className="addUserDetails"
                  style={{
                    fontFamily: "futuramedium",
                    color: "#141212",
                    height: "40px",
                    borderTop: "none",
                    borderRight: "none",
                    borderLeft: "none",
                  }}
                  value={
                    userRole !== 1
                      ? selectedCompanyName // Use selectedCompanyName when userRole is not 1
                      : selectedCompanyNameFromDropdown
                  }
                  onChange={(e) => {
                    const selectedOption = e.target.value;
                    if (selectedOption === "Select a Company") {
                      // Reset the selected company when "Select a Company" is chosen
                      setSelectedCompanyNameFromDropdown("");
                      setSelectedCompanyIdFromDropdown("");
                    } else {
                      setSelectedCompanyNameFromDropdown(selectedOption);
                      const selectedCompanyDropdown = companyList.find(
                        (company) => company.name === selectedOption
                      );
                      if (selectedCompanyDropdown) {
                        setSelectedCompanyIdFromDropdown(
                          selectedCompanyDropdown.id
                        );
                      }
                    }
                  }}
                >
                  <option value="Select a Company">Select a Company</option>
                  {companyList.map((company) => (
                    <option key={company.id} value={company.name}>
                      {company.name}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>
          <div className="finish-row">
            <div className="finish-column wholeRow">
              <p
                className="detailsTitle"
                style={{
                  fontSize: "13px",
                  marginBottom: "5px",
                }}
              >
                Site*:
              </p>
              <select
                onClick={handleAddSiteModal}
                onBlur={(e) => {
                  // Check if the current value is not a valid site name
                  if (!sitesList.some((site) => site.name === e.target.value)) {
                    setSelectedSiteName("Select A Site");
                    setSelectedSiteId(""); // Optionally reset the selected site ID as well
                  }
                }}
                onFocus={fetchSiteList2}
                onHide={handlecloseAddSiteModal}
                className="addUserDetails"
                style={{
                  fontFamily: "futuramedium",
                  color: "#141212",
                  height: "40px",
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  color: !selectedCompanyIdFromDropdown && userRole !== 2 ? "gray" : "#141212",
                }}
                value={
                  siteDetails.company || selectedCompanyIdFromDropdown // bring it back to company if there are any errors
                    ? selectedSiteName
                    : "Loading..."
                }
                onChange={(e) => {
                  const selectedOption = e.target.value;
                  setSelectedSiteName(selectedOption);
                  // Find the corresponding site ID based on the selected site name
                  const selectedSite = sitesList.find(
                    (site) => site.name === selectedOption
                  );
                  if (selectedSite) {
                    setSelectedSiteId(selectedSite.id);
                  }
                }}
                disabled={!selectedCompanyIdFromDropdown  && userRole !== 2 }
              >
                <option value="">Select a site</option>
                {sitesList.map((site) => (
                  <option key={site.id} value={site.name}>
                    {site.name}
                  </option>
                ))}
                <option value="Add Site">Add Site</option>
              </select>

              <Modal
                show={showAddSiteModal}
                centered
                onHide={handlecloseAddSiteModal}
              >
                <AddSiteInLocationModal
                  toggleAddSiteInLocationModal={toggleAddSiteInLocationModal}
                  onClose={handlecloseAddSiteModal}
                  onHide={handlecloseAddSiteModal}
                  onSiteAdded={(newSiteName) => {
                    setShowAddSiteModal(false);
                    setSelectedSiteName(newSiteName);
                  }}
                />
              </Modal>
            </div>
          </div> */}

            <div className="finish-row">
              <div className="finish-column">
                <p
                  className="detailsTitle"
                  style={{
                    fontSize: "13px",
                    marginBottom: "5px",
                  }}
                >
                  Latitude:
                </p>
                <input
                  type="text"
                  className="addUserDetails"
                  style={{
                    fontFamily: "futurabook",
                    color: "#141212",
                  }}
                  value={
                    userInput.latitude !== null ? userInput.latitude : null
                  }
                  onChange={(e) =>
                    handleInputChange("latitude", e.target.value)
                  }
                />
              </div>
              <div className="finish-column">
                <p
                  className="detailsTitle"
                  style={{
                    fontSize: "13px",
                    marginBottom: "5px",
                  }}
                >
                  Longitude:
                </p>
                <input
                  type="text"
                  className="addUserDetails"
                  style={{
                    fontFamily: "futurabook",
                    color: "#141212",
                  }}
                  value={
                    userInput.longitude !== null ? userInput.longitude : null
                  }
                  onChange={(e) =>
                    handleInputChange("longitude", e.target.value)
                  }
                />
              </div>
              <span
                onMouseEnter={() => setIsSelectLocationHovered(true)}
                onMouseLeave={() => setIsSelectLocationHovered(null)}
                style={{
                  position: "absolute",
                  left: "94%",
                  width: "35px",
                  borderRadius: "100px",
                  padding: "5px",
                  fontSize: "15px",
                  background: "#F6C90E",
                  border: "none",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                  cursor: "pointer",
                }}
                onClick={handleSelectLocationClick}
              >
                <span
                  style={{
                    position: "relative",
                  }}
                >
                  <FaLocationArrow />
                  {isSelectLocationHovered && (
                    <div
                      style={{
                        position: "absolute",
                        top: "100%",
                        left: "50%",
                        transform: "translateX(-50%)",
                        background: "#ffffff",
                        padding: "2px",
                        borderRadius: "10px",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        zIndex: 999,
                        width: "90px",
                        fontSize: "12px",
                      }}
                    >
                      Select Location
                    </div>
                  )}
                </span>
              </span>
            </div>
            <Modal
              show={showSelectLocationModal}
              onHide={handleCloseSelectLocationModal}
              centered
            >
              <SelectSiteLocationModal
                onSelectLocation={onSelectLocation}
                onClose={handleCloseSelectLocationModal}
                selectedLat = {userInput.latitude}
                selectedLong = {userInput.longitude}
              />{" "}
              {/* Pass the function as a prop */}
            </Modal>
          </div>
          <div className="finish-column">
            <div
              className="toggle-container"
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <p
                className="detailsTitle"
                style={{
                  fontSize: "13px",
                  marginBottom: "5px",
                }}
              >
                Is Active?
              </p>
              <label className="toggle">
                <input
                  type="checkbox"
                  checked={userInput.isActive} // Use isActive from userInput
                  onChange={
                    () => handleInputChange("isActive", !userInput.isActive) // Toggle isActive in userInput
                  }
                />
                <div className="slider">
                  <span
                    className={userInput.isActive ? "yes-text" : "no-text"}
                    style={{
                      fontFamily: "futurabook",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "12px",
                        marginTop: "2px",
                        textAlign: "center",
                      }}
                    >
                      {userInput.isActive ? "YES" : "NO"}
                    </p>
                  </span>
                </div>
              </label>
            </div>
            <div
              style={{
                width: "100%",
                justifyContent: "flex-end",
                display: "flex",
                marginTop: "20px",
              }}
            >
              {isError && errorMessage && (
                <div
                  className="error-message"
                  style={{
                    color: "red",
                    fontSize: "10px",
                    marginTop: "15px",
                    marginRight: "10px",
                  }}
                >
                  {typeof errorMessage === "string" ? (
                    <p
                      style={{
                        fontSize: "13px",
                      }}
                    >
                      {errorMessage}
                    </p>
                  ) : (
                    Object.values(errorMessage).map((error, index) => (
                      <p
                        style={{
                          fontSize: "13px",
                        }}
                        key={index}
                      >
                        {error}
                      </p>
                    ))
                  )}
                </div>
              )}
              <button
                className="next-button"
                style={{
                  fontSize: "13px",
                  width: "18%",
                }}
                onClick={updateSite}
              >
                Update Location
              </button>
              <Modal
                show={showEditSuccessModal}
                //  onHide={handleCloseSuccessModal}
                centered
              >
                <Modal.Body>
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="close"
                      aria-label="Close"
                      onClick={handleCloseFailedModal}
                      style={{
                        background: "none",
                        border: "none",
                        fontSize: "35px",
                        position: "absolute",
                      }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <EditLocationSuccess />
                </Modal.Body>
              </Modal>
              <Modal
                show={showFailedModal}
                //  onHide={handleCloseFailedModal}
                centered
              >
                <Modal.Body>
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="close"
                      aria-label="Close"
                      onClick={handleCloseFailedModal}
                      style={{
                        background: "none",
                        border: "none",
                        fontSize: "35px",
                        position: "absolute",
                      }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <FailedEditLocationModal onClose={handleCloseFailedModal} />
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditLocationModal;
