import React from "react";
import "../../css/homecss/SafeTtagBenefits.css";
import {
  ThinShieldCheck,
  ThinArrowsRotate,
  ThinTriangleExclamation,
} from "../../fontAwesomeComponents/FaIcons";

const SafeTtagBenefits = () => {
  return (
    <div className="safeTtagBenefits">
      <div></div>
      <div className="title">
        <div>
          <h4 className="safettagbenefitstitle"
            style={{
              marginLeft: "10%",
              marginRight: "10%",
              marginTop: "5%",
              fontFamily: "futura, sans-serif",
              color: "#F6C90E",
            }}
          >
            Safe T Tag Benefits
          </h4>
          <div className="HeadText">
            <div className="headTextContent">
              <h4 className="headtextbenefits"
                style={{
                  color: "#fff",
                  fontFamily: "futura",
                  fontSize: "36px",
                  maxWidth: "40%",
                  marginLeft: "10%",
                }}
              >
               A-Grade Safe T Tag solution for electrical equipment.
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div
        className="columnsContainer"
        style={{ marginLeft: "10%", marginRight: "10%", gap: "15px" }}
      >
        <div
          className="leftColumn"
          style={{
            marginRight: "8%",
          }}
        >
          <div className="subText">
            The Safe T Tag provides a safe solution for workers who need to
            “Lockout” plugged electrical equipment. The main use of a Safe T Tag
            Electrical Plug Block is intended to secure electrical plugs to
            prevent their equipment from being accidentally switch-on. In
            addition to a physical lockout device, the Safe T Tag when used in
            conjunction with the digital QR Coded Centralised Database,
            digitally communicates clear essential / important user entered
            information and warnings about the lockout process; this then
            ensures everyone understands the Tagout situation, from the
            corporate office right through to the Electrician and subsequent
            users of the equipment (if required).
            <li
              style={{
                marginTop:"50px"
              }}
            >
              Compliments Routine Maintenance activities and workplace systems.
            </li>
            <li>
              Allows for stored information to retrieved electronically,
              protecting your most valuable assets.
            </li>
            <li>Allows for instant online record and control activities.</li>
            <li>
              Use of the Safe T Tag will not only save lives, but it will also
              add value to any maintenance program.
            </li>
            <li>It only takes seconds to apply and is safe to use.</li>
            <li>
              Using a durable and non-conductive Safe T Tag solution, not
              relying on signs and labels alone.
            </li>
            <li>
              A once-used device activates the “Red Band”, indicating that the
              device cannot be reset or reused.
            </li>
            <li>An applied Safe T Tag guards against any language barriers.</li>
            <li>Giving peace of mind and added security.</li>
          </div>
        </div>
        <div className="rightColumn">
          <div className="benefitItem">
            <div style={{ marginBottom: "50px" }}>
              <ThinShieldCheck
                height="30px"
                width="30px"
                color="#EEEEEE"
                className="icon1"
              />
            </div>

            <div className="benefitContent">
              <h4
                style={{
                  fontFamily: "futura, sans-serif",
                  marginBottom: "10PX",
                }}
              >
                Potentially saving lives and further damage.
              </h4>
              <p style={{ fontSize: "14px" }}>
                The Safe T Tag device is an effective and safe solution, to
                protect against electric shock and further damage to equipment.
                Once the Safe T Tag device has been correctly installed, it will
                not allow the device to be re-energised without the Safe T Tag
                being physically removed; unlike paper tags attached to cable
                that can be ignored or simply no seen.
              </p>
            </div>
          </div>
          <div className="benefitItem">
            <div style={{ marginBottom: "50px" }}>
            <ThinShieldCheck
                height="30px"
                width="30px"
                color="#EEEEEE"
                className="icon1"
              />
            </div>

            <div className="benefitContent">
              <h4
                style={{
                  fontFamily: "futura, sans-serif",
                  marginBottom: "10px",
                }}
              >
                No Lock and key required with a Safe T Tag device.
              </h4>
              <p style={{ fontSize: "14px" }}>
                The Safe T Tag is a modern electrical plug lockout device,
                without the lock and key. Unlike its predecessor that required a
                physical key to be located before the Electrical Plug Block
                could be removed, the one-time use Safe T Tag can be removed
                with a pair of heavy-duty pliers; amongst other benefits it may
                also speed up the overall repair process.
              </p>
            </div>
          </div>
          <div className="benefitItem">
            <div style={{ marginBottom: "50px" }}>
            <ThinShieldCheck
                height="30px"
                width="30px"
                color="#EEEEEE"
                className="icon1"
              />
            </div>
            <div className="benefitContent">
              <h4
                style={{
                  fontFamily: "futura, sans-serif",
                  marginBottom: "10px",
                }}
              >
                A physical tagout device for maintenance and repair work.
              </h4>
              <p style={{ fontSize: "14px" }}>
                Another benefit of a Safe T Tag device is the increased safety.
                It helps prevent accidental or unauthorized use of electrical
                equipment during maintenance or repair work. By locking out the
                power source and restricting access to the equipment, it ensures
                that workers are protected from potential electrical hazards
                such as electric shocks or arc flashes. The use of this device
                may also help in complying with safety regulations (AS/NZS
                4836:2011 - AS 4024.1603-2006) and reduce the risk of accidents
                or injuries.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SafeTtagBenefits;
