import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { publicRequest } from "../../requestMethods";
import { FaLocationArrow } from "react-icons/fa";
import SelectSiteLocationModal from "../../modals/SelectSiteLocationModal";
import AddLocationFailedModal from "./AddLocationFailedModal";
import AddLocationInTagSuccessModal from "./ValidationModal/AddLocationSuccessModal";

const AddLocationInSiteModal = ({ onClose, onSiteAdd, siteId }) => {
  const currentUser = useSelector((state) => state.user);
  const userId = useSelector((state) => state.user.id);
  const selectedUserCompanyId = useSelector((state) => state.selectedCompany); //userCompanyId
  const selectedCompanyName = useSelector((state) => state.selectedCompanyName);
  const selectedCompanyId = useSelector((state) => state.selectedCompanyId); //companyId
  const token = useSelector((state) => state.user.token);
  const userRole = useSelector((state) => state.selectedCompanyRoleId);
  const [formSubmitted, setFormSubmitted] = useState(false);
  // console.log(userRole);
  const [locationNameErrorMessage, setLocatioNameErrorMessage] = useState("");
  console.log(siteId);

  const [site, setSite] = useState();
  const [company, setCompany] = useState();
  const [user, setUser] = useState();
  const [isSelectLocationHovered, setIsSelectLocationHovered] = useState(false);

  //creating loc
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [addressLine1ErrorMessage, setAddressLine1ErrorMessage] = useState("");
  const [addressLine2ErrorMessage, setAddressLine2ErrorMessage] = useState("");

  ///sitestates

  //   const [
  //     showAddSiteInLocationSuccessModal,
  //     setShowAddSiteInLocationSuccessModal,
  //   ] = useState(false);

  const toggleAddSiteInLocationSuccessModal = () => {
    setShowAddSuccessModal(!showAddSuccessModal);
  };

  const [companyIdState, setCompanyIdState] = useState("");

  //modal

  const handleBackToProfile = () => {
    // Call the onClose callback to close both modals
    onClose();
  };

  const [showAddSuccessModal, setShowAddSuccessModal] = useState(false);

  const handleCloseSuccessSiteModal = () => {
    setShowAddSuccessModal(false);
    onClose();
  };

  const [showSelectLocationModal, setShowSelectLocationModal] = useState(false);

  const handleCloseSelectLocationModal = () => {
    setShowSelectLocationModal(false);
  };

  const handleSelectLocationClick = () => {
    if (navigator.geolocation) {
      // If Geolocation API is supported by the browser
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // If the user allows location access
          setShowSelectLocationModal(true);
        },
        (error) => {
          // If the user denies location access or there's an error
          alert("You need to allow location access to proceed.");
        }
      );
    } else {
      // If Geolocation API is not supported by the browser
      alert("Geolocation is not supported by your browser.");
    }
  };

  const handleCloseSelectLocation = () => {
    setShowSelectLocationModal(!showSelectLocationModal);
  };

  const [showFailedModal, setShowFailedModal] = useState(false);

  const handleCloseFailedModal = () => {
    setShowFailedModal(false);
  };

  const [selectedCompanyFromDropdown, setSelectedCompanyFromDropdown] =
    useState("");
  const [selectedCompanyIdFromDropdown, setSelectedCompanyIdFromDropdown] =
    useState("");
  const [companyList, setCompanyList] = useState([]);

  const [selectedCompanyNameFromDropdown, setSelectedCompanyNameFromDropdown] =
    useState("");

  const fetchData = async (url) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await publicRequest({
        method: "GET",
        url: url,
        headers: headers,
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!userRole === 1 && !selectedCompanyIdFromDropdown) {
      return;
    }

    const fetchCompanies = async () => {
      const companyResponse = await fetchData("lookup/companies");

      if (companyResponse && userRole === 1) {
        setCompanyList(companyResponse);
        console.log(companyList);
      }
    };

    fetchCompanies(); // Fetch companies when the component mounts

    fetchCompanies(); // Fetch companies when the component mounts
  }, [selectedCompanyIdFromDropdown, userRole]);

  const [siteDetails, setSiteDetails] = useState({
    loggedUserId: userId,
    loggedUserCompanyId: selectedUserCompanyId,
    //  companyId: userRole === 1 ? companyIdState : selectedCompanyId, // if admin give dropdown of companies and select from those copanies, if company admin use company id from redux
    companyId:
      userRole === 1 ? selectedCompanyIdFromDropdown : selectedCompanyId,
    name: "",
    company:
      userRole === 1 ? selectedCompanyNameFromDropdown : selectedCompanyName,
    location: "",
    addressLine1: "",
    addressLine2: "",
    latitude: null,
    longitude: null,

    //input other fields here later
  });

  //creating sites

  const createSite = async () => {
    setFormSubmitted(true);
    const data = {
      loggedUserId: userId,
      loggedUserCompanyId: selectedUserCompanyId,
      companyId:
        userRole === 1 ? selectedCompanyIdFromDropdown : selectedCompanyId,
      name: siteDetails.name,
      addressLine1: siteDetails.addressLine1,
      addressline2: siteDetails.addressLine2,
      latitude: siteDetails.latitude,
      longitude: siteDetails.longitude,
      siteId: siteId,
    };

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    // console.log(data);

    try {
      const response = await publicRequest({
        method: "POST",
        url: "/location/create",
        data: data,
        headers: headers,
      });

      setShowAddSuccessModal(true);
    } catch (error) {
      console.error(error);
      setShowFailedModal(true);
      setErrorMessage(error.response.data.errors);
      setIsError(true);
    }
  };

  console.log(companyList);

  //map

  const [selectedLocation, setSelectedLocation] = useState({
    lat: null,
    lng: null,
  });

  const handleLocationSelect = (lat, lng) => {
    setSelectedLocation({ lat, lng });
  };

  const handleInputChange = (field, value) => {
    const alphanumericRegex = /^[a-zA-Z0-9\s]+$/;
    if (field === "name") {
      const alphanumericRegex = /^[a-zA-Z0-9\s]+$/;
      if (!alphanumericRegex.test(value)) {
        setLocatioNameErrorMessage({
          name: "Location Name must only contain alphanumeric characters.",
        });
        setIsError(true);
      } else {
        setLocatioNameErrorMessage("");
        setIsError(false);
      }
    }

    if (field === "addressLine1") {
      if (!alphanumericRegex.test(value)) {
        setAddressLine1ErrorMessage("Address Line 1 must only contain alphanumeric characters.");
        setIsError(true);
      } else {
        setAddressLine1ErrorMessage("");
        setIsError(false);
      }
    }

    if (field === "addressLine2") {
      if (!alphanumericRegex.test(value)) {
        setAddressLine2ErrorMessage("Address Line 2 must only contain alphanumeric characters.");
        setIsError(true);
      } else {
        setAddressLine2ErrorMessage("");
        setIsError(false);
      }
    }

    setSiteDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const onSelectLocation = (lat, lng) => {
    // This function will be called from SelectSiteLocationModal
    // with the selected latitude and longitude
    setSiteDetails((prevDetails) => ({
      ...prevDetails,
      latitude: lat,
      longitude: lng,
    }));
  };

  return (
    <div className="ModalChildDiv">
      <div
        style={{
          alignItems: "center",
          display: "flex",
          width: "100%",
          justifyContent: "center",
          zIndex: 800,
        }}
      >
        <div
          className="modal-content custom-modal-content"
          style={{
            padding: "10px",
            paddingLeft: "20px",
            paddingRight: "20px",
            background: "none",
            border: "none",
          }}
        >
          <div className="row">
            <div
              className="finalTitleDiv"
              style={{
                alignItems: "flex-start",
              }}
            >
              <h3
                style={{
                  fontFamily: "futuramedium",
                  fontWeight: "bold",
                  fontSize: "30px",
                  marginTop: "15px",
                }}
              >
                Add Location
              </h3>
            </div>
          </div>
          <div className="finish-row">
            <div className="finish-column wholeRow">
              <p
                className="detailsTitle"
                style={{
                  fontSize: "13px",
                  marginBottom: "5px",
                }}
              >
                Location Name*:
              </p>
              <input
                type="text"
                className="addUserDetails"
                placeholder="Name"
                style={{
                  fontFamily: "futurabook",
                  color: "#141212",
                }}
                value={siteDetails.name}
                onChange={(e) => handleInputChange("name", e.target.value)}
              />

              {siteDetails.name === "" && formSubmitted && (
                <div className="validation-message">
                  Location Name is required
                </div>
              )}

              {locationNameErrorMessage.name && formSubmitted && (
                <div
                  className="validation-message"
                  style={{
                    marginTop: "0.1px",
                  }}
                >
                  {locationNameErrorMessage.name}
                </div>
              )}
            </div>
          </div>

          <div className="finish-row">
            <div className="finish-column wholeRow">
              <p
                className="detailsTitle"
                style={{
                  fontSize: "13px",
                  marginBottom: "5px",
                }}
              >
                Company Name*:
              </p>
              {userRole !== 1 ? (
                <div
                  className="addUserDetails"
                  style={{
                    fontFamily: "futuramedium",
                    color: "#141212",
                    height: "40px",
                    borderTop: "none",
                    borderRight: "none",
                    borderLeft: "none",
                    lineHeight: "40px", // Center vertically
                  }}
                >
                  {selectedCompanyName}
                </div>
              ) : (
                <select
                  type="text"
                  className="addUserDetails"
                  style={{
                    fontFamily: "futuramedium",
                    color: "#141212",
                    height: "40px",
                    borderTop: "none",
                    borderRight: "none",
                    borderLeft: "none",
                  }}
                  value={
                    userRole !== 1
                      ? selectedCompanyName // Use selectedCompanyName when userRole is not 1
                      : selectedCompanyNameFromDropdown
                  }
                  onChange={(e) => {
                    const selectedOption = e.target.value;
                    if (selectedOption === "Select a Company") {
                      // Reset the selected company when "Select a Company" is chosen
                      setSelectedCompanyNameFromDropdown("");
                      setSelectedCompanyIdFromDropdown("");
                    } else {
                      setSelectedCompanyNameFromDropdown(selectedOption);
                      const selectedCompanyDropdown = companyList.find(
                        (company) => company.name === selectedOption
                      );
                      if (selectedCompanyDropdown) {
                        setSelectedCompanyIdFromDropdown(
                          selectedCompanyDropdown.id
                        );
                      }
                    }
                  }}
                >
                  <option value="">Select a Company</option>
                  {companyList.map((company) => (
                    <option key={company.id} value={company.name}>
                      {company.name}
                    </option>
                  ))}
                </select>
              )}
              {userRole === 1 &&
                selectedCompanyIdFromDropdown === "" &&
                formSubmitted && (
                  <div className="validation-message">
                    {" "}
                    Please Select a Company
                  </div>
                )}
            </div>
          </div>
          <div className="finish-row">
            <div className="finish-column">
              <p
                className="detailsTitle"
                style={{
                  fontSize: "13px",
                  marginBottom: "5px",
                }}
              >
                Location Address Line 1*:
              </p>

              <input
                type="text"
                className="addUserDetails"
                placeholder="Address Line 1"
                style={{
                  fontFamily: "futurabook",
                  color: "#141212",
                }}
                value={siteDetails.addressLine1}
                onChange={(e) =>
                  handleInputChange("addressLine1", e.target.value)
                }
              />
              {siteDetails.addressLine1 === "" && formSubmitted && (
                <div className="validation-message">
                  Location Address Line 1 is required
                </div>
              )}

              {addressLine1ErrorMessage && formSubmitted && (
                <div className="validation-message">
                  {addressLine1ErrorMessage}
                </div>
              )}
            </div>
            <div className="finish-column">
              <p
                className="detailsTitle"
                style={{
                  fontSize: "13px",
                  marginBottom: "5px",
                }}
              >
                Location Address Line 2:
              </p>

              <input
                type="text"
                className="addUserDetails"
                placeholder="Address Line 2"
                style={{
                  fontFamily: "futurabook",
                  color: "#141212",
                }}
                value={siteDetails.addressLine2}
                onChange={(e) =>
                  handleInputChange("addressLine2", e.target.value)
                }
                maxLength={30}
              />
              {addressLine2ErrorMessage && formSubmitted && (
                <div className="validation-message">
                  {addressLine2ErrorMessage}
                </div>
              )}
            </div>
          </div>
          <Modal
            show={showSelectLocationModal}
            onHide={handleCloseSelectLocationModal}
            centered
          >
            <SelectSiteLocationModal
              onSelectLocation={onSelectLocation}
              onClose={handleCloseSelectLocation}
            />{" "}
            {/* Pass the function as a prop */}
          </Modal>
          <div className="finish-row">
            <div className="finish-column">
              <p
                className="detailsTitle"
                style={{
                  fontSize: "13px",
                  marginBottom: "5px",
                }}
              >
                Latitude:
              </p>
              <input
                type="text"
                className="addUserDetails"
                placeholder="Latitude"
                style={{
                  fontFamily: "futurabook",
                  color: "#141212",
                }}
                value={siteDetails.latitude}
                onChange={(e) => handleInputChange("latitude", e.target.value)}
              />
            </div>
            <div className="finish-column">
              <p
                className="detailsTitle"
                style={{
                  fontSize: "13px",
                  marginBottom: "5px",
                }}
              >
                Longitude:
              </p>
              <input
                type="text"
                className="addUserDetails"
                placeholder="Longitude"
                style={{
                  fontFamily: "futurabook",
                  color: "#141212",
                }}
                value={siteDetails.longitude}
                onChange={(e) => handleInputChange("longitude", e.target.value)}
              />
            </div>
            <span
              onMouseEnter={() => setIsSelectLocationHovered(true)}
              onMouseLeave={() => setIsSelectLocationHovered(null)}
              style={{
                position: "absolute",
                left: "94%",
                width: "35px",
                borderRadius: "100px",
                padding: "5px",
                fontSize: "15px",
                background: "#F6C90E",
                border: "none",
                display: "flex",
                justifyContent: "center",
                marginBottom: "25px",
                cursor: "pointer",
              }}
              onClick={handleSelectLocationClick}
            >
              <span
                style={{
                  position: "relative",
                }}
              >
                <FaLocationArrow />
                {isSelectLocationHovered && (
                  <div
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: "50%",
                      transform: "translateX(-50%)",
                      background: "#ffffff",
                      padding: "2px",
                      borderRadius: "10px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      zIndex: 999,
                      width: "90px",
                      fontSize: "12px",
                    }}
                  >
                    Select Location
                  </div>
                )}
              </span>
            </span>
          </div>

          <div
            style={{
              width: "100%",
              justifyContent: "space-between",
              display: "flex",
              marginTop: "20px",
            }}
          >
            <p
              style={{
                fontSize: "11px",
                color: "#141212",
              }}
            >
              The (*) Star denotes that the question must be completed to SAVE
              the data
            </p>
            {/* {isError && errorMessage && (
              <div
                className="error-message"
                style={{
                  color: "red",
                  fontSize: "10px",
                  marginTop: "15px",
                  marginRight: "10px",
                }}
              >
                {typeof errorMessage === "string" ? (
                  <p
                    style={{
                      fontSize: "13px",
                    }}
                  >
                    {errorMessage}
                  </p>
                ) : (
                  Object.values(errorMessage).map((error, index) => (
                    <p
                      style={{
                        fontSize: "13px",
                      }}
                      key={index}
                    >
                      {error}
                    </p>
                  ))
                )}
              </div>
            )} */}
            <button
              className="next-button"
              style={{
                fontSize: "13px",
                width: "18%",
              }}
              onClick={createSite}
            >
              Add New Location
            </button>
          </div>
          <Modal
            show={showAddSuccessModal}
            //        onHide={toggleAddSiteInLocationSuccessModal} // Close the modal when the backdrop is clicked
            centered
          >
            <Modal.Body>
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={handleCloseSuccessSiteModal}
                  style={{
                    background: "none",
                    border: "none",
                    fontSize: "20px",
                    position: "absolute",
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <AddLocationInTagSuccessModal
                onClose={handleCloseSuccessSiteModal}
              />
            </Modal.Body>
          </Modal>

          <Modal
            show={showFailedModal}
            //    onHide={handleCloseFailedModal}
            centered
          >
            <Modal.Body>
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={handleCloseFailedModal}
                  style={{
                    background: "none",
                    border: "none",
                    fontSize: "20px",
                    position: "absolute",
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <AddLocationFailedModal onClose={handleCloseFailedModal} />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default AddLocationInSiteModal;
