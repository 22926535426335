import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../../css/userProfilecss/UserProfile.css";
import { Link } from "react-router-dom";
import { publicRequest } from "../../requestMethods";
import { Modal } from "react-bootstrap";
import SingleEditCompanyModal from "../../modals/SingleEditCompanyModal";
import SingleEditAccountModal from "../../modals/SingleEditAccountModal";
import AddDisplayPictureModal from "./AddDisplayPictureModal";
import ResetPolicySuccess from "./ValidationModals/ResetPolicySuccess";
import FailedResetPolicy from "./ValidationModals/FailedResetPolicy";

const UserProfileComponent = () => {
  const firstName = useSelector((state) => state.user.firstName);
  const lastName = useSelector((state) => state.user.lastName);
  const userFullName = firstName + " " + lastName;
  const userEmail = useSelector((state) => state.user.userName);
  const userLicenseNumber = useSelector((state) => state.user.licenseNumber);
  const userId = useSelector((state) => state.user.id);
  const token = useSelector((state) => state.user.token);
  const companyId = useSelector((state) => state.selectedCompanyId);
  const userRole = useSelector((state) => state.selectedCompanyRoleId);
  const selectedUserCompanyId = useSelector((state) => state.selectedCompany);
  const isSafeTTagadmin = userRole == "1";

  const isAdmin = userRole == "1" || userRole == "2";
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [loading, setLoading] = useState(true);

  const formatDate2 = (dateString) => {
    // Parse the input date string in the format "MM/DD/YYYY HH:mm:ss"
    const [day, month, year, hours, minutes, seconds] = dateString.split(/[\s\/:]/);
  
    // Create a Date object using the components
    const date = new Date(
      Date.UTC(Number(year), Number(month) - 1, Number(day), Number(hours), Number(minutes), Number(seconds))
    );
  
    // Convert the date to the user's timezone
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true, // Use 12-hour format with AM/PM
      timeZone: userTimezone, // Set the user's timezone
    };
  
    return new Intl.DateTimeFormat("en-AU", options).format(date);
  };
  

  const [showEditCompanyModal, setShowEditCompanyModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const [showResetSuccessModal, setShowResetSuccessModal] = useState(false);

  const handleCloseResetSuccessModal = () => {
    setShowResetSuccessModal(false);
  };

  const [showResetFailedModal, setShowResetFailedModal] = useState(false);

  const handleCloseResetFailedModal = () => {
    setShowResetFailedModal(false);
  };

  const [showUploadProfilePictureModal, setShowUploadProfilePictureModal] =
    useState(false);

  const handleUploadProfilePicture = () => {
    setShowUploadProfilePictureModal(true);
  };

  const handleCloseUploadProfilePicture = () => {
    setShowUploadProfilePictureModal(false);
  };

  const handleEditCompanyClick = (company) => {
    setSelectedCompany(company);
    setShowEditCompanyModal(true);
  };

  const handleCloseEditCompanyModal = () => {
    setShowEditCompanyModal(false);
  };

  const [selectedUser, setSelectedUser] = useState(null);

  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const handleEditUserClick = (user) => {
    setSelectedUser(user);
    setShowEditUserModal(true);
  };

  const handleCloseEditUserModal = () => {
    setShowEditUserModal(false);
  };

  const [showLicense, setShowLicense] = useState(false);
  const [licenseButtonLabel, setLicenseButtonLabel] = useState(
    "Electrician's License Number"
  );

  const [user, setUser] = useState();
  const [company, setCompany] = useState();

  const handleLicenseClick = () => {
    setShowLicense(!showLicense);
    setLicenseButtonLabel(
      showLicense
        ? "Electrician's License Number"
        : user
        ? user.licenseNumber
        : "Loading..."
    );
  };

  const fetchData = async (url) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await publicRequest({
        method: "GET",
        url: url,
        headers: headers,
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching data", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchUserAndCompanyData = async () => {
      try {
        const userResponse = await fetchData(`/user/${userId}/profile`);
        const companyResponse = await fetchData(`/company/${companyId}`);

        if (userResponse) {
          setUser(userResponse);
        }

        if (companyResponse) {
          setCompany(companyResponse);
        }

        // Set loading to false once data is fetched
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setLoading(false);
      }
    };

    fetchUserAndCompanyData();
  }, [token, userId, companyId]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };

  const handleResetTerms = async () => {
    const data = {
      loggedUserId: userId,
      loggedUserCompanyId: selectedUserCompanyId,
    };

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await publicRequest({
        method: "POST",
        url: "/admin/policy/reset",
        data: data,
        headers: headers,
      });

      setShowResetSuccessModal(true);
    } catch (error) {
      console.error(error);

      setShowResetFailedModal(true);
    }
  };

  if (loading) {
    return (
      <div
        className="userProfileContainer"
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Loading...
      </div>
    );
  }

  return (
    <div className="userProfileContainer">
      <div className="profilePictureContainer">
        <div className="imageContainer">
          {user && user.profilePicture ? (
            <img
              src={`data:image/jpeg;base64,${user.profilePicture}`}
              style={{ height: "250px", width: "250px", borderRadius: "50%" }}
              alt="Profile"
            />
          ) : (
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/1024px-Default_pfp.svg.png"
              style={{ height: "250px", width: "250px" }}
              alt="Profile"
            />
          )}

          <p
            className="changePhotoButton"
            style={{ color: "#007BFF", fontSize: "13px" }}
            onClick={handleUploadProfilePicture}
          >
            Change Display Photo
          </p>
          <Modal
            show={showUploadProfilePictureModal}
            onHide={handleCloseUploadProfilePicture}
            centered
          >
            <AddDisplayPictureModal />
          </Modal>
        </div>

        <div className="userInfoContainer">
          <p
            style={{
              fontFamily: "futuramedium",
              fontSize: "20px",
              fontWeight: "bolder",
            }}
          >
            {/* {userFullName} */} {user ? user.firstName : "Loading..."}{" "}
            {user ? user.lastName : ""}
          </p>
          <p
            style={{
              fontFamily: "futuramedium",
              fontSize: "18px",
            }}
          >
            {/* {userEmail} */} {user ? user.userName : "Loading"}
          </p>
          <button
            onClick={handleLicenseClick}
            style={{
              fontFamily: "futuraMedium",
              padding: "15px",
              fontWeight: "bold",
              border: "none",
              borderRadius: "10px",
            }}
          >
            {licenseButtonLabel}
          </button>
          {showLicense && <label>{userLicenseNumber}</label>}
        </div>
      </div>

      <div
        className="accountInfoContainer"
        style={{
          marginLeft: "25px",
        }}
      >
        <div className="accountTitle">
          <p className="infoTitle">My Account</p>
          <div className="editButtons">
            {isAdmin && (
              <button
                style={{
                  border: "none",
                  backgroundColor: "#3A475033",
                  borderRadius: "7px",
                  padding: "5px",
                  fontSize: "13.5px",
                  fontFamily: "futuramedium",
                }}
                onClick={() => handleEditCompanyClick(company)}
              >
                Edit Company Details
              </button>
            )}

            <Modal
              size="xl"
              show={showEditCompanyModal}
              //      onHide={handleCloseEditCompanyModal}
              centered
            >
              <Modal.Body>
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={handleCloseEditCompanyModal}
                    style={{
                      background: "none",
                      border: "none",
                      fontSize: "30px",
                      position: "absolute",
                      zIndex: "999",
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <SingleEditCompanyModal company={selectedCompany} />
              </Modal.Body>
            </Modal>

            <button
              style={{
                border: "none",
                backgroundColor: "#3A475033",
                borderRadius: "7px",
                padding: "5px",
                fontSize: "13.5px",
                fontFamily: "futuramedium",
              }}
              onClick={() => handleEditUserClick(user)}
            >
              Edit Account
            </button>

            <Modal
              size="xl"
              centered
              show={showEditUserModal}
              //      onHide={handleCloseEditUserModal}
            >
              <Modal.Body>
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={handleCloseEditUserModal}
                    style={{
                      background: "none",
                      border: "none",
                      fontSize: "35px",
                      position: "absolute",
                      zIndex: "999",
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <SingleEditAccountModal user={selectedUser} />
              </Modal.Body>
            </Modal>

            <Link to="/changepassword" className="change-password-link">
              <button
                className="change-password-button"
                style={{
                  border: "none",
                  backgroundColor: "#28A745",
                  borderRadius: "7px",
                  padding: "5px",
                  fontSize: "13.5px",
                  fontFamily: "futuramedium",
                  color: "#FFFFFF",
                }}
              >
                Change Password
              </button>
            </Link>
            {isSafeTTagadmin && (
              <button
                style={{
                  border: "none",
                  backgroundColor: "#DC3545",
                  borderRadius: "7px",
                  padding: "5px",
                  fontSize: "13.5px",
                  fontFamily: "futuramedium",
                  color: "#FFFFFF",
                  marginLeft: "10px",
                }}
                onClick={() => handleResetTerms()}
              >
                Reset Terms and Conditions
              </button>
            )}
            <Modal centered show={showResetSuccessModal}>
              <Modal.Body>
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={handleCloseResetSuccessModal}
                    style={{
                      background: "none",
                      border: "none",
                      fontSize: "35px",
                      position: "absolute",
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <ResetPolicySuccess onClose={handleCloseResetFailedModal} />
              </Modal.Body>
            </Modal>

            <Modal centered show={showResetFailedModal}>
              <Modal.Body>
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={handleCloseResetFailedModal}
                    style={{
                      background: "none",
                      border: "none",
                      fontSize: "35px",
                      position: "absolute",
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <FailedResetPolicy onClose={handleCloseResetFailedModal} />
              </Modal.Body>
            </Modal>
          </div>
        </div>
        <hr />

        <div className="accountDetails">
          <p
            style={{
              fontFamily: "futuramedium",
              fontSize: "18px",
              fontWeight: "bolder",
            }}
          >
            {" "}
            {userEmail}
          </p>
          <p
            style={{
              fontFamily: "futurabook",
              fontWeight: "bold",
              fontSize: "15px",
            }}
          >
            Mobile Number:{" "}
            {user
              ? user.mobilePhone.includes("+")
                ? user.mobilePhone
                : `+${user.mobilePhone}`
              : "Loading..."}
          </p>

          <p
            style={{
              fontFamily: "futurabook",
              fontWeight: "bold",
              fontSize: "15px",
            }}
          >
            Office Phone Number:{" "}
            {company ? `${company.officePhone}` : "Loading..."}
          </p>
        </div>

        <div className="accountTitle">
          <p className="infoTitle">Account Information</p>
        </div>
        <hr />

        <div className="accountDetails">
          <p
            style={{
              fontFamily: "futurabook",
              fontWeight: "bold",
              fontSize: "15px",
            }}
          >
            Date of Birth: {user ? formatDate(user.doB) : "Loading..."}
          </p>
          <p
            style={{
              fontFamily: "futurabook",
              fontWeight: "bold",
              fontSize: "15px",
            }}
          >
            Address:{" "}
            {company
              ? company.addressLine1 +
                " " +
                company.addressLine2 +
                " " +
                company.city
              : "Loading..."}
          </p>
          <p
            style={{
              fontFamily: "futurabook",
              fontWeight: "bold",
              fontSize: "15px",
            }}
          >
            Current Company Name: {company ? company.name : "Loading..."}
          </p>
          <p
            style={{
              fontFamily: "futurabook",
              fontWeight: "bold",
              fontSize: "15px",
            }}
          >
            {/* Current Location:{" "} */}
          </p>
          <p
            style={{
              fontFamily: "futurabook",
              fontWeight: "bold",
              fontSize: "15px",
            }}
          >
            {/* Current Site:{" "} */}
          </p>
        </div>
        {!isSafeTTagadmin && (
          <div
            className="accountDetails"
            style={{
              marginTop: "40px",
              marginLeft: "-20px",
            }}
          >
            <label>
              <input type="checkbox" checked readOnly disabled />
              <span
                style={{
                  fontFamily: "futurabook",
                  //   fontWeight: "bold",
                  fontSize: "15px",
                  marginLeft: "5px",
                  color: "#3A4750",
                }}
              >
                Safe T Tag{" "}
                {/* <a
                  href="/disclaimer"
                  style={{
                    color: "black",
                    textDecoration: "none",
                    fontFamily: "futuraheavy",
                  }}
                >
                  Disclaimer
                </a>{" "}
                and{" "} */}
                <a
                  href="/privacypolicy"
                  style={{
                    color: "black",
                    textDecoration: "none",
                    fontFamily: "futuraheavy",
                  }}
                >
                  Privacy Policy
                </a>{" "}
                <span
                  style={{
                    color: "#707070",
                  }}
                >
                  and{" "}
                </span>{" "}
                <a
                  style={{ color: "black", textDecoration: "none" }}
                  href="/termsandconditions"
                >
                  Terms & Conditions{" "}
                </a>
                accepted on{" "}
                {user ? formatDate2(user.dateAccepted) : "Loading..."}
              </span>
            </label>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserProfileComponent;
