import React, { useState, useEffect } from "react";
import { publicRequest } from "../requestMethods";
import ChangePasswordComponent from "../components/ChangePasswordComponent/ChangePasswordComponent";
import { CircleX } from "../fontAwesomeComponents/FaIcons";
import { useNavigate } from "react-router-dom";

const ResetPasswordPage = () => {
  const [resetCode, setResetCode] = useState("");
  const [resetCodeValid, setResetCodeValid] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  const navigate = useNavigate();
  const handleBackToProfile = () => {
    navigate("/login");
  };

  console.log("this is user info", userInfo);

  // Function to retrieve the reset code from the URL
  const getResetCodeFromURL = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const resetCodeParam = urlParams.get("value");
    if (resetCodeParam) {
      setResetCode(decodeURIComponent(resetCodeParam));
    }
  };

  const queryParams = {
    code: resetCode,
  };

  useEffect(() => {
    // Call the function to get the reset code from the URL
    getResetCodeFromURL();
  }, []); // Empty dependency array to run once when the component mounts

  const fetchData = async (url) => {
    try {
      const queryString = new URLSearchParams(queryParams).toString();
      const fullUrl = url + (queryString ? `?${queryString}` : "");

      const response = await publicRequest({
        method: "GET",
        url: fullUrl,
      });
      return response.data;
    } catch (error) {
      setResetCodeValid(false);
      console.error("error fetching data", error);
    }
  };

  useEffect(() => {
    // Function to validate the reset code
    const validateResetCode = async () => {
      try {
        if (resetCode) {
          const validateCodeParams = {
            code: resetCode,
          };

          // const resetCodeResponse = await publicRequest(
          //   "/auth/validatereset",
          //   "GET", // You should specify the HTTP method (e.g., POST)
          //   validateCodeParams
          // );

          const resetCodeResponse = await fetchData(
            "/auth/validatereset",
            queryParams
          );

          // Assuming the response contains user information
          setUserInfo(resetCodeResponse); // You need to adjust this based on your API response structure

          console.log("this is response", resetCodeResponse);
          setResetCodeValid(true);
        } else {
          setResetCodeValid(false);
        }
      } catch (error) {
        // Handle error or set resetCodeValid to false if the code is not valid
        setResetCodeValid(false);
        console.error(error);
      }
    };

    // Call the validation function when the component mounts and whenever resetCode changes
    validateResetCode();
  }, [resetCode]);

  return (
    <div>
      {userInfo && Object.keys(userInfo).length > 0 ? (
        <ChangePasswordComponent user={userInfo} />
      ) : (
        <div
          style={{
            height: "100vh",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <div
              style={{
                marginBottom: "20px",
                backgroundColor: "#EFC8C8",
                padding: "10px",
                borderRadius: "16px",
              }}
            >
              <CircleX height="50px" width="50px" color="#DC3545" />
            </div>

            <h2
              style={{
                fontFamily: "futuraheavy",
                fontSize: "25px",
                marginBottom: "20px",
              }}
            >
              Invalid Token
            </h2>

            <button
              className="back-to-login-button"
              onClick={handleBackToProfile}
              style={{
                backgroundColor: "#DC3545",
                width: "80%",
                fontFamily: "futurabook",
                color: "#FFFFFF",
                height: "50px",
                border: "none",
                borderRadius: "10px",
              }}
            >
              Back to Login
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResetPasswordPage;
