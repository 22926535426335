import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../css/Navbar.css";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../redux/userRedux";
import { BellIconSolid } from "../fontAwesomeComponents/FaIcons";
import { Dropdown } from "react-bootstrap";
import Notification from "./Notification";
import { publicRequest } from "../requestMethods";
import { refreshTokenFunction } from "../redux/apiCals";

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      const screenWidth = window.innerWidth;
      setIsMobile(screenWidth < 768); // Adjust this value according to your design requirements
    };

    checkIfMobile();

    window.addEventListener("resize", checkIfMobile);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  const [activeLink, setActiveLink] = useState("");
  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  const [userData, setUserData] = useState([]);
  const [isError, setIsError] = useState(false);

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  // const [isLocationEnabled, setIsLocationEnabled] = useState(
  //   navigator.geolocation
  // );

  const [isLocationEnabled, setIsLocationEnabled] = useState(true); // Default to true

  useEffect(() => {
    const checkLocationPermission = async () => {
      try {
        const permissionStatus = await navigator.permissions.query({
          name: "geolocation",
        });
        setIsLocationEnabled(permissionStatus.state === "granted");
      } catch (error) {
        console.error("Error checking location permission:", error);
        setIsLocationEnabled(false); // Assume location is disabled in case of error
      }
    };

    checkLocationPermission();

    // Add cleanup function if needed
  }, []);

  const fetchData = async (url) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await publicRequest({
        method: "GET",
        url: url,
        headers: headers,
      });

      return response.data;
    } catch (error) {
      if (user) {
        try {
          const res = await refreshTokenFunction(dispatch, {
            token,
            refreshToken,
          });
          console.log(res);
        } catch (refreshError) {
          console.error("Error refreshing token", refreshError);
        }
      }

      console.error("Error fetching data", error);
      return null;
    }
  };

  useEffect(() => {
    const handleOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOnlineStatus);

    return () => {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOnlineStatus);
    };
  }, []);

  const location = useLocation();
  const navigate = useNavigate(); // Initialize useNavigate
  // const isRegisterPage = location.pathname === '/register';
  const isHomePage = location.pathname === "/";
  const isHome =
    location.pathname === "/" ||
    location.pathname === "/tags" ||
    location.pathname === "/sites" ||
    location.pathname === "/companies" ||
    location.pathname === "/userlist" ||
    location.pathname === "/userapproval" ||
    location.pathname === "/company" ||
    location.pathname === "/sites" ||
    location.pathname === "/home" ||
    location.pathname === "/locations" ||
    location.pathname === "/items" ||
    location.pathname === "/tags/archive" ||
    location.pathname === "/tags/deleted" ||
    location.pathname === "/items/deleted" ||
    location.pathname === "/audittrail";

  const isDownload = location.pathname === "/downloadtheapp";

  const isContactUs = location.pathname === "/contactus";

  const isDistributors = location.pathname === "/distributors";

  const isGallery = location.pathname === "/gallery";

  const user = useSelector((state) => state.user);
  const token = user ? user.token : "";
  const userId = user ? user.id : "";
  const userFirstName = user ? user.firstName : "";
  const userLastName = user ? user.lastName : "";
  const refreshToken = user ? user.refreshToken : "";
  const selectedUserCompanyId = useSelector((state) => state.selectedCompany);

  const dispatch = useDispatch();

  const logoutFunction = async () => {
    try {
      const logoutLink = "/auth/logout";

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const logoutBody = {
        loggedUserId: userId,
        loggedUserCompanyId: selectedUserCompanyId,
      };

      // Now make the logout request
      const response = await publicRequest({
        method: "POST",
        url: logoutLink,
        headers: headers,
        data: logoutBody,
      });

      dispatch(logout());

      navigate("/home");
    } catch (error) {
      console.error("Error during logout", error);
      dispatch(logout());
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    await logoutFunction();
  };

  useEffect(() => {
    const fetchUserData = async () => {
      // Check if there is a user before attempting to fetch data
      if (userId) {
        const userResponse = await fetchData(`/user/${userId}/profile`);

        if (userResponse) {
          setUserData(userResponse);
        }
      }
    };

    fetchUserData();

    // Trigger refresh token function only if there is a user
  }, [userId, user]); // Add userId and user as dependencies

  // useEffect(() => {
  //   setIsLocationEnabled(navigator.geolocation);
  // }, []);

  return (
    <div>
      {isOnline ? null : (
        <div className="navbaralert"
          style={{ backgroundColor: "red", width: "100%", textAlign: "center" }}
        >
          You are offline. Please check your internet connection.
        </div>
      )}

      {!isLocationEnabled && !isMobile &&(
        <div  className="navbaralert"
          style={{
            backgroundColor: "red",
            width: "100%",
            textAlign: "center",
          }}
        >
          Location services are turned off. Please enable them to access Safe T
          Tag features.
        </div>
      )}

      <div className="navbar">
        <div className="navContainer">
          {!isMobile && (
            <div className="logo">
              <Link to="/home" onClick={() => handleLinkClick("/")}>
                <img
                  src="../../Images/Logo.png"
                  alt="Logo"
                  style={{
                    opacity: "0",
                    minWidth: "225px",
                  }}
                />
              </Link>
            </div>
          )}

          <div>
            <div className="navText">
              <Link
                to={user ? "/tags" : "/home"}
                className={isHome ? "active" : ""}
                onClick={() => handleLinkClick("/")}
              >
                HOME
              </Link>

              <Link
                to="/distributors"
                className={isDistributors ? "active" : ""}
                onClick={() => handleLinkClick("/distributors")}
              >
                DISTRIBUTOR
              </Link>
              <Link
                to="/downloadtheapp"
                className={isDownload ? "active" : ""}
                onClick={() => handleLinkClick("/downloadtheapp")}
              >
                DOWNLOAD THE APP
              </Link>
              <Link
                to="/gallery"
                className={isGallery ? "active" : ""}
                onClick={() => handleLinkClick("/gallery")}
              >
                GALLERY
              </Link>
              <Link
                to="/contactus"
                className={isContactUs ? "active" : ""}
                onClick={() => handleLinkClick("/contactus")}
              >
                CONTACT US
              </Link>
            </div>
          </div>

          <div className="user-logout-container">
            {!user && !isMobile && (
              // Show "Register" and "Login" buttons on the Register page
              <div className="auth-buttons">
                <Link to="/login" className="logout-button">
                  Login
                </Link>

                <Link to="/register" className="register-button">
                  Register
                </Link>
              </div>
            )}
            {user && (
              // Show "Logout" button on the Home page if the user is active
              <div
                style={{ display: "flex", gap: "1px", alignItems: "center" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {/* <Notification /> */}
                  <Dropdown className="" style={{ marginLeft: "-5px" }}>
                    <Dropdown.Toggle
                      className="userProfileLink"
                      style={{
                        textDecoration: "none",
                        color: "#000000",
                        backgroundColor: "none",
                        background: "none",
                        border: "none",
                      }}
                    >
                      {userFirstName}
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{}}>
                      <Dropdown.Item href="/userprofile">
                        User Profile
                      </Dropdown.Item>
                      <Dropdown.Item href="/logout" onClick={handleDelete}>
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {userData && userData.profilePicture ? (
                    <img
                      src={`data:image/jpeg;base64,${userData.profilePicture}`}
                      style={{
                        height: "30px",
                        width: "30px",
                        borderRadius: "50%",
                      }}
                      alt="Profile"
                    />
                  ) : (
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/1024px-Default_pfp.svg.png"
                      style={{ height: "30px", width: "30px" }}
                      alt="Profile"
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
