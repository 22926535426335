import React, { useState, useEffect } from "react";
import "../../css/userApprovalcss/userApproval.css";
import { publicRequest } from "../../requestMethods";
import {
  ThinEditIcon,
  ThinArchive,
  RefreshIcon,
  DownloadIcon,
  ThinAddNote,
} from "../../fontAwesomeComponents/FaIcons";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import AddCompanyModal from "./AddCompanyModal";
import EditCompanyModal from "../../modals/EditCompanyModal";
import AddCompanyNotes from "./AddCompanyNotes";

const CompanyList = ({ searchQuery }) => {
  // modal section

  const [showAddCompanyModal, setShowAddCompanyModal] = useState(false);
  const [isEditHovered, setIsEditHovered] = useState(false);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [filteredSites, setFilteredSites] = useState([]);
  const [isRefreshHovered, setIsRefreshHovered] = useState(false);

  const sortCompaniesAlphabetically = (companies) => {
    return companies.sort((a, b) => a.name.localeCompare(b.name));
  };



  const [isLoading, setIsLoading] = useState(false);

  const [siteNoteExpanded, setSiteNoteExpanded] = useState({});
  const [openAddSiteNoteModal, setOpenAddSiteNoteModal] = useState(false);
  const [hoveredSite, setHoveredSite] = useState(null);

  const handleOpenAddSiteNoteModal = (company) => {
    setSelectedCompany(company);
    setOpenAddSiteNoteModal(true);
    setHoveredSite(company);
  };

  const handleCloseAddSiteNoteModal = () => {
    setOpenAddSiteNoteModal(false);
    setHoveredSite(null);
  };

  const handleAddCompanyClick = () => {
    setShowAddCompanyModal(true);
  };

  const handleCloseAddCompanyModal = () => {
    setShowAddCompanyModal(false);
  };

  const [showEditCompanyModal, setShowEditCompanyModal] = useState(false);
  const handleEditCompanyClick = (company) => {
    setSelectedCompany(company);
    setShowEditCompanyModal(true);
  };

  const handlecloseEditCompanyModal = () => {
    setShowEditCompanyModal(false);
  };

  const userRole = useSelector((state) => state.selectedCompanyRoleId);

  const isUserAdmin = userRole == "1" || userRole == "2";

  const isSafeTTagadmin = userRole == "1";

  ////////////

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectAllChange = (isSelectAll) => {
    setSelectAllChecked(!selectAllChecked);

    if (!selectAllChecked) {
      setSelectedRows([...Array(filteredCompanies.length).keys()]);
    } else {
      setSelectedRows([]);
    }
  };

  const handleSelectAllChangeBox = () => {
    setSelectAllChecked(!selectAllChecked);

    if (!selectAllChecked) {
      setSelectedRows([...Array(filteredCompanies.length).keys()]);
    } else {
      setSelectedRows([]);
    }
  };

  const [companyList, setcompanyList] = useState([]); // State to hold company list data

  const handleRowSelection = (index) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(index)
        ? prevSelectedRows.filter((item) => item !== index)
        : [...prevSelectedRows, index]
    );
  };

  const token = useSelector((state) => state.user.token);
  const selectedCompanyState = useSelector((state) => state.selectedCompany);
  const id = useSelector((state) => state.user.id);

  const [companiesList, setCompaniesList] = useState([]);
  const [companyData, setCompanyData] = useState([]);

  //getting companies

  const fetchData = async (url) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const params = {
        LoggedUserId: id,
        LoggedUserCompanyId: selectedCompanyState,
      };

      const queryString = new URLSearchParams(params).toString();
      const fullUrl = url + (queryString ? `?${queryString}` : "");

      const response = await publicRequest({
        method: "GET",
        url: fullUrl,
        headers: headers,
      });

      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching data", error);
      return null;
    }
  };
  //gettingCompanyDetails

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        setIsLoading(true);
        const getCompanyResponse = await fetchData(`/company/list`);
        if (getCompanyResponse) {
          const sortedCompanies = sortCompaniesAlphabetically(getCompanyResponse);
          setCompaniesList(sortedCompanies);

          // Fetch data for each company based on their IDs
          const companyDataPromises = getCompanyResponse.map(
            async (company) => {
              const companyResponse = await fetchData(`/company/${company.id}`);
              if (companyResponse) {
                setcompanyList(companyResponse);

                const siteNoteResponse = company.companyNotes || [];

                if (siteNoteResponse.length > 0) {
                  const latestNote = siteNoteResponse[0];
                  const customSiteNote = `${latestNote.note}`;

                  company.note = customSiteNote;
                } else {
                  company.note = "";
                }
              }
              return companyResponse;
            }
          );

          // Wait for all company data requests to complete
          const companyDataResponses = await Promise.all(companyDataPromises);

          // Set the companyData state with the fetched data
          setCompanyData(companyDataResponses);
        }
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCompanyData();
  }, []);


  const refreshCompanies = async () => {
    try {
      setIsLoading(true);
      const getCompanyResponse = await fetchData(`/company/list`);
      if (getCompanyResponse) {
        const sortedCompanies = sortCompaniesAlphabetically(getCompanyResponse);
        setCompaniesList(sortedCompanies);

        // Fetch data for each company based on their IDs
        const companyDataPromises = getCompanyResponse.map(
          async (company) => {
            const companyResponse = await fetchData(`/company/${company.id}`);
            if (companyResponse) {
              setcompanyList(companyResponse);

              const siteNoteResponse = company.companyNotes || [];

              if (siteNoteResponse.length > 0) {
                const latestNote = siteNoteResponse[0];
                const customSiteNote = `${latestNote.note}`;

                company.note = customSiteNote;
              } else {
                company.note = "";
              }
            }
            return companyResponse;
          }
        );

        // Wait for all company data requests to complete
        const companyDataResponses = await Promise.all(companyDataPromises);

        // Set the companyData state with the fetched data
        setCompanyData(companyDataResponses);
      }
    } catch (error) {
      console.error("Error fetching data", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const filteredUser = companiesList.filter((user) => {
      // Customize the conditions for filtering based on your tag structure

      return (
        user.abnAcnIdentifierNumber
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        user.addressLine1.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.addressLine2.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.countryName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.officePhone.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.stateName.toLowerCase().includes(searchQuery.toLowerCase())

        // Add more conditions as needed
        // ...
      );
    });

    setFilteredCompanies(filteredUser);
    setSelectAllChecked(false);
    setCurrentPage(1);
  }, [searchQuery, companiesList]);

  //pages

  const [pagination, setPagination] = useState(1); // Current page number
  const itemsPerPageOptions = [10, 20, 30]; // Number of items per page options
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredCompanies.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 3, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 3, 1));
  };

  const renderPageButtons = () => {
    // Sort the companyData array based on the created date in descending order
    // const sortedCompanyData = companyData.sort(
    //   (a, b) =>
    //     new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    // );

    const pageButtons = [];
    const maxPagesToShow = 3; // Set the maximum number of pages to show

    const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          onClick={() => handlePageClick(i)}
          className={currentPage === i ? "active-page" : ""}
          style={{
            color: "black",
            borderRadius: "5px",
            border: "none",
            marginLeft: "10px",
            backgroundColor: currentPage === i ? "#F6C90E" : "#3A475033",
            width: "30px",
            fontFamily: "futuramedium",
            cursor: "pointer",
          }}
        >
          {i}
        </button>
      );
    }

    return pageButtons;
  };

  // ... (previous code remains unchanged)

  const totalPages = Math.ceil(companiesList.length / itemsPerPage);

  ////////

  return (
    <div
      className="TablePageMainDiv"
      style={{ marginLeft: "150px", marginRight: "150px" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <div
          className="approvalTitle"
          style={{
            fontFamily: "futuramedium",
            color: "#141212",
            fontSize: "30px",
          }}
        >
          Company List
        </div>
        {!isSafeTTagadmin && (
          <div
            style={{
              fontFamily: "futuramedium",
              color: "#141212",
              fontSize: "12px",
              height: "45px",
              fontWeight: "bold",
              marginTop: "45px",
            }}
          ></div>
        )}

        {isSafeTTagadmin && (
          <div
            className="save-button"
            style={{
              fontFamily: "futuramedium",
              color: "#141212",
              fontSize: "12px",
              height: "45px",
              fontWeight: "bold",
              width: "150px",
            }}
            onClick={handleAddCompanyClick}
          >
            +Add Company
          </div>
        )}

        <Modal
          size="xl"
          show={showAddCompanyModal}
          //    onHide={handleCloseAddCompanyModal}
          centered
        >
          <Modal.Body>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={handleCloseAddCompanyModal}
                style={{
                  background: "none",
                  border: "none",
                  fontSize: "35px",
                  position: "absolute",
                  zIndex: "9999",
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <AddCompanyModal />
          </Modal.Body>
        </Modal>
      </div>

      <div
        className="minibar"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            fontFamily: "futuramedium",
          }}
        >
          <p>Show</p>
          <div
            style={{
              backgroundColor: "#e5e5e5",
              borderRadius: "10px",
              padding: "4px",
              cursor: "pointer",
              height: "30px",
              width: "50px",
              marginLeft: "1px",
              marginRight: "5px",
            }}
          >
            <select
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
              style={{
                border: "none",
                background: "none",
                cursor: "pointer",
              }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
            </select>
          </div>
          <p>items</p>
        </div>

        <div style={{ display: "flex" }}>
          {/* <p
            style={{
              color: "#3A4750",
              fontSize: "16px",
              fontFamily: "futurabook",
            }}
          >
            last refresh was 25mins ago
          </p>
          <div
            className="icon"
            style={{
              backgroundColor: "#3A475033",
              borderRadius: "50%",
              color: "#3A4750",
              width: "32px",
              height: "32px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
            }}
          >
            <RefreshIcon />
          </div> */}
          {/* <div
            className="icon"
            style={{
              backgroundColor: "#3A475033",
              borderRadius: "20%", // Use 50% for a circle
              color: "#3A4750",
              width: "32px",
              height: "32px",
              display: "flex", // Use flex display
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
            }}
          >
            <DownloadIcon />
          </div> */}

<div
            className="icon"
            onMouseEnter={() => setIsRefreshHovered(true)}
            onMouseLeave={() => setIsRefreshHovered(null)}
            style={{
              backgroundColor: "#3A475033",
              borderRadius: "50%",
              color: "#3A4750",
              width: "32px",
              height: "32px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
              cursor: "pointer",
              position: "relative",
            }}
            onClick={refreshCompanies}
          >
            <RefreshIcon />

            {isRefreshHovered && (
              <p
                style={{
                  position: "absolute",
                  top: "100%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  background: "#ffffff",
                  padding: "2px",
                  borderRadius: "10px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  zIndex: 999,
               
                  fontSize: "12px",
                  width: "100px",
                  textAlign: "center",
                }}
              >
                     Refresh the page
              </p>
            )}
          </div>



        </div>
      </div>
      {/* ------------------------------------------------------ */}
      {/* ------------------------------------------------------ */}
      {isLoading ? (
        <p>Loading data...</p>
      ) : (
        <>
          <div className="approvalTable">
            <table className="custom-table">
              <div className="tHead">
                <thead>
                  <tr>
                    <th>
                      <div className="checkbox-dropdown">
                        <input
                          type="checkbox"
                          id="selectAll"
                          checked={selectAllChecked}
                          onChange={handleSelectAllChangeBox}
                        />
                        <div className="dropdown">
                          <span className="dropdown-arrow">&#9662;</span>
                          <div className="dropdown-content">
                            <div onClick={() => handleSelectAllChange(true)}>
                              Select All
                            </div>
                            <div onClick={() => handleSelectAllChange(false)}>
                              Deselect All
                            </div>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th
                      style={{
                        width: "700px",
                      }}
                    >
                      Name
                    </th>
                    <th
                      style={{
                        width: "700px",
                      }}
                    >
                      Address
                    </th>
                    <th
                      style={{
                        width: "700px",
                      }}
                    >
                      Office Phone Number
                    </th>

                    <th
                      style={{
                        width: "700px",
                      }}
                    >
                      ABN/ACN Idenfitier Number
                    </th>
                    {isSafeTTagadmin && (
                      <th
                        style={{
                          width: "700px",
                        }}
                      >
                        Company Note
                      </th>
                    )}

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="tBody">
                  {currentItems.map((company, index) => (
                    <tr
                      key={index}
                      className={selectedRows.includes(index) ? "selected" : ""}
                    >
                      <td>
                        <input
                          type="checkbox"
                          id={`companyCheckbox${index}`}
                          checked={selectedRows.includes(index)}
                          onChange={() => handleRowSelection(index)}
                        />
                        <label htmlFor={`companyCheckbox${index}`}></label>
                      </td>
                      <td>{company.name}</td>
                      <td>
                        {company.addressLine1} {company.addressLine2}
                        {company.city}
                      </td>
                      <td>{company.officePhone}</td>

                      <td>{company.abnAcnIdentifierNumber}</td>
{isSafeTTagadmin &&(
    <td className="descriptionNoteColumn">
    {company.note &&
    company.note.length > 40 &&
    !siteNoteExpanded[company.id] ? (
      <>
        {company.note.slice(0, 40)}{" "}
        <span
          onClick={() =>
            setSiteNoteExpanded({
              ...siteNoteExpanded,
              [company.id]: true,
            })
          }
          style={{
            cursor: "pointer",
          }}
        >
          ...
        </span>
      </>
    ) : (
      <>
        {company.note ? company.note : "-"}
        {company.note && company.note.length > 40 && (
          <span
            onClick={() =>
              setSiteNoteExpanded({
                ...siteNoteExpanded,
                [company.id]: false,
              })
            }
            style={{
              cursor: "pointer",
              color: "blue",
            }}
          >
            &nbsp; See Less
          </span>
        )}
      </>
    )}
  </td>
)
}
                    

                      <td>
                        <div
                          className="iconboxes"
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            marginLeft: "10px",
                            display: "flex",
                          }}
                        >
                          <div
                            className="iconBox"
                            onMouseEnter={() => setIsEditHovered(company)}
                            onMouseLeave={() => setIsEditHovered(null)}
                            style={{
                              cursor: "pointer",
                              position: "relative",
                            }}
                            onClick={() => handleEditCompanyClick(company)}
                          >
                            <ThinEditIcon height="15px" width="15px" fill="#" />
                            {isEditHovered === company && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "100%",
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                  background: "#ffffff",
                                  padding: "2px",
                                  borderRadius: "10px",
                                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                  zIndex: 999,
                                  width: "90px",
                                  fontSize: "12px",
                                }}
                              >
                                Edit Company
                              </div>
                            )}
                          </div>

                          {selectedCompany === company && ( // Check if this row's modal should be open
                            <Modal
                              size="xl"
                              show={showEditCompanyModal}
                              //    onHide={handlecloseEditCompanyModal}
                              centered
                            >
                              <Modal.Body>
                                <div className="d-flex justify-content-end">
                                  <button
                                    type="button"
                                    className="close"
                                    aria-label="Close"
                                    onClick={handlecloseEditCompanyModal}
                                    style={{
                                      background: "none",
                                      border: "none",
                                      fontSize: "35px",
                                      position: "absolute",
                                      zIndex: "9999",
                                    }}
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <EditCompanyModal company={selectedCompany} />{" "}
                              </Modal.Body>
                              {/* Pass the selected company to the modal */}
                            </Modal>
                          )}

                          <div
                            className="iconBox"
                            onMouseEnter={() => setHoveredSite(company)}
                            onMouseLeave={() => setHoveredSite(null)}
                            style={{
                              cursor: "pointer",
                              position: "relative",
                            }}
                            onClick={() => handleOpenAddSiteNoteModal(company)}
                            // onClick={() => handleOpenEditTagModal(tag)}
                          >
                            <ThinAddNote height="15px" width="15px" fill="#" />
                            {hoveredSite === company && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "100%",
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                  background: "#ffffff",
                                  padding: "2px",
                                  borderRadius: "10px",
                                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                  zIndex: 999,
                                  width: "70px",
                                }}
                              >
                                Add Note
                              </div>
                            )}
                          </div>
                          {selectedCompany === company && (
                            <Modal
                              size="xl"
                              centered
                              show={openAddSiteNoteModal}
                              //   onHide={handleCloseAddTagNoteModal}
                            >
                              <Modal.Body>
                                <div className="d-flex justify-content-end">
                                  <button
                                    type="button"
                                    className="close"
                                    aria-label="Close"
                                    onClick={handleCloseAddSiteNoteModal}
                                    style={{
                                      background: "none",
                                      border: "none",
                                      fontSize: "35px",
                                      position: "absolute",
                                      zIndex: "999",
                                    }}
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <AddCompanyNotes company={selectedCompany} />
                              </Modal.Body>
                            </Modal>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </div>
            </table>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <div>
              Showing {Math.min(indexOfLastItem, companiesList.length)} of{" "}
              {companiesList.length} items
            </div>
            <div
              style={{
                marginRight: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <button
                onClick={handlePreviousPage}
                style={{
                  color: "black",
                  borderRadius: "5px",
                  border: "none",
                  marginLeft: "10px",
                  backgroundColor: "#3A475033",
                  width: "80px",
                  fontFamily: "futuramedium",
                  cursor: "pointer",
                }}
              >
                Previous
              </button>

              {renderPageButtons()}

              <button
                onClick={handleNextPage}
                style={{
                  color: "black",
                  borderRadius: "5px",
                  border: "none",
                  marginLeft: "10px",
                  backgroundColor: "#3A475033",
                  width: "60px",
                  fontFamily: "futuramedium",
                  cursor: "pointer",
                }}
              >
                Next
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CompanyList;
