import React from "react";
import {
  LockLightning,
  PlugUnable,
  Qrphone,
} from "../../fontAwesomeComponents/FaIcons";

import "../../css/homecss/SafeTtagFeatures.css";

function SafeTtagFeatures() {
  return (
    <div className="featuresContainer">
      <div>
        <h3 className="featuresTitle"
          style={{
            color: "#F6C90E",
            fontSize: "100%",
            fontFamily: "futura, sans-serif",
          }}
        >
          FEATURES
        </h3>
        <h2 className="featurestitle2" style={{ fontFamily: "futura, sans-serif" }}>
          Safe T Tag Features
        </h2>
      </div>
      <div
        className="featureSection"
        style={{ display: "flex", marginLeft: "10%", marginRight: "10%" }}
      >
        {/* Feature 1 */}
        <div
          className="featureBlock"
          style={{
            flex: "1",
            height: "100%",
            minHeight: "770px",
            marginBottom: "30px",
          }}
        >
          <div className="locklightningicon">
            <LockLightning />
            <h3 className="featureblockktitle1">Electrical Lockout Devices</h3>
          </div>
          <p style={{ marginTop: "5px", fontSize: "15px" }}>
            The Safe T Tag device can also be used to lock out specific
            electrical equipment during maintenance or repair work, preventing
            accidental energization. They ensure the safety of everyone by
            effectively blocking the flow of electrical energy to the equipment,
            eliminating risks of electric shock or electrocution.
          </p>{" "}
        </div>

        {/* Feature 2 */}
        <div
          className="featureBlock middle-gold"
          style={{
            flex: "1",
            border: "2px solid #F6C90E",
            height: "100%",
            minHeight: "770px",
            marginBottom: "30px",
          }}
        >
          <div className="plugdiv">
            <PlugUnable />
            <h3 className="featureblockktitle">Used as a Tagout Device</h3>
          </div>
          <p className="plugP" style={{ marginTop: "40px", fontSize: "15px" }}>
            Any prominent warning device such as an installed Safe T Tag
            energy-isolating device can be securely fastened to indicate that
            the machine or equipment to which it is attached may not be operated
            until the tagout device is removed.
          </p>
        </div>

        {/* Feature 3 */}
        <div
          className="featureBlock"
          style={{
            flex: "1",
            height: "100%",
            minHeight: "770px",
            marginBottom: "30px",
          }}
        >
          <div
            style={{
              marginTop: "130px",
            }}
          >
            <Qrphone />
            <h3 className="featureblockktitle">QR Coded Centralised Database</h3>
          </div>
          <p style={{ marginTop: "20px", fontSize: "14px" }}>
            A Safe T Tag LOTO (Lockout Tagout) device consists of a QR Coded
            Digital Label and a physical one-use electrical plug tagout
            solution. In addition to a physical lockout device, the Safe T Tag
            has a digital label that is essential to communicate important
            information and warnings. The Safe T Tag used in conjunction with
            the QR Coded online database can provide clear instructions and
            warnings about the locked-out device, ensuring everyone understands
            the lockout situation. The User entered information may include the
            exact location address, mapped GPS location, model and serial
            number, pictures, fault description, even added notes that pertain
            to the equipment that the Safe T Tag is connected to.
          </p>
        </div>
      </div>
    </div>
  );
}

export default SafeTtagFeatures;
