import React from "react";
import '../../css/GalleryImagescss/gallery.css'

const GalleryTitle = () => {
  return (
    <div className="galleryDiv"
      style={{
        alignItems: "center",
        // justifyContent:"center",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh", // Use minHeight instead of height for full viewport height
        background: "#141212",
        paddingTop: "50px",
        paddingLeft: "10%",
        paddingRight: "10%",
      }}
    >
      <h1 className="galleryH"
        style={{
          fontFamily: "futura",
          color: "#EEEEEE",
          fontSize: "65px",
          textAlign:"center"
        }}
      >
        Our Gallery
      </h1>
      <p
        style={{
          textAlign: "center",
          color: "#EEEEEE",
          fontFamily: "futurabook",
        }}
      >
        Visualize the Power of Safety: Unveiling an Innovative Electrical Safety
        Blocking Device.
        <br />
        Discover a collection of compelling visuals that showcase the advanced
        features and effectiveness of our electrical safety blocking device.
        <br />
        With a wide range of images below, you may witness the cutting-edge
        technology and robust design of the Safe T Tag device. <br /> If you
        would like to add to our photo gallery, please contact us via the{" "}
        <a
          style={{
            textDecoration: "none",

            color: "white",
          }}
          href="/contactus"
        >
          Contact Us
        </a>{" "}
        link.
      </p>

      <div
        className="imagesContainer"
        style={{
          display: 'flex',
          marginTop: "50px",
          gap: "50px",
          justifyContent: "center",
          alignItems: "center",
          width: "100%"
        }}
      >
        <div
          className="firstImage"
          style={{
            flex: 1,
            height: "700px",
            width: "400px",
  
          }}
        ></div>

        <div
          className="secondImage"
          style={{
            flex: 1,
            height: "700px",
            width: "400px",
            
          }}
        ></div>
      </div>
    </div>
  );
};

export default GalleryTitle;
