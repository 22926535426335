import React, { useState } from "react";
import UserApprovalBar from '../components/UserApproval/UserApprovalBar'
import UserListTable from '../components/UserListComponents/UserListTable'
import Navbar from '../components/NavBar'

const UserList = () => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (query) => {
    // Update the search query state
    setSearchQuery(query);
  };
  return (
    <div
    style={{
        backgroundColor:"#EEEEEE", height:"100%", 
    }}
    >
        <Navbar/>
      <UserApprovalBar searchQuery={searchQuery} onSearch={handleSearch} />
      <UserListTable searchQuery={searchQuery}/>
    </div>
  )
}

export default UserList
