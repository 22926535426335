import React, { useState, useEffect } from "react";
import ReAuthorizeComponent from "../components/ReAuthorize/ReAuthorizeComponent";
import { useSelector } from "react-redux";
import { CircleX } from "../fontAwesomeComponents/FaIcons";
import { useNavigate } from "react-router-dom";
import { publicRequest } from "../requestMethods";
import Navbar from "../components/NavBar";

const ReAuthorizePage = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const userCompanyId = useSelector((state) => state.selectedCompany);
  const [userInfo, setUserInfo] = useState({});

  // Use proper state management

  // Initialize resetCodeParam
  const resetCodeParam = new URLSearchParams(window.location.search).get(
    "value"
  );

  const [resetCodeValid, setResetCodeValid] = useState(false); //change to false later
  const [approvalCode, setApprovalCode] = useState("");

  // Update this function to set approvalCode correctly
  const getApprovalCodeFromURL = () => {
    if (resetCodeParam) {
      setApprovalCode(decodeURIComponent(resetCodeParam));
    }
  };

  const queryParams = {
    code: approvalCode,
    LoggedUserId: user.id,
    LoggedUserCompanyId: userCompanyId,
  };

  const fetchData = async (url) => {
    try {
      const headers = {
        Authorization: `Bearer ${user.token}`,
      };

      const queryString = new URLSearchParams(queryParams).toString();
      const fullUrl = url + (queryString ? `?${queryString}` : "");

      const response = await publicRequest({
        method: "GET",
        url: fullUrl,
        headers: headers,
      });
      return response.data;
    } catch (error) {
      setResetCodeValid(false);
      console.error("error fetching data", error);
    }
  };

  useEffect(() => {
    // Call the function to get the approval code from the URL
    getApprovalCodeFromURL();
  }, [resetCodeParam]); // Update the dependency array

  useEffect(() => {
    const validateApprovalCode = async () => {
      try {
        if (approvalCode) {
          const validateCodeParams = {
            code: approvalCode, // Update with the actual code
          };

          const approvalCodeResponse = await fetchData(
            `auth/validatereauthorize/`,
            queryParams
          );

          setUserInfo(approvalCodeResponse);
          console.log(userInfo);
          console.log("this is approvalresponse", approvalCodeResponse);
          if (userInfo && Object.keys(userInfo).length > 0) {
            setResetCodeValid(true);
          } else {
            setResetCodeValid(false);
          }
        } else {
          setResetCodeValid(false);
        }
      } catch (error) {
        setResetCodeValid(false);
        console.error("Error validating code", error);
      }
    };
    validateApprovalCode();
  }, [approvalCode]); // Include approvalCode in the dependency array

  const handleBackToHome = () => {
    navigate("/");
  };

  const handleBackToLogin = () => {
    navigate("/login");
  };

  return (
    <div>
      {userInfo && Object.keys(userInfo).length > 0 ? (
        <div>
          <Navbar />
          <ReAuthorizeComponent userInfo={userInfo} />
        </div>
      ) : !resetCodeValid ? (
        <div
          style={{
            height: "100vh",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <div
              style={{
                marginBottom: "20px",
                backgroundColor: "#EFC8C8",
                padding: "10px",
                borderRadius: "16px",
              }}
            >
              <CircleX height="50px" width="50px" color="#DC3545" />
            </div>

            <h2
              style={{
                fontFamily: "futuraheavy",
                fontSize: "25px",
                marginBottom: "20px",
              }}
            >
              Invalid Token
            </h2>

            <button
              className="back-to-login-button"
              onClick={handleBackToHome}
              style={{
                backgroundColor: "#DC3545",
                width: "80%",
                fontFamily: "futurabook",
                color: "#FFFFFF",
                height: "50px",
                border: "none",
                borderRadius: "10px",
              }}
            >
              Back to Home
            </button>
          </div>
        </div>
      ) : (
        <div
          style={{
            height: "100vh",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <div
              style={{
                marginBottom: "20px",
                backgroundColor: "#EFC8C8",
                padding: "10px",
                borderRadius: "16px",
              }}
            >
              <CircleX height="50px" width="50px" color="#DC3545" />
            </div>

            <h2
              style={{
                fontFamily: "futuraheavy",
                fontSize: "25px",
                marginBottom: "20px",
              }}
            >
              Please Login to Continue
            </h2>

            <button
              className="back-to-login-button"
              onClick={handleBackToLogin}
              style={{
                backgroundColor: "#DC3545",
                width: "80%",
                fontFamily: "futurabook",
                color: "#FFFFFF",
                height: "50px",
                border: "none",
                borderRadius: "10px",
              }}
            >
              Back to Login
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReAuthorizePage;
