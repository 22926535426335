import React, { useState } from "react";
import UserApprovalBar from "../components/UserApproval/UserApprovalBar";
import ItemsTable from "../components/ItemList/ItemsTable";
import Navbar from "../components/NavBar";

const Items = () => {

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (query) => {
    // Update the search query state
    setSearchQuery(query);
  };



  return (
    <div>
      <div
        style={{
          backgroundColor: "#EEEEEE",
          minHeight: "100vh",
          paddingBottom: "25px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Navbar />
        <UserApprovalBar searchQuery={searchQuery} onSearch={handleSearch} />
        <ItemsTable   searchQuery={searchQuery}/>
      </div>
    </div>
  );
};

export default Items;
