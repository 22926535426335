import {
  loginStart,
  loginSuccess,
  loginFailure,
  registerStart,
  registerSuccess,
  registerFailed,
  refreshTokenFailed,
  refreshTokenStart,
  refreshTokenSuccess,
  logout,
} from "./userRedux";
import { publicRequest } from "../requestMethods";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';



export const handleDelete = async (dispatch, user) => {
  try{
    const res = await publicRequest.post("/auth/logout", user);
    dispatch(logout(res.data));

  }catch(err){
    console.error(err);
    dispatch(logout());
  }
  }

export const login = async (dispatch, user) => {
  dispatch(loginStart());

  try {
    const res = await publicRequest.post("/auth/login", user);
    dispatch(loginSuccess(res.data));
  } catch (err) {
    // dispatch(loginFailure());
    if (err.response.status === 401) {
      dispatch(loginFailure(err.response.data.title));
    } else if (err.response.status === 400) {
      dispatch(loginFailure(err.response.data.errors));
    } else {
      dispatch(loginFailure());
    }
    // dispatch(loginFailure(err.response.data.errors));
    console.error(err.response.status);
    console.error(err);
  }
};

// Modify the refreshToken function to accept accessToken and refreshToken
export const refreshTokenFunction = async (
  dispatch,
  accessToken,
  refreshToken,
  user
) => {
  // Check if there is a user
  if (!accessToken) {
    return; // Exit early if no user is present
  }

  dispatch(refreshTokenStart());

  try {
    const requestBody = {
      accessToken: accessToken.token, // Access token
      refreshToken: accessToken.refreshToken, // Refresh token
    };

    console.log("this is request", requestBody);
    
    const res = await publicRequest({
      method: "POST",
      url: "/auth/refresh",
      data: requestBody,
      headers: {
        "Content-Type": "application/json",
      },
    });

    dispatch(refreshTokenSuccess(res.data)); // Dispatch success action
  } catch (err) {
    dispatch(refreshTokenFailed(err.response.data.errors)); // Dispatch failure action
    console.log(err);
    console.error(err);
   // alert("Invalid Token please login again");

   Swal.fire({
    icon: 'error',
    title: 'Session Expired',
    text: 'Please Log in again',
  });
  
    handleDelete(dispatch); // Use handleDelete to trigger logout

    
  }
};


export const register = (user, company) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "registerStart" });

      const response = await publicRequest.post(
        "/auth/register",
        user,
        company
      );

      dispatch({ type: "registerSuccess", payload: response.data });
    } catch (error) {
      // Dispatch an action with the error if registration fails
      dispatch({ type: "registerFailure", payload: error.message });
    }
  };
};


