import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../css/registercss/RegisterBar.css';
import { ThinUserIcon, ThinBuilding, ThingFlag } from '../../fontAwesomeComponents/FaIcons';

const RegisterBar = ({ activeComponent }) => {
  return (
    <div className='bar-container' style={{ height: "100%" }}>
      <div className="register-bar">
        <div className={`register-icon ${activeComponent === 1 ? 'active' : ''}`}>
          <ThinUserIcon  height="25" width="25"/>
          <p>1. User Profile</p>
        </div>
        <div className={`register-icon ${activeComponent === 2 ? 'active' : ''}`}>
          <ThinBuilding height="25" width="25"/>
          <p>2. Company Profile</p>
        </div>
        <div className={`register-icon ${activeComponent === 3 ? 'active' : ''}`}>
          <ThingFlag height="25" width="25" />
          <p>3. Finish</p>
        </div>
      </div>
    </div>
  );
};

export default RegisterBar;
