import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CircleX } from '../fontAwesomeComponents/FaIcons';

const RegisterErrorModal = ({ onClose }) => {
    const navigate = useNavigate();

    const handleBackToProfile = () => {
      onClose();
    };
    
  return (
      

      <div
          style={{
              height:"37vh",
  
          }}
      >
        <div
          style={{
              width:"100%",
              display:"flex",
              alignItems:"center",
              justifyContent:"center",
              flexDirection:"column",
              height:"100%",
          }}
        >
          <div
            style={{
              marginBottom:"20px",
              backgroundColor:"#EFC8C8",
              padding:"10px",
              borderRadius:"16px"
            }}
          >
          <CircleX height="50px" width="50px" color="#DC3545"/>
          </div>
         
          <h2
              style={{
                  fontFamily:"futuraheavy",
                  fontSize:"25px",
                  marginBottom:"20px"
              }}
          >Register Failed!</h2>
          
          <button className="back-to-login-button" onClick={handleBackToProfile}
              style={{
                  backgroundColor:"#DC3545",
                  width:"80%",
                  fontFamily:"futurabook",
                  color:"#FFFFFF",
                  height:"50px",
                  border:"none",
                  borderRadius:"10px"
              }}
          >
            Back
          </button>
        </div>
      </div>
    );
  };

export default RegisterErrorModal
