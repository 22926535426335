import React from "react";
import { Telephone, ThinClock, ThinEnvelope, ThinLocation } from "../../fontAwesomeComponents/FaIcons";

const ContactUsDetails = () => {
  return (
    <div className="contact-detailsContainer"
    style={{
      marginTop:"100px"
    }} 
    >
      <div className="contactInfo">
        <div className="infoTitle">
          <span
            className="contact-icon"
            style={{
              backgroundColor: "#F6C90E33",
              border: "none",
            }}
          >
            <Telephone width="40px" height="30px" />
          </span>
          <div>
            <h4 className="infoName">Telephone Number</h4>
            <p className="infoSubtitle"
                style={{
                    fontSize:"17px"
                }}
            >Local calling: (08) 9304 9991 <br/>International: +61 8 9304 9991</p>
          </div>
        </div>
      </div>

      <div className="contactInfo">
        <div className="infoTitle">
          <span
            className="contact-icon"
            style={{
              backgroundColor: "#F6C90E33",
              border: "none",
            }}
          >
            <ThinLocation width="40px" height="30px" />
          </span>
          <div>
            <h4 className="infoName">Location</h4>
            <p className="infoSubtitle"
                  style={{
                    fontSize:"17px"
                }}
            >PO Box 1249 <br/> Joondalup WA 6028 <br/> AUSTRALIA</p>
          </div>
        </div>
      </div>

      <div className="contactInfo">
        <div className="infoTitle"
            
        >
          <span className="contact-icon"
              style={{
                backgroundColor: "#F6C90E33",
                border: "none",
              }}
          >
            <ThinEnvelope width="40px" height="30px"/>
          </span>
          <div>
            <h4 className="infoName"> Email Address</h4>
            <p className="infoSubtitle"
                style={{
                    fontSize:"17px"
                }}
            >enquiries@safettag.com</p>
          </div>
        </div>
      </div>

      <div className="contactInfo">
        <div className="infoTitle">
          <span className="contact-icon"
             style={{
                backgroundColor: "#F6C90E33",
                border: "none",
              }}
          >
           <ThinClock  width="40px" height="30px"/>
          </span>
          <div>
            <h4 className="infoName"> Business Hours</h4>
            <p className="infoSubtitle"
                style={{
                    fontSize:"17px"
                }}
            >
              Monday-Friday: 9am to 5pm <br /> Saturday: Closed <br /> Sunday:
              Closed
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsDetails;
