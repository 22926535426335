import React, { useState } from "react";
import { RiEyeLine, RiEyeCloseLine, RiArrowRightLine } from "react-icons/ri"; // Make sure to import the icons
// import Navbar from "../components/NavBar";
import "../../css/userProfilecss/ChangePassword.css";
import { publicRequest } from "../../requestMethods";
import { useSelector } from "react-redux";
import ChangePasswordSuccessModal from "../../modals/ChangePasswordSuccessModal";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FailedToUpdatePasswordModal from "../../modals/FailedToUpdatePasswordModal";

const ChangePasswordComponent = ({user}) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true); // Add passwordsMatch state


  console.log(user);

  //modal
  const [showEditSuccessModal, setShowEditSuccessModal] = useState(false);

  const handleCloseAddUserModal = () => {
    setShowEditSuccessModal(false);
  };

  const [showFailedPasswordModal, setShowFailedPasswordModal] = useState(false);

  const handleCloseFailedPasswordModal = () => {
    setShowFailedPasswordModal(false);
  };

  const toggleFailedPasswordModal = () => {
    setShowFailedPasswordModal(!showFailedPasswordModal);
  };

//   const userId = useSelector((state) => state.user.id);
//   const token = useSelector((state) => state.user.token);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    const isMatching = event.target.value === password;
    setConfirmPassword(event.target.value);
    setPasswordsMatch(isMatching);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };

  const isPasswordValid = () => {
    // Your password validation logic here
    const minLength = 12;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSymbol = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password);
    // Add dictionary check logic if needed

    return (
      password.length >= minLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumber &&
      hasSymbol
    );
  };

  const passwordStrengthColors = [
    "#FF0000", // Weak
    "#FF9900", // Fair
    "#FFFF00", // Okay
    "#00FF00", // Good
    "#00FF99", // Strong
  ];

  const getPasswordStrengthColor = () => {
    if (password.length >= 12) return passwordStrengthColors[2];
    return passwordStrengthColors[Math.min(Math.floor(password.length / 6), 1)];
  };

  const updatePassword = async () => {
    try {
      if (password !== confirmPassword) {
        console.log("Passwords do not match.");
        return;
      }

      const headers = {
        Authorization: `Bearer ${user.token}`,
      };

      const requestData = {
        userId: user.id, // Replace with the actual userId
        password: password,
      };

      // const response = await publicRequest.post(
      //   `/user/${user.id}/changepassword`,
      //   requestData,{ headers });

      const response = await publicRequest({
        method:"POST",
        url:`/user/${user.id}/changepassword`,
        data: requestData,
        headers: headers
      })
      

      console.log(response); // Handle the response accordingly
      setShowEditSuccessModal(true);
    } catch (error) {
      console.error("Error updating password:", error);
      setShowFailedPasswordModal(true);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#EEEEEE",
        height: "100vh",  
        padding:"100px"
      }}
    >
      {/* <Navbar /> */}
      <div
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          marginTop: "75px",
        }}
      >
        <div
          className="changePasswordContainer"
          style={{
            padding: "30px",
            width: "35%",
          }}
        >
          <h3 style={{ fontFamily: "futuraheavy", marginBottom: "15px" }}>
            Change Password
          </h3>
          <div
            style={{
              width: "100%",
            }}
          >
            <div className="input-container">
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handlePasswordChange}
                placeholder="Enter new password"
              />
              {showPassword ? (
                <RiEyeCloseLine
                  className="password-icon"
                  onClick={toggleShowPassword}
                />
              ) : (
                <RiEyeLine
                  className="password-icon"
                  onClick={toggleShowPassword}
                />
              )}
            </div>
          </div>
          <div
            style={{
              width: "100%",
            }}
          >
            <div className="input-container">
              <input
                type={showPassword2 ? "text" : "password"}
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                placeholder="Confirm new password"
              />
              {showPassword2 ? (
                <RiEyeCloseLine
                  className="password-icon"
                  onClick={toggleShowPassword2}
                />
              ) : (
                <RiEyeLine
                  className="password-icon"
                  onClick={toggleShowPassword2}
                />
              )}
              <div>
                {!passwordsMatch && ( // Display error message if passwords don't match
                  <p
                    className="register-ErrorContainer"
                    style={{
                      marginTop: "-20px",
                      opacity: 0.7,
                      padding: "5px",
                      fontFamily: "futurabook",
                      marginLeft: "30px",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      style={{ marginRight: "1px" }}
                    />
                    Passwords don't match
                  </p>
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: "1px",
              width: "100%",
              marginLeft: "25px",
              paddingRight: "1px",
            }}
          >
            {password.length > -1 && (
              <div>
                {passwordStrengthColors.map((color, index) => (
                  <span
                    key={index}
                    style={{
                      display: "inline-block",
                      width: "19%",
                      height: "7px",
                      backgroundColor:
                        index <= Math.min(Math.floor(password.length / 6), 4)
                          ? color
                          : "#eeeeee",
                      margin: "0 2px",
                      borderRadius: "4px",
                    }}
                  ></span>
                ))}
                <div className="passwordRequirements">
                  <p
                    style={{
                      fontFamily: "futurabook",
                      color: "#707070",
                      fontSize: "12px",
                      margin: "4px 0",
                    }}
                  >
                    Minimum of 12 characters
                  </p>
                  <p
                    style={{
                      fontFamily: "futurabook",
                      color: "#707070",
                      fontSize: "12px",
                      margin: "4px 0",
                    }}
                  >
                    A combination of uppercase letters, lowercase letters,
                    numbers, and symbols.
                  </p>
                  <p
                    style={{
                      fontFamily: "futurabook",
                      color: "#707070",
                      fontSize: "12px",
                      margin: "4px 0",
                    }}
                  >
                    Not a word that can be found in a dictionary.
                  </p>
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              width: "100%",
            }}
          >
            <button
              disabled={!isPasswordValid()}
              style={{
                border: "none",
                borderRadius: "10px",
                backgroundColor: "#F6C90E",
                width: "100%",
                height: "50px",
                color: "#141212",
                fontFamily: "futuraheavy",
                marginTop: "15px",
                cursor: isPasswordValid() ? "pointer" : "not-allowed",
              }}
              onClick={updatePassword}
            >
              Update Password
              <RiArrowRightLine
                style={{
                  marginBottom: "2px",
                  fontWeight: "bolder",
                  fontSize: "15px",
                }}
              />
              <Modal
                show={showEditSuccessModal}
                onHide={handleCloseAddUserModal}
                centered
              >
                <ChangePasswordSuccessModal />
              </Modal>
            </button>
            <Modal
              show={showFailedPasswordModal}
              onHide={handleCloseFailedPasswordModal}
              centered
            >
              <FailedToUpdatePasswordModal
                onClose={toggleFailedPasswordModal}
              />
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordComponent;
