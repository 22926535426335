import React, { useEffect, useState } from "react";
import { publicRequest } from "../../requestMethods";
import {
  DownloadIcon,
  ThinAddNote,
  DeleteIcon,
} from "../../fontAwesomeComponents/FaIcons";
import { useSelector } from "react-redux";
import AddTagAttachmentNoteModal from "../TagsList/AddTagAttachments/AddTagAttachmentNoteModal";
import { Modal } from "react-bootstrap";
import ViewTagAttachmentNotesModal from "../TagsList/AddTagAttachments/ViewTagAttachmentNotesModal";

const ArchivedItemAttachments = ({ item, refreshNotes }) => {
  const token = useSelector((state) => state.user.token);
  const userId = useSelector((state) => state.user.id);
  const selectedUserCompanyId = useSelector((state) => state.selectedCompany);
  const [attachmentList, setAttachmentList] = useState([]);
  const [isAddNoteHovered, setIsAddNoteHovered] = useState(false);
  const [isDownloadAttachment, setIsDownloadAttachment] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState(null);

  const fetchData = async (url) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const params = {
        LoggedUserId: userId,
        LoggedUserCompanyId: selectedUserCompanyId,
      };

      const queryString = new URLSearchParams(params).toString();
      const fullUrl = url + (queryString ? `?${queryString}` : "");

      const response = await publicRequest({
        method: "GET",
        url: fullUrl,
        headers: headers,
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchAttachments = async () => {
      try {
        const params = {
          LoggedUserId: userId,
          LoggedUserCompanyId: selectedUserCompanyId,
        };

        const attachmentsResponse = await fetchData(
          `item/${item.id}/attachment/archive/list`,
          params
        );

        if (attachmentsResponse) {
          setAttachmentList(attachmentsResponse);
          attachmentsResponse.sort(
            (a, b) => new Date(b.dateCreatedUtc) - new Date(a.dateCreatedUtc)
          );
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchAttachments();
  }, []);

  const downloadAttachment = async (attachment) => {
    const queryParamsForDownload = {
      LoggedUserId: userId,
      LoggedUserCompanyId: selectedUserCompanyId,
    };

    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const requestConfigForDownload = {
        method: "GET",
        headers: headers,
        params: queryParamsForDownload,
        responseType: "blob",
      };

      const response = await publicRequest(
        `item/${item.id}/attachment/download/${attachment.id}`,
        requestConfigForDownload
      );

      if (response) {
        const blob = new Blob([response.data]);
        const url = window.URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = attachment.fileName;
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(downloadLink);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="ModalChildDiv">
      {attachmentList.length > 0 && (
        <div>
          <div className="finish-row" style={{ padding: "20px" }}>
            <div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <p
                  className="detailsTitle"
                  style={{
                    fontSize: "30px",
                    marginBottom: "5px",
                    color: "#141212",
                    fontFamily: "futuramedium",
                  }}
                >
                  Attachments
                </p>
              </div>
            </div>
            <div className="finish-column wholeRow">
              <div className="approvalTable" style={{}}>
                <table>
                  <div className="tHead">
                    <thead>
                      <tr>
                        <th style={{ width: "453px" }}>File Name</th>
                        <th className="" style={{ width: "333px" }}>
                          Created By
                        </th>
                        <th style={{ minWidth: "203px" }}>Date Added</th>
                        <th style={{ width: "333px" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody className="tBody">
                      {attachmentList.map((attachment, index) => (
                        <tr key={index}>
                          <td style={{ width: "50%" }}>
                            {attachment.fileName.length > 20
                              ? attachment.fileName.slice(0, 20) + "..."
                              : attachment.fileName}
                          </td>
                          <td style={{ width: "100%" }}>
                            {attachment.createdBy}
                          </td>
                          <td>{attachment.dateCreatedUtc}</td>
                          <td>
                            <div style={{ display: "flex" }}>
                              <div
                                onMouseEnter={() =>
                                  setIsAddNoteHovered(attachment)
                                }
                                onMouseLeave={() =>
                                  setIsAddNoteHovered(null)
                                }
                                className="iconBox"
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "5px",
                                  justifyContent: "center",
                                  position: "relative",
                                }}
                                onClick={() =>
                                  console.log("Add Note clicked")
                                }
                              >
                                <ThinAddNote
                                  color=""
                                  height="13px"
                                  width="13px"
                                />
                                {isAddNoteHovered === attachment && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "100%",
                                      left: "50%",
                                      transform: "translateX(-50%)",
                                      background: "#ffffff",
                                      padding: "2px",
                                      borderRadius: "10px",
                                      boxShadow:
                                        "0 2px 4px rgba(0, 0, 0, 0.1)",
                                      zIndex: 999,
                                      width: "70px",
                                    }}
                                  >
                                    Add Note
                                  </div>
                                )}
                              </div>
                              <div
                                onMouseEnter={() =>
                                  setIsDownloadAttachment(attachment)
                                }
                                onMouseLeave={() =>
                                  setIsDownloadAttachment(null)
                                }
                                className="iconBox"
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "5px",
                                  justifyContent: "center",
                                  position: "relative",
                                }}
                                onClick={() =>
                                  downloadAttachment(attachment)
                                }
                              >
                                <DownloadIcon
                                  color=""
                                  height="13px"
                                  width="13px"
                                />
                                {isDownloadAttachment === attachment && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "100%",
                                      left: "50%",
                                      transform: "translateX(-50%)",
                                      background: "#ffffff",
                                      padding: "2px",
                                      borderRadius: "10px",
                                      boxShadow:
                                        "0 2px 4px rgba(0, 0, 0, 0.1)",
                                        zIndex: 999,
                                        width: "70px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      Download Attachment
                                    </div>
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </div>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
        {attachmentList.length < 1 && (
          <p
            style={{
              textAlign: "center",
              fontFamily: "futurabook",
              marginTop: "10px",
            }}
          >
            No Attachments
          </p>
        )}
      </div>
    );
  };
  
  export default ArchivedItemAttachments;