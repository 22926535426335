import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Register from "./pages/Register";
import LoginPage from "./pages/LoginPage";
import "./App.css";
import { useSelector, useDispatch } from "react-redux";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Disclaimer from "./pages/Disclaimer";
import SelectCompany from "./pages/SelectCompany";
import UserApproval from "./pages/UserApproval";
import Tags from "./pages/Tags";
import UserList from "./pages/UserList";
import ChangePassword from "./pages/ChangePassword";
import UserProfile from "./pages/UserProfile";
import {
  setSelectedCompany,
  setSelectedCompanyName,
  setSelectedCompanyId,
  setSelectedCompanyRoleId,
  updateTokens,
} from "./redux/userRedux";
// Import swal
import Swal from "sweetalert2";
import Companies from "./pages/Companies";
import Sites from "./pages/Sites";
import Locations from "./pages/Locations";
import DownloadTheApp from "./pages/DownloadTheApp";
import ContactUs from "./pages/ContactUs";
import Items from "./pages/Items";
import TagsArchive from "./pages/TagsArchive";
import ItemArchive from "./pages/ItemArchive";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ReAuthorizePage from "./pages/ReAuthorizePage";
import DeletedTagsPage from "./pages/DeletedTagsPage";
import DeletedItemsPage from "./pages/DeletedItemsPage";
import Distributors from "./pages/Distributors";
import AuditTrail from "./pages/AuditTrail";
import UserInvitePage from "./pages/UserInvitePage";
import TermsAndConditions from "./pages/TermsAndConditions";
import GalleryPage from "./pages/GalleryPage";
import { refreshTokenFunction } from "./redux/apiCals";
import { Modal } from "react-bootstrap";
import TokenHasExpiredModal from "./modals/TokenHasExpiredModal";
import { logout } from "./redux/userRedux";
import Cookies from "js-cookie";
import UserLogsPage from "./pages/UserLogsPage";
import MobileView from "./pages/MobileView";

function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      const screenWidth = window.innerWidth;
      setIsMobile(screenWidth < 768); // Adjust this value according to your design requirements
    };

    checkIfMobile();

    window.addEventListener("resize", checkIfMobile);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  useEffect(() => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      document.body.style.zoom = "75%";
    }
  }, []);


  useEffect(() => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      document.body.style.zoom = "75%";
    }
  }, []);

  const user = useSelector((state) => state.user);
  const selectedCompany = useSelector((state) => state.selectedCompany);
  const dispatch = useDispatch();
  const termsChecker = useSelector((state) => state.isTermAccepted);

  const token = user ? user.token : "";
  const refreshToken = user ? user.refreshToken : "";

  const [isError, setIsError] = useState(false);

  const handleDelete = (dispatch) => {
    Cookies.remove("lastActivityTime");
    dispatch(logout());
  };

  const handleActivity = () => {
    Cookies.set("lastActivityTime", new Date().getTime());
  };

  const handleLogout = () => {
    Swal.fire({
      title: "Logged Out",
      text: "You've been logged out due to inactivity",
      icon: "warning",
      confirmButtonText: "Login",
    }).then(() => {
      Cookies.remove("lastActivityTime");
      dispatch(logout());
    });
  };

  useEffect(() => {
    if (!user) {
      return; // Exit early if there's no user
    }

    const interval = setInterval(() => {
      const lastActivityTime = Cookies.get("lastActivityTime");
      if (
        lastActivityTime &&
        new Date().getTime() - parseInt(lastActivityTime) > 900000
      ) {
        // 15 minutes in milliseconds
        handleLogout();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [user]); // Add user to the dependency array

  useEffect(() => {
    if (!user) {
      return; // Exit early if there's no user
    }
  
    const refreshTokenInterval = setInterval(() => {
      // Call the refresh token function
      refreshTokenFunction(dispatch, {
        token,
        refreshToken,
      });
      // No need to refresh the page here
    }, 540000); // 15 minutes in milliseconds
  
    return () => clearInterval(refreshTokenInterval);
  }, [user, token, refreshToken, dispatch]); // Add token, refreshToken, and dispatch to the dependency array
  
  // Handle user activity
  useEffect(() => {
    if (!user) {
      return; // Exit early if there's no user
    }
  
    const handleUserActivity = () => {
      handleActivity(); // Update last activity time on user activity
    };
  
    const activityListeners = ["mousemove", "keypress"];
    activityListeners.forEach(event => {
      document.addEventListener(event, handleUserActivity);
    });
  
    return () => {
      activityListeners.forEach(event => {
        document.removeEventListener(event, handleUserActivity);
      });
    };
  }, [user]); // Add user to the dependency array
  

  const termNotAccepted = user && user.isTermAccepted === false;

  const hasMultipleCompanies =
    user &&
    user.userCompany &&
    user.userCompany.length > 1 &&
    selectedCompany === null;

  // Log the number of companies the user has
  console.log(
    "Number of user companies:",
    user && user.userCompany ? user.userCompany.length : 0
  );

  if (isMobile) {
    return (
      <BrowserRouter>
      <Routes>
      <Route path="/" element={<Home />} />
        <Route path="/tags" element={<MobileView />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        {/* Redirect to '/' when visiting other undefined routes */}
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route path="/userapproval" element={<MobileView />} />
        <Route path="/userlist" element={<MobileView />} />
        <Route path="/changepassword" element={<MobileView />} />
        <Route path="/userprofile" element={<MobileView />} />
        <Route path="/companies" element={<MobileView />} />
        <Route path="/sites" element={<MobileView />} />
        <Route path="/locations" element={<MobileView />} />
        <Route path="/downloadtheapp" element={<DownloadTheApp />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/items" element={<MobileView />} />
        <Route path="/tags/archive" element={<MobileView />} />
        <Route path="/items/archive" element={<MobileView />} />
        <Route path="/reauthorize" element={<MobileView />} />
        <Route path="/tags/deleted" element={<MobileView />} />
        <Route path="/items/deleted" element={<MobileView />} />
        <Route path="/distributors" element={<Distributors />} />
        <Route path="/audittrail" element={<MobileView />} />
        <Route path="/termsandconditions" element={<TermsAndConditions />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/userLogs" element={<MobileView />} />
        <Route path="/register" element={<MobileView />} />
      </Routes>
    </BrowserRouter>
    );
  }

  if (hasMultipleCompanies) {
    // ...
  } else if (user && user.userCompany && user.userCompany.length === 1) {
    // If user has a single company, set the selected company to the companyId
    // This is the new part you're looking to add
    dispatch(setSelectedCompany(user.userCompany[0].userCompanyId));
    dispatch(setSelectedCompanyName(user.userCompany[0].companyName));
    dispatch(setSelectedCompanyId(user.userCompany[0].companyId));
    dispatch(setSelectedCompanyRoleId(user.userCompany[0].role));
  }

  // Check if the user is logged in
  if (!user) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<Register />} />
          <Route path="/" element={<Home />} />
          {/* Redirect to '/login' for other undefined routes when not logged in */}
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="/selectcompany" element={<SelectCompany />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          {/* remove later */}
          {/* <Route path="/userapproval" element={<UserApproval />} /> */}
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/downloadtheapp" element={<DownloadTheApp />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/resetpassword" element={<ResetPasswordPage />} />
          {/* <Route path = "/reauthorize" element = {<ReAuthorizePage/>} /> */}
          <Route path="/distributors" element={<Distributors />} />
          <Route path="/termsandconditions" element={<TermsAndConditions />} />
          <Route path="/userinvited" element={<UserInvitePage />} />
          <Route path="/gallery" element={<GalleryPage />} />
        </Routes>
      </BrowserRouter>
    );
  }

  if (termNotAccepted) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* Redirect to '/login' for other undefined routes when not logged in */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    );
  }

  // Check if the user has multiple companies
  if (hasMultipleCompanies) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/selectcompany" element={<SelectCompany />} />
          {/* Redirect to '/selectcompany' when visiting other undefined routes */}
          <Route path="*" element={<Navigate to="/selectcompany" replace />} />
        </Routes>
      </BrowserRouter>
    );
  }

  // Default route for users with a single company
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Tags />} />
        <Route path="/home" element={<Home />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        {/* Redirect to '/' when visiting other undefined routes */}
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route path="/userapproval" element={<UserApproval />} />
        <Route path="/userlist" element={<UserList />} />
        <Route path="/changepassword" element={<ChangePassword />} />
        <Route path="/userprofile" element={<UserProfile />} />
        <Route path="/companies" element={<Companies />} />
        <Route path="/sites" element={<Sites />} />
        <Route path="/locations" element={<Locations />} />
        <Route path="/downloadtheapp" element={<DownloadTheApp />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/items" element={<Items />} />
        <Route path="/tags/archive" element={<TagsArchive />} />
        <Route path="/items/archive" element={<ItemArchive />} />
        <Route path="/reauthorize" element={<ReAuthorizePage />} />
        <Route path="/tags/deleted" element={<DeletedTagsPage />} />
        <Route path="/items/deleted" element={<DeletedItemsPage />} />
        <Route path="/distributors" element={<Distributors />} />
        <Route path="/audittrail" element={<AuditTrail />} />
        <Route path="/termsandconditions" element={<TermsAndConditions />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/userLogs" element={<UserLogsPage />} />
     
      </Routes>
    </BrowserRouter>
  );
}

export default App;
