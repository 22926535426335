import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ThinCircleCheck } from "../../../fontAwesomeComponents/FaIcons";

const ForgotPasswordSuccessModal = ({ onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isLogin = location.pathname === "/login";

  const handleBackToProfile = () => {
    if (isLogin) {
      window.location.reload();
    } else {
      navigate("/login");
    }
  };

  const handleCloseWhenInLogin = () => {
    onClose();
  };

  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "100%",
          paddingTop:"30px"
        }}
      >
        <div
          style={{
            marginBottom: "20px",
            backgroundColor: "#D4EDDA",
            padding: "10px",
            borderRadius: "16px",
          }}
        >
          <ThinCircleCheck height="50px" width="50px" />
        </div>

        <h2
          style={{
            fontFamily: "futuraheavy",
            fontSize: "25px",
            marginBottom: "20px",
            textAlign:"center"
          }}
        >
          Your Password Reset has been Successful.
        </h2>

        <button
          className="back-to-login-button"
          onClick={handleBackToProfile}
          style={{
            backgroundColor: "#28A745",
            width: "80%",
            fontFamily: "futurabook",
            color: "#FFFFFF",
            height: "50px",
            border: "none",
            borderRadius: "10px",
          }}
        >
          Back to login Page
        </button>

        <p
          style={{
            textAlign: "center",
            fontSize: "9px",
            fontFamily: "futurabook",
              paddingLeft: "10px",
              paddingRight: "10px",
            marginTop:"30px",
            color: "#141212"
          }}
        >
          Security Note: If applicable, access to all other company databases at
          Safe T Tag, will be reauthorised/permitted after the Companies
          Administrators have re-APPROVED your access. A Company Administrator
          will now need to click a link contained within an email from Safe T
          Tag, allowing you access back into their database at Safe T Tag. Note:
          Safe T Tag cannot assist with the re-authorisation process. If you
          require immediate access into another companies database, please
          contact the Companies Administrator directly.
        </p>
      </div>
    </div>
  );
};

export default ForgotPasswordSuccessModal;
