import React, { useState } from "react";
import UserApprovalBar from '../components/UserApproval/UserApprovalBar'
import Navbar from '../components/NavBar'
import CompanyList from '../components/CompanyListComponents/CompanyList'

const Companies = () => {

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (query) => {
    // Update the search query state
    setSearchQuery(query);
  };


  return (
    <div
    style={{
      background:"#EEEEEE"
    }}
    >
        <Navbar/>
        <UserApprovalBar searchQuery={searchQuery} onSearch={handleSearch} />
        <CompanyList  searchQuery={searchQuery}/>
    </div>
  )
}

export default Companies