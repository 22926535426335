import React, { useState } from "react";
import ContactUsDetails from "./ContactUsDetails";
import { publicRequest } from "../../requestMethods";
import SendEmailSuccess from "./ValidationModals/SendEmailSuccess";
import FailedSendEmail from "./ValidationModals/FailedSendEmail";
import { Modal } from "react-bootstrap";
import '../../css/ContactUsCss/contactus.css'

const ContactFields = () => {
  const [formData, setFormData] = useState({
    companyName: "",
    address: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    let value = e.target.value;
  
    // If the field is the phone field, use regex to allow only numbers
    if (e.target.name === "phone") {
      // Remove non-numeric characters
      value = value.replace(/\D/g, "");
    }
  
    setFormData({
      ...formData,
      [e.target.name]: e.target.name === "phone" ? `+${value}` : value,
    });
  };
  
  


  const handleSubmit = async () => {
    setFormSubmitted(true);
    try {
      const sendEmail = await publicRequest({
        method: "POST",
        url: "admin/contactus",
        data: formData,
      });

      console.log("Email sent successfully", sendEmail);
      setEmailSentModal(true);

      // setFormData({
      //   companyName: "",
      //   address: "",
      //   firstName: "",
      //   lastName: "",
      //   email: "",
      //   phone: "",
      //   message: "",
      // });
    } catch (error) {
      console.error("Error sending email", error);
      setEmailError(true);
    }
  };

  const [emailSentModal, setEmailSentModal] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const handleOpenSentEmail = () => {
    setEmailSentModal(true);
  };

  const handleCloseSentEmail = () => {
    setEmailSentModal(false);
  };

  const handleOpenFailedEmail = () => {
    setEmailError(true);
  };

  const handleCloseFailedEmail = () => {
    setEmailError(false);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidContactNumber = (mobilePhone) => {
    const trimmedNumber = mobilePhone.replace(/\D/g, ""); // Remove non-numeric characters
    if (trimmedNumber.startsWith("61") && trimmedNumber.length === 11) {
      return true;
    }  else if (
      trimmedNumber.startsWith("678") &&
      (trimmedNumber.length === 7)
    ) {
      return true;
    } 
    else if (
      trimmedNumber.startsWith("64") &&
      [8,9].includes(trimmedNumber.length)
    ) {
      return true;
    } 
    else {
      return false;
    }
  };


  return (
    <div className="contactFieldsMainDiv"
      style={{
        display: "grid",
        gridTemplateColumns: "2fr 1fr", // Two equal columns
        columnGap: "30px",
        marginRight: "15%",
        marginLeft: "15%",
        color: "#EEEEEE",
        paddingTop: "80px",
        borderBottom: "1px solid #707070",
      }}
    >
      {/* Left Column */}
      <div>
        <div className="contactUsTitle">
          <h3 className="contactUsH"
            style={{
              fontFamily: "futuraheavy",
              fontSize: "45px",
            }}
          >
            Get in touch
          </h3>
          <p className="contactUsP"
            style={{
              fontFamily: "futurabook",
              fontSize: "18px",
            }}
          >
            If you have a question, concern, or feedback, please do not hesitate
            to contact us. Our customer service team is available to assist you
            and will respond to your inquiry as soon as possible. We value your
            input and are committed to providing the best possible experience
            for our customers. Thank you for choosing our company, and we look
            forward to looking after you!
          </p>
          <span
            style={{
              fontFamily: "futuraheavy",
              fontSize: "22px",
            }}
          >
            Send a message
          </span>
        </div>
        {/* Input Fields */}
        <div>
          {/* First Row: Company Name */}
          <div
            style={{ display: "grid", gridTemplateColumns: "1fr", gap: "10px" }}
          >
            <div>
              <label htmlFor="companyName"></label>
              <input
                type="text"
                id="companyName"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                style={{
                  width: "100%",
                  backgroundColor: "#303841",
                  color: "#EEEEEE",
                  border: "none",
                  borderRadius: "10px",
                  padding: "15px",
                  fontFamily: "futurabook",
                }}
                placeholder="Company / Business Name"
                placeholderTextColor="#EEEEEE"
                maxLength={50}
              />
            </div>
          </div>
          {/* Second Row: Address */}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr",
              gap: "10px",
              marginTop: "15px",
            }}
          >
            <div>
              <input
                type="text"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                style={{
                  width: "100%",
                  backgroundColor: "#303841",
                  color: "#EEEEEE",
                  border: "none",
                  borderRadius: "10px",
                  padding: "15px",
                  fontFamily: "futurabook",
                }}
                placeholder="Address"
                placeholderTextColor="#EEEEEE"
                maxLength={60}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "10px",
            marginTop: "15px",
          }}
        >
          <div>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              style={{
                width: "100%",
                backgroundColor: "#303841",
                color: "#EEEEEE",
                border: "none",
                borderRadius: "10px",
                padding: "15px",
                fontFamily: "futurabook",
              }}
              placeholder="First Name*"
              placeholderTextColor="#EEEEEE"
              maxLength={50}
            />
            {formData.firstName === "" && formSubmitted && (
              <div
                className="validation-message"
                style={{
                  marginTop: "5px",
                }}
              >
                First Name is required
              </div>
            )}
          </div>
          <div>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              style={{
                width: "100%",
                backgroundColor: "#303841",
                color: "#EEEEEE",
                border: "none",
                borderRadius: "10px",
                padding: "15px",
                fontFamily: "futurabook",
              }}
              placeholder="Last Name*"
              placeholderTextColor="#EEEEEE"
              maxLength={50}
            />

            {formData.lastName === "" && formSubmitted && (
              <div
                className="validation-message"
                style={{
                  marginTop: "5px",
                }}
              >
                Last Name is required
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "10px",
            marginTop: "15px",
          }}
        >
          <div>
            <input
              type="text"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              style={{
                width: "100%",
                backgroundColor: "#303841",
                color: "#EEEEEE",
                border: "none",
                borderRadius: "10px",
                padding: "15px",
                fontFamily: "futurabook",
              }}
              placeholder="Contact Number*"
              placeholderTextColor="#EEEEEE"
              maxLength={12}
            />
            {formSubmitted && formData.phone === "" && (
              <div className="validation-message" style={{ marginTop: "5px" }}>
                Contact Number is required
              </div>
            )}
            {formSubmitted &&
              formData.phone !== "" &&
              !isValidContactNumber(formData.phone) && (
                <div
                  className="validation-message"
                  style={{ marginTop: "5px" }}
                >
                  Please enter a valid contact number
                </div>
              )}
          </div>

          <div>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              style={{
                width: "100%",
                backgroundColor: "#303841",
                color: "#EEEEEE",
                border: "none",
                borderRadius: "10px",
                padding: "15px",
                fontFamily: "futurabook",
              }}
              placeholder="Email Address*"
              placeholderTextColor="#EEEEEE"
              maxLength={60}
            />
            {formSubmitted && formData.email === "" && (
              <div className="validation-message" style={{ marginTop: "5px" }}>
                Email is required
              </div>
            )}
            {formSubmitted &&
              formData.email !== "" &&
              !isValidEmail(formData.email) && (
                <div
                  className="validation-message"
                  style={{ marginTop: "5px" }}
                >
                  Please enter a valid email
                </div>
              )}
          </div>
        </div>

        {/* 5th Row for Message */}
        <div style={{ marginTop: "15px" }}>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            style={{
              width: "100%",
              height: "200px",
              backgroundColor: "#303841",
              color: "#EEEEEE",
              border: "none",
              borderRadius: "10px",
              padding: "15px",
              fontFamily: "futurabook",
              fontWeight: "bold",
            }}
            placeholder="How can we help you today?"
            placeholderTextColor="#EEEEEE"
          ></textarea>
        </div>

        {/* Button */}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "-10px",
            paddingBottom: "40px",
          }}
        >
          <button
            className="save-button"
            style={{ width: "100px", fontFamily: "futuraheavy" }}
            onClick={handleSubmit}
          >
            Submit
          </button>

          <Modal
            show={emailSentModal}
            //  onHide={handleCloseSentEmail}
            centered
          >
            <Modal.Body>
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={handleCloseSentEmail}
                  style={{
                    background: "none",
                    border: "none",
                    fontSize: "35px",
                    position: "absolute",
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <SendEmailSuccess onClose={handleCloseSentEmail} />
            </Modal.Body>
          </Modal>

          <Modal
            show={emailError}
            //  onHide={handleCloseFailedEmail}
            centered
          >
            <Modal.Body>
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={handleCloseFailedEmail}
                  style={{
                    background: "none",
                    border: "none",
                    fontSize: "35px",
                    position: "absolute",
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <FailedSendEmail onClose={handleCloseFailedEmail} />
            </Modal.Body>
          </Modal>
        </div>
      </div>

      {/* Right Column */}
      <div
        style={{
          marginTop: "175px",
        }}
      >
        <ContactUsDetails />
      </div>
    </div>
  );
};

export default ContactFields;
