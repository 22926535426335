import React, { useEffect, useState } from "react";
import Navbar from '../components/NavBar'
import PrivacyPolicyFooter from '../components/privacyPolicyComponents/PrivacyPolicyFooter'
import ContactDetails from '../components/privacyPolicyComponents/ContactDetails'
import TermsAndConditionsContainer from '../components/TermsAndConditions/TermsAndConditionsContainer'

const TermsAndConditions = () => {

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      const screenWidth = window.innerWidth;
      setIsMobile(screenWidth < 768); // Adjust this value according to your design requirements
    };

    checkIfMobile();

    window.addEventListener("resize", checkIfMobile);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  return (
    <div className='parent'
    style={{
      backgroundColor: '#EEEEEE',
      backgroundSize:"cover",
    }}>
        <Navbar/>
        <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginLeft: isMobile ? "20px" : "15%",
          marginRight: isMobile ? "20px" : "15%",
        }}
      >
      <TermsAndConditionsContainer/>
      <div style={{ marginTop: "100px" }}>
          {!isMobile && <ContactDetails />}

          {/* <Map/> */}
        </div>
       
        </div>
        <PrivacyPolicyFooter/>
        
    </div>
  )
}

export default TermsAndConditions