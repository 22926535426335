import React from 'react';
import "../../css/privacyPolicycss/PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className='policy-container'>
    <div className="">
      <h1>Privacy Policy for Safe T Tag</h1>
      <p>Last updated: 14 March 2024</p>
      <p>This Privacy Policy outlines how we collect, use, disclose, and manage your personal information when you use our mobile application (or website) and its related services. By using our Platform, you consent to the practices described in this Privacy Policy. If you do not agree with the practices described herein, please do not use any part of our Platform.</p>
      
      <h2>Information We Collect</h2>
      <p>We may collect personal information from you in a number of different ways including directly from you or in the course of our dealings with you when you provide personal information to us via the Platform or when you contact and correspond with us. The personal information we collect about you may include (but may not be limited to) your name, email address, residential address, telephone number, payment card information, your device type, operating system, unique device identifier, IP address, geolocation, Google analytics, and usage information.</p>
      
      <h2>How We Use Your Information</h2>
      <p>We use the information we collect for various purposes including:</p>
      <ul>
        <li>Providing and maintaining the mobile application (or website)'s functionality.</li>
        <li>Personalizing your experience and offering tailored content.</li>
        <li>Sending notifications and updates.</li>
        <li>Communicating with you such as by sending you emails or push notifications.</li>
        <li>Analysing and improving our services.</li>
        <li>Responding to your inquiries and providing customer support.</li>
      </ul>

      <h2>Sharing Your Information</h2>
      <p>We may use or disclose your personal information for the purpose for which it was collected and in accordance with Australian law such as:</p>
      <ul>
        <li>to provide you with the Services;</li>
        <li>to verify your identity;</li>
        <li>to contact you;</li>
        <li>to manage our relationships with our third-party providers;</li>
        <li>to offer additional products and services and other special offers and promotions related to the Services;</li>
        <li>to promote our Services to our clients and individuals who may be interested in our Services;</li>
        <li>to facilitate our internal business operations including to fulfil our legal requirements, to provide customer support, to support and manage our employees, to protect our property, to prevent and investigate crime or possible crime, and to provide information to external service providers;</li>
        <li>to communicate with others for one or more of those purposes.</li>
      </ul>
      <p>We may also use and disclose your personal information for a secondary purpose that is related to a purpose for which we collected it where you would reasonably expect us to use or disclose your personal information for that secondary purpose.</p>
      <p>We may also disclose your personal information to third parties including:</p>
      <ul>
        <li>where you have expressly or impliedly consented to the use or disclosure;</li>
        <li>where the use or disclosure is authorised or required by or under an Australian law or court/tribunal order; and</li>
        <li>with third-party service providers we use in conducting our business.</li>
      </ul>
      <p>Some of the third parties to whom we disclose your personal information may be located outside Australia.</p>

      <h2>Data Security</h2>
      <p>We are committed to securing your personal information and have implemented appropriate safeguards to protect it. We will take reasonable steps to keep any personal information we hold about you secure. However, we exclude all liability for the consequences of any unauthorised access to your personal information. Please notify us immediately if you become aware of any breach of security.</p>
      <p>You can access and update your information at any time by logging into your account and making the desired changes.</p>

      <h2>Third Party Links</h2>
      <p>Through your use of the Platform, you may receive or access links to third-party websites and/or the opportunity to share on social media. Your access to such third-party websites or use of social media features is subject to the privacy policies and practices of those third-party websites and/or social media providers. We encourage you to review the privacy policies of any third-party websites through which you access the Platform.</p>

      <h2>Your Choices</h2>
      <p>You have the right to access, correct, or delete your personal information. You may do so by contacting us at safettag.com. Upon contacting us and subsequently verifying your identity, we will take steps to delete your information as soon as reasonably practical. However, please note that we may need to retain certain information for legal or business purposes.</p>

      <h2>Changes to this Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will provide notice of any changes on the mobile application (or website) or through other means. Your continued use of the mobile application (or website) after the effective date of the updated Privacy Policy constitutes your acceptance of the revised terms.</p>

      <h2>Contact Information</h2>
      <p>If you have questions or concerns about this Privacy Policy or our data handling practices, please contact us at safettag.com.</p>

      <h2>Defined Terms</h2>
      <p><strong>App:</strong> the Safe T Tag mobile application.</p>
      <p><strong>Platform:</strong> the Website and App.</p>
      <p><strong>Services:</strong> any services or goods supplied by us in connection with the Website, App, and our business generally.</p>
      <p><strong>We, us, and our:</strong> TACD Enterprises Pty Ltd (ACN 160 617 685) trading as Safe T Tag (ABN 34 160 617 685) and includes its subsidiaries, agents, employees, affiliated or related entities, assigns, and successors.</p>
      <p><strong>Website:</strong> our website www.safettag.com.</p>
      <p><strong>You:</strong> the person accessing or using the Platform and/or the Services.</p>
    </div>
    </div>
  );
};

export default PrivacyPolicy;
