import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { publicRequest } from "../../requestMethods";
import AddSiteInLocationModal from "../LocationListComponents/AddSiteInLocationModal";
import { Modal } from "react-bootstrap";
import AddItemSuccessModal from "./ValidationModals/AddItemSuccessModal";
import FailedAddItemModal from "./ValidationModals/FailedItemAddModal";
import AddLocationInSiteModal from "../LocationListComponents/AddLocationInSiteModal";

const AddItemModal = () => {
  const currentUser = useSelector((state) => state.user);
  const userId = useSelector((state) => state.user.id);
  const selectedUserCompanyId = useSelector((state) => state.selectedCompany); //usercompanyid
  const selectedCompanyId = useSelector((state) => state.selectedCompanyId);
  const selectedCompanyName = useSelector((state) => state.selectedCompanyName);
  const userRole = useSelector((state) => state.selectedCompanyRoleId);
  const token = useSelector((state) => state.user.token);

  const [siteList, setSiteList] = useState([]);
  const [selectedSiteName, setSelectedSiteName] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [selectedSiteId, setSelectedSiteId] = useState("");
  const [identifierTypeList, setIdentifierTypeList] = useState([]);
  const [selectedIdentifierType, setSelectedIdentifierType] = useState("");
  const [isSerialNumberDisabled, setIsSerialNumberDisabled] = useState(false);
  const [selectedSite, setSelectedSite] = useState(null);
  const [sitesList, setSitesList] = useState([]);
  const [selectedCompanyIdFromDropdown, setSelectedCompanyIdFromDropdown] =
    useState("");
  const [selectedLocationName, setSelectedLocationName] = useState("");
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [serialNumberCount, setSerialNumberCount] = useState(0);
  const [identifierCount, setIdentifierCount] = useState(0);
  const [descriptionCount, setDescriptionCount] = useState(0);
  const [descriptionError, setDescriptionError] = useState("");
  const [serialNumberError, setSerialNumberError] = useState("");
  const [identifierNumberError, setIdentifierNumberError] = useState('');

  
  const [locationList, setLocationList] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [isError, setIsError] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [serialNumberErrorFormat, setSerialNumberErrorFormat] = useState("");
  //files
  const fileInputRef = useRef(null);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileErrors, setFileErrors] = useState("");
  const maxFileCount = 3; // Maximum number of files allowed

  const handleFileChange = (e) => {
    const selected = e.target.files;
    const allowedFormats = ["pdf", "doc", "docx", "png", "jpg"];
    const currentFiles = [...selectedFiles]; // Create a copy of the current selectedFiles

    if (currentFiles.length + selected.length > maxFileCount) {
      setFileErrors("You can select up to 3 files.");
      return;
    }

    let errors = "";

    for (let i = 0; i < selected.length; i++) {
      const file = selected[i];
      const fileNameParts = file.name.split(".");
      const fileExtension =
        fileNameParts[fileNameParts.length - 1].toLowerCase();

      if (!allowedFormats.includes(fileExtension)) {
        errors += `File '${file.name}' is not allowed. `;
      }

      if (file.size > 1024 * 1024) {
        errors += `File '${file.name}' exceeds the 1MB limit. `;
      }

      // Check if the current file already exists in selectedFiles
      if (
        !currentFiles.some((existingFile) => existingFile.name === file.name)
      ) {
        // Push the file into currentFiles
        currentFiles.push(file);
      }
    }

    if (errors !== "") {
      setFileErrors(errors);
    } else {
      setFileErrors("");
      setSelectedFiles(currentFiles); // Set the selected files
    }
  };

  const handleFileRemove = (fileName) => {
    const updatedFiles = selectedFiles.filter((file) => file.name !== fileName);
    setSelectedFiles(updatedFiles);
  };

  ///

  //modal

  const [showAddSiteModal, setShowAddSiteModal] = useState(false);
  const [showAddLocationModal, setShowAddLocationModal] = useState(false);

  function handleAddSiteModal(e) {
    const selectedOption = e.target.value;
    if (selectedOption === "Add Site") {
      setShowAddSiteModal(true);
    }
  }

  function handleAddLocationModal(e) {
    const selectedOption = e.target.value;
    if (selectedOption === "Add Location") {
      setShowAddLocationModal(true);
    }
  }

  const handleCloseAddLocationModal = () => {
    setShowAddLocationModal(false);
  };

  const toggleFailedAddItemModal = () => {
    setShowFailedModal(!showFailedModal);
  };

  const handlecloseAddSiteModal = () => {
    setShowAddSiteModal(false);
  };

  const toggleAddSiteInLocationModal = () => {
    setShowAddSiteModal(!showAddSiteModal);
  };

  const toggleAddLocationInSiteModal = () => {
    setShowAddLocationModal(!showAddLocationModal);
  };

  const [showAddSuccessModal, setShowAddSuccessModal] = useState(false);

  const handleCloseSuccessSiteModal = () => {
    setShowAddSuccessModal(false);
  };

  const [showFailedModal, setShowFailedModal] = useState(false);

  const handleCloseFailedModal = () => {
    setShowFailedModal(false);
  };

  //////////////////////////////////////////////////////////////////////

  const [locationDetails, setLocationDetails] = useState({
    addressLine1: "",
    addressLine2: "",
  });

  const fetchSiteList2 = async () => {
    if (userRole === 1 && !selectedCompanyIdFromDropdown) {
      return;
    }

    const companyIdToUse =
      userRole === 1 ? selectedCompanyIdFromDropdown : selectedCompanyId;

    const siteResponse = await fetchData(
      `lookup/company/${companyIdToUse}/sites`
    );

    if (siteResponse) {
      setSiteList(siteResponse);
    }
  };

  const [locationAddressLine1, setLocationAddressLine1] = useState("");
  const [locationAddressLine2, setLocationAddressLine2] = useState("");

  const fetchLocationList2 = async () => {
    try {
      const queryParamsForLocation = {
        LoggedUserId: userId,
        LoggedUserCompanyId: selectedUserCompanyId,
        SiteId: selectedSiteId,
      };
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const requestConfig = {
        method: "GET",
        headers: headers,
        params: queryParamsForLocation,
      };

      const getLocationsResponse = await publicRequest(
        "/location/site",
        requestConfig
      );

      if (getLocationsResponse) {
        setLocationList(getLocationsResponse.data);
        //  setLoading(false);

        console.log("this is location list", locationList);

        //when I add this
        // const queryParamsForSite = {
        //           LoggedUserId: userId,
        //           LoggedUserCompanyId: selectedUserCompanyId,
        //           SiteId: selectedSiteId, // Use the site's ID here
        //         };

        // const requestConfigForSiteDetails = {
        //     method: "GET",
        //     headers : headers,
        //     params : queryParamsForSite
        // }

        // const getSiteDetails = await publicRequest("/site" , requestConfigForSiteDetails);

        // if (getSiteDetails) {
        //     setSiteDetails(getSiteDetails);

        //     console.log("sitedetails", siteDetails);
        // }
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const fetchLocationData = async () => {
    const queryParamsForSite = {
      //  SiteId: selectedSiteId, // Use the site's ID here
      LoggedUserId: userId,
      LoggedUserCompanyId: selectedUserCompanyId,
      SiteId: selectedSiteId,
    };
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const requestConfigForSiteDetails = {
        method: "GET",
        headers: headers,
        params: queryParamsForSite, // Make sure queryParamsForSite is correctly set
      };

      const getSiteDetails = await publicRequest(
        `/location/${selectedLocationId}`,
        requestConfigForSiteDetails
      );

      if (getSiteDetails) {
        setLocationAddressLine1(getSiteDetails.data.addressLine1);
        setLocationAddressLine2(getSiteDetails.data.addressLine2);
        console.log("addressline1", siteAddressLine1); // Log the fetched data here
        console.log("addressline2", siteAddressLine2);
      }
    } catch (error) {
      console.error("Error fetching site details", error);
    }
  };

  const queryParams = {
    LoggedUserId: userId,
    LoggedUserCompanyId: selectedCompanyId,
  };

  const queryParamsForSiteList = {
    LoggedUserId: userId,
    LoggedUserCompanyId: selectedUserCompanyId,
  };

  const [siteAddressLine1, setSiteAddressLine1] = useState("");
  const [siteAddressLine2, setSiteAddressLine2] = useState("");

  const fetchSiteData = async () => {
    const queryParamsForSite = {
      LoggedUserId: userId,
      LoggedUserCompanyId: selectedUserCompanyId,
      SiteId: selectedSiteId, // Use the site's ID here
    };
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const requestConfigForSiteDetails = {
        method: "GET",
        headers: headers,
        params: queryParamsForSite, // Make sure queryParamsForSite is correctly set
      };

      const getSiteDetails = await publicRequest(
        `/site/${selectedSiteId}`,
        requestConfigForSiteDetails
      );

      if (getSiteDetails) {
        setSiteAddressLine1(getSiteDetails.data.addressLine1);
        setSiteAddressLine2(getSiteDetails.data.addressLine2);
        console.log("addressline1", siteAddressLine1); // Log the fetched data here
        console.log("addressline2", siteAddressLine2);
      }
    } catch (error) {
      console.error("Error fetching site details", error);
    }
  };

  const fetchData = async (url) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const queryString = new URLSearchParams(queryParams).toString();
      const fullUrl = url + (queryString ? `?${queryString}` : "");

      const response = await publicRequest({
        method: "GET",
        url: fullUrl,
        headers: headers,
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchSiteList = async () => {
      if (userRole === 6 && !selectedCompanyIdFromDropdown) {
        return;
      }

      const companyIdToUse =
        userRole === 6 ? selectedCompanyIdFromDropdown : selectedCompanyId;

      const siteResponse = await fetchData(
        `lookup/company/${companyIdToUse}/sites`
      );

      if (siteResponse) {
        setSiteList(siteResponse);
        console.log(siteList);
      }
    };

    const fetchSitesData = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const requestConfig = {
          method: "GET",
          headers: headers,
          params: queryParamsForSiteList,
        };

        const getSitesResponse = await publicRequest(
          "/site/list",
          requestConfig
        );

        if (getSitesResponse) {
          setSitesList(getSitesResponse.data);
          //  setLoading(false);

          console.log("this is site list", sitesList);
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    const fetchLocationDataUE = async () => {
      const queryParamsForSite = {
        //  SiteId: selectedSiteId, // Use the site's ID here
        LoggedUserId: userId,
        LoggedUserCompanyId: selectedUserCompanyId,
        SiteId: selectedSiteId,
      };
      try {
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const requestConfigForSiteDetails = {
          method: "GET",
          headers: headers,
          params: queryParamsForSite, // Make sure queryParamsForSite is correctly set
        };

        const getSiteDetails = await publicRequest(
          `/location/${selectedLocationId}`,
          requestConfigForSiteDetails
        );

        if (getSiteDetails) {
          setLocationAddressLine1(getSiteDetails.data.addressLine1);
          setLocationAddressLine2(getSiteDetails.data.addressLine2);
          console.log("addressline1", siteAddressLine1); // Log the fetched data here
          console.log("addressline2", siteAddressLine2);
        }
      } catch (error) {
        console.error("Error fetching site details", error);
      }
    };

    const fetchLocationData = async () => {
      try {
        const queryParamsForLocation = {
          LoggedUserId: userId,
          LoggedUserCompanyId: selectedUserCompanyId,
          SiteId: selectedSiteId,
        };
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const requestConfig = {
          method: "GET",
          headers: headers,
          params: queryParamsForLocation,
        };

        const getLocationsResponse = await publicRequest(
          "/location/site",
          requestConfig
        );

        if (getLocationsResponse) {
          setLocationList(getLocationsResponse.data);
          //  setLoading(false);

          console.log("this is location list", locationList);

          //when I add this
          // const queryParamsForSite = {
          //           LoggedUserId: userId,
          //           LoggedUserCompanyId: selectedUserCompanyId,
          //           SiteId: selectedSiteId, // Use the site's ID here
          //         };

          // const requestConfigForSiteDetails = {
          //     method: "GET",
          //     headers : headers,
          //     params : queryParamsForSite
          // }

          // const getSiteDetails = await publicRequest("/site" , requestConfigForSiteDetails);

          // if (getSiteDetails) {
          //     setSiteDetails(getSiteDetails);

          //     console.log("sitedetails", siteDetails);
          // }
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    const fetchCompanies = async () => {
      const companyResponse = await fetchData("lookup/companies");

      if (companyResponse && userRole === 1) {
        setCompanyList(companyResponse);
        console.log(companyList);
      }
    };

    const fetchIdentifierType = async () => {
      const identifierTypeResponse = await fetchData("lookup/identifierType");

      if (identifierTypeResponse) {
        setIdentifierTypeList(identifierTypeResponse);
        console.log(identifierTypeList);
      }
    };

    fetchIdentifierType();
    fetchCompanies();
    fetchSiteList();
    fetchSitesData();
    fetchLocationData();
    fetchLocationDataUE();

    if (selectedSiteId) {
      fetchSiteData();
    }

    if (selectedLocationId) {
      fetchLocationDataUE();
    }

    if (identifierTypeList.length > 0 && !selectedIdentifierType) {
      setSelectedIdentifierType(identifierTypeList[0].id);
    }

    // Fetch site details when selectedSiteId changes
  }, [
    selectedCompanyIdFromDropdown,
    userRole,
    selectedSiteId,
    selectedLocationId,
  ]);

  const [siteDetails, setSiteDetails] = useState({
    loggedUserId: userId,
    loggedUserCompanyId: selectedUserCompanyId,
    // companyId:
    //   userRole === 1 ? selectedCompanyIdFromDropdown : selectedCompanyId,
    companyId: selectedCompanyId,
    name: "",
    company: selectedCompanyName,
    location: "",
    addressLine1: "",
    addressLine2: "",
    latitude: "",
    longitude: "",
  });

  const [itemDetails, setItemDetails] = useState({
    loggedUserId: userId,
    loggedUserCompanyId: selectedUserCompanyId,
    serialNumber: "",
    identifiersNumber: "",
    tagItemTypeId: "",
    siteId: selectedSiteId,
    locationId: selectedLocationId,
    description: "",
    files: selectedFiles,
  });

  const createItem = async () => {
    // const data = {
    //   loggedUserId: userId,
    //   loggedUserCompanyId: selectedUserCompanyId,
    //   companyId: selectedCompanyId,
    //   siteId: selectedSiteId,
    //   serialNumber: itemDetails.serialNumber,
    //   tagItemTypeId: selectedIdentifierType,
    //   description: itemDetails.description,
    // };

    // console.log(data);

    // const headers = {
    //   Authorization: `Bearer ${token}`,
    // };
    setFormSubmitted(true);
    const formData = new FormData();
    formData.append("LoggedUserId", userId);
    formData.append("LoggedUserCompanyId", selectedUserCompanyId);
    formData.append("companyId", selectedCompanyId);
    formData.append("SiteId", selectedSiteId);
    formData.append("SerialNumber", itemDetails.serialNumber);
    formData.append("tagItemTypeId", selectedIdentifierType);
    formData.append("Description", itemDetails.description);
    formData.append("LocationId", selectedLocationId);
    formData.append("IdentifiersNumber", itemDetails.identifiersNumber);

    // Append files to the FormData object
    selectedFiles.forEach((file, index) => {
      formData.append(`Files`, file);
    });

    console.log("this is formdata", formData);

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data", // Set the content type to multipart/form-data
    };

    try {
      const response = await publicRequest({
        method: "POST",
        url: "/item/create",
        data: formData,
        headers: headers,
      });
      console.log(response.data);
      setShowAddSuccessModal(true);
    } catch (error) {
      console.log(error);
      setShowFailedModal(true);
      console.error(error);
      setErrorMessage(error.response.data.errors || error.response.data.title);
      setIsError(true);
      if(error.response.data.status === 409) {
        setSerialNumberError("Item Number already exists")
        return;
      }
    }
  };

  const handleInputChange = (field, value) => {
    setItemDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));

    if (field === "serialNumber") {
      const serialNumberRegex = /^[0-9]{4}[A-Z]{4}$/;

      if (!serialNumberRegex.test(value)) {
        setSerialNumberError(
          "Serial Number must have 4 digits followed by 4 letters (e.g. 1234ABCD)."
        );
      } else {
        setSerialNumberError(null);
      }

           setItemDetails((prevDetails) => ({
        ...prevDetails,
        [field]: value.toUpperCase(),
      }));
      setSerialNumberCount(value.length);
    }

    if (field === "identifiersNumber") { 
      const identifierNumberRegex  = /^[a-zA-Z0-9]*$/;



      if (!identifierNumberRegex.test(value)) {
        setIdentifierNumberError(
          "Identifier Number must only contain Alpha Numeric characters"
        );
      } else {
        setIdentifierNumberError(null);
      }

           setItemDetails((prevDetails) => ({
        ...prevDetails,
        [field]: value.toUpperCase(),
      }));
      setIdentifierCount(value.length);
    }


    

    if (field === "description") {
      // Regular expression to match alphanumeric characters and spaces
      const alphanumericRegex = /^[a-zA-Z0-9\s]*$/;

      if (!alphanumericRegex.test(value)) {
        setDescriptionError("Only alphanumeric characters are allowed.");
      } else {
        setDescriptionError(""); // Clear the error message
      }

      setItemDetails((prevDetails) => ({
        ...prevDetails,
        [field]: value,
      }));
      setDescriptionCount(value.length);
    }

    if (field === "siteId") {
      const selectedSite = siteList.find((site) => site.id === value);
      if (selectedSite) {
        setSelectedSite(selectedSite);
        setSiteDetails((prevDetails) => ({
          ...prevDetails,
          addressLine1: selectedSite.addressLine1,
          addressLine2: selectedSite.addressLine2,
        }));
      }
    }
  };

  const handleCreateItem = () => {
    if (!showFailedModal && !showAddSuccessModal) {
      setFormSubmitted(true);
      createItem();
    }
  };


  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [showFileInput, setShowFileInput] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);

  const handleUploadFile = () => {
    if (agreedToTerms) {
      fileInputRef.current.click();
    } else {
      setShowTermsModal(true);
    }
  };

  const handleAgreeToTerms = () => {
    setAgreedToTerms(true);
    setShowFileInput(true);
    setShowTermsModal(false);

    fileInputRef.current.click();
  };

  const handleCloseTermsModal = () => {
    setShowTermsModal(false);
  };


  return (
    <div className="ModalChildDiv">
      <div
        style={{
          alignItems: "center",
          display: "flex",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <div
          className="modal-content custom-modal-content"
          style={{
            padding: "10px",
            paddingLeft: "20px",
            paddingRight: "20px",
            background: "none",
            border: "none",
          }}
        >
          <div className="row">
            <div
              className="finalTitleDiv"
              style={{
                alignItems: "flex-start",
              }}
            >
              <h3
                style={{
                  fontFamily: "futuramedium",
                  fontWeight: "bold",
                  fontSize: "30px",
                  marginTop: "15px",
                }}
              >
                Add Item
              </h3>
            </div>
          </div>

                <div className="finish-row">
          <div className="finish-column" style={{ position: "relative" }}>
              <p
                className="detailsTitle"
                style={{
                  fontSize: "13px",
                  marginBottom: "11px",
                }}
              >
               Item Number*:
              </p>
              <input
                type="text"
                className="addUserDetails"
                placeholder="eg. 1234ABCD"
                style={{
                  fontFamily: "futurabook",

                  color: "#141212",
                  paddingRight: "40px", // Add space for the counter
                }}
                value={itemDetails.serialNumber}
                onChange={(e) =>
                  handleInputChange(
                    "serialNumber",
                    e.target.value.toUpperCase()
                  )
                }
                maxLength={8} // Limit the input to 8 characters
                disabled={isSerialNumberDisabled}
              />
              {itemDetails.serialNumber === "" && formSubmitted && (
                <div className="validation-message">
                  Serial Number is Required
                </div>
              )}

              {serialNumberErrorFormat && (
                <div
                  style={{
                    position: "relative",
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {serialNumberErrorFormat}
                </div>
              )}
              <div
                style={{
                  position: "absolute",
                  top: serialNumberError ? "50%" : "65%",
                  right: "10px",
                  fontSize: "12px",
                  transform: "translateY(-50%)",
                  color: "#666",
                }}
              >
                {serialNumberCount}/8
              </div>
              {serialNumberError && formSubmitted && (
                <div
                  style={{
                    position: "relative",
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {serialNumberError}
                </div>
              )}
            </div>
            </div>

          <div className="finish-row">
            <div className="finish-column">
              <p
                className="detailsTitle"
                style={{
                  fontSize: "13px",
                  marginBottom: "5px",
                }}
              >
                Identifier Type*:
              </p>
              <select
                className="addUserDetails"
                style={{
                  fontFamily: "futurabook",
                  fontWeight: "bold",
                  color: "#141212",
                  height: "30px",
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                }}
                value={selectedIdentifierType}
                onChange={(e) => {
                  const selectedOption = e.target.value;
                  setSelectedIdentifierType(selectedOption);

                  // Check if the selected option is "Identifier Type" and set the disabled state accordingly
                  const isDisabled = selectedOption === "";
                  setIsSerialNumberDisabled(isDisabled);
                }}
              >
                {/* <option value="">Identifier Type</option> */}
                {identifierTypeList.map((identifierType) => (
                  <option key={identifierType.id} value={identifierType.id}>
                    {identifierType.name}
                  </option>
                ))}
              </select>
            </div>


            <div className="finish-column" style={{ position: "relative" }}>
              <p
                className="detailsTitle"
                style={{
                  fontSize: "13px",
                  marginBottom: "10px",
                }}
              >
                Identifier Number*:
              </p>
              <input
                type="text"
                className="addUserDetails"
                placeholder="Identifier Number"
                style={{
                  fontFamily: "futurabook",
                  color: "#141212",
                  paddingRight: "40px", // Add space for the counter
                }}
                value={itemDetails.identifiersNumber}
                onChange={(e) =>
                  handleInputChange(
                    "identifiersNumber",
                    e.target.value.toUpperCase()
                  )
                }
                maxLength={30} // Limit the input to 8 characters
                disabled={isSerialNumberDisabled}
              />
               {itemDetails.identifiersNumber === "" && formSubmitted && (
                <div className="validation-message">
                  Identifier Number is Required
                </div>
              )} 
              <div
                style={{
                  position: "absolute",
                  top: identifierNumberError ? "50%" : "65%",
                  right: "10px",
                  fontSize: "12px",
                  transform: "translateY(-50%)",
                  color: "#666",
                }}
              >
                {identifierCount}/30
              </div>
              {identifierNumberError && formSubmitted && (
                <div
                  style={{
                    position: "relative",
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {identifierNumberError}
                </div>
              )}
            </div>

          </div>

          <div className="finish-row">
            <div
              className="finish-column wholeRow"
              style={{ position: "relative" }}
            >
              <p
                className="detailsTitle"
                style={{
                  fontSize: "13px",
                  marginBottom: "5px",
                }}
              >
                Items Description*:
              </p>

              <input
                type="text"
                className="addUserDetails"
                placeholder="Item Description"
                style={{
                  fontFamily: "futurabook",
                  color: "#141212",
                }}
                value={itemDetails.description}
                onChange={(e) =>
                  handleInputChange("description", e.target.value)
                }
                maxLength={100}
              />
              {itemDetails.description === "" && formSubmitted && (
                <div className="validation-message">
                  Description is Required
                </div>
              )}
              <div
                style={{
                  position: "absolute",
                  top: descriptionError ? "50%" : "65%",
                  right: "5px",
                  fontSize: "12px",
                  transform: "translateY(-50%)",
                  color: "#666",
                }}
              >
                {descriptionCount}/100
              </div>
              {descriptionError && (
                <div
                  style={{
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  {descriptionError}
                </div>
              )}
            </div>
          </div>

          <div className="finish-row">
            <div className="finish-column wholeRow">
              <p
                className="detailsTitle"
                style={{
                  fontSize: "13px",
                  marginBottom: "5px",
                }}
              >
                Site Name*:
              </p>
              <select
                onClick={handleAddSiteModal}
                onBlur={(e) => {
                  if (!siteList.some((site) => site.name === e.target.value)) {
                    setSelectedSiteName("Select A Site");
                    setSelectedSiteId("");
                  }
                }}
                onFocus={fetchSiteList2}
                onHide={handlecloseAddSiteModal}
                className="addUserDetails"
                style={{
                  fontFamily: "futuramedium",
                  color: "#141212",
                  height: "40px",
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                }}
                value={
                  siteDetails.company || selectedCompanyIdFromDropdown
                    ? selectedSiteName
                    : "Loading..."
                }
                onChange={(e) => {
                  const selectedOption = e.target.value;
                  setSelectedSiteName(selectedOption);

                  const selectedSite = siteList.find(
                    (site) => site.name === selectedOption
                  );
                  if (selectedSite) {
                    setSelectedSiteId(selectedSite.id);
                    fetchSiteData();
                  }
                }}
              >
                <option value="">Select a site</option>
                {siteList.map((site) => (
                  <option key={site.id} value={site.name}>
                    {site.name}
                  </option>
                ))}
                <option value="Add Site">Add Site</option>
              </select>

              {selectedSiteId === "" && formSubmitted && (
                <div className="validation-message">Please Select a Site</div>
              )}

              <Modal
                show={showAddSiteModal}
                centered
                size="xl"
                //    onHide={handlecloseAddSiteModal}
              >
                <Modal.Body>
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="close"
                      aria-label="Close"
                      onClick={handlecloseAddSiteModal}
                      style={{
                        background: "none",
                        border: "none",
                        fontSize: "20px",
                        position: "absolute",
                        zIndex: 999,
                      }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <AddSiteInLocationModal
                    toggleAddSiteInLocationModal={toggleAddSiteInLocationModal}
                    onClose={handlecloseAddSiteModal}
                    onHide={handlecloseAddSiteModal}
                    onSiteAdded={(newSiteName) => {
                      setShowAddSiteModal(false);
                      setSelectedSiteName(newSiteName);
                    }}
                  />
                </Modal.Body>
              </Modal>
            </div>
          </div>

          <div className="finish-row">
            <div className="finish-column">
              <p
                className="detailsTitle"
                style={{
                  fontSize: "13px",
                  marginBottom: "5px",
                }}
              >
                Site Address Line 1*:
              </p>

              <input
                type="text"
                className="addUserDetails"
                placeholder="Address Line 1"
                style={{
                  fontFamily: "futurabook",
                  color: "#141212",
                }}
                value={siteAddressLine1}
                onChange={(e) =>
                  handleInputChange("addressLine1", e.target.value)
                }
                disabled
              />
            </div>
            <div className="finish-column">
              <p
                className="detailsTitle"
                style={{
                  fontSize: "13px",
                  marginBottom: "5px",
                }}
              >
                Site Address Line 2:
              </p>

              <input
                type="text"
                className="addUserDetails"
                placeholder="Address Line 2"
                style={{
                  fontFamily: "futurabook",
                  color: "#141212",
                }}
                value={siteAddressLine2}
                onChange={(e) =>
                  handleInputChange("addressLine2", e.target.value)
                }
                disabled
              />
            </div>
          </div>

          <div className="finish-row">
            <div className="finish-column wholeRow">
              <p
                className="detailsTitle"
                style={{
                  fontSize: "13px",
                  marginBottom: "5px",
                }}
              >
                Location Name*:
              </p>
              <select
                onClick={handleAddLocationModal}
                onBlur={(e) => {
                  if (
                    !locationList.some(
                      (location) => location.name === e.target.value
                    )
                  ) {
                    setSelectedLocationName("Select A Location");
                    setSelectedLocationId("");
                  }
                }}
                onFocus={fetchLocationList2}
                onHide={handlecloseAddSiteModal}
                className="addUserDetails"
                style={{
                  fontFamily: "futuramedium",
                  color: selectedSiteId ? "#141212" : "gray",
                  height: "40px",
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                }}
                disabled={!selectedSiteId}
                value={
                  siteDetails.company || selectedCompanyIdFromDropdown
                    ? selectedLocationName
                    : "Loading..."
                }
                onChange={(e) => {
                  const selectedOption = e.target.value;
                  setSelectedLocationName(selectedOption);

                  const selectedLocation = locationList.find(
                    (location) => location.name === selectedOption
                  );
                  if (selectedLocation) {
                    setSelectedLocationId(selectedLocation.id);
                    fetchLocationData();
                  }
                }}
              >
                <option value="">Select a Location</option>
                {locationList.map((location) => (
                  <option key={location.id} value={location.name}>
                    {location.name}
                  </option>
                ))}
                <option value="Add Location">Add Location</option>
              </select>

              {selectedLocationId === "" && formSubmitted && (
                <div className="validation-message">
                  Please Select a Location
                </div>
              )}

              <Modal
                centered
                show={showAddLocationModal}
                //     onHide={handleCloseAddLocationModal}
                size="xl"
              >
                <Modal.Body>
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="close"
                      aria-label="Close"
                      onClick={handleCloseAddLocationModal}
                      style={{
                        background: "none",
                        border: "none",
                        fontSize: "35px",
                        position: "absolute",
                        zIndex: "999",
                      }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <AddLocationInSiteModal
                    toggleAddLocationInSiteModal={toggleAddLocationInSiteModal}
                    onClose={handleCloseAddLocationModal}
                    selectedSite={selectedSiteId}
                    onSiteAdded={(newLocationName) => {
                      setShowAddSiteModal(false);
                      setSelectedLocationName(newLocationName);
                    }}
                    siteId={selectedSiteId}
                  />
                </Modal.Body>
              </Modal>
            </div>
          </div>

          <div className="finish-row">
            <div className="finish-column">
              <p
                className="detailsTitle"
                style={{
                  fontSize: "13px",
                  marginBottom: "5px",
                }}
              >
                Location Address Line 1*:
              </p>

              <input
                type="text"
                className="addUserDetails"
                placeholder="Address Line 1"
                style={{
                  fontFamily: "futurabook",
                  color: "#141212",
                }}
                value={locationAddressLine1}
                onChange={(e) =>
                  handleInputChange("addressLine1", e.target.value)
                }
                disabled
              />
            </div>
            <div className="finish-column">
              <p
                className="detailsTitle"
                style={{
                  fontSize: "13px",
                  marginBottom: "5px",
                }}
              >
                Location Address Line 2:
              </p>

              <input
                type="text"
                className="addUserDetails"
                placeholder="Address Line 2"
                style={{
                  fontFamily: "futurabook",
                  color: "#141212",
                }}
                value={locationAddressLine2}
                onChange={(e) =>
                  handleInputChange("addressLine2", e.target.value)
                }
                disabled
              />
            </div>
          </div>

          <div className="finish-row">
            <div
              className="finish-column wholeRow"
              style={{ position: "relative" }}
            >
              <p
                className="detailsTitle"
                style={{
                  fontSize: "13px",
                  marginBottom: "5px",
                }}
              >
                Attach File:
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                }}
              >
                <input
                  type="file"
                  multiple
                  accept=".pdf,.doc,.docx,.png,.jpg"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  ref={fileInputRef} // Use fileInputRef here
                />
                <button
                     onClick={handleUploadFile}
                  style={{
                    fontFamily: "futuramedium",
                    backgroundColor: "#3A4750",
                    borderRadius: "5px",
                    color: "#EEEEEE",
                    height: "40px",
                    marginTop: "15px",
                  }}
                >
                  Upload File
                </button>


                <Modal show={showTermsModal} onHide={handleCloseTermsModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      <span
                        style={{
                          fontSize: "19px",
                        }}
                      >
                        Terms and Conditions
                      </span>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {/* Your terms and conditions content */}
                    <p
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      At Safe T Tag, accessible from safettag.com, one of our
                      main priorities is the privacy of our visitors. This
                      Privacy Policy document contains types of information that
                      is collected and recorded by Safe T Tag and how we use it.
                      If you have additional questions or require more
                      information about our Privacy Policy, do not hesitate to
                      contact us. This Privacy Policy applies only to our online
                      activities and is valid for visitors to our website with
                      regards to the information that they shared and/or collect
                      in Safe T Tag. This policy is not applicable to any
                      information collected offline or via channels other than
                      this website. Consent By using our website Consent By
                      using our website, you hereby consent to our Privacy
                      Policy and agree to its terms. Information we collect The
                      personal information that you are asked to provide, and
                      the reasons why you are asked to provide it, will be made
                      clear to you at the point we ask you to provide your
                      personal information. If you contact us directly, we may
                      receive additional information about you such as your
                      name, email address, phone number, the contents of the
                      message and/or attachments you may send us, and any other
                      information you may choose to provide. When you register
                      for an Account, we may ask for your contact information,
                      including items such as name, company name, address, email
                      address, and telephone number. How we use your information
                      We use the information we collect in various ways,
                      including to: Provide, operate, and maintain our website
                      Improve, personalize, and expand our website Understand
                      and analyze how you use our website Develop new products,
                      services, features, and functionality Communicate with
                      you, either directly or through one of our partners,
                      including for customer service, to provide you with
                      updates and other information relating to the website, and
                      for marketing and promotional purposes Send you emails
                      Find and prevent fraud Log Files Safe T Tag follows a
                      standard procedure of using log files. These files log
                      visitors when they visit websites. All hosting companies
                      do this and a part of hosting services' analytics. The
                      information collected by log files include internet
                      protocol (IP) addresses, browser type, Internet Service
                      Provider (ISP), date and time stamp, referring/exit pages,
                      and possibly the number of clicks. These are not linked to
                      any information that is personally identifiable. The
                      purpose of the information is for analyzing trends,
                      administering the site, tracking users' movement on the
                      website, and gathering demographic information. Cookies
                      and Web Beacons Like any other website, Safe T Tag uses
                      “cookies”. These cookies are used to store information
                      including visitors' preferences, and the pages on the
                      website that the visitor accessed or visited. The
                      information is used to optimize the users' experience by
                      customizing our web page content based on visitors'
                      browser type and/or other information. Google DoubleClick
                      DART Cookie Google is one of a third-party vendor on our
                      site. It also uses cookies, known as DART cookies, to
                      serve ads to our site visitors based upon their visit to
                      www.website.com and other sites on the internet. However,
                      visitors may choose to decline the use of DART cookies by
                      visiting the Google ad and content network Privacy Policy
                      at the following URL -
                      https://policies.google.com/technologies/ads Advertising
                      Partners Privacy Policies You may consult this list to
                      find the Privacy Policy for each of the advertising
                      partners of Safe T Tag. Third-party ad servers or ad
                      networks uses technologies like cookies, JavaScript, or
                      Web Beacons that are used in their respective
                      advertisements and links that appear on Safe T Tag, which
                      are sent directly to users' browser. They automatically
                      receive your IP address when this occurs. These
                      technologies are used to measure the effectiveness of
                      their advertising campaigns and/or to personalize the
                      advertising content that you see on websites that you
                      visit. Note that Safe T Tag has no access to or control
                      over these cookies that are used by third-party
                      advertisers. Third Party Privacy Policies Safe T Tag's
                      Privacy Policy does not apply to other advertisers or
                      websites. Thus, we are advising you to consult the
                      respective Privacy Policies of these third-party ad
                      servers for more detailed information. It may include
                      their practices and instructions about how to opt-out of
                      certain options. You can choose to disable cookies through
                      your individual browser options. To know more detailed
                      information about cookie management with specific web
                      browsers, it can be found at the browsers' respective
                      websites. CCPA Privacy Rights (Do Not Sell My Personal
                      Information)
                    </p>
                  </Modal.Body>
                  <Modal.Footer>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <button
                        onClick={handleAgreeToTerms}
                        style={{
                          fontSize: "12px",
                          border: "none",
                          backgroundColor: "#EFCB46",
                          borderRadius: "5px",
                        }}
                      >
                        I Agree to the Terms and Conditions above
                      </button>
                      <button
                        onClick={handleCloseTermsModal}
                        style={{
                          fontSize: "10px",
                          border: "none",
                          borderRadius: "5px",
                        }}
                      >
                        Cancel Uploading File Attachment
                      </button>
                    </div>
                  </Modal.Footer>
                </Modal>


                <span style={{ marginLeft: "10px", display: "flex" }}>
                  {selectedFiles.length > 0
                    ? selectedFiles.map((file) => (
                        <span key={file.name}>
                          {file.name}{" "}
                          <button
                            onClick={() => handleFileRemove(file.name)}
                            style={{
                              background: "none",
                              border: "none",
                              color: "red",
                              cursor: "pointer",
                            }}
                          >
                            x
                          </button>
                          <br />
                        </span>
                      ))
                    : "No File Chosen"}
                </span>
                <div
                  style={{
                    position: "absolute",
                    bottom: -10,
                    left: 0,
                    right: 0,
                    borderBottom: "2px solid #707070", // Add a bottom border
                    padding: "5px",
                    fontSize: "12px",
                    color: "#777",
                  }}
                ></div>
              </div>
            </div>
            {fileErrors && (
              <div
                style={{
                  color: "red",
                  fontSize: "12px",
                  marginTop: "5px",
                }}
              >
                {fileErrors}
              </div>
            )}
          </div>

          <div
            style={{
              width: "100%",
              justifyContent: "space-between",
              display: "flex",
              marginTop: "20px",
            }}
          >
            <div className="passwordRequirements">
              <p
                style={{
                  fontFamily: "futurabook",
                  color: "#707070",
                  fontSize: "12px",
                  margin: "4px 0",
                }}
              >
                File size must not exceed 1MB.
              </p>
              <p
                style={{
                  fontFamily: "futurabook",
                  color: "#707070",
                  fontSize: "12px",
                  margin: "4px 0",
                }}
              >
                Accepted file formats: PDF, DOCX, PNG, JPG, JPEG.
              </p>
              <p
                style={{
                  fontFamily: "futurabook",
                  color: "#707070",
                  fontSize: "12px",
                  margin: "4px 0",
                }}
              >
                Maximum of 3 attachments allowed per tag.
              </p>

              <p
                style={{
                  fontFamily: "futurabook",
                  color: "#707070",
                  fontSize: "12px",
                  margin: "4px 0",
                }}
              >
                Attachments will only be retained for 12 months.
              </p>

              <br/>

              <p
              style={{
                fontSize:"11px",
                color:"#141212"
              }}
            >
            The (*) Star denotes that the question must be completed to SAVE the data
            </p>
            </div>
            {/* {isError && errorMessage && (
              <div className="error-message" style={{}}>
                {typeof errorMessage === "string" ? (
                  <p
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginTop: "20px",
                      marginRight: "20px",
                    }}
                  >
                    {errorMessage}
                  </p>
                ) : (
                  Object.values(errorMessage).map((error, index) => (
                    <p
                      key={index}
                      style={{
                        color: "red",
                        fontSize: "12px",
                        marginTop: "20px",
                        marginRight: "20px",
                      }}
                    >
                      {error}
                    </p>
                  ))
                )}
              </div>
            )} */}
            <button
              className="next-button"
              style={{
                fontSize: "13px",
                width: "18%",
               
              }}
              onClick={handleCreateItem}
         //     disabled={descriptionError || serialNumberError}
            >
              <Modal
                show={showAddSuccessModal}
                //      onHide={handleCloseSuccessSiteModal}
                centered
              >
                <Modal.Body>
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="close"
                      aria-label="Close"
                      onClick={handleCloseSuccessSiteModal}
                      style={{
                        background: "none",
                        border: "none",
                        fontSize: "20px",
                        position: "absolute",
                      }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <AddItemSuccessModal />
                </Modal.Body>
              </Modal>
              {/* <Modal show={} onHide={""} centered> */}
              {/* <AddLocationFailedModal /> */}
              {/* </Modal> */}
              <Modal
                show={showFailedModal}
                onClose={handleCloseFailedModal}
                //     onHide={handleCloseFailedModal}
                toggleAddSiteInLocationModal={toggleAddSiteInLocationModal}
                onFailedAddSite={() => {
                  setShowFailedModal(false);
                }}
                centered
              >
                <Modal.Body>
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="close"
                      aria-label="Close"
                      onClick={handleCloseFailedModal}
                      style={{
                        background: "none",
                        border: "none",
                        fontSize: "20px",
                        position: "absolute",
                      }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <FailedAddItemModal onClose={toggleFailedAddItemModal} />
                </Modal.Body>
              </Modal>
              Add Item
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddItemModal;
