import React from "react";
import { useNavigate } from "react-router-dom";
import { CircleX } from "../../../fontAwesomeComponents/FaIcons";
import { useSelector } from "react-redux";
import { publicRequest } from "../../../requestMethods";

const ConfirmArchiveNoteModal = ({
  onClose,
  tagId,
  attachment,
  refreshNotes,
  note
}) => {
  const token = useSelector((state) => state.user.token);
  const userId = useSelector((state) => state.user.id);
  const selectedCompanyId = useSelector((state) => state.selectedCompanyId);
  const selectedUserCompanyId = useSelector((state) => state.selectedCompany);

  console.log(attachment);

  const navigate = useNavigate();

  const handleBackToProfile = () => {
    onClose();
  };

  const ArchiveTagAttachment = async () => {
    const ArchiveAttachmentParams = {
      LoggedUserId: userId,
      LoggedUserCompanyId: selectedUserCompanyId,
    };

    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const requestConfigForArchiveTag = {
        method: "DELETE",
        headers: headers,
        params: ArchiveAttachmentParams,
      };

      const archiveAttachment = await publicRequest(
        `/tag/${tagId.id}/attachment/${note.tagAttachmentId}/archive/${note.id}`,
        requestConfigForArchiveTag
      );

      if (archiveAttachment) {
        console.log("attachmentremoved");
        refreshNotes();
      }

      handleBackToProfile();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      style={{
        height: "37vh",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div
          style={{
            marginBottom: "20px",
            backgroundColor: "#EFC8C8",
            padding: "10px",
            borderRadius: "16px",
          }}
        >
          <CircleX height="50px" width="50px" color="#DC3545" />
        </div>

        <h2
          style={{
            fontFamily: "futuraheavy",
            fontSize: "25px",
            marginBottom: "20px",
            textAlign: "center",
            paddingRight: "20px",
            paddingLeft: "20px",
          }}
        >
          Are you sure you want to delete this note?
        </h2>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "50%",
          }}
        >
          <button
            className="back-to-login-button"
            onClick={ArchiveTagAttachment}
            style={{
              backgroundColor: "#28A745",
              width: "100px",
              fontFamily: "futurabook",
              color: "#FFFFFF",
              height: "50px",
              border: "none",
              borderRadius: "10px",
            }}
          >
            Confirm
          </button>
          <button
            className="back-to-login-button"
            onClick={handleBackToProfile}
            style={{
              backgroundColor: "#eeeeee",
              width: "100px",
              fontFamily: "futurabook",
              color: "#303841",
              height: "50px",
              border: "none",
              borderRadius: "10px",
            }}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmArchiveNoteModal;
