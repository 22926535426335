import React from "react";
import { FaApple, FaGooglePlay } from "react-icons/fa";
import "../../css/homecss/AdditionalFeatures.css";

const AdditionalFeatures = () => {

  const handleClick = (url) => {
    window.open(url, "_blank", "noopener noreferrer");
  };

  const handleClick2 = (url) => {
    window.open(url, "_blank", "noopener noreferrer");
  };



  return (
    <div className="additionalFeatures">
      <div
        className="feature-header"
        style={{
          marginLeft: "10%",
          marginRight: "10%",
          marginTop: "30px",
        }}
      >
        <div className="feature-content">
          <h2 className="text-light feature-title">
            Gain access to a range of additional features.
          </h2>
          <p className="featuretext">
            When used with the QR Coded Secure Centralised Database, all the
            User entered information is available from corporate, right through
            to the Electrician and end user (if applicable / permitted) Storage
            of everything from site name and exact location address, mapped GPS
            location, model and serial number, pictures, fault description, with
            reporting, is also included within the Safe T Tag app; all stored
            for a minimum 12 months at no additional charge on our secured
            online database, unless a longer paid Period Subscription Contract
            is approved. The QR Coded Secure Centralised Database has tiered
            authorisation levels to limit data and personnel access if and where
            required. This 12-month free subscription is only available to
            licenced Electricians and currently registered Electrical companies.
          </p>

          <div className="app-links">
            <div
              className="app-link-container"
              style={{ height: "150px", width: "300px", cursor:"pointer"  }}
              onClick={() => handleClick("https://apps.apple.com/app/id6467346986")}
            >
              <a
                href="https://www.example.com/appstore"
                target="_blank"
                rel="noopener noreferrer"
              ></a>
            </div>

            <div
              className="googlePlay-link-container"
              style={{ height: "150px", width: "300px", cursor:"pointer" }}
              onClick={() => handleClick2("http://play.google.com/store/apps/details?id=com.safettag.safettag")}
            >
              <a
                href="https://www.example.com/googleplay"
                target="_blank"
                rel="noopener noreferrer"

              ></a>
            </div>
          </div>
        </div>

        <div className="mock-image-container"></div>
      </div>
    </div>
  );
};

export default AdditionalFeatures;
