import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { publicRequest } from "../../../requestMethods";
import AddTagNoteSuccessModal from "../../TagsList/ValidationModals/AddTagNoteSuccessModal";
import {
  RefreshIcon,
  DeleteIcon,
} from "../../../fontAwesomeComponents/FaIcons";
import MapModal from "../../MapComponent/MapModal";
import { Modal } from "react-bootstrap";
import ConfirmArchiveNoteModal from "../ValidationModals/ConfirmArchiveNoteModal";

const ViewItemAttachmentsModal = ({ item, attachment }) => {
  console.log("this is tag", item);
  console.log("this is attachment", attachment);

  const [isArchiveHovered, setIsArchivehovered] = useState(false);
  const [hoveredNote, setHoveredNote] = useState(null);
  const [isRefreshHovered, setIsRefreshHovered] = useState(false);

  const loadMoreNotes = () => {
    const newLoadMoreCount = loadMoreCount + 3; // Load 3 more notes
    setDisplayedNotes(noteList.slice(0, newLoadMoreCount));
    setLoadMoreCount(newLoadMoreCount);
  };

  const formatDate = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${day}/${month}/${year}`;
  };

  const formatTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
  
    // Set the timezone offset to UTC
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  
    // Use toLocaleTimeString to automatically adjust the time to the user's local timezone
    const formattedTime = date.toLocaleTimeString(undefined, {
      hour: 'numeric',
      minute: 'numeric',
    //  second: 'numeric',
      hour12: true, // Use 12-hour format
    });
  
    return formattedTime;
  };

  const transformFilename = (filename) => {
    if (filename.length > 20) {
      const truncatedFilename = filename.slice(0, 17) + "...";
      const fileExtension = filename.split(".").pop();
      return `${truncatedFilename}.${fileExtension}`;
    }
    return filename;
  };

  const [showAddSuccessModal, setShowAddSuccessModal] = useState(false);
  const [showFailedModal, setShowFailedModal] = useState(false);

  const [showArchiveNoteModal, setShowArchiveNoteModal] = useState(false);

  const handleOpenArchiveNote = (note) => {
    setSelectedNote(note);
    setShowArchiveNoteModal(true);
    setHoveredNote(note);
  };

  const handleCloseArchiveNote = () => {
    setShowArchiveNoteModal(false);
    setHoveredNote(null);
  };

  const handleCloseFailedModal = () => {
    setShowFailedModal(false);
  };

  const handleCloseSuccessModal = () => {
    setShowAddSuccessModal(false);
  };

  const handleCloseMapModal = () => {
    setShowMapModal(false);
  };

  const [showMapModal, setShowMapModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);

  const handleOpenMapModal = (note) => {
    setSelectedNote(note);
    setShowMapModal(true);
  };

  const currentUser = useSelector((state) => state.user);
  const userId = useSelector((state) => state.user.id);
  const selectedUserCompanyId = useSelector((state) => state.selectedCompany); //userCompanyId
  const selectedCompanyId = useSelector((state) => state.selectedCompanyId); //companyId
  const token = useSelector((state) => state.user.token);
  const userRole = useSelector((state) => state.user.selectedCompanyRoleId);

  const [noteCount, setNoteCount] = useState(0);
  const [noteList, setNoteList] = useState([]);
  const [displayedNotes, setDisplayedNotes] = useState([]);
  const [loadMoreCount, setLoadMoreCount] = useState(3);
  const [sortOption, setSortOption] = useState("latestToOldest");

  const [currentLatitude, setCurrentLatitude] = useState("");
  const [currentLongitude, setCurrentLongitude] = useState("");
  const [locationPermissionDenied, setLocationPermissionDenied] =
    useState(false);

  const sortNotes = (notes) => {
    if (sortOption === "latestToOldest") {
      return notes.sort(
        (a, b) => new Date(b.dateCreatedUtc) - new Date(a.dateCreatedUtc)
      );
    } else {
      return notes.sort(
        (a, b) => new Date(a.dateCreatedUtc) - new Date(b.dateCreatedUtc)
      );
    }
  };

  const [tagNote, setTagNote] = useState("");

  const handleTagNoteChange = (event) => {
    // Define a regular expression pattern that allows only alphanumeric characters and spaces
    const pattern = /^[a-zA-Z0-9\s]*$/;

    // Check if the input value matches the pattern
    if (pattern.test(event.target.value)) {
      // If it matches, update the tagNote state
      setTagNote(event.target.value);
      setNoteCount(event.target.value.length);
    }
    // You can also display an error message or perform other actions if the input doesn't match the pattern.
  };

  const gettingNotesParams = {
    LoggedUserId: userId,
    LoggedUserCompanyId: selectedUserCompanyId,
    itemId: item.id,
  };

  console.log(gettingNotesParams);
  //
  const fetchData = async (url) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const queryString = new URLSearchParams(gettingNotesParams).toString();
      const fullUrl = url + (queryString ? `?${queryString}` : "");

      const response = await publicRequest({
        method: "GET",
        url: fullUrl,
        headers: headers,
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const getLocation = () => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setCurrentLatitude(position.coords.latitude);
            setCurrentLongitude(position.coords.longitude);
            console.log(currentLatitude);
            console.log(currentLongitude);
          },
          (error) => {
            if (error.code === 1) {
              setLocationPermissionDenied(true);
            }
            console.error("Error getting location:", error);
          }
        );
      } else {
        console.error("Geolocation not available in this browser.");
      }
    };

    // Get location initially
    getLocation();

    // Fetch location every 5 minutes
    const locationInterval = setInterval(getLocation, 5 * 60 * 1000);

    const fetchNoteList = async () => {
      try {
        const attachmentNoteParams = {
          LoggedUserId: userId,
          LoggedUserCompanyId: selectedUserCompanyId,
        };

        const getNoteList = await fetchData(
          `/item/${item.id}/noteattachment/list`,
          attachmentNoteParams
        );
        if (getNoteList) {
          // Sort the notes by dateCreatedUtc based on the selected option
          const sortedNotes = sortNotes(getNoteList);

          setNoteList(sortedNotes);
          // Display the first 3 notes initially
          setDisplayedNotes(sortedNotes.slice(0, loadMoreCount));
          console.log("this is note list", sortedNotes);
        }
      } catch (error) {
        console.error("error fetching notes", error);
      }
    };

    fetchNoteList();

    // Clear location interval on component unmount
    return () => {
      clearInterval(locationInterval);
    };
  }, [item.id, loadMoreCount, sortOption]);

  const refreshNotes = async () => {
    try {
      const attachmentNoteParams = {
        LoggedUserId: userId,
        LoggedUserCompanyId: selectedUserCompanyId,
      };

      const getNoteList = await fetchData(
        `/item/${item.id}/noteattachment/list`,
        attachmentNoteParams
      );

      if (getNoteList) {
        const sortedNotes = sortNotes(getNoteList);

        setNoteList(sortedNotes);

        setDisplayedNotes(sortedNotes.slice(0, loadMoreCount));
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="ModalChildDiv">
      {noteList.length >= 1 && (
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "10px",
              justifyContent: "flex-start",
              marginLeft: "17px",
            }}
          ></div>
          <div className="noteContainer" style={{}}>
            <p
              className="detailsTitle"
              style={{
                fontSize: "22px",

                color: "#141212",
                fontFamily: "futuramedium",
                borderBottom: "1px solid #000",
              }}
            >
              <span
              onMouseEnter={() => setIsRefreshHovered(true)}
              onMouseLeave={() => setIsRefreshHovered(false)}
                style={{
                    cursor:"pointer",
                    position:"relative"
                }}
              >
                <RefreshIcon
                  height="10px"
                  width="10px"
                  onClick={refreshNotes}
                />
                   {isRefreshHovered && (
                          <div
                            style={{
                              position: "absolute",
                              top: "100%",
                              left: "50%",
                              transform: "translateX(-50%)",
                              background: "#ffffff",
                              padding: "2px",
                              borderRadius: "10px",
                              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                              zIndex: 999,
                              width: "70px",
                              fontSize:"10px",
                              fontFamily:"futurabook"
                            }}
                          >
                            Refresh Notes
                          </div>
                        )}
                {" "}
              </span>{" "}
              Attachment Notes:
            </p>
            {displayedNotes
              .sort((a, b) => {
                if (sortOption === "latestToOldest") {
                  return (
                    new Date(b.dateCreatedUtc) - new Date(a.dateCreatedUtc)
                  );
                } else {
                  return (
                    new Date(a.dateCreatedUtc) - new Date(b.dateCreatedUtc)
                  );
                }
              })
              .map((note, index) => (
                <div
                  key={index}
                  style={{
                    padding: "15px  ", // Add a border to the noteContainer
                    display: "flex",
                    justifyContent: "space-between", // Add this line to space content between
                    backgroundColor: "#eeeeee",
                    marginBottom: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <div className="noteContent">
                    <span
                      className="noteText"
                      style={{
                        fontFamily: "futurabook",
                        fontSize: "12px",
                        color: "#707070",
                      }}
                    >
                      {" "}
                      Note:{" "}
                    </span>{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                        fontFamily: "futurabook",
                        fontSize: "13px",
                      }}
                    >
                      {note.note ? `${note.note}` : "-"}{" "}
                    </span>{" "}
                    <br />
                    <span
                      className="noteText"
                      style={{
                        fontFamily: "futurabook",
                        fontSize: "12px",
                        color: "#707070",
                      }}
                    >
                      Attachment:
                    </span>{" "}
                    <span
                      style={{
                        fontFamily: "futurabook",
                        fontWeight: "bold",
                        fontSize: "13px",
                      }}
                    >
                      {transformFilename(note.fileName)}{" "}
                    </span>
                    <div
                      className="noteHeader"
                      style={{
                        marginTop: "2px",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "futurabook",
                          fontSize: "12px",
                          color: "#707070",
                        }}
                      >
                        Saved in:
                        <span
                          onClick={() => handleOpenMapModal(note)}
                          style={{
                            color: "gray",
                            cursor: "pointer",
                            fontFamily: "futuraheavy",
                            fontSize: "12px",
                          }}
                        >
                          &nbsp;Latitude:{note.latitude}, Longitude
                          {note.longitude}&nbsp;
                        </span>
                        on the {formatDate(note.dateCreatedUtc)} at{" "}
                        {formatTime(note.dateCreatedUtc)} by {note.createdBy}
                      </span>
                    </div>
                  </div>
                  <div
                    onMouseEnter={() => setHoveredNote(note)}
                    onMouseLeave={() => setHoveredNote(null)}
                    style={{
                      marginLeft: "25px",
                      cursor: "pointer",
                      position: "relative",
                    }}
                  >
                    <DeleteIcon
                      height="13px"
                      fill={hoveredNote === note ? "black" : "gray"}
                      color={hoveredNote === note ? "black" : "gray"}
                      onClick={() => handleOpenArchiveNote(note)}
                    />
                    {hoveredNote === note && (
                      <div
                        style={{
                          position: "absolute",
                          top: "40%",
                          left: "50%",
                          transform: "translateX(-50%)",
                          background: "#ffffff",
                          padding: "5px",
                          borderRadius: "10px",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                          zIndex: 999,
                          width: "80px",
                          fontSize: "12px",
                        }}
                      >
                        Delete Note
                      </div>
                    )}
                  </div>

                  {selectedNote === note && (
                    <Modal
                      show={showArchiveNoteModal}
                 //     onHide={handleCloseArchiveNote}
                      centered
                    >
                                         <Modal.Body>
                        <div className="d-flex justify-content-end">
                          <button
                            type="button"
                            className="close"
                            aria-label="Close"
                            onClick={handleCloseArchiveNote}
                            style={{
                              background: "none",
                              border: "none",
                              fontSize: "20px",
                              position: "absolute",
                            }}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                      <ConfirmArchiveNoteModal
                        onClose={handleCloseArchiveNote}
                        attachment={attachment}
                        note={selectedNote}
                        refreshNotes={refreshNotes}
                      
                        itemId={item}
                      />
                        </Modal.Body>
                    </Modal>
                  )}

                  {selectedNote === note && (
                    <Modal
                      show={showMapModal}
                      onHide={handleCloseMapModal}
                      centered
                    >
                      <MapModal
                        latitude={note.latitude}
                        longitude={note.longitude}
                      />
                    </Modal>
                  )}
                </div>
              ))}
          </div>
          {noteList.length > loadMoreCount && (
            <div className="load-more">
              <button className="loadmoreButton" onClick={loadMoreNotes}>
                Load More
                <i
                  style={{
                    marginLeft: "7px",
                  }}
                >
                  <RefreshIcon />
                </i>
              </button>
            </div>
          )}
        </div>
      )}

      {noteList.length === 0 && (
        <p
          style={{
            textAlign: "center",
            fontFamily: "futuramedium",
            
          }}
        >
                <span
              onMouseEnter={() => setIsRefreshHovered(true)}
              onMouseLeave={() => setIsRefreshHovered(false)}
                style={{
                    cursor:"pointer",
                    position:"relative"
                }}
              >
                <RefreshIcon
                  height="20px"
                  width="20px"
                  onClick={refreshNotes}
                />
                   {isRefreshHovered && (
                          <div
                            style={{
                              position: "absolute",
                              top: "100%",
                              left: "50%",
                              transform: "translateX(-50%)",
                              background: "#ffffff",
                              padding: "2px",
                              borderRadius: "10px",
                              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                              zIndex: 999,
                              width: "70px",
                              fontSize:"10px",
                              fontFamily:"futurabook"
                            }}
                          >
                            Refresh Notes
                          </div>
                        )}
                {" "}
              </span>{" "}
          No notes Available
        </p>
      )}
    </div>
  );
};

export default ViewItemAttachmentsModal;
