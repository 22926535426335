import React, { useState, useEffect } from "react";
import { Modal, ModalHeader } from "react-bootstrap";
import { UseSelector, useSelector } from "react-redux/es/hooks/useSelector";
import { publicRequest } from "../../requestMethods";
import { FaLocationArrow } from "react-icons/fa";
import SelectSiteLocationModal from "../../modals/SelectSiteLocationModal";
import AddLocationSuccessModal from "./AddLocationSuccessModal";
import AddLocationFailedModal from "./AddLocationFailedModal";
import AddSiteInLocationModal from "./AddSiteInLocationModal";

const AddLocationModal = ({ onClose }) => {
  const currentUser = useSelector((state) => state.user);
  const userId = useSelector((state) => state.user.id);
  const selectedUserCompanyId = useSelector((state) => state.selectedCompany); //userCompanyId
  const selectedCompanyName = useSelector((state) => state.selectedCompanyName);
  const selectedCompanyId = useSelector((state) => state.selectedCompanyId); //companyId
  const token = useSelector((state) => state.user.token);
  const userRole = useSelector((state) => state.selectedCompanyRoleId);

  const [formSubmitted, setFormSubmitted] = useState(false);

  const [showAddSiteModal, setShowAddSiteModal] = useState(false);
  const [selectedSite, setSelectedSite] = useState("");

  const [showAddSiteInLocationModal, setShowAddSiteInLocationModal] =
    useState(false);

  const toggleAddSiteInLocationModal = () => {
    setShowAddSiteInLocationModal(!showAddSiteInLocationModal);
    onClose();
  };

  const fetchSiteList2 = async () => {
    if (userRole === 1 && !selectedCompanyIdFromDropdown) {
      // Don't fetch data if a company is not selected
      return;
    }

    const companyIdToUse =
      userRole === 1 ? selectedCompanyIdFromDropdown : selectedCompanyId;
    const siteResponse = await fetchData(
      `lookup/company/${companyIdToUse}/sites`
    );

    if (siteResponse) {
      setSitesList(siteResponse);
      console.log(sitesList);
    }
  };

  // console.log(userRole);

  const [site, setSite] = useState();
  const [company, setCompany] = useState();
  const [user, setUser] = useState();
  const [addressLine1ErrorMessage, setAddressLine1ErrorMessage] = useState("");
  const [addressLine2ErrorMessage, setAddressLine2ErrorMessage] = useState("");

  ///sitestates
  const [companyIdState, setCompanyIdState] = useState("");
  const [isSelectLocationHovered, setIsSelectLocationHovered] = useState(false);

  //modal
  function handleAddSiteModal(e) {
    const selectedOption = e.target.value;
    if (selectedOption === "Add Site") {
      setShowAddSiteModal(true);
    }
  }

  const handlecloseAddSiteModal = () => {
    setShowAddSiteModal(false);
  };

  const [showAddSuccessModal, setShowAddSuccessModal] = useState(false);

  const handleCloseSuccessSiteModal = () => {
    setShowAddSuccessModal(false);
    onClose();
  };

  const [showSelectLocationModal, setShowSelectLocationModal] = useState(false);

  const handleCloseSelectLocationModal = () => {
    setShowSelectLocationModal(false);
  };

  const handleSelectLocationClick = () => {
    if (navigator.geolocation) {
      // If Geolocation API is supported by the browser
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // If the user allows location access
          setShowSelectLocationModal(true);
        },
        (error) => {
          // If the user denies location access or there's an error
          alert("You need to allow location access to proceed.");
        }
      );
    } else {
      // If Geolocation API is not supported by the browser
      alert("Geolocation is not supported by your browser.");
    }
  };

  const [showFailedModal, setShowFailedModal] = useState(false);

  const handleCloseFailedModal = () => {
    setShowFailedModal(false);
  };

  const fetchData = async (url) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await publicRequest({
        method: "GET",
        url: url,
        headers: headers,
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const [sitesList, setSitesList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [selectedSiteName, setSelectedSiteName] = useState("");
  const [selectedSiteId, setSelectedSiteId] = useState("");
  const [selectedCompanyIdFromDropdown, setSelectedCompanyIdFromDropdown] =
    useState("");
  const [selectedCompanyNameFromDropdown, setSelectedCompanyNameFromDropdown] =
    useState("");

  console.log(selectedCompanyIdFromDropdown);

  useEffect(() => {
    const fetchSiteList = async () => {
      if (userRole === 1 && !selectedCompanyIdFromDropdown) {
        // Don't fetch data if a company is not selected
        return;
      }

      const companyIdToUse =
        userRole === 1 ? selectedCompanyIdFromDropdown : selectedCompanyId;
      const siteResponse = await fetchData(
        `lookup/company/${companyIdToUse}/sites`
      );

      if (siteResponse) {
        setSitesList(siteResponse);
        console.log(sitesList);
      }
    };

    const fetchCompanies = async () => {
      const companyResponse = await fetchData("lookup/companies");

      if (companyResponse && userRole === 1) {
        setCompanyList(companyResponse);
        console.log(companyList);
      }
    };

    fetchCompanies(); // Fetch companies when the component mounts

    fetchSiteList();
  }, [selectedCompanyIdFromDropdown, userRole]);

  const [siteDetails, setSiteDetails] = useState({
    loggedUserId: userId,
    loggedUserCompanyId: selectedUserCompanyId,
    companyId:
      userRole === 1 ? selectedCompanyIdFromDropdown : selectedCompanyId, // if admin give dropdown of companies and select from those copanies, if company admin use company id from redux
    //   companyId: selectedCompanyId,
    name: "",
    company: selectedCompanyName,
    location: "",
    addressLine1: "",
    addressLine2: "",
    latitude: null,
    longitude: null,

    //input other fields here later
  });

  //creating loc
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);

  const [locationNameErrorMessage, setLocatioNameErrorMessage] = useState("");

  const createLocation = async () => {
    setFormSubmitted(true);
    const data = {
      loggedUserId: userId,
      loggedUserCompanyId: selectedUserCompanyId,
      companyId:
        userRole === 1 ? selectedCompanyIdFromDropdown : selectedCompanyId,
      name: siteDetails.name,
      addressLine1: siteDetails.addressLine1,
      addressLine2: siteDetails.addressLine2,
      latitude: siteDetails.latitude,
      longitude: siteDetails.longitude,
      siteId: selectedSiteId, // Use the selected site's ID
    };

    console.log(data);

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    // console.log(data);

    try {
      const response = await publicRequest({
        method: "POST",
        url: "/location/create",
        data: data,
        headers: headers,
      });
      setShowAddSuccessModal(true);
    } catch (error) {
      setShowFailedModal(true);
      console.error(error);
      console.error(error.response.data.errors);
      setErrorMessage(error.response.data.errors);
      setIsError(true);
    }
  };

  const [selectedLocation, setSelectedLocation] = useState({
    lat: null,
    lng: null,
  });

  const handleLocationSelect = (lat, lng) => {
    setSelectedLocation({ lat, lng });
  };

  const handleInputChange = (field, value) => {
    const alphanumericRegex = /^[a-zA-Z0-9\s]+$/;
    if (field === "name") {
      const alphanumericRegex = /^[a-zA-Z0-9\s]+$/;
      if (!alphanumericRegex.test(value)) {
        setLocatioNameErrorMessage({
          name: "Location Name must only contain alphanumeric characters.",
        });
        setIsError(true);
      } else {
        setLocatioNameErrorMessage("");
        setIsError(false);
      }
    }

    if (field === "addressLine1") {
      if (!alphanumericRegex.test(value)) {
        setAddressLine1ErrorMessage("Address Line 1 must only contain alphanumeric characters.");
        setIsError(true);
      } else {
        setAddressLine1ErrorMessage("");
        setIsError(false);
      }
    }

    if (field === "addressLine2") {
      if (!alphanumericRegex.test(value)) {
        setAddressLine2ErrorMessage("Address Line 2 must only contain alphanumeric characters.");
        setIsError(true);
      } else {
        setAddressLine2ErrorMessage("");
        setIsError(false);
      }
    }

    setSiteDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const onSelectLocation = (lat, lng) => {
    // This function will be called from SelectSiteLocationModal
    // with the selected latitude and longitude
    setSiteDetails((prevDetails) => ({
      ...prevDetails,
      latitude: lat,
      longitude: lng,
    }));
  };

  return (
    <div className="ModalChildDiv">
      <div
        style={{
          alignItems: "center",
          display: "flex",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <div
          className="modal-content custom-modal-content"
          style={{
            padding: "10px",
            paddingLeft: "20px",
            paddingRight: "20px",
            border: "none",
            background: "none",
          }}
        >
          <div className="row">
            <div
              className="finalTitleDiv"
              style={{
                alignItems: "flex-start",
              }}
            >
              <h3
                style={{
                  fontFamily: "futuramedium",
                  fontWeight: "bold",
                  fontSize: "30px",
                  marginTop: "15px",
                }}
              >
                Add Location
              </h3>
            </div>
          </div>
          <div className="finish-row">
            <div className="finish-column wholeRow">
              <p
                className="detailsTitle"
                style={{
                  fontSize: "13px",
                  marginBottom: "5px",
                }}
              >
                Location Name*:
              </p>
              <input
                type="text"
                className="addUserDetails"
                placeholder="Name"
                style={{
                  fontFamily: "futurabook",
                  color: "#141212",
                }}
                required
                value={siteDetails.name}
                onChange={(e) => handleInputChange("name", e.target.value)}
              />
              {siteDetails.name === "" && formSubmitted && (
                <div className="validation-message">
                  Location Name is Required
                </div>
              )}

              {locationNameErrorMessage.name && formSubmitted && (
                <div className="validation-message">
                  {locationNameErrorMessage.name}
                </div>
              )}
            </div>
          </div>

          <div className="finish-row">
            <div className="finish-column wholeRow">
              <p
                className="detailsTitle"
                style={{
                  fontSize: "13px",
                  marginBottom: "5px",
                }}
              >
                Company Name*:
              </p>
              {userRole !== 1 ? (
                <div
                  className="addUserDetails"
                  style={{
                    fontFamily: "futuramedium",
                    color: "#141212",
                    height: "40px",
                    borderTop: "none",
                    borderRight: "none",
                    borderLeft: "none",
                    lineHeight: "40px", // Center vertically
                  }}
                >
                  {selectedCompanyName}
                </div>
              ) : (
                <select
                  type="text"
                  className="addUserDetails"
                  style={{
                    fontFamily: "futuramedium",
                    color: "#141212",
                    height: "40px",
                    borderTop: "none",
                    borderRight: "none",
                    borderLeft: "none",
                  }}
                  value={
                    userRole !== 1
                      ? selectedCompanyName // Use selectedCompanyName when userRole is not 1
                      : selectedCompanyNameFromDropdown
                  }
                  onChange={(e) => {
                    const selectedOption = e.target.value;
                    if (selectedOption === "Select a Company") {
                      // Reset the selected company when "Select a Company" is chosen
                      setSelectedCompanyNameFromDropdown("");
                      setSelectedCompanyIdFromDropdown("");
                    } else {
                      setSelectedCompanyNameFromDropdown(selectedOption);
                      const selectedCompanyDropdown = companyList.find(
                        (company) => company.name === selectedOption
                      );
                      if (selectedCompanyDropdown) {
                        setSelectedCompanyIdFromDropdown(
                          selectedCompanyDropdown.id
                        );
                      }
                    }
                  }}
                >
                  <option value="Select a Company">Select a Company</option>
                  {companyList.map((company) => (
                    <option key={company.id} value={company.name}>
                      {company.name}
                    </option>
                  ))}
                </select>
              )}
              {userRole === 1 &&
                selectedCompanyIdFromDropdown === "" &&
                formSubmitted && (
                  <div className="validation-message">
                    {" "}
                    Please Select a Company
                  </div>
                )}
            </div>
          </div>
          <div className="finish-row">
            <div className="finish-column wholeRow">
              <p
                className="detailsTitle"
                style={{
                  fontSize: "13px",
                  marginBottom: "5px",
                }}
              >
                Site Name*:
              </p>
              <select
                onClick={handleAddSiteModal}
                onBlur={(e) => {
                  // Check if the current value is not a valid site name
                  if (!sitesList.some((site) => site.name === e.target.value)) {
                    setSelectedSiteName("Select A Site");
                    setSelectedSiteId(""); // Optionally reset the selected site ID as well
                  }
                }}
                onFocus={fetchSiteList2}
                onHide={handlecloseAddSiteModal}
                className="addUserDetails"
                style={{
                  fontFamily: "futuramedium",
                  color: "#141212",
                  height: "40px",
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  color:
                    !selectedCompanyIdFromDropdown && userRole !== 2
                      ? "gray"
                      : "#141212",
                }}
                value={
                  siteDetails.company || selectedCompanyIdFromDropdown // bring it back to company if there are any errors
                    ? selectedSiteName
                    : "Loading..."
                }
                onChange={(e) => {
                  const selectedOption = e.target.value;
                  setSelectedSiteName(selectedOption);
                  // Find the corresponding site ID based on the selected site name
                  const selectedSite = sitesList.find(
                    (site) => site.name === selectedOption
                  );
                  if (selectedSite) {
                    setSelectedSiteId(selectedSite.id);
                  }
                }}
                //   disabled={!selectedCompanyIdFromDropdown }
              >
                <option value="">Select a site</option>
                {sitesList.map((site) => (
                  <option key={site.id} value={site.name}>
                    {site.name}
                  </option>
                ))}
                <option value="Add Site">Add Site</option>
              </select>

              {selectedSiteId === "" && formSubmitted && (
                <div className="validation-message">Please Select a Site</div>
              )}

              <Modal
                size="xl"
                show={showAddSiteModal}
                centered
                //   onHide={handlecloseAddSiteModal}
              >
                <Modal.Body>
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="close"
                      aria-label="Close"
                      onClick={handlecloseAddSiteModal}
                      style={{
                        background: "none",
                        border: "none",
                        fontSize: "35px",
                        position: "absolute",
                        zIndex: "999",
                      }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <AddSiteInLocationModal
                    toggleAddSiteInLocationModal={toggleAddSiteInLocationModal}
                    onClose={handlecloseAddSiteModal}
                    onHide={handlecloseAddSiteModal}
                    onSiteAdded={(newSiteName) => {
                      setShowAddSiteModal(false);
                      setSelectedSiteName(newSiteName);
                    }}
                  />
                </Modal.Body>
              </Modal>
            </div>
          </div>

          <div className="finish-row">
            <div className="finish-column">
              <p
                className="detailsTitle"
                style={{
                  fontSize: "13px",
                  marginBottom: "5px",
                }}
              >
                Location Address Line 1*:
              </p>

              <input
                type="text"
                className="addUserDetails"
                placeholder="Address Line 1"
                style={{
                  fontFamily: "futurabook",
                  color: "#141212",
                }}
                value={siteDetails.addressLine1}
                onChange={(e) =>
                  handleInputChange("addressLine1", e.target.value)
                }
                maxLength={30}
              />

              {siteDetails.addressLine1 === "" && formSubmitted && (
                <div className="validation-message">
                  Location Address Line 1 is Required
                </div>
              )}

              {addressLine1ErrorMessage && formSubmitted && (
                <div className="validation-message">
                  {addressLine1ErrorMessage}
                </div>
              )}
            </div>
            <div className="finish-column">
              <p
                className="detailsTitle"
                style={{
                  fontSize: "13px",
                  marginBottom: "5px",
                }}
              >
                Location Address Line 2:
              </p>

              <input
                type="text"
                className="addUserDetails"
                placeholder="Address Line 2"
                style={{
                  fontFamily: "futurabook",
                  color: "#141212",
                }}
                value={siteDetails.addressLine2}
                onChange={(e) =>
                  handleInputChange("addressLine2", e.target.value)
                }
                maxLength={30}
              />

              {addressLine2ErrorMessage && formSubmitted && (
                <div className="validation-message">
                  {addressLine2ErrorMessage}
                </div>
              )}
            </div>
          </div>
          <Modal
            show={showSelectLocationModal}
            onHide={handleCloseSelectLocationModal}
            centered
          >
            <SelectSiteLocationModal
              onSelectLocation={onSelectLocation}
              onClose={handleCloseSelectLocationModal}
            />{" "}
            {/* Pass the function as a prop */}
          </Modal>
          <div className="finish-row">
            <div className="finish-column">
              <p
                className="detailsTitle"
                style={{
                  fontSize: "13px",
                  marginBottom: "5px",
                }}
              >
                Latitude:
              </p>
              <input
                type="text"
                className="addUserDetails"
                placeholder="Latitude"
                style={{
                  fontFamily: "futurabook",
                  color: "#141212",
                }}
                disabled
                value={siteDetails.latitude}
                onChange={(e) => handleInputChange("latitude", e.target.value)}
              />
            </div>
            <div className="finish-column">
              <p
                className="detailsTitle"
                style={{
                  fontSize: "13px",
                  marginBottom: "5px",
                }}
              >
                Longitude:
              </p>
              <input
                type="text"
                className="addUserDetails"
                placeholder="Longitude"
                style={{
                  fontFamily: "futurabook",
                  color: "#141212",
                }}
                disabled
                value={siteDetails.longitude}
                onChange={(e) => handleInputChange("longitude", e.target.value)}
              />
            </div>
            <span
              onMouseEnter={() => setIsSelectLocationHovered(true)}
              onMouseLeave={() => setIsSelectLocationHovered(null)}
              style={{
                position: "absolute",
                left: "94%",
                width: "35px",
                borderRadius: "100px",
                padding: "5px",
                fontSize: "15px",
                background: "#F6C90E",
                border: "none",
                display: "flex",
                justifyContent: "center",
                marginBottom: "25px",
                cursor: "pointer",
              }}
              onClick={handleSelectLocationClick}
            >
              <span
                style={{
                  position: "relative",
                }}
              >
                <FaLocationArrow />
                {isSelectLocationHovered && (
                  <div
                    style={{
                      position: "absolute",
                      top: "100%",
                      left: "50%",
                      transform: "translateX(-50%)",
                      background: "#ffffff",
                      padding: "2px",
                      borderRadius: "10px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      zIndex: 999,
                      width: "90px",
                      fontSize: "12px",
                    }}
                  >
                    Select Location
                  </div>
                )}
              </span>
            </span>
          </div>

          <div
            style={{
              width: "100%",
              justifyContent: "space-between",
              display: "flex",
              marginTop: "20px",
            }}
          >
            <p
              style={{
                fontSize: "11px",
                color: "#141212",
              }}
            >
              The (*) Star denotes that the question must be completed to SAVE
              the data
            </p>
            {/* {isError && errorMessage && (
              <div
                className="error-message"
                style={{
                  color: "red",
                  fontSize: "10px",
                  marginTop: "15px",
                  marginRight: "10px",
                }}
              >
                {typeof errorMessage === "string" ? (
                  <p
                    style={{
                      fontSize: "13px",
                    }}
                  >
                    {errorMessage}
                  </p>
                ) : (
                  Object.values(errorMessage).map((error, index) => (
                    <p
                      style={{
                        fontSize: "13px",
                      }}
                      key={index}
                    >
                      {error}
                    </p>
                  ))
                )}
              </div>
            )} */}
            <button
              className="next-button"
              style={{
                fontSize: "13px",
                width: "18%",
              }}
              onClick={createLocation}
            >
              Add Location
            </button>
            <Modal
              show={showAddSuccessModal}
              //   onHide={handleCloseSuccessSiteModal}
              centered
            >
              <Modal.Body>
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={handleCloseSuccessSiteModal}
                    style={{
                      background: "none",
                      border: "none",
                      fontSize: "20px",
                      position: "absolute",
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <AddLocationSuccessModal
                  onClose={handleCloseSuccessSiteModal}
                />
              </Modal.Body>
            </Modal>
            <Modal
              show={showFailedModal}
              //     onHide={handleCloseFailedModal}
              centered
            >
              <Modal.Body>
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={handleCloseFailedModal}
                    style={{
                      background: "none",
                      border: "none",
                      fontSize: "20px",
                      position: "absolute",
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <AddLocationFailedModal onClose={handleCloseFailedModal} />
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLocationModal;
