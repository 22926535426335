import axios from "axios";
import axiosInstance from "./AxiosInterceptor";

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL

const user = JSON.parse(localStorage.getItem("persist:root"))?.user;
const currentUser = user && JSON.parse(user)?.currentUser;
const accessToken = currentUser?.accessToken || "";

export const publicRequest = axios.create({
    ...axiosInstance,
  baseURL: BASE_API_URL,

})

export const userRequest = axios.create({
  baseURL: BASE_API_URL,
  headers: { Authorization: `bearer ${accessToken}`},
});

