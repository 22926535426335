import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { publicRequest } from "../requestMethods";
import "../css/modalcss/addUserModal.css";
import "bootstrap/dist/css/bootstrap.min.css"; // Make sure to import Bootstrap's CSS

import EditSuccessModal from "./EditSuccessModal";

import { Modal } from "react-bootstrap";
import FailedEditUserModal from "./FailedEditUserModal";
import { format, parseISO } from "date-fns";

const EditUserModal = ({ user }) => {
  console.log("this is user", user);

  const [showEditSuccessModal, setShowEditSuccessModal] = useState(false);
  const [showFailedEditModal, setShowFailedEditModal] = useState(false);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(""); // Initially, no role is selected
  const [dateValidationError, setDateValidationError] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const userRole = useSelector((state) => state.selectedCompanyRoleId);
  const [selectedCompanyIdFromDropdown, setSelectedCompanyIdFromDropdown] =
    useState("");
  const [companyList, setCompanyList] = useState([]);
  

  const [selectedCompanyNameFromDropdown, setSelectedCompanyNameFromDropdown] =
    useState("");

  const handleCloseSuccessUserModal = () => {
    setShowEditSuccessModal(false);
  };

  const handleOpenFailedModal = () => {
    setShowFailedEditModal(true);
  };

  const handleCloseFailedModal = () => {
    setShowFailedEditModal(false);
  };

  const isValidContactNumber = (mobilePhone) => {
    const trimmedNumber = mobilePhone.replace(/\D/g, ""); // Remove non-numeric characters
    if (trimmedNumber.startsWith("61") && trimmedNumber.length === 11) {
      return true;
    } else if (trimmedNumber.startsWith("678") && trimmedNumber.length === 7) {
      return true;
    } else {
      return false;
    }
  };

  const isValidOfficeContactNumber = (officePhone) => {
    const trimmedNumber = officePhone.replace(/\D/g, ""); // Remove non-numeric characters
  
    // Check if the trimmed number starts with valid prefixes and has a valid length
    if (
      (trimmedNumber.startsWith("61") && trimmedNumber.length === 11) ||
      (trimmedNumber.startsWith("64") && trimmedNumber.length === 11) ||
      (trimmedNumber.startsWith("678") && trimmedNumber.length === 7) ||
      trimmedNumber.length === 0 // Allow empty or blank officePhone
    ) {
      return true;
    } else {
      return false;
    }
  };
  
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const [userInput, setUserInput] = useState({
    firstName: "",
    lastName: "",
    mobilePhone: "",
    officePhone: "",
    dob: "",
    email: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    licenseNumber: "",
    company: "",
    currentLocation: "",
    currentSite: "",
  });

  const [userDataLoading, setUserDataLoading] = useState(true);
  const [companyDataLoading, setCompanyDataLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [companyData, setCompanyData] = useState({});
  const token = useSelector((state) => state.user.token);
  const userId = useSelector((state) => state.user.id);
  const selectedCompanyId = useSelector((state) => state.selectedCompany);
  const selectedCompanyName = useSelector((state) => state.selectedCompanyName);
  const selectedUserCompanyId = useSelector((state) => state.selectedCompanyId);
  const [countryStates, setCountryStates] = useState([]);

  const [errorMessage, setErrorMessage] = useState([]);
  const [isError, setIsError] = useState(false);

  //for getting user and company data

  const fetchData = async (url) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await publicRequest({
        method: "GET",
        url: url,
        headers: headers,
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching data", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchUserAndCompanyData = async () => {
      try {
        const userResponse = await fetchData(`/user/${user.id}/profile`);
        const companyResponse = await fetchData(`/company/${user.companyId}`);
        const countryResponse = await fetchData("/lookup/countries");
        const displayRoles = await fetchData("lookup/roles");

        if (userResponse) {
          setUserData(userResponse);
          console.log(userData);
          setUserDataLoading(false);

          setUserInput({
            firstName: userResponse.firstName || "",
            lastName: userResponse.lastName || "",
            mobilePhone: userResponse.mobilePhone || "",
            officePhone: userResponse.phone || "",
            email: userResponse.email || "",
            dob: userResponse.doB
              ? format(parseISO(userResponse.doB), "yyyy-MM-dd")
              : "",
            // officePhone: userResponse.phone || "",
            licenseNumber: userResponse.licenseNumber || "",
            roleId: user.roleId || "",
          });
          //
          setSelectedRole(user.roleId || "");
        }

        console.log("userresponse", userResponse);
        if (companyResponse) {
          setCompanyData(companyResponse);
          setCompanyDataLoading(false);

          setUserData((prevDetails) => ({
            ...prevDetails,
            country: companyResponse.countryId,
            officePhone: companyResponse.officePhone,
            addressLine1: companyResponse.addressLine1,
            addressLine2: companyResponse.addressLine2,
            city: companyResponse.city,
            state: companyResponse.stateId,
            postalCode: companyResponse.postCode,
            roleId: user.roleId,
          }));

          console.log(companyResponse);

          const companyCountry = countryResponse.find(
            (country) => country.id === companyResponse.countryId
          );
          if (companyCountry) {
            setUserData((prevDetails) => ({
              ...prevDetails,
              country: companyCountry.name,
            }));
          }

          const statesResponse = await fetchData(
            `/lookup/country/${companyResponse.countryId}/states`
          );
          if (statesResponse) {
            setCountryStates(statesResponse);
            const selectedState = statesResponse.find(
              (state) => state.id === companyResponse.stateId
            );
            if (selectedState) {
              setUserData((prevDetails) => ({
                ...prevDetails,
                state: selectedState.name,
              }));
            }
          }

          if (displayRoles) {
            setUserData((prevDetails) => ({
              ...prevDetails,
              roleId: displayRoles.name,
            }));

            console.log(displayRoles);
          }

          const roleDropdown = await fetchData("/lookup/roles");
          if (roleDropdown) {
            setRoles(roleDropdown);
          }
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchUserAndCompanyData();
  }, [user.id, user.companyId]);

  useEffect(() => {
    if (!userRole === 1 && !selectedCompanyIdFromDropdown) {
      return;
    }

    const fetchCompanies = async () => {
      const companyResponse = await fetchData("lookup/companies");

      if (companyResponse && userRole === 1) {
        setCompanyList(companyResponse);
        console.log(companyList);
      }
    };

    fetchCompanies(); // Fetch companies when the component mounts

    fetchCompanies(); // Fetch companies when the component mounts
  }, [selectedCompanyIdFromDropdown, userRole]);

  const handleRoleChange = (e) => {
    const selectedRoleId = e.target.value;
    setSelectedRole(selectedRoleId);

    setUserData((prevDetails) => ({
      ...prevDetails,
      roleId: selectedRoleId,
    }));
  };

  const formatDateForSending = (dateString) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    let month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    let day = date.getUTCDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const updateUser = async () => {
    // Check if all required fields are filled
    if (
      userInput.firstName === "" ||
      userInput.lastName === "" ||
      userInput.mobilePhone === "" ||
      userInput.dob === "" ||
      userInput.email === "" ||
      userInput.licenseNumber === ""
    ) {
      setFormSubmitted(true);
      return; // Exit the function if validation fails
    }
  
    setFormSubmitted(true);
  
    // Check if mobilePhone and officePhone are valid
    if (!isValidContactNumber(userInput.mobilePhone)) {
      setIsError(true);
      return;
    }
  
    if (userInput.officePhone !== "" && !isValidOfficeContactNumber(userInput.officePhone)) {
      setIsError(true);
      return;
    }
  
    // Check if mobilePhone starts with "61" or "678"
    if (
      userInput.mobilePhone.startsWith("61") ||
      userInput.mobilePhone.startsWith("678")
    ) {
      userInput.mobilePhone = "+" + userInput.mobilePhone;
    }
  
    const selectedDate = new Date(userInput.dob);
    const fifteenYearsAgo = new Date();
    fifteenYearsAgo.setFullYear(fifteenYearsAgo.getFullYear() - 15);
  
    selectedDate.setFullYear(selectedDate.getFullYear());
  
    if (selectedDate > fifteenYearsAgo) {
      setErrorMessage(["User must be 15 years old or older."]);
      setIsError(true);
      return; // Exit the function if validation fails
    }
  
    const data = {
      loggedUserId: userId,
      loggedUserCompanyId: selectedCompanyId,
      userId: user.id,
      userCompanyId: selectedCompanyIdFromDropdown || user.userCompanyId,
      firstName: userInput.firstName,
      lastName: userInput.lastName,
      email: userInput.email,
      dob: selectedDate.toISOString().split("T")[0], // Format date for sending without timezone conversion
      mobilePhone: userInput.mobilePhone,
      licenseNumber: userInput.licenseNumber,
      roleId: userData.roleId || userInput.roleId,
      isActive: user.isActive,
      officePhone: userInput.officePhone,
    };
  
    console.log("this is data", data);
    console.log("this is selectedCompanyIdFromDropdown", selectedCompanyIdFromDropdown);
  
    const headers = {
      Authorization: `Bearer ${token}`,
    };
  
    try {
      const response = await publicRequest({
        method: "POST",
        url: `/user/${user.id}/update`,
        data: data,
        headers: headers,
      });
  
      setShowEditSuccessModal(true);
      console.log(response.data);
    } catch (error) {
      setShowFailedEditModal(true);
      console.error(error.response.data.errors);
      if (error.response.status === 409) {
        setErrorMessage("Email already exists");
        setIsError(true);
      } else {
        setErrorMessage(error.response.data.errors);
        setIsError(true);
      }
      console.log("this is error message", errorMessage);
    }
  };
  

  const handleInputChange = (field, value) => {
    // Check if the field is mobilePhone or officePhone
      // Check if the field is mobilePhone or officePhone
      if (field === "mobilePhone" || field === "officePhone") {
        // Allow the '+' sign to be typed
        if (value === "+" || value === "") {
          setUserInput((prevDetails) => ({
            ...prevDetails,
            [field]: value,
          }));
        } else {
          // Remove non-numeric characters from the input
          const numericValue = value.replace(/\D/g, "");
          // Add the '+' sign if the value is not empty
          const formattedValue = numericValue ? "+" + numericValue : "";
          setUserInput((prevDetails) => ({
            ...prevDetails,
            [field]: formattedValue,
          }));
        }
      } else {
        // For other fields, update the state normally
        setUserInput((prevDetails) => ({
          ...prevDetails,
          [field]: value,
        }));
      }
    
  };

  return (
    <div className="ModalChildDiv"
      style={{
        alignItems: "center",
        display: "flex",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <div
        className="modal-content custom-modal-content"
        style={{
          padding: "10px",
          paddingLeft: "20px",
          paddingRight: "20px",
          background: "none",
          border: "none",
        }}
      >
        <div className="row">
          <div
            className="finalTitleDiv"
            style={{
              alignItems: "flex-start",
            }}
          >
            <h3
              style={{
                fontFamily: "futuramedium",
                fontWeight: "bold",
                fontSize: "30px",
                marginTop: "15px",
              }}
            >
              Edit User
            </h3>
          </div>
        </div>
        <div className="finish-row">
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              First Name*:
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
                fontSize: "16.5px",
              }}
              value={
                userDataLoading || companyDataLoading
                  ? "Loading..."
                  : userInput.firstName
              }
              onChange={(e) => handleInputChange("firstName", e.target.value)}
            />
            {userInput.firstName === "" && formSubmitted && (
              <div className="validation-message" style={{}}>
                First Name is required
              </div>
            )}
          </div>
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Last Name*:
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
                fontSize: "16.5px",
              }}
              value={userInput.lastName}
              onChange={(e) => handleInputChange("lastName", e.target.value)}
            />
            {userInput.lastName === "" && formSubmitted && (
              <div className="validation-message" style={{}}>
                Last Name is required
              </div>
            )}
          </div>
        </div>
        {userRole === 1 && (
          <div className="finish-row">
            <div className="finish-column wholeRow">
              <p
                className="detailsTitle"
                style={{
                  fontSize: "13px",
                  marginBottom: "5px",
                }}
              >
                Company Name*:
              </p>
              <select
                className="addUserDetails"
                style={{
                  fontFamily: "futuramedium",
                  color: "#141212",
                  height: "40px",
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                }}
                value={
                  user.companyName !== ""
                    ? selectedCompanyNameFromDropdown || user.companyName
                    : selectedCompanyNameFromDropdown
                }
                onChange={(e) => {
                  const selectedOption = e.target.value;
                  if (selectedOption === "Select a Company") {
                    setSelectedCompanyNameFromDropdown("");
                    setSelectedCompanyIdFromDropdown("");
                  } else {
                    setSelectedCompanyNameFromDropdown(selectedOption);
                    const selectedCompanyDropdown = companyList.find(
                      (company) => company.name === selectedOption
                    );
                    if (selectedCompanyDropdown) {
                      setSelectedCompanyIdFromDropdown(
                        selectedCompanyDropdown.id
                      );
                    }
                  }
                }}
              >
                <option value="">Select a Company</option>
                {companyList.map((company) => (
                  <option key={company.id} value={company.name}>
                    {company.name}
                  </option>
                ))}
              </select>

              {/* {selectedCompanyIdFromDropdown === "" && formSubmitted && (
                <div className="validation-message">
                  Please Select a Company
                </div>
              )} */}
            </div>
          </div>
        )}
        <div className="finish-row">
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Master License Number*:
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
              value={userInput.licenseNumber}
              onChange={(e) =>
                handleInputChange("licenseNumber", e.target.value)
              }
            />
            {userInput.licenseNumber === "" && formSubmitted && (
              <div className="validation-message" style={{}}>
                Master License number is required
              </div>
            )}
          </div>

          <div>
            <p
              className="detailsTitle"
              style={{ fontSize: "13px", marginBottom: "5px" }}
            >
              Role*:
            </p>
            <select
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                fontWeight: "bold",
                color: "#141212",
                height: "30px",
                borderLeft: "none",
                borderRight: "none",
                borderTop: "none",
              }}
              value={selectedRole}
              onChange={handleRoleChange}
            >
              <option value="">Select a Role</option>
              {userRole === 1
                ? // Render all roles without slicing
                  roles.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))
                : // Render roles except the first one after slicing
                  roles.slice(1).map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
            </select>
            {selectedRole === "" && formSubmitted && (
              <div className="validation-message">Please Select a Role</div>
            )}
          </div>
        </div>
        <div className="finish-row">
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Mobile Phone Number*:
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
              value={userInput.mobilePhone}
              onChange={(e) => {
                const inputValue = e.target.value;
                const strippedValue = inputValue.replace("+", ""); // Remove the "+" character
                handleInputChange("mobilePhone", strippedValue);
              }}
              maxLength={12}
            />

            {formSubmitted && userInput.mobilePhone === "" && (
              <div className="validation-message" style={{ marginTop: "5px" }}>
                Mobile Phone number is required
              </div>
            )}
            {formSubmitted &&
              userInput.mobilePhone !== "" &&
              !isValidContactNumber(userInput.mobilePhone) && (
                <div className="validation-message" style={{}}>
                  Please enter a valid Mobile Phone number
                </div>
              )}
          </div>

          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Phone Number:
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
              value={userInput.officePhone}
              onChange={(e) => {
                const inputValue = e.target.value;
                const strippedValue = inputValue.replace("+", ""); // Remove the "+" character
                handleInputChange("officePhone", strippedValue);
              }}
              maxLength={12}
            />
            {formSubmitted &&
              userInput.officePhone !== "" &&
              !isValidOfficeContactNumber(userInput.officePhone) && (
                <div className="validation-message" style={{}}>
                  Please enter a valid Phone number
                </div>
              )}
          </div>
        </div>
        <div className="finish-row">
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Date of Birth*:
            </p>
            <input
              type="date"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
              value={
                (userData.doB instanceof Date
                  ? userData.doB.toISOString().split("T")[0]
                  : null) || userInput.dob
              }
              onChange={(e) => handleInputChange("dob", e.target.value)}
              max="9999-12-31" // Set max attribute to prevent future dates
              maxLength={10} // Set maxLength to 10 to restrict the length of input
            />

            {userInput.dob === "" && formSubmitted && (
              <div className="validation-message" style={{}}>
                Date of Birth is required
              </div>
            )}
            {isError && formSubmitted && (
              <div
                className="validation-message"
                style={{
                  marginTop: "0.5px",
                }}
              >
                {errorMessage}
              </div>
            )}
          </div>
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Email Address*:
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
              value={userInput.email}
              onChange={(e) => handleInputChange("email", e.target.value)}
            />
            {userInput.email === "" && formSubmitted && (
              <div
                className="validation-message"
                style={
                  {
                    // Add your custom styles here
                  }
                }
              >
                Email is required
              </div>
            )}
            {formSubmitted &&
              userInput.email !== "" &&
              !isValidEmail(userInput.email) && (
                <div
                  className="validation-message"
                  style={
                    {
                      /* Add your custom styles here */
                    }
                  }
                >
                  Please enter a valid email
                </div>
              )}
            {isError && errorMessage === "Email already exists" && (
              <div
                className="validation-message"
                style={
                  {
                    /* Add your custom styles here */
                  }
                }
              >
                Email already exists
              </div>
            )}
          </div>
        </div>
        <div className="finish-row"></div>

        <div className="finish-row">
          {/* <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Current Location:
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
            />
          </div>
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Current Site:
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
            />
          </div> */}
        </div>
        <div
          style={{
            width: "100%",
            justifyContent: "flex-end",
            display: "flex",
            marginTop: "20px",
          }}
        >
          {/* {isError && errorMessage && (
            <div
              className="error-message"
              style={{
                color: "red",
                fontSize: "10px",
                marginTop: "15px",
                marginRight: "10px",
              }}
            >
              {typeof errorMessage === "string" ? (
                <p
                  style={{
                    fontSize: "13px",
                  }}
                >
                  {errorMessage}
                </p>
              ) : (
                Object.values(errorMessage).map((error, index) => (
                  <p
                    style={{
                      fontSize: "13px",
                    }}
                    key={index}
                  >
                    {error}
                  </p>
                ))
              )}
            </div>
          )} */}
          <button
            className="next-button"
            style={{
              fontSize: "13px",
              width: "18%",
            }}
            onClick={updateUser}
          >
            Save
          </button>
          <Modal
            show={showEditSuccessModal}
            //     onHide={handleCloseSuccessUserModal}
            cente
          >
            <Modal.Body>
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={handleCloseSuccessUserModal}
                  style={{
                    background: "none",
                    border: "none",
                    fontSize: "35px",
                    position: "absolute",
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <EditSuccessModal onClose={handleCloseSuccessUserModal} />
            </Modal.Body>
          </Modal>

          <Modal
            show={showFailedEditModal}
            //  onHide={handleCloseSuccessUserModal}
            centered
          >
            <Modal.Body>
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={handleCloseFailedModal}
                  style={{
                    background: "none",
                    border: "none",
                    fontSize: "35px",
                    position: "absolute",
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <FailedEditUserModal onClose={handleCloseFailedModal} />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default EditUserModal;
