import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import {
  RegularTag,
  RegularLightBulb,
  RegularBuilding,
  RegularUserGear,
  RegularBarcode,
} from "../../fontAwesomeComponents/FaIcons";
import "../../css/userApprovalcss/userApproval.css";
import { Modal } from "react-bootstrap";
import QrScanner from "../QRScanner/QrScanner";
import { useSelector } from "react-redux";

const UserApprovalBar = ({ searchQuery, onSearch }) => {
  // search
  const [searchInput, setSearchInput] = useState("");

  const handleSearch = () => {
    // Pass the searchInput value to onSearch
    onSearch(searchInput);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  //modal

  const [showQrModal, setShowQrModal] = useState(false);

  const handleCloseQrModal = () => {
    setShowQrModal(false);
  };

  const userRole = useSelector((state) => state.selectedCompanyRoleId);

  const isUserAdmin = userRole == "1" || userRole == "2" || userRole == "3";

  const isUserActuallyAdmin = userRole == "1" || userRole == "2";

  const isSafeTTagadmin = userRole == "1";

  const location = useLocation();
  const isTags =
    location.pathname === "/" ||
    location.pathname === "/tags/archive" ||
    location.pathname === "/tags/deleted";
  const isItems =
    location.pathname === "/items" ||
    location.pathname === "/items/archive" ||
    location.pathname === "/items/deleted";
  const isCompanyLink =
    location.pathname === "/companies" ||
    location.pathname === "/sites" ||
    location.pathname === "/locations";
  const isAdminLinks =
    location.pathname === "/userlist" ||
    location.pathname === "/userapproval" ||
    location.pathname === "/audittrail" ||
    location.pathname === "/userLogs";
  const isCompany = location.pathname === "/companies";
  const isSites = location.pathname === "/sites";
  const isLocation = location.pathname === "/locations";

  const isUserList = location.pathname === "/userlist";
  const isUserApproval = location.pathname === "/userapproval";
  const isAuditTrail = location.pathname === "/audittrail";
  const isUserLogs = location.pathname === "/userLogs";

  return (
    <div
      className="userapprovalbar"
      style={{ display: "flex", backgroundColor: "#FFFFFF", height: "60px" }}
    >
      <div className="approvalBarContents"
        style={{
          marginLeft: "150px",
          marginRight: "150px",
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Link
          to="/"
          style={{
            textDecoration: "none",
            fontFamily: "futuraheavy",
            fontSize: "15px",
            marginRight: "30px",
            position: "relative", // Make the parent element relative for positioning the pseudo-element
            color: isTags ? "#f6c90e" : "#141212", // Conditionally set the text color
          }}
        >
          <RegularTag
            width="25"
            height="25"
            isActive={isTags}
            className="iconBar"
          />
          TAGS
          {isTags && (
            <span
              style={{
                content: "''",
                display: "block",
                width: "110%",
                height: "5px",
                backgroundColor: "#f6c90e",
                position: "absolute",
                bottom: "-18px",
                left: "0",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            />
          )}
        </Link>

        <Link
          to="/items"
          style={{
            textDecoration: "none",
            fontFamily: "futuraheavy",
            fontSize: "15px",
            marginRight: "10px",
            position: "relative",
            color: isItems ? "#f6c90e" : "#141212",
          }}
        >
          <RegularLightBulb
            width="25"
            height="25"
            isActive={isItems}
            className="iconBar"
          />
          ITEMS
          {isItems && (
            <span
              style={{
                content: "''",
                display: "block",
                width: "110%",
                height: "5px",
                backgroundColor: "#f6c90e",
                position: "absolute",
                bottom: "-18px",
                left: "0",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            />
          )}
        </Link>

        <Dropdown className="">
          <Dropdown.Toggle
            className="approval-dropdown"
            style={{
              textDecoration: "none",
              fontFamily: "futuraheavy",
              color: isCompanyLink ? "#f6c90e" : "#141212",
              fontSize: "15px",
              marginRight: "10px",
            }}
          >
            <RegularBuilding
              width="25"
              height="25"
              isActive={isCompanyLink}
              className="iconBar"
            />
            COMPANY
            {isCompanyLink && (
              <span
                style={{
                  content: "''",
                  display: "block",
                  width: "85%",
                  height: "5px",
                  backgroundColor: "#f6c90e",
                  position: "absolute",
                  bottom: "-10px",
                  left: "8px",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                }}
              />
            )}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {isSafeTTagadmin && (
              <Dropdown.Item
                href="/companies"
                style={{
                  //fontWeight: isCompany ? "bold" : "",
                  fontFamily: isCompany ? "futuraheavy" : "",
                }}
              >
                {" "}
                Companies{" "}
              </Dropdown.Item>
            )}

            <Dropdown.Item
              href="/sites"
              style={{
                fontFamily: isSites ? "futuraheavy" : "",
              }}
            >
              Sites
            </Dropdown.Item>
            <Dropdown.Item
              href="/locations"
              style={{
                fontFamily: isLocation ? "futuraheavy" : "",
              }}
            >
              Locations
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {isUserAdmin && (
          <Dropdown className="">
            <Dropdown.Toggle
              className="approval-dropdown"
              style={{
                textDecoration: "none",
                fontFamily: "futuraheavy",
                color: isAdminLinks ? "#f6c90e" : "#141212",
                fontSize: "15px",
                marginLeft: "-10px",
              }}
            >
              <RegularUserGear
                width="25"
                height="25"
                isActive={isAdminLinks}
                className="iconBar"
              />
              ADMIN
              {isAdminLinks && (
                <span
                  style={{
                    content: "''",
                    display: "block",
                    width: "100%",
                    height: "5px",
                    backgroundColor: "#f6c90e",
                    position: "absolute",
                    bottom: "-10px",
                    left: "-5px",

                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                />
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                href="/userlist"
                style={{
                  fontFamily: isUserList ? "futuraheavy" : "",
                }}
              >
                User List
              </Dropdown.Item>
              <Dropdown.Item
                href="/userapproval"
                style={{
                  fontFamily: isUserApproval ? "futuraheavy" : "",
                }}
              >
                User Approval
              </Dropdown.Item>
              {isUserActuallyAdmin && (
                <Dropdown.Item
                  href="/audittrail"
                  style={{
                    fontFamily: isAuditTrail ? "futuraheavy" : "",
                  }}
                >
                  Audit Trail
                </Dropdown.Item>
              )}

              {isSafeTTagadmin && (
                <Dropdown.Item
                  href="/userLogs"
                  style={{
                    fontFamily: isUserLogs ? "futuraheavy" : "",
                  }}
                >
                  User Logs
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        )}

        <div
          className="userSearch"
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "auto", // Align to the right
          }}
        >
          {/* <button
            style={{
              background: "none",
              border: "none",
            }}
            onClick={setShowQrModal}
          >
            <RegularBarcode width="35" height="35" />
          </button>
          <Modal show={showQrModal} onHide={handleCloseQrModal} centered>
            <QrScanner />
          </Modal> */}
          <div>
            <input
              className="searchBoxUA"
              type="text"
              placeholder="Search"
              style={{
                fontSize: "15px", // Change the font size of the input
                border: "none", // Remove the default border
                borderBottom: "1px solid #3A4750", // Add an underline below
                padding: "5px", // Add some padding for spacing
                outline: "none", // Remove the default outline
                width: "450px", // Adjust the width as needed
                marginLeft: "10px", // Add some margin between the icon and the input
                fontFamily: "futurabook",
                fontWeight: "light",
              }}
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <FaSearch
              className="user-search-icon"
              onClick={handleSearch}
              style={{ opacity: "100%", cursor: "pointer" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserApprovalBar;
