import React, { useState } from "react";
import UserApprovalTable from '../components/UserApproval/UserApprovalTable';
import Navbar from '../components/NavBar';
import UserApprovalBar from '../components/UserApproval/UserApprovalBar';

const UserApproval = () => {

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (query) => {
    // Update the search query state
    setSearchQuery(query);
  };

  return (
    <div
    style={{
        backgroundColor:"#EEEEEE", height:"100%", 
    }}
>
    
        <Navbar/>

        <UserApprovalBar  searchQuery={searchQuery} onSearch={handleSearch}/>
        <UserApprovalTable  searchQuery={searchQuery}/>
    </div>

  )
}

export default UserApproval;