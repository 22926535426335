import React from 'react'
import { FaFacebookF, FaTwitter, FaInstagram, FaGoogle, FaYoutube, FaLinkedin } from 'react-icons/fa';
import { XTwitter } from "../../fontAwesomeComponents/FaIcons"


const PrivacyPolicyFooter = () => {
  return (
    <div className='privacypolicyfooter'
        style={{marginLeft:"15%", marginRight:"15%", padding:"15px"}}
    >
    <div
        style={{ justifyContent:"space-between", display:"flex", width:"350px", marginBottom:"7px", marginTop:"40px"}}
    >
      {/* Social media logos */}
      <FaFacebookF color="#536672" size={35}
      />
      <XTwitter color="#536672" size={35} />
      <FaInstagram color="#536672" size={35} />
      {/* <FaGoogle color="#536672" size={35} /> */}
      <FaYoutube color="#536672" size={35} />
      <FaLinkedin color="#536672" size={35} />
    </div>
    <p>
    <span className="footer-links"
  style={{
    fontFamily: "futuraheavy",
    color: "#536672",
    fontSize: "13px",
  }}
>
  <a style={{ color: "#536672" }}>
    Safe T Tag{" "}
  </a>

  <a href="/privacypolicy" style={{ color: "#536672" }}>
    Privacy Policy
  </a>  and{" "}
  <a href='/termsandconditions'  style={{ color: "#536672" }}>Terms & Conditions</a> 
  <br />
  © Copyright Safe T Tag 2024 All rights reserved
</span>

    </p>
  </div>

  )
}

export default PrivacyPolicyFooter