import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CircleX } from '../../../fontAwesomeComponents/FaIcons';

const FailedUndeleteTag = ({ onClose }) => {
  const navigate = useNavigate();

  const handleBackToProfile = () => {
    onClose();
  };

  const location = useLocation();
  const isTagsPage = location.pathname === '/tags/deleted';
  const isItemsPage = location.pathname === '/items/deleted';

  const message = isTagsPage ? 'Failed to Delete Tag' : isItemsPage ? 'Failed to Delete Item' : '';

  return (
    <div
      style={{
        height: '37vh',
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <div
          style={{
            marginBottom: '20px',
            backgroundColor: '#EFC8C8',
            padding: '10px',
            borderRadius: '16px',
          }}
        >
          <CircleX height="50px" width="50px" color="#DC3545" />
        </div>

        <h2
          style={{
            fontFamily: 'futuraheavy',
            fontSize: '25px',
            marginBottom: '20px',
          }}
        >
          {message}
        </h2>

        <button
          className="back-to-login-button"
          onClick={handleBackToProfile}
          style={{
            backgroundColor: '#DC3545',
            width: '80%',
            fontFamily: 'futurabook',
            color: '#FFFFFF',
            height: '50px',
            border: 'none',
            borderRadius: '10px',
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default FailedUndeleteTag;
