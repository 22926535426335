import React, { useEffect, useState } from "react";
import AddTagNoteSuccessModal from "../TagsList/ValidationModals/AddTagNoteSuccessModal";
import FailedTagNoteModal from "../TagsList/ValidationModals/FailedTagNoteModal";
import { useSelector } from "react-redux";
import { publicRequest } from "../../requestMethods";
import { Modal } from "react-bootstrap";
import "../../css/notesCss/TagsCss.css";
import { RefreshIcon } from "../../fontAwesomeComponents/FaIcons";
import MapModal from "../MapComponent/MapModal";

const AddSiteNotes = ({site}) => {

    console.log(site);

    const [showAddSuccessModal, setShowAddSuccessModal] = useState(false);
    const [showFailedModal, setShowFailedModal] = useState(false);
  
    const handleCloseFailedModal = () => {
      setShowFailedModal(false);
    };
  
    const handleCloseMapModal = () => {
      setShowMapModal(false);
    };
  
    const handleCloseSuccessModal = () => {
      setShowAddSuccessModal(false);
    };
  
    const [showMapModal, setShowMapModal] = useState(false);
    const [selectedNote, setSelectedNote] = useState(null);
  
    const [isLoading, setIsLoading] = useState(false);
  
    const handleOpenMapModal = (note) => {
      setSelectedNote(note);
      setShowMapModal(true);
    };
  
    const currentUser = useSelector((state) => state.user);
    const userId = useSelector((state) => state.user.id);
    const selectedUserCompanyId = useSelector((state) => state.selectedCompany); //userCompanyId
    const selectedCompanyId = useSelector((state) => state.selectedCompanyId); //companyId
    const token = useSelector((state) => state.user.token);
    const userRole = useSelector((state) => state.user.selectedCompanyRoleId);
  
    const [noteCount, setNoteCount] = useState(0);
    const [noteList, setNoteList] = useState([]);
    const [displayedNotes, setDisplayedNotes] = useState([]);
    const [loadMoreCount, setLoadMoreCount] = useState(3);
    const [sortOption, setSortOption] = useState("latestToOldest");
  
    const [currentLatitude, setCurrentLatitude] = useState("");
    const [currentLongitude, setCurrentLongitude] = useState("");
    const [locationPermissionDenied, setLocationPermissionDenied] =
      useState(false);
  
    const sortNotes = (notes) => {
      if (sortOption === "latestToOldest") {
        return notes.sort(
          (a, b) => new Date(b.dateCreatedUtc) - new Date(a.dateCreatedUtc)
        );
      } else {
        return notes.sort(
          (a, b) => new Date(a.dateCreatedUtc) - new Date(b.dateCreatedUtc)
        );
      }
    };
  
    const [tagNote, setTagNote] = useState("");
  
    const handleTagNoteChange = (event) => {
      // Define a regular expression pattern that allows only alphanumeric characters and spaces
      const pattern = /^[a-zA-Z0-9\s]*$/;
  
      // Check if the input value matches the pattern
      if (pattern.test(event.target.value)) {
        // If it matches, update the tagNote state
        setTagNote(event.target.value);
        setNoteCount(event.target.value.length);
      }
      // You can also display an error message or perform other actions if the input doesn't match the pattern.
    };
  
    const gettingNotesParams = {
      LoggedUserId: userId,
      LoggedUserCompanyId: selectedUserCompanyId,
      tagId: site.id,
    };
  
    console.log(gettingNotesParams);
    //
    const fetchData = async (url) => {
      try {
        const headers = {
          Authorization: `Bearer ${token}`,
        };
  
        const queryString = new URLSearchParams(gettingNotesParams).toString();
        const fullUrl = url + (queryString ? `?${queryString}` : "");
  
        const response = await publicRequest({
          method: "GET",
          url: fullUrl,
          headers: headers,
        });
  
        return response.data;
      } catch (error) {
        console.error(error);
      }
    };
  
    useEffect(() => {
      const getLocation = () => {
        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              setCurrentLatitude(position.coords.latitude);
              setCurrentLongitude(position.coords.longitude);
              console.log(currentLatitude);
              console.log(currentLongitude);
            },
            (error) => {
              if (error.code === 1) {
                setLocationPermissionDenied(true);
              }
              console.error("Error getting location:", error);
            }
          );
        } else {
          console.error("Geolocation not available in this browser.");
        }
      };
  
      // Get location initially
      getLocation();
  
      // Fetch location every 5 minutes
      const locationInterval = setInterval(getLocation, 5 * 60 * 1000);
  
      const fetchNoteList = async () => {
        try {
    
          const getNoteList = await fetchData(`/site/${site.id}/note/list`);
          if (getNoteList) {
            // Sort the notes by dateCreatedUtc based on the selected option
            const sortedNotes = sortNotes(getNoteList);
  
            setNoteList(sortedNotes);
            // Display the first 3 notes initially
            setDisplayedNotes(sortedNotes.slice(0, loadMoreCount));
            console.log("this is note list", sortedNotes);
          }
        } catch (error) {
          console.error("error fetching notes", error);
        }
      };
  
      fetchNoteList();
  
      // Clear location interval on component unmount
      return () => {
        clearInterval(locationInterval);
      };
    }, [site.id, loadMoreCount, sortOption]);
  
    const refreshNotes = async () => {
      try {
        const getNoteList = await fetchData(`site/${site.id}/note/list`);
        if (getNoteList) {
          const sortedNotes = sortNotes(getNoteList);
          setNoteList(sortedNotes);
          setDisplayedNotes(sortedNotes.slice(0, loadMoreCount));
  
          setTagNote("");
          setNoteCount(0);
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    const addNote = async () => {
      if (!site.isActive) {
        // If the site is not active, display an error message
        alert("Site is not active, cannot add note");
        return;
      }
    
      if (locationPermissionDenied) {
        // Show a message to the user that location access is required
        alert("Please allow location access to proceed.");
        return;
      }
    
      // Proceed with adding the note
      const data = {
        loggedUserId: userId,
        loggedUserCompanyId: selectedUserCompanyId,
        tagId: site.id,
        // serialNumber: site.serialNumber,
        note: tagNote,
        latitude: currentLatitude,
        longitude: currentLongitude,
      };
    
      console.log("this is site note data", data);
    
      const headers = {
        Authorization: `Bearer ${token}`,
      };
    
      try {
        setIsLoading(true);
        const response = await publicRequest({
          method: "POST",
          url: `/site/${site.id}/note/create`,
          data: data,
          headers: headers,
        });
    
        setShowAddSuccessModal(true);
      } catch (error) {
        console.log(error);
        setShowFailedModal(true);
      } finally {
        setIsLoading(false);
      }
    };
    
  
    const formatDate = (dateTimeString) => {
      const date = new Date(dateTimeString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
      const year = date.getFullYear();
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const seconds = date.getSeconds().toString().padStart(2, "0");
  
      return `${day}/${month}/${year}`;
    };
  
    const formatTime = (dateTimeString) => {
      const date = new Date(dateTimeString);
    
      // Set the timezone offset to UTC
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    
      // Use toLocaleTimeString to automatically adjust the time to the user's local timezone
      const formattedTime = date.toLocaleTimeString(undefined, {
        hour: 'numeric',
        minute: 'numeric',
      //  second: 'numeric',
        hour12: true, // Use 12-hour format
      });
    
      return formattedTime;
    };
  
    const loadMoreNotes = () => {
      const newLoadMoreCount = loadMoreCount + 3; // Load 3 more notes
      setDisplayedNotes(noteList.slice(0, newLoadMoreCount));
      setLoadMoreCount(newLoadMoreCount);
    };
  
    return (
      <div className="ModalChildDiv">
        <div
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <div
            className="modal-content custom-modal-content"
            style={{
              padding: "10px",
              paddingLeft: "20px",
              paddingRight: "20px",
              background: "none",
              border: "none",
            }}
          >
            <div className="row">
              <div
                className="finalTitleDiv"
                style={{
                  alignItems: "flex-start",
                }}
              >
                <h3
                  style={{
                    fontFamily: "futuraMedium",
                    fontWeight: "bold",
                    fontSize: "30px",
                    marginTop: "15px",
                  }}
                >
                  Notes
                </h3>
              </div>
            </div>
  
            <div className="finish-row">
              <div
                className="finish-column wholeRow"
                style={{
                  position: "relative",
                }}
              >
                <p
                  className="detailsTitle"
                  style={{
                    fontSize: "13px",
                    marginBottom: "20px",
                  }}
                >
                  Add a Note
                </p>
  
                <textarea
                  type="text"
                  className="addUserDetails"
                  placeholder="Insert your comment/note here"
                  style={{
                    fontFamily: "futurabook",
                    color: "#141212",
                    height: "80px",
                    fontWeight: "bold",
                    outline: "none",
                    padding: "10px",
                    resize: "none",
                  }}
                  spellCheck="false"
                  maxLength={100}
                  value={tagNote} // Bind the textarea value to tagNote state
                  onChange={handleTagNoteChange} // Add an onChange handler to update tagNote
                />
  
                <div
                  style={{
                    position: "absolute",
                    top: "52%",
                    right: "5px",
                    fontSize: "12px",
                    transform: "translateY(-50%)",
                    color: "#666",
                  }}
                >
                  {noteCount}/100
                </div>
                <Modal
                  show={showAddSuccessModal}
                  //     onHide={handleCloseSuccessModal}
                  centered
                >
                  <Modal.Body>
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={handleCloseSuccessModal}
                        style={{
                          background: "none",
                          border: "none",
                          fontSize: "35px",
                          position: "absolute",
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <AddTagNoteSuccessModal
                      onClose={handleCloseSuccessModal}
                      refreshNotes={refreshNotes}
                    />
                  </Modal.Body>
                </Modal>
                <Modal show={""} onHide={""} centered>
                  {/* <AddLocationFailedModal /> */}
                </Modal>
  
                <Modal
                  show={showFailedModal}
                  //   onHide={handleCloseFailedModal}
                  centered
                >
                  <Modal.Body>
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={handleCloseFailedModal}
                        style={{
                          background: "none",
                          border: "none",
                          fontSize: "35px",
                          position: "absolute",
                        }}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <FailedTagNoteModal onClose={handleCloseFailedModal}/>
                  </Modal.Body>
                </Modal>
                <button
                  className="save-button"
                  style={{
                    width: "100%",
                    height: "50px",
                    marginTop: "5px",
                  }}
                  onClick={addNote}
                  disabled={isLoading}
                >
                  {isLoading ? "Adding Note..." : "Add Note"} 
                </button>
              </div>
            </div>
  
            {/* note list ============================================================*/}
  
            {noteList.length >= 1 && (
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                    justifyContent: "flex-end",
                  }}
                >
                  {/* <span style={{ marginRight: "10px", fontFamily:"futuraheavy" }}>Sort by:</span> */}
                  {/* <select
                    value={sortOption}
                    onChange={(e) => setSortOption(e.target.value)}
                    style={{
                      border:"none",
                      borderRadius:"5px",
                      height:"30px",
                      padding:"5px",
                      background:"#eeeeee",
                      cursor:"pointer",
                
                    }}
                  >
                    <option value="latestToOldest">Latest To Oldest</option>
                    <option value="oldestToLatest">Oldest To Latest</option>
                  </select> */}
                </div>
                <div className="noteContainer">
                  {displayedNotes
                    .sort((a, b) => {
                      if (sortOption === "latestToOldest") {
                        return (
                          new Date(b.dateCreatedUtc) - new Date(a.dateCreatedUtc)
                        );
                      } else {
                        return (
                          new Date(a.dateCreatedUtc) - new Date(b.dateCreatedUtc)
                        );
                      }
                    })
                    .map((note, index) => (
                      <div key={index}>
                        <div className="noteContent">
                          <span
                            className="noteText"
                            style={{
                              fontFamily: "futurabook",
                              fontSize: "16px",
                            }}
                          >
                            Note:
                          </span>
                          <span
                            style={{
                              fontFamily: "futuramedium",
                              fontSize: "18px",
                            }}
                          >
                            {note.note ? ` ${note.note}` : "-"}
                          </span>
                        </div>
                        <div className="noteHeader">
                          <p
                            className="noteTitle"
                            style={{
                              fontFamily: "futurabook",
                              fontSize: "12px",
                              color: "#707070",
                              borderBottom: "1px solid black",
                              width: "100%",
                              padding: "5px 0",
                            }}
                          >
                            Saved in
                            <span
                              onClick={() => handleOpenMapModal(note)}
                              style={{
                                color: "gray",
                                cursor: "pointer",
                                fontFamily: "futuraheavy",
                                fontSize: "12px",
                              }}
                            >
                              &nbsp;Latitude:{note.latitude}, Longitude
                              {note.longitude}&nbsp;
                            </span>
                            on the {formatDate(note.dateCreatedUtc)} at{" "}
                            {formatTime(note.dateCreatedUtc)} by {note.createdBy}
                          </p>
                          <br />
                        </div>
  
                        {selectedNote === note && (
                          <Modal
                            show={showMapModal}
                            onHide={handleCloseMapModal}
                            centered
                          >
                            <MapModal
                              latitude={note.latitude}
                              longitude={note.longitude}
                            />
                          </Modal>
                        )}
                      </div>
                    ))}
                </div>
                {noteList.length > loadMoreCount && (
                  <div className="load-more">
                    <button className="loadmoreButton" onClick={loadMoreNotes}>
                      Load More
                      <i
                        style={{
                          marginLeft: "7px",
                        }}
                      >
                        <RefreshIcon />
                      </i>
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };
export default AddSiteNotes
