import React, { useState } from "react";
import Navbar from '../components/NavBar'
import UserApprovalBar from '../components/UserApproval/UserApprovalBar'
import DeletedItemsTable from '../components/DeletedItemsComponents/DeletedItemsTable'

const DeletedItemsPage = () => {

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (query) => {
    // Update the search query state
    setSearchQuery(query);
  };


  return (
    <div>
        <Navbar/>
        <UserApprovalBar searchQuery={searchQuery} onSearch={handleSearch} />
        <DeletedItemsTable searchQuery={searchQuery}/>
    </div>
  )
}

export default DeletedItemsPage