import React, { useState, useEffect } from "react";
import { publicRequest } from "../requestMethods";
import ChangePasswordComponent from "../components/ChangePasswordComponent/ChangePasswordComponent";
import { CircleX } from "../fontAwesomeComponents/FaIcons";
import { useNavigate } from "react-router-dom";

const UserInvitePage = () => {
  const [inviteCode, setInviteCode] = useState("");
  const [inviteCodeValid, setInviteCodeValid] = useState(false);
  const [userInfo, setUserInfo] = useState([]);

  const navigate = useNavigate();

  const handleBackToProfile = () => {
    navigate("/login");
  };

  const getInviteCodeFromURL = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const inviteCodeParam = urlParams.get("value");

    if (inviteCodeParam) {
      setInviteCode(decodeURIComponent(inviteCodeParam));
    }
  };

  const queryParams = {
    code: inviteCode,
  };

  useEffect(() => {
    getInviteCodeFromURL();
  }, []);

  const fetchData = async (url) => {
    try {
      const queryString = new URLSearchParams(queryParams).toString();
      const fullUrl = url + (queryString ? `?${queryString}` : "");

      const response = await publicRequest({
        method:"GET",
        url: fullUrl,
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    const validateUserInviteCode = async () => {
        try{
            if(inviteCode){
                const validateUserInviteCode = {
                    code: inviteCode
                };

                const inviteCodeResponse = await fetchData("/auth/validateuserinvite", queryParams);

                setUserInfo(inviteCodeResponse);

                setInviteCodeValid(true);
            }else {
                setInviteCodeValid(false);
            }
        }catch(error){
            console.error(error);
            setInviteCodeValid(false);
        }
    };

    validateUserInviteCode();
  }, [inviteCode]);


  return (
  <div>
      {userInfo && Object.keys(userInfo).length > 0 ? (
        <ChangePasswordComponent user={userInfo} />
      ) : (
        <div
          style={{
            height: "100vh",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <div
              style={{
                marginBottom: "20px",
                backgroundColor: "#EFC8C8",
                padding: "10px",
                borderRadius: "16px",
              }}
            >
              <CircleX height="50px" width="50px" color="#DC3545" />
            </div>

            <h2
              style={{
                fontFamily: "futuraheavy",
                fontSize: "25px",
                marginBottom: "20px",
              }}
            >
              Invalid Token
            </h2>

            <button
              className="back-to-login-button"
              onClick={handleBackToProfile}
              style={{
                backgroundColor: "#DC3545",
                width: "80%",
                fontFamily: "futurabook",
                color: "#FFFFFF",
                height: "50px",
                border: "none",
                borderRadius: "10px",
              }}
            >
              Back to Login
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserInvitePage;
