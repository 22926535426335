import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"; // Assuming you're using React Navigation
import { publicRequest } from "../requestMethods";
import { useDispatch } from "react-redux";
import { loginSuccess,logout,setTermAccepted } from "../redux/userRedux";
import { refreshTokenFunction } from "../redux/apiCals";

const TermsAndConditions = () => {
  const user = useSelector((state) => state.user);
  const navigate = useNavigate(); // Use the useNavigate hook
  const dispatch = useDispatch();
  const refreshToken = useSelector((state) => state.user.refreshToken);
  const token = useSelector((state) => state.user.token);

  const refresh = () => {
    window.location.reload();
  };


  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleAcceptTerms = async () => {

    const data = {
    loggedUserId: user.id,
    };

    console.log(data);

    const headers = {
      Authorization: `Bearer ${user.token}`,
    };

    console.log(headers);

    try {
      const response = await publicRequest({
        method: "PUT",
        url: "user/policy/accept",
        data: data,
        headers: headers,
      });
   //   dispatch(setTermAccepted(true));
 //  dispatch(logout());
      console.log(response);
      const res = await refreshTokenFunction(dispatch, { token, refreshToken });
      console.log(res);
    
      // Navigate to "/tags" page after successful acceptance
      navigate("/tags");
    } catch (error) {
      console.log("error");
    }
  };

  return (
    <div className="ModalChildDiv"
      style={{
        height: "100%",
        padding: "30px",
      }}
    >
   <h1
          className="privacypolicytitle"
          style={{
            fontFamily: "futuramedium",
            marginBottom: "25px",
            fontSize: "30px",
          }}
        >
          Terms and Conditions for Safe T Tag
        </h1>

        <h1 style={{ fontFamily: "futuramedium", fontSize: "20px" }}>
          {/* Privacy Policy for Safe T Tag */}
        </h1>

        <p
          className="privacyText"
          style={{
            fontSize: "15px",
          }}
        >
          Last Updated: 14 March 2024
          <br />
          <br />
          These terms and conditions govern your use of the Safe T Tag mobile
          application or website provided by us.
          <br />
          <br />
          Definitions
          <br />
          <br />
          <strong>App</strong> means the Safe T Tag mobile application created
          and managed by TACD.
          <br />
          <br />
          <strong>Claims</strong> means all demands, claims, proceedings,
          penalties, fines, liabilities or similar (whether criminal or civil,
          in contract, tort or otherwise).
          <br />
          <br />
          <strong>Intellectual Property Rights</strong> means all registered and
          unregistered intellectual property rights of any kind anywhere in the
          world, including rights in or in relation to copyright, trademarks,
          patents, designs, applications, Confidential Information and any other
          intellectual property rights related to in connection with the App,
          Website or Product, excluding Moral Rights.
          <br />
          <br />
          <strong>Moral Rights</strong> means the rights set out in Part IX of
          the Copyright Act 1968 (Cth) as conferred on the author and creator of
          the Intellectual Property.
          <br />
          <br />
          <strong>Product</strong> means the product available for order or
          purchase by Users from the Website.
          <br />
          <br />
          <strong>TACD</strong> means TACD Enterprises Pty Ltd (ACN 160 617 685)
          trading as Safe T Tag (ABN 34 160 617 685) and includes its
          subsidiaries, agents, employees, affiliated or related entities,
          assigns and successors. We, us, or our have corresponding meanings.
          <br />
          <br />
          <strong>Terms</strong> means these terms and conditions.
          <br />
          <br />
          <strong>Upgrade</strong> means an upgrade, update, enhancement,
          improvement or patch to the App supplied by us.
          <br />
          <br />
          <strong>User</strong> means a user of the App and Website. You or your
          have corresponding meanings.
          <br />
          <br />
          <strong>Website</strong> means the website www.safettag.com.
          <br />
          <br />
          At Safe T Tag, accessible from safettag.com, one of our main
          priorities is the privacy of our visitors. This Privacy Policy
          document contains types of information that is collected and recorded
          by Safe T Tag and how we use it.
          <br />
          <br />
          <strong>General Terms & Conditions</strong>
          <br />
          <br />
          By downloading, accessing, or using the App or Website, you agree to
          be bound by these Terms. If you do not agree to these Terms, please
          immediately cease accessing or using the App or Website. Our App or
          Website may contain links to other apps or websites that are owned and
          operated by third parties. We are not responsible for the operation or
          content located on or through any such app or website. Use of the Safe
          T Tag website or App.1.1 License: We grant you a non-exclusive,
          non-transferable, revocable license to use the App for your personal
          or business use. Products subject to availability The Products
          available on the App or Website may change from time to time. Users
          must not bring any claim against TACD in relation to the nature or
          availability of any Product, the App or Website. TACD does not
          guarantee the availability of any Product, and Users must not bring
          any Claim against TACD in relation to the same. The App is to be used
          for informative or educational purposes only, and is not a replacement
          for professional advice. You must seek advice from a licensed
          electrician, engineer or other appropriate professional in relation to
          any queries or troubleshooting you may have about any electrical
          equipment, devices or objects which the Product may be used for. Any
          work that you undertake while using our Product or App must comply
          with any legislation, codes, regulations or safety standards that are
          applicable in the country in which the work is undertaken. You must
          apply to the App each Upgrade released by us from time to time. We do
          not have any obligation to provide support for the App in relation to
          any version of the App that does not incorporate the most recent
          Upgrade or is not supported by your mobile device.
        </p>

        <p>
          1. Restrictions: You agree not to:
          <ul>
            <li
              style={{
                fontSize: "15px",
              }}
            >
              • Copy, reproduce, reverse engineer, decompile, or disassemble the
              App or any part of the App;
            </li>

            <li
              style={{
                fontSize: "15px",
              }}
            >
              • Circumvent or remove or attempt to circumvent or remove any
              technological measures applied to the App for the purposes of
              preventing unauthorised use;
            </li>

            <li
              style={{
                fontSize: "15px",
              }}
            >
              • Use the App for any unlawful or unauthorized purpose;
            </li>

            <li
              style={{
                fontSize: "15px",
              }}
            >
              • Interfere with the functioning of the App or disrupt its
              servers;
            </li>

            <li
              style={{
                fontSize: "15px",
              }}
            >
              • Use the App in any way that may cause harm or damage to
              electrical systems or any connected devices; or • violate any
              applicable laws, rules, or regulations.
            </li>
          </ul>
        </p>

        <p>
          2. User Accounts
          <ul>
            <span
              style={{
                fontSize: "80%",
              }}
            >
              2.1 Registration:
            </span>
            <li
              style={{
                fontSize: "15px",
                marginLeft: "15px",
              }}
            >
              To access certain features of the App, you may be required to
              create an account. You agree to provide accurate, current, and
              complete information during the registration process.
            </li>

            <span
              style={{
                fontSize: "80%",
              }}
            >
              2.2. Account Security
            </span>
            <li
              style={{
                fontSize: "15px",
                marginLeft: "15px",
              }}
            >
              You are responsible for maintaining the confidentiality of your
              account credentials and for all activities that occur under your
              account. You agree to notify us immediately of any unauthorized
              access or use of your account.
            </li>

            <span
              style={{
                fontSize: "80%",
              }}
            >
              2.3. Data Security
            </span>
            <li
              style={{
                fontSize: "15px",
                marginLeft: "15px",
              }}
            >
              All data entered is stored for a maximum period of 12 months after
              the last day that a Product was first scanned and created into the
              QR Coded Secure Centralised Database. Only authorised TACD
              personnel, a Company Administrator, a Company Supervisor or
              Company Users have access to this data. At the end of the free 12
              month period noted in this clause, all data including archived,
              deleted and unresolved data relating to the App or Product, will
              be deleted unless you enter into a separate paid “Period
              Subscription Contract” with us. Unless required by law, all
              information will be deleted after a 12 month period has elapsed
              from the date the Tag or Item was first scanned and created into
              the QR Coded Secure Centralised Database.
            </li>
          </ul>
        </p>

        <p>
          3. Privacy
          <ul>
            <li
              style={{
                fontSize: "15px",
                marginLeft: "15px",
              }}
            >
              3.1. Your use of the App is subject to our Privacy Policy, which
              can be accessed through the Website. By using the App, you consent
              to the collection and use of information as described in the
              Privacy Policy.
            </li>
          </ul>
        </p>

        <p>
          4. Intellectual Property
          <ul>
            <li
              style={{
                fontSize: "15px",
                marginLeft: "15px",
              }}
            >
              All Intellectual Property Rights in or in relation to TACD, its
              products and services and all other such rights in connection with
              the App and Website are owned by TACD. You agree that you will not
              (whether alone or in conjunction with any other person/entity)
              reproduce, distribute, modify, display, perform, or otherwise use
              any aspect of the Products, the App or the Website or authorise or
              instruct any third party to do the same.
            </li>
          </ul>
        </p>

        <p>
          5. No warranties
          <ul>
            <li
              style={{
                fontSize: "15px",
                marginLeft: "15px",
              }}
            >
              5.1. TACD makes no warranty or representation (and gives no
              endorsement) in relation to the quality, suitability, standard, or
              fitness for purpose of the App, Website or Products offered by
              TACD from time to time.
              <br />
              <span>
                (a) You acknowledge that: the App or Website may not be
                error-free and that non-materials errors on the App or Website
                will not constitute a breach by TACD; and
              </span>
              <br />
              <span>
                {" "}
                (b) the App or Website has not been developed to meet your
                specific requirements, and accordingly you are responsible for
                ensuring that the App or Website is suitable to meet youre
                rquirements.{" "}
              </span>
            </li>
          </ul>
        </p>

        <p>
          6. Limitation of Liability
          <ul>
            <li
              style={{
                fontSize: "15px",
                marginLeft: "15px",
              }}
            >
              6.1. To the maximum extent permitted by law, TACD will not be
              liable to Users for any Claims arising directly or indirectly out
              of the User’s use of the App, Website or Products, including but
              not limited to: any consequential, indirect, remote or
              unforeseeable loss or similar loss including but not limited to
              loss of or damage to profits, income, revenue, use, production,
              anticipated savings, business, contracts, commercial
              opportunities, or goodwill; and
            </li>

            <li
              style={{
                fontSize: "15px",
                marginLeft: "15px",
              }}
            >
              6.2. Any loss or corruption of any data, database or software,
              whether directly or indirectly caused by using the App or Website.
            </li>

            <li
              style={{
                fontSize: "15px",
                marginLeft: "15px",
              }}
            >
              6.3. You are liable for and indemnify us, our related bodies and
              our officers, employees and agents from and against any direct or
              indirect Claims however suffered or incurred by us, our related
              bodies and our officers, employees and agents (including
              consequential losses, loss of revenue and related losses) in
              connection with:
              <br />
              (a) Your use of our Website, App or Product;
              <br />
              (b) breach by you of any term, obligation, warranty,
              representation, undertaking or other acknowledgement contained in
              these Terms;
              <br />
              (c) tort, including negligence or breach of a legislative
              requirement;
              <br />
              (d) breach of equitable duty; and(e) any Claims by a third party
              against u s arising out of or in connection with any matter set
              out in subparagraph (a) to (d) above.
            </li>

            <li
              style={{
                fontSize: "15px",
                marginLeft: "15px",
              }}
            >
              6.4. The liability of TACD (if any) to you for any loss or Claim
              arising under these Terms shall be limited to the amount which
              TACD is able to recover under a policy of insurance held by TACD
              for its business.
            </li>
          </ul>
        </p>

        <p>
          7. Changes to the Terms.
          <ul>
            <li
              style={{
                fontSize: "15px",
                marginLeft: "15px",
              }}
            >
              7.1. We may amend or replace any of the Terms at any time without
              notice to Users. Such changes will take effect on and from the
              implementation of the changes.
            </li>
            <li
              style={{
                fontSize: "15px",
                marginLeft: "15px",
              }}
            >
              7.2. You are responsible for reviewing these Terms periodically
              and your continued use of the App after any changes constitute
              your acceptance of the updated Terms.
            </li>
            <li
              style={{
                fontSize: "15px",
                marginLeft: "15px",
              }}
            >
              7.3. We may amend or replace any of our Safe T Tag Privacy Policy
              and other publicly advertised documents at any time without notice
              to Users. We will temporarily limit access to our Safe T Tag App
              and QR Coded Secure Centralised Database to you, a company or
              representative with a valid login until the new Privacy Policy,
              and other publicly advertised legal document/s has been
              electronically accepted during the login process to safettag.com.
            </li>
          </ul>
        </p>

        <p>
          8. Deregistered or Unlicensed access
          <ul>
            <li
              style={{
                fontSize: "15px",
                marginLeft: "15px",
              }}
            >
              On notification to TACD, any company or electrician that becomes
              unregistered or unlicensed will be removed from the QR Coded
              Secure centralised Database, whereby no access to the database, to
              any uploaded or entered information will be available.
            </li>
          </ul>
        </p>

        <p>
          9. Termination
          <ul>
            <li
              style={{
                fontSize: "15px",
                marginLeft: "15px",
              }}
            >
              We may terminate or suspend your access to the App without notice
              at our absolute discretion, for any breach of these Terms.
            </li>
          </ul>
        </p>
        

        <p>
          10. Contact Information
          <ul>
            <li
              style={{
                fontSize: "15px",
                marginLeft: "15px",
              }}
            >
              If you have any questions about these Terms and conditions, please
              contact us at safettag.com
            </li>
          </ul>
        </p>

      <div>
        <div
          style={{
            width: "100%",
            justifyContent: "space-between",
            display: "flex",
            marginLeft: "-15px",
          }}
        >
          <label
            
          >
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              style={{
                marginRight:"10px"
              }}
            />
            I have read and accept the terms and conditions
          </label>
        </div>
        <div
          style={{
            justifyContent: "flex-end",
            width: "100%",
            display: "flex",
          }}
        >
          <button
            className="save-button"
            onClick={handleAcceptTerms}
            disabled={!isChecked}
            style={{
              width: "90px",
              height:"50px",
              // Add the cursor style inline
              cursor: !isChecked ? "not-allowed" : "pointer",
              // Other styles
            }}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
