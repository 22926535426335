import React from 'react'
import Navbar from '../components/NavBar'
import ContactFields from '../components/ContactUsComponents/ContactFields'
import Footer from '../components/Footer'

const ContactUs = () => {
  return (
    <div
        style={{
            background:"#141212"
        }}
    >
        <Navbar/>
        <ContactFields/>
        <Footer/>
    </div>
  )
}

export default ContactUs