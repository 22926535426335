import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { publicRequest } from "../../../requestMethods";
import { CircleX } from "../../../fontAwesomeComponents/FaIcons";
import ItemArchiveSuccessModal from "./ItemArchiveSuccessModal";
import FailedDeleteTagModal from "../../TagArchive/ValidationModals/FailedDeleteTagModal";
import { Modal } from "react-bootstrap";
// import DeleteTagSuccessModal from "../../TagArchive/ValidationModals/DeleteTagSuccessModal";

const ConfirmDeleteItemModal = ({
  onClose,
  item,
  refreshArchiveList,
  refreshTagList,
  refreshItemList
}) => {
  const token = useSelector((state) => state.user.token);
  const userId = useSelector((state) => state.user.id);
  const selectedUserCompanyId = useSelector((state) => state.selectedCompany);

  const location = useLocation();
  const isArchiveRoute =
    location.pathname === "/items";

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showArchiveSuccessModal, setShowArchiveSuccessModal] = useState(false);
  const [showFailedModal, setShowFailedModal] = useState(false);

  const handleOpenSuccessModal = () => {
    setShowSuccessModal(true);
  };

  const handleBackToProfile = () => {
    onClose();
  };

  const handleOpenArchiveModal = () => {
    setShowArchiveSuccessModal(true);
  };

  const handleCloseArchiveArchiveModal = () => {
    setShowArchiveSuccessModal(false);
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    handleBackToProfile();
    
  };

  const toggleSuccessModal = () => {
    setShowSuccessModal(!showSuccessModal);
  };

  const handleOpenFailedModal = () => {
    setShowFailedModal(true);
  };

  const handleCloseFailedModal = () => {
    setShowFailedModal(false);
  };

  const toggleFailedModal = () => {
    setShowFailedModal(!showFailedModal);
  };



  const DeleteTagFunction = async () => {
    const DeleteTagParams = {
      LoggedUserId: userId,
      LoggedUserCompanyId: selectedUserCompanyId,
    };

    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const requestConfigForDeleteTag = {
        method: "DELETE",
        headers: headers,
        params: DeleteTagParams,
      };

      const deleteTag = await publicRequest(
        `/item/${item.id}/delete`,
        requestConfigForDeleteTag
      );

      if (deleteTag) {
        console.log("tag deleted");
        refreshArchiveList();
        setShowSuccessModal(true);
      }
    } catch (error) {
      console.error(error);
     // setShowFailedModal(true);
    }
  };

  const ArchiveTag = async () => {
    const ArchiveTagParams = {
      LoggedUserId: userId,
      LoggedUserCompanyId: selectedUserCompanyId,
    };

    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const requestConfigForArchiveTag = {
        method: "DELETE",
        headers: headers,
        params: ArchiveTagParams,
      };

      const archiveTagLink = await publicRequest(
        `/item/${item.id}/archive`,
        requestConfigForArchiveTag
      );

      if (archiveTagLink) {
        console.log("tag archived");
        setShowArchiveSuccessModal(true);
        refreshItemList();
        handleBackToProfile();
      }
      
      //modal
    } catch (error) {
      console.error(error);
     setShowFailedModal(true);
    }
  };

  const confirmActionText = isArchiveRoute
    ? "Are you sure you want to archive this Item?"
    : "Are you sure you want to delete this Item??";

  const confirmActionFunction = isArchiveRoute ? ArchiveTag : DeleteTagFunction;

  return (
    <div style={{ height: "37vh" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div
          style={{
            marginBottom: "20px",
            backgroundColor: "#EFC8C8",
            padding: "10px",
            borderRadius: "16px",
          }}
        >
          <CircleX height="50px" width="50px" color="#DC3545" />
        </div>

        <h2
          style={{
            fontFamily: "futuraheavy",
            fontSize: "25px",
            marginBottom: "20px",
            textAlign: "center",
            paddingRight: "20px",
            paddingLeft: "20px",
          }}
        >
          {confirmActionText}
        </h2>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "50%",
          }}
        >
          <button
            className="back-to-login-button"
            onClick={confirmActionFunction}
            style={{
              backgroundColor: "#28A745",
              width: "100px",
              fontFamily: "futurabook",
              color: "#FFFFFF",
              height: "50px",
              border: "none",
              borderRadius: "10px",
            }}
          >
            Yes
        
        
          </button>

          {/* <Modal
              centered
              show={showSuccessModal}
        //      onHide={handleCloseSuccessModal}
            >
                          <Modal.Body>
                        <div className="d-flex justify-content-end">
                          <button
                            type="button"
                            className="close"
                            aria-label="Close"
                            onClick={handleCloseSuccessModal}
                            style={{
                              background: "none",
                              border: "none",
                              fontSize: "20px",
                              position: "absolute",
                            }}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
              <DeleteTagSuccessModal
            //    toggleSuccessModal={toggleSuccessModal}
                onClose={toggleSuccessModal}
              />
                   </Modal.Body>
            </Modal> */}


            <Modal
              centered
              show={showArchiveSuccessModal}
             // onHide={handleCloseArchiveArchiveModal}
            >
                   <Modal.Body>
                        <div className="d-flex justify-content-end">
                          <button
                            type="button"
                            className="close"
                            aria-label="Close"
                            onClick={handleCloseArchiveArchiveModal}
                            style={{
                              background: "none",
                              border: "none",
                              fontSize: "20px",
                              position: "absolute",
                            }}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
              <ItemArchiveSuccessModal
                toggleSuccessModal={toggleSuccessModal}
                onClose={handleCloseSuccessModal}
                refreshArchiveList={refreshArchiveList}
              />
                 </Modal.Body>
            </Modal>

          {/* <Modal
            centered
            show={showFailedModal}
      //      onHide={handleCloseFailedModal}
          >
                 <Modal.Body>
                        <div className="d-flex justify-content-end">
                          <button
                            type="button"
                            className="close"
                            aria-label="Close"
                            onClick={handleCloseFailedModal}
                            style={{
                              background: "none",
                              border: "none",
                              fontSize: "20px",
                              position: "absolute",
                            }}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
            <FailedDeleteTagModal
            //  toggleFailedModal={toggleFailedModal}
              onClose={toggleFailedModal}
            />
            </Modal.Body>
          </Modal> */}

          <button
            className="back-to-login-button"
            onClick={handleBackToProfile}
            style={{
              backgroundColor: "#DC3545",
              width: "100px",
              fontFamily: "futurabook",
              color: "#FFFFFF",
              height: "50px",
              border: "none",
              borderRadius: "10px",
            }}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteItemModal;
