import React, { useState, useEffect } from "react";
import "../../css/userApprovalcss/userApproval.css";
import { publicRequest } from "../../requestMethods";
import {
  ThinEditIcon,
  ThinArchive,
  RefreshIcon,
  DownloadIcon,
} from "../../fontAwesomeComponents/FaIcons";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import AddLocationModal from "./AddLocationModal";
import EditLocationModal from "./EditLocationModal";

const LocationsTable = ({searchQuery}) => {
  //modals
  const [openAddLocation, setOpenAddLocation] = useState(false);
  const [isEditHovered, setIsEditHovered] = useState(false);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [isRefreshHovered, setIsRefreshHovered] = useState(false);


  const handleAddLocationClick = () => {
    setOpenAddLocation(true);
  };

  const closeAddLocation = () => {
    setOpenAddLocation(false);
  };

  const [showEditLocationModal, setShowEditLocationModal] = useState(false);

  const handleEditLocationModal = (location) => {
    setSelectedLocation(location);
    setShowEditLocationModal(true);
  };

  const closeEditModal = () => {
    setShowEditLocationModal(false);
  };

  //

  const sortLocationsAlphabetically = (locations) => {
    return locations.sort((a, b) => a.name.localeCompare(b.name));
  };

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [locationsList, setLocationsList] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [siteData, setSiteData] = useState([]);
  
  const [isLoading, setIsLoading] = useState(false);

  const handleSelectAllChange = (isSelectAll) => {
    if (isSelectAll) {
      setSelectAllChecked(true);
      setSelectedRows([...Array(locationsList.length).keys()]);
    } else {
      setSelectAllChecked(false);
      setSelectedRows([]);
    }
  };

  const handleSelectAllChangeBox = () => {
    setSelectAllChecked(!selectAllChecked);

    if (!selectAllChecked) {
      setSelectedRows([...Array(locationsList.length).keys()]);
    } else {
      setSelectedRows([]);
    }
  };

  const handleRowSelection = (index) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(index)
        ? prevSelectedRows.filter((item) => item !== index)
        : [...prevSelectedRows, index]
    );
  };

  const token = useSelector((state) => state.user.token);
  const userCompanyId = useSelector((state) => state.selectedCompany);
  const id = useSelector((state) => state.user.id);

  const queryParams = {
    LoggedUserId: id,
    LoggedUserCompanyId: userCompanyId,
  };

  //getting location

  const fetchData = async (url) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const queryString = new URLSearchParams(queryParams).toString();
      const fullUrl = url + (queryString ? `?${queryString}` : "");

      const response = await publicRequest({
        method: "GET",
        url: fullUrl,
        headers: headers,
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching data", error);
      return null;
    }
  };

  //getting location details

  const fetchLocationData = async () => {
    try {
      setIsLoading(true);
      const getLocationResponse = await fetchData("/location/list");
      const getSitesResponse = await fetchData("/site/list");

      if (getSitesResponse) {
        setSiteData(getSitesResponse);
      }

      if (getLocationResponse) {
        // Sort locations alphabetically by name
        const sortedLocations = sortLocationsAlphabetically(getLocationResponse);
        setLocationsList(sortedLocations);

        const locationDataPromises = sortedLocations.map(async (location) => {
          const site = siteData.find((site) => site.id === location.siteId);
          if (site) {
            const queryParamsForLocation = {
              LoggedUserId: id,
              LoggedUserCompanyId: userCompanyId,
              SiteId: site.id,
              LocationId: location.id,
            };
            const locationResponse = await fetchData("/location", queryParamsForLocation);
            return locationResponse;
          }
          return null;
        });

        const locationDataResponse = await Promise.all(locationDataPromises);
        setLocationData(locationDataResponse);
      }
    } catch (error) {
      console.error("Error fetching data", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLocationData();
  }, []);


  const refreshLocation = async () => {
    try {
      setIsLoading(true);
      const getLocationResponse = await fetchData("/location/list");
      const getSitesResponse = await fetchData("/site/list");

      if (getSitesResponse) {
        setSiteData(getSitesResponse);
      }

      if (getLocationResponse) {
        // Sort locations alphabetically by name
        const sortedLocations = sortLocationsAlphabetically(getLocationResponse);
        setLocationsList(sortedLocations);

        const locationDataPromises = sortedLocations.map(async (location) => {
          const site = siteData.find((site) => site.id === location.siteId);
          if (site) {
            const queryParamsForLocation = {
              LoggedUserId: id,
              LoggedUserCompanyId: userCompanyId,
              SiteId: site.id,
              LocationId: location.id,
            };
            const locationResponse = await fetchData("/location", queryParamsForLocation);
            return locationResponse;
          }
          return null;
        });

        const locationDataResponse = await Promise.all(locationDataPromises);
        setLocationData(locationDataResponse);
      }
    } catch (error) {
      console.error("Error fetching data", error);
    } finally {
      setIsLoading(false);
    }
  };
  


useEffect(() => {
  // Check if locationsList is null or undefined before filtering
  if (!locationsList) return;

  const filteredUser = locationsList.filter((user) => {
    // Customize the conditions for filtering based on your tag structure
    console.log(user);
    const latitudeString = user.latitude != null ? user.latitude.toString() : ''; // Check if latitude is not null
    const longitudeString = user.longitude != null ? user.longitude.toString() : '';

    const fullCoordinates = latitudeString + ' ' + longitudeString; // Use a space separator

    // Split the search query into latitude and longitude strings
    const [searchLatitude, searchLongitude] = searchQuery.split(' ');

    return (
      user.addressLine1.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.addressLine2.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.companyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.siteName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      latitudeString.toLowerCase().includes(searchLatitude) || // Compare latitude with searchLatitude
      longitudeString.toLowerCase().includes(searchLongitude) || // Compare longitude with searchLongitude
      fullCoordinates === searchQuery // Check if the full coordinates match the search query exactly
      // ...
    );
  });
  setFilteredLocations(filteredUser);
  setSelectAllChecked(false);
  setCurrentPage(1);
  //   getTagBySerialNumber();
}, [searchQuery, locationsList]);

  
  

  //pages
  const [pagination, setPagination] = useState(1);
  const itemsPerPageOptions = [10, 20, 30];
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredLocations.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 3, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 3, 1));
  };

  const renderPageButtons = () => {
    // Sort the companyData array based on the created date in descending order
    // const sortedCompanyData = locationData.sort(
    //   (a, b) =>
    //     new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    // );

    const pageButtons = [];
    const maxPagesToShow = 3; // Set the maximum number of pages to show

    const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          onClick={() => handlePageClick(i)}
          className={currentPage === i ? "active-page" : ""}
          style={{
            color: "black",
            borderRadius: "5px",
            border: "none",
            marginLeft: "10px",
            backgroundColor: currentPage === i ? "#F6C90E" : "#3A475033",
            width: "30px",
            fontFamily: "futuramedium",
            cursor: "pointer",
          }}
        >
          {i}
        </button>
      );
    }

    return pageButtons;
  };

  // ... (previous code remains unchanged)

  const totalPages = Math.ceil(locationsList.length / itemsPerPage);

  return (
    <div  className="TablePageMainDiv"
      style={{
        marginLeft: "150px",
        marginRight: "150px",

      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <div
          className="approvalTitle"
          style={{
            fontFamily: "futuramedium",
            color: "#141212",
            fontSize: "30px",
          }}
        >
          Locations
        </div>
        <div
          className="save-button"
          style={{
            fontFamily: "futuramedium",
            color: "#141212",
            fontSize: "12px",
            height: "45px",
            fontWeight: "bold",
            padding:"3px",
            width:"100px"
          }}
          onClick={() => handleAddLocationClick()}
        >
          +Add Location
        </div>
        <Modal  size="xl" show={openAddLocation} //onHide={closeAddLocation}
         centered>
              <Modal.Body>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={closeAddLocation}
                style={{
                  background: "none",
                  border: "none",
                  fontSize: "35px",
                  position: "absolute",
                  zIndex: "9999",
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          <AddLocationModal />
          </Modal.Body>
        </Modal>
      </div>
      <div
        className="minibar"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            fontFamily: "futuramedium",
          }}
        >
          <p>Show</p>
          <div
            style={{
              backgroundColor: "#e5e5e5",
              borderRadius: "10px",
              padding: "4px",
              cursor: "pointer",
              height: "30px",
              width: "50px",
              marginLeft: "1px",
              marginRight: "5px",
            }}
          >
            <select
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
              style={{
                border: "none",
                background: "none",
                cursor: "pointer",
              }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
            </select>
          </div>
          <p>items</p>
        </div>

        <div style={{ display: "flex" }}>
        <div
            className="icon"
            onMouseEnter={() => setIsRefreshHovered(true)}
            onMouseLeave={() => setIsRefreshHovered(null)}
            style={{
              backgroundColor: "#3A475033",
              borderRadius: "50%",
              color: "#3A4750",
              width: "32px",
              height: "32px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
              cursor: "pointer",
              position: "relative",
            }}
            onClick={refreshLocation}
          >
            <RefreshIcon />

            {isRefreshHovered && (
              <p
                style={{
                  position: "absolute",
                  top: "100%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  background: "#ffffff",
                  padding: "2px",
                  borderRadius: "10px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  zIndex: 999,
               
                  fontSize: "12px",
                  width: "100px",
                  textAlign: "center",
                }}
              >
                     Refresh the page
              </p>
            )}
          </div>



          {/* <p
            style={{
              color: "#3A4750",
              fontSize: "16px",
              fontFamily: "futurabook",
            }}
          >
            last refresh was 25mins ago
          </p>
          <div
            className="icon"
            style={{
              backgroundColor: "#3A475033",
              borderRadius: "50%",
              color: "#3A4750",
              width: "32px",
              height: "32px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
            }}
          >
            <RefreshIcon />
          </div> */}
          {/* <div
            style={{
              backgroundColor: "#3A475033",
              borderRadius: "10%",
              color: "#3A4750",
              width: "100px",
              height: "32px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
              fontFamily: "futurabook",
            }}
          >
            <button
              style={{
                border: "none",
                background: "none",
              }}
            >
              Show Archive
            </button>
          </div> */}
          {/* <div
            className="icon"
            style={{
              backgroundColor: "#3A475033",
              borderRadius: "20%", // Use 50% for a circle
              color: "#3A4750",
              width: "32px",
              height: "32px",
              display: "flex", // Use flex display
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
            }}
          >
            <DownloadIcon />
          </div> */}
        </div>
      </div>
        {/* ------------------------------------------------------ */}
        {isLoading ? (
        <p>Loading data...</p>
      ) : (
        <>
      <div className="approvalTable">
        <table
          className="custon-table"
          style={{
            width: "100%",
          }}
        >
          <div
            className="tHead"
            style={{
              width: "100%",
            }}
          >
            <thead>
              <tr>
                <th>
                  <div className="checkbox-dropdown">
                    <input
                      type="checkbox"
                      id="selectAll"
                      checked={selectAllChecked}
                      onChange={handleSelectAllChangeBox}
                    />
                    <div className="dropdown">
                      <span className="dropdown-arrow">&#9662;</span>
                      <div className="dropdown-content">
                        <div onClick={() => handleSelectAllChange(true)}>
                          Select All
                        </div>
                        <div onClick={() => handleSelectAllChange(false)}>
                          Deselect All
                        </div>
                      </div>
                    </div>
                  </div>
                </th>
                <th
              style={{
                width: "400px",
              }}
                >
                  Location Name
                </th>
                <th
             style={{
              width: "400px",
            }}
                >
                  Address
                </th>
                <th
                style={{
                  width: "400px",
                }}
                >
                  Site Name
                </th>
                <th
               style={{
                width: "400px",
              }}
                >
                  GPS Coordinates
                </th>
                <th
              style={{
                width: "400px",
              }}
                >
                  Company
                </th>
                <th
                  style={{
                    width: "100px",
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="tBody">
              {currentItems.map((location, index) => (
                <tr
                  key={index}
                  className={selectedRows.includes(index) ? "selected" : ""}
                >
                  <td>
                    <input
                      type="checkbox"
                      id={`companyCheckbox ${index}`}
                      checked={selectedRows.includes(index)}
                      onChange={() => handleRowSelection(index)}
                 
                    />
                    <label htmlFor={`companyCheckbox${index}`}></label>
                  </td>

                  <td
                    style={{
                      minWidth: "150px",
                     
                    }}
                  >
                    {location ? location.name : "Loading..."}
                  </td>
                  <td>
                    {location.addressLine1} {location.addressLine2}
                  </td>
                  <td>{location.siteName}</td>
                  <td>
                    {location.latitude} {location.longitude}
                  </td>
                  <td>{location.companyName}</td>
                  <td>
                    <div className=""
                      style={{
                        alignItems:"center",
                        justifyContent:'center',
                        width:"100%",
                        display:'flex'
                      }}
                    >
                      <div
                        className="iconBox"
                        onMouseEnter={() => setIsEditHovered(location)}
                        onMouseLeave={() => setIsEditHovered(null)}
                        style={{
                          cursor: "pointer",
                          position:"relative"
                        }}
                        onClick={() => handleEditLocationModal(location)}
                      >
                        <ThinEditIcon height="15px" width="15px" fill="#" />
                        {isEditHovered === location && (
                          <div
                            style={{
                              position: "absolute",
                              top: "100%",
                              left: "50%",
                              transform: "translateX(-50%)",
                              background: "#ffffff",
                              padding: "2px",
                              borderRadius: "10px",
                              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                              zIndex: 999,
                              width: "90px",
                              fontSize: "12px",
                            }}
                          >
                            Edit Location
                          </div>
                        )}
                      </div>

                      {selectedLocation === location && ( // Check if this row's modal should be open
                        <Modal size="xl"
                          show={showEditLocationModal}
                      //    onHide={closeEditModal}
                          centered
                        >
                            <Modal.Body>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={closeEditModal}
                style={{
                  background: "none",
                  border: "none",
                  fontSize: "35px",
                  position: "absolute",
                  zIndex: "9999",
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
                          <EditLocationModal location={selectedLocation} />
                          </Modal.Body>
                        </Modal>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </div>
        </table>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <div>
          Showing {Math.min(indexOfLastItem, locationsList.length)} of{" "}
          {locationsList.length} items
        </div>
        <div
          style={{
            marginRight: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <button
            onClick={handlePreviousPage}
            style={{
              color: "black",
              borderRadius: "5px",
              border: "none",
              marginLeft: "10px",
              backgroundColor: "#3A475033",
              width: "80px",
              fontFamily: "futuramedium",
              cursor: "pointer",
            }}
          >
            Previous
          </button>

          {renderPageButtons()}

          <button
            onClick={handleNextPage}
            style={{
              color: "black",
              borderRadius: "5px",
              border: "none",
              marginLeft: "10px",
              backgroundColor: "#3A475033",
              width: "60px",
              fontFamily: "futuramedium",
              cursor: "pointer",
            }}
          >
            Next
          </button>
        </div>
      </div>
      </>
      )}
    </div>
  );
};

export default LocationsTable;
