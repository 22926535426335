import React from 'react'
import NavBar from '../components/NavBar';
import DownloadAdditionalFeatures from '../components/DownloadComponents/DownloadAdditionalFeatures';
import Footer from '../components/Footer';
import DownloadLinkComponent from '../components/DownloadComponents/DownloadLinkComponent';


const DownloadTheApp = () => {
  return (
    <div>
        <NavBar/>
        <DownloadLinkComponent/>
        <DownloadAdditionalFeatures/>
        <Footer/>
    </div>
  )
}

export default DownloadTheApp;