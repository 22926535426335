import React from 'react'
import Navbar from '../components/NavBar'
import UserProfileComponent from '../components/userProfileComponents/UserProfileComponent'

const UserProfile = () => {
  return (
    <div
      style={{
        backgroundColor:"#EEEEEE",
        height:"100vh"
      }}
    >
        <Navbar/>
        <UserProfileComponent/>
    </div>
  )
}

export default UserProfile