import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faLocation, faEnvelope, faClock } from '@fortawesome/free-solid-svg-icons';
import '../../css/privacyPolicycss/PrivacyPolicy.css';

const ContactDetails = () => {
  
  return (
    <div className='contact-detailsContainer'>
      <div className='contactInfo'>
        <div className='infoTitle'>  
            <span className='contact-icon'>
            <FontAwesomeIcon icon={faPhone} />
          </span>
    <div>
        <h4 className='infoName'>Telephone Number
        </h4>
        <p className='infoSubtitle'>
          (08) 9304 9991
        </p>
    </div>
    </div>

      </div>

      <div className='contactInfo'>
        <div className='infoTitle'>  
            <span className='contact-icon'>
            <FontAwesomeIcon icon={faLocation} />
          </span>
    <div>
        <h4 className='infoName'>Location
        </h4>
        <p className="infoSubtitle"
            
            >PO Box 1249 <br/> Joondalup WA 6028 <br/> AUSTRALIA</p>
    </div>
    </div>

      </div>

      <div className='contactInfo'>
        <div className='infoTitle'>  
            <span className='contact-icon'>
            <FontAwesomeIcon icon={faEnvelope} />
          </span>
    <div>
        <h4 className='infoName'>  Email Address
        </h4>
        <p className='infoSubtitle'>
       enquiries@safettag.com
        </p>
    </div>
    </div>

      </div>

      <div className='contactInfo'>
        <div className='infoTitle'>  
            <span className='contact-icon'>
            <FontAwesomeIcon icon={faClock} />
          </span>
    <div>
        <h4 className='infoName'>  Business Hours
        </h4>
        <p className='infoSubtitle'>
       Monday-Friday: 9am to 5pm <br/> Saturday: Closed <br/> Sunday: Closed
        </p>
    </div>
    </div>

      </div>
    </div>
  );
}

export default ContactDetails;
