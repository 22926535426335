import React from 'react'
import DisclaimerContainer from '../components/disclaimerComponents/DisclaimerContainer'
import Navbar from '../components/NavBar'
import PrivacyPolicyFooter from '../components/privacyPolicyComponents/PrivacyPolicyFooter'
import ContactDetails from '../components/privacyPolicyComponents/ContactDetails'
import Map from '../components/privacyPolicyComponents/Map'

const Disclaimer = () => {
    return (
        <div className='parent'
        style={{
          backgroundColor: '#EEEEEE',
          backgroundSize:"cover",
        }}>
            <Navbar/>
            <div
              style={{display:"flex", flexDirection:"row",     marginLeft:"15%", marginRight:"15%"}}
            >
            <DisclaimerContainer/>
            <div
                  style={{marginTop:"80px"}}
            >
            <ContactDetails/>
            {/* <Map/> */}
            </div>
            </div>
            <PrivacyPolicyFooter/>
        </div>
      )
    }

export default Disclaimer;