import React, { useState } from "react";
import Navbar from '../components/NavBar';
import UserApprovalBar from '../components/UserApproval/UserApprovalBar';
import TagsTable from '../components/TagsList/TagsTable';

const Tags = () => {


  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (query) => {
    // Update the search query state
    setSearchQuery(query);
  };


  // ad

  return (
    <div>
      <div
        style={{
          backgroundColor: "#EEEEEE",
          minHeight: "100vh",
          paddingBottom: "25px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Navbar />
        <UserApprovalBar searchQuery={searchQuery} onSearch={handleSearch}  />
        <TagsTable searchQuery={searchQuery}/>
      </div>
    </div>
  );
};

export default Tags;
