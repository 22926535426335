import React, { useState } from "react";
import UserApprovalBar from '../components/UserApproval/UserApprovalBar'
import Navbar from '../components/NavBar'
import AuditTrailTable from '../components/AuditTrailComponents/AuditTrailTable'

const AuditTrail = () => {


    
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (query) => {
    // Update the search query state
    setSearchQuery(query);
  };


  return (
    <div
    style={{
        backgroundColor: "#EEEEEE",
        minHeight: "100vh" || "100%",
        paddingBottom: "25px",
        display: "flex",
        flexDirection: "column"
      }}
    >
        <Navbar/>
      <UserApprovalBar searchQuery={searchQuery} onSearch={handleSearch}/>
      <AuditTrailTable searchQuery={searchQuery}/>
    </div>
  )
}

export default AuditTrail
