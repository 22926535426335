import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ThinCircleCheck } from '../../fontAwesomeComponents/FaIcons';
import { useSelector } from 'react-redux';

const AddLocationSuccessModal = ({ onClose }) => {
  const userRole = useSelector((state) => state.selectedCompanyRoleId);
  const navigate = useNavigate();

  const handleBackToProfile = () => {
    window.location.reload();
  };

  const handleButtonClick = () => {
    if (userRole === 1) {
      onClose(); // Trigger onClose if userRole is 1
    } else {
      handleBackToProfile(); // Otherwise, handle back to profile
    }
  };

  return (
    <div style={{ height: '37vh' }}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <div
          style={{
            marginBottom: '20px',
            backgroundColor: '#D4EDDA',
            padding: '10px',
            borderRadius: '16px',
          }}
        >
          <ThinCircleCheck height="50px" width="50px" />
        </div>

        <h2
          style={{
            fontFamily: 'futuraheavy',
            fontSize: '25px',
            marginBottom: '20px',
          }}
        >
          New location has been added
        </h2>

        <button
          className="back-to-login-button"
          onClick={handleButtonClick}
          style={{
            backgroundColor: '#28A745',
            width: '80%',
            fontFamily: 'futurabook',
            color: '#FFFFFF',
            height: '50px',
            border: 'none',
            borderRadius: '10px',
          }}
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default AddLocationSuccessModal;
