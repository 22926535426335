import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import customMarkerImage from "../Images/mapMarker.png";

const SelectSiteLocationModal = ({ onSelectLocation, onClose, selectedLat, selectedLong }) => {
  const [currentLocation, setCurrentLocation] = useState([0, 0]);
  const [modalOpen, setModalOpen] = useState(true);

  const customIcon = new L.Icon({
    iconUrl: customMarkerImage,
    iconSize: [20, 28],
  });

  const customIconHidden = new L.Icon({
    iconUrl: '',
    iconSize: [0, 0]
  });

  const saveLocation = () => {
    if (currentLocation) {
      // Round and limit decimal places to 6 for the coordinates
      const roundedLat = currentLocation[0].toFixed(6);
      const roundedLng = currentLocation[1].toFixed(6);

      console.log("Saving location:", [roundedLat, roundedLng]);
      onSelectLocation(roundedLat, roundedLng);
      onClose();
    } else {
      console.log("Location is not available.");
    }
  };

  useEffect(() => {
    let watchId;

    const updateLocation = (position) => {
      const { latitude, longitude } = position.coords;
      const newPosition = [latitude, longitude];
      setCurrentLocation(newPosition);
    };

    if (!selectedLat || !selectedLong) {
      if ("geolocation" in navigator) {
        watchId = navigator.geolocation.watchPosition(updateLocation);
      } else {
        console.log("Geolocation is not available in this browser.");
      }
    } else {
      setCurrentLocation([selectedLat, selectedLong]);
    }

    return () => {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId);
      }
    };
  }, [selectedLat, selectedLong]);

  return (
    <div className="ModalChildDiv"
      style={{
        width: "100%",
        display: 'flex',
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      {currentLocation[0] !== 0 && ( // Render MapContainer only if current location is available
        <MapContainer
          center={currentLocation}
          zoom={15}
          style={{ height: "330px", width: "100%" }}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <CustomMarker
            customIcon={customIcon}
            setCurrentLocation={setCurrentLocation}
            initialPosition={currentLocation}
          />
        </MapContainer>
      )}

      <button className="save-button"
        style={{
          width: "30%",
          marginTop: "10px"
        }}
        onClick={saveLocation}>Save Location</button>
    </div>
  );
};

const CustomMarker = ({ customIcon, setCurrentLocation, initialPosition }) => {
  const map = useMap();
  const [markerPosition, setMarkerPosition] = useState(initialPosition);

  useEffect(() => {
    if (markerPosition) {
      const marker = L.marker(markerPosition, {
        icon: customIcon,
        draggable: true,
      }).addTo(map);

      marker.on("dragend", (e) => {
        const newPosition = e.target.getLatLng();
        setCurrentLocation([newPosition.lat, newPosition.lng]);
        setMarkerPosition([newPosition.lat, newPosition.lng]);
      });

      return () => {
        map.removeLayer(marker);
      };
    }
  }, [map, markerPosition, setCurrentLocation]);

  return (
    null
  )
};

export default SelectSiteLocationModal;
