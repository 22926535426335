import React from 'react'
import GalleryTitle from '../components/GalleryComponents/GalleryTitle'
import Navbar from '../components/NavBar'
import Footer from '../components/Footer'

const GalleryPage = () => {
  return (
    <div>
        <Navbar/>
      <GalleryTitle/>
      <Footer/>
    </div>
  )
}

export default GalleryPage
