import React, { useState } from "react";
import { LockIcon } from "../../fontAwesomeComponents/FaIcons";
import { RiMailLine, RiArrowRightSLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { publicRequest } from "../../requestMethods";
import ForgotPasswordSuccessModal from "./ValidationModals/ForgotPasswordSuccessfulModal";
import { Modal } from "react-bootstrap";

const ForgotPasswordModal = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setError] = useState({});
  const [isError, setIsError] = useState(false);
  const [successModal, setOpenSuccessModal] = useState(false);

  const handleOpenSuccessModal = () => {
    setOpenSuccessModal(true);
  }

  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  }

  const toggleForgotPasswordModal= () => {
    setOpenSuccessModal(!successModal);
  }

  const resetPassword = async () => {
    try {
      const resetPassResponse = await publicRequest.put(
        `/user/resetpassword/${email}`
      );
      // Handle the reset password response
      if (resetPassResponse) {
        console.log("Password reset success");
        setOpenSuccessModal(true);
      }
    } catch (error) {
      console.error(error);
      setError(error.response.data.errors || error.response.data.title);
      setIsError(true);
    }
  };

  return (
    <div className="ModalChildDiv"
      style={{
        height: "auto",
        padding: "25px",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "100%",
          marginTop: "25px",
        }}
      >
        <div
          style={{
            marginBottom: "5px",
            backgroundColor: "#D4EDDA",
            padding: "10px",
            borderRadius: "16px",
          }}
        >
          <LockIcon height="50px" width="50px" />
        </div>
        <h2
          style={{
            fontFamily: "futuraheavy",
            fontSize: "25px",
            marginBottom: "5px",
          }}
        >
          Forgot Password
        </h2>
        <span>Enter the email address associated with your account</span>
        <div
          className="input-container"
          style={
            {
              // Add your custom styling here
            }
          }
        >
          <input
            type="text"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <RiMailLine className="input-icon" />
        </div>
        {isError && errorMessage && (
          <div
            className="error-message"
            style={{
              width: "100%",
              justifyContent: "flex-start",
              color: "red",
            }}
          >
            {typeof errorMessage === "string" ? (
              <p
                style={{
                  fontSize: "12px",
                }}
              >
                {errorMessage}
              </p>
            ) : (
              Object.values(errorMessage).map((error, index) => (
                <p
                  key={index}
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {error}
                </p>
              ))
            )}
          </div>
        )}
        <button
          className="save-button"
          style={{
            width: "100%",
            marginTop: "0",
            fontFamily: "futuraheavy",
            fontWeight: "bold",
          }}
          onClick={() => {
            resetPassword();
          }}
        >
          Continue <RiArrowRightSLine className="login-icon" />
        </button>
        <Modal show={successModal} 
      //  onHide={handleCloseSuccessModal}
         centered>
        <Modal.Body>
                        <div className="d-flex justify-content-end">
                          <button
                            type="button"
                            className="close"
                            aria-label="Close"
                            onClick={toggleForgotPasswordModal}
                            style={{
                              background: "none",
                              border: "none",
                              fontSize: "35px",
                              position: "absolute",
                              zIndex:"999"
                            }}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
          <ForgotPasswordSuccessModal onClose={toggleForgotPasswordModal}/>
          </Modal.Body>
        </Modal>
        <span>
          Back to{" "}
          <Link
            to="/login"
            style={{
              textDecoration: "none",
              color: "#231F20",
            }}
          >
            <strong>Login</strong>
          </Link>{" "}
          Page
        </span>
      </div>
    </div>
  );
};

export default ForgotPasswordModal;
