import React from 'react'
import Navbar from '../components/NavBar'
import DistributorsComponent from '../components/DistributorsPage/DistributorsComponent'
import DistributorDetails from '../components/DistributorsPage/DistributorDetails'
import DistributorHeadline from '../components/DistributorsPage/DistributorHeadline'
import DistributorLocation from '../components/DistributorsPage/DistributorLocation'
import Footer from '../components/Footer'

const Distributors = () => {
  return (
    <div
        style={{
            backgroundColor:"#141212"
        }}
    >
      <Navbar/>
      <DistributorsComponent/>
      <DistributorDetails/>
      <DistributorHeadline/>
      <DistributorLocation/>
      <Footer/>
    </div>
  )
}

export default Distributors
