import React, { useEffect, useState } from "react";
import { publicRequest } from "../../requestMethods";
import {
  ThinUnarchive,
  RefreshIcon,
  DownloadIcon,
  DeleteIcon,
} from "../../fontAwesomeComponents/FaIcons";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import "../../css/Tables.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ItemUnarchiveSuccess from "./ValidationModal/ItemUnarchiveModal";
import ConfirmDeleteItemModal from "./ValidationModal/ConfirmDeleteItemModal";
import ArchivedTagsAttachments from "../TagArchive/ArchivedTagsAttachments";
import ArchivedItemAttachments from "./ArchivedItemsAttachments";

const ItemArchiveTable = ({searchQuery}) => {
  //states

  const navigate = useNavigate();

  const handleArchiveButtonClick = () => {
    navigate("/items/deleted");
  };

  const location = useLocation();

  const isItemsArchive = location.pathname === "/items/archive";

  const [selectedItem, setSelectedItem] = useState(null);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [itemNoteList, setItemNoteList] = useState([]);
  const [isRefreshHovered, setIsRefreshHovered] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);

  const [openUnarchiveModal, setOpenUnarchiveModal] = useState(false);

  const token = useSelector((state) => state.user.token);
  const userCompanyId = useSelector((state) => state.selectedCompany);
  const id = useSelector((state) => state.user.id);

  const [itemNoteExpanded, setItemNoteExpanded] = useState({});
  const [itemDescriptionExpanded, setItemDescriptionExpanded] = useState({});

  const [openAddItemNoteModal, setOpenAddItemNoteModal] = useState(false);

  //modals
  const [openEditItemModal, setOpenEditItemModal] = useState(false);
  const [openAddItemModal, setOpenAddItemModal] = useState(false);
  const [showArchiveSuccessModal, setShowArchiveSuccessModal] = useState(false);

  const [isDelete, setIsDelete] = useState(null);
  const [isUnarchive, setIsUnarchive] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const handleOpenAddItemModal = () => {
    setOpenAddItemModal(true);
  };

  const handleCloseAddItemModal = () => {
    setOpenAddItemModal(false);
  };

  const handleOpenAddItemNoteModal = (item) => {
    setSelectedItem(item);
    setOpenAddItemNoteModal(true);
  };

  const handleCloseAddItemNoteModal = () => {
    setOpenAddItemNoteModal(false);
  };

  const handleOpenEditItemModal = (item) => {
    setSelectedItem(item);
    setOpenEditItemModal(true);
  };

  const handleCloseEditItemModal = () => {
    setOpenEditItemModal(false);
  };

  const handleOpenUnarchiveModal = (item) => {
    setSelectedItem(item);
    UnArchiveItemFunction(item);
    setOpenUnarchiveModal(true);
  };

  const handleCloseUnarchiveModal = () => {
    setOpenUnarchiveModal(false);
    refreshArchiveList();
  };

  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);

  const handleOpenConfirmDeleteModal = (item) => {
    setSelectedItem(item);
    setOpenConfirmDeleteModal(true);
  };

  const handleCloseConfirmDeleteModal = () => {
    setOpenConfirmDeleteModal(false);
  };

  const toggleConfirmDeleteModal = () => {
    setOpenConfirmDeleteModal(!openConfirmDeleteModal);
  };





  const [openItemAttachments, setOpenItemAttachments] = useState(false);

  const handleOpenItemAttachments = (item) => {
    setSelectedItem(item);
    setOpenItemAttachments(true);
  };

  const handleCloseItemAttachments = () => {
    setOpenItemAttachments(false);
  };



  ///////////////////////

  const handleSelectAllChange = () => {
    setSelectAllChecked(!selectAllChecked);
    setSelectedRows(selectAllChecked ? [] : [...Array(itemList.length).keys()]);
  };

  const handleRowSelection = (index) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(index)
        ? prevSelectedRows.filter((item) => item !== index)
        : [...prevSelectedRows, index]
    );
  };

  const formatAndConvertDate = (dateString) => {
    if (!dateString) return "";
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      timeZone: userTimezone,
    };
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString(undefined, options);
    const [month, day, year] = formattedDate.split("/");
    return `${day}/${month}/${year}`;
  };

  const formatDate = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${day}/${month}/${year}`;
  };

  const formatTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
  };

  //getting items

  const queryParams = {
    LoggedUserId: id,
    LoggedUserCompanyId: userCompanyId,
  };

  const fetchData = async (url) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const queryString = new URLSearchParams(queryParams).toString();
      const fullUrl = url + (queryString ? `?${queryString}` : "");

      const response = await publicRequest({
        method: "GET",
        url: fullUrl,
        headers: headers,
      });

      return response.data;
    } catch (error) {
      console.log("error fetching items", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchItemData = async () => {
      try {
        setIsLoading(true);
        const getItemResponse = await fetchData("/item/archive/list");
        if (getItemResponse) {
          // Convert date strings to Date objects
          getItemResponse.forEach((item) => {
            item.dateCreatedUtc = new Date(item.dateCreatedUtc);
          });

          // Sort itemsList by date in descending order
          getItemResponse.sort((a, b) => b.dateCreatedUtc - a.dateCreatedUtc);

          setItemList(getItemResponse);
        }

        const itemNotePromise = getItemResponse.map(async (item) => {
          const queryParamsForItemNotes = {
            itemId: item.id,
            LoggedUserId: userCompanyId,
            LoggedUserCompanyId: userCompanyId,
          };
          const itemNoteResponse = await fetchData(
            `/item/${item.id}/note/list`,
            queryParamsForItemNotes
          );

          // Sort the notes in descending order by dateCreatedUtc
          itemNoteResponse.sort(
            (a, b) => new Date(b.dateCreatedUtc) - new Date(a.dateCreatedUtc)
          );

          // Set the latest note to item.note
          // Create a custom string for item.note using latitude, longitude, createdOn, and createdBy
          if (itemNoteResponse.length > 0) {
            const latestNote = itemNoteResponse[0];
            item.note = `Saved in Latitude: ${
              latestNote.latitude
            }, Longitude: ${latestNote.longitude} on the ${formatDate(
              latestNote.dateCreatedUtc
            )} at${" "} ${formatTime(latestNote.dateCreatedUtc)} By ${
              latestNote.createdBy
            }`;

            item.note1 = `${latestNote.latitude}`;
          } else {
            item.note = ""; // No notes, set to an empty string
          }

          console.log("Itemnotedata", itemNoteResponse);
          setItemData(itemNoteResponse);
          return itemNoteResponse;
        });

        const itemNoteDataResponse = await Promise.all(itemNotePromise);
        setItemNoteList(itemNoteDataResponse);
        console.log("this is itemnote data", itemNoteDataResponse);
      } catch (error) {
        console.error("error fetching data", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchItemData();
  }, []);

  const fetchItemData = async () => {
    try {
      setIsLoading(true);
      const getTagResponse = await fetchData("/item/archive/list");

      console.log(itemList);
      if (getTagResponse) {
        const updatedTagsList = getTagResponse.map((tag) => {
          tag.dateCreatedUtc = new Date(tag.dateCreatedUtc);

          // Map tag notes and set the custom "Tag Note" string
          const tagNoteResponse = tag.tagNotes || [];

          // Check if there are notes
          if (tagNoteResponse.length > 0) {
            const latestNote = tagNoteResponse[0];
            const customTagNote = ` ${latestNote.note}`;

            tag.note = customTagNote;
            tag.note1 = latestNote.latitude;
          } else {
            tag.note = ""; // No notes, set to an empty string
          }

          return tag;
        });

        // Sort tagsList by date in descending order
        updatedTagsList.sort((a, b) => b.dateCreatedUtc - a.dateCreatedUtc);

        setItemList(updatedTagsList);
      }
    } catch (error) {
      console.error("error fetching data", error);
    } finally {
      setIsLoading(false);
    }
  };

  const ArchiveItem = async (item) => {
    const ArchiveItemParams = {
      LoggedUserId: id,
      LoggedUserCompanyId: userCompanyId,
    };

    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const requestConfigForArchiveItem = {
        method: "DELETE",
        headers: headers,
        params: ArchiveItemParams,
      };

      const archiveItemLink = await publicRequest(
        `/item/${item.id}/archive`,
        requestConfigForArchiveItem
      );

      if (archiveItemLink) {
        console.log("item removed");
        setShowArchiveSuccessModal(true);
      }

      //modal
    } catch (error) {
      console.error(error);
    }
  };

  const UnArchiveItemFunction = async (item) => {
    const UnarchiveParams = {
      LoggedUserId: id,
      LoggedUserCompanyId: userCompanyId,
    };

    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const requestConfigForArchiveItem = {
        method: "PUT",
        headers: headers,
        params: UnarchiveParams,
      };

      const UnarchiveItem = await publicRequest(
        `item/${item.id}/unarchive`,
        requestConfigForArchiveItem
      );

      if (UnarchiveItem) {
        console.log("item unarchived");
        setOpenUnarchiveModal(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const refreshArchiveList = async () => {
    try {
      const getTagResponse = await fetchData("/item/archive/list");
      if (getTagResponse) {
        // Convert date strings to Date objects
        getTagResponse.forEach((tag) => {
          tag.dateCreatedUtc = new Date(tag.dateCreatedUtc);
        });

        // Sort tagsList by date in descending order
        getTagResponse.sort((a, b) => b.dateCreatedUtc - a.dateCreatedUtc);

        setItemList(getTagResponse);
      }
    } catch (error) {
      console.error(error);
    }
  };


  



  //pages
  const [pagination, setPagination] = useState(1);
  const itemsPerPageOptions = [10, 20, 30];
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = itemList.slice(indexOfFirstItem, indexOfLastItem);

  const renderPageButtons = () => {
    const pageButtons = [];
    const maxPagesToShow = 3; // Set the maximum number of pages to show
  
    const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
  
    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          onClick={() => handlePageClick(i)}
          className={currentPage === i ? "active-page" : ""}
          style={{
            color: "black",
            borderRadius: "5px",
            border: "none",
            marginLeft: "10px",
            backgroundColor: currentPage === i ? "#F6C90E" : "#3A475033",
            width: "30px",
            fontFamily: "futuramedium",
            cursor: "pointer",
          }}
        >
          {i}
        </button>
      );
    }
  
    return pageButtons;
  };

  const totalPages = Math.ceil(itemList.length / itemsPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 3, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 3, 1));
  };

  useEffect(() => {
    const filteredTags = itemList.filter((tag) => {
      // Customize the conditions for filtering based on your tag structure

      return (
        tag.serialNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tag.siteName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tag.createdBy.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tag.identifiersNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tag.locationName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tag.tagItemTypeName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tag.dateAppliedUtc.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tag.description.toLowerCase().includes(searchQuery.toLowerCase()) 
        // Add more conditions as needed
        // ...
      );
    });

    setFilteredItems(filteredTags);
    setSelectAllChecked(false);
    setCurrentPage(1);

    
  }, [searchQuery, itemList]);


  return (
    <div  className="TablePageMainDiv"
      style={{
        marginLeft: "150px",
        marginRight: "150px",
        marginTop: "25px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <div
          className="approvalTitle"
          style={{
            fontFamily: "futuramedium",
            color: "#141212",
            fontSize: "30px",
            marginBottom: "20px",
          }}
        >
          {isItemsArchive ? (
            <Link
              to="/items"
              style={{ textDecoration: "none", color: "#141212" }}
            >
              <span
                style={{
                  fontFamily: "futurabook",
                }}
              >
                {" "}
                Items{" "}
              </span>{" "}
              <span style={{ color: "#141212" }}> &gt; Archive</span>
            </Link>
          ) : (
            "Items"
          )}
        </div>
      </div>
      <div
        className="minibar"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            fontFamily: "futuramedium",
          }}
        >
          <p>Show</p>
          <div
            style={{
              backgroundColor: "#e5e5e5",
              borderRadius: "10px",
              padding: "4px",
              cursor: "pointer",
              height: "30px",
              width: "50px",
              marginLeft: "1px",
              marginRight: "5px",
            }}
          >
            <select
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
              style={{
                border: "none",
                background: "none",
                cursor: "pointer",
              }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
            </select>
          </div>
          <p>items</p>
        </div>

        <div style={{ display: "flex" }}>
          {/* <p
            style={{
              color: "#3A4750",
              fontSize: "16px",
              fontFamily: "futurabook",
            }}
          >
            last refresh was 25mins ago
          </p>
          <div
            className="icon"
            style={{
              backgroundColor: "#3A475033",
              borderRadius: "50%",
              color: "#3A4750",
              width: "32px",
              height: "32px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
            }}
          >
            <RefreshIcon />
          </div> */}

<div
            className="icon"
            onMouseEnter={() => setIsRefreshHovered(true)}
            onMouseLeave={() => setIsRefreshHovered(null)}
       
            style={{
              backgroundColor: "#3A475033",
              borderRadius: "50%",
              color: "#3A4750",
              width: "32px",
              height: "32px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
              cursor: "pointer",
              position:"relative"
            }}
            onClick={fetchItemData}
          >
            <RefreshIcon />
            
            {isRefreshHovered && (
                              <p
                                style={{
                                  position: "absolute",
                                  top: "100%",
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                  background: "#ffffff",
                                  padding: "2px",
                                  borderRadius: "10px",
                                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                  zIndex: 999,
                               
                                  fontSize:"12px",
                                  width:"120px",
                                  textAlign:"center"
                                }}
                              
                              >
                                 Refresh the page
                              </p>
                            )}

          </div>
          <div
            style={{
              backgroundColor: "#3A475033",
              borderRadius: "10%",
              color: "#3A4750",
              width: "100px",
              height: "32px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
              fontFamily: "futurabook",
            }}
          >
            <button
              style={{
                border: "none",
                background: "none",
              }}
              onClick={handleArchiveButtonClick}
            >
              Deleted Items
            </button>
          </div>
          {/* <div
            className="icon"
            style={{
              backgroundColor: "#3A475033",
              borderRadius: "20%", // Use 50% for a circle
              color: "#3A4750",
              width: "32px",
              height: "32px",
              display: "flex", // Use flex display
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
            }}
          >
            <DownloadIcon />
          </div> */}
        </div>
      </div>

      {/* ------------------------------------------------------ */}
      {isLoading ? (
        <p>Loading data...</p>
      ) : (
        <>
          <div className="approvalTable">
            <table className="custom-table">
              <div className="tHead">
                <thead>
                  <tr>
                    <th>
                      <div className="checkbox-dropdown">
                        <input
                          type="checkbox"
                          id="selectAll"
                          checked={selectAllChecked}
                          onChange={handleSelectAllChange}
                        />
                        <div className="dropdown">
                          <span className="dropdown-arrow">&#9662;</span>
                          <div className="dropdown-content">
                            <div onClick={() => handleSelectAllChange(true)}>
                              Select All
                            </div>
                            <div onClick={() => handleSelectAllChange(false)}>
                              Deselect All
                            </div>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th
                      className=""
                      style={{
                        width: "300px",
                      }}
                    >
                      Applied By
                    </th>
                    <th className="" style={{ width: "250px" }}>
                      Applied Date
                    </th>
                    <th
                      className=""
                      style={{
                        width: "300px",
                      }}
                    >
                      Item Number
                    </th>
                    <th className="" style={{ width: "250px" }}>
                      Identifier Number
                    </th>
                    <th
                      className=""
                      style={{
                        width: "300px",
                      }}
                    >
                      Identifier Type
                    </th>
                    <th
                      className=""
                      style={{
                        width: "300px",
                      }}
                    >
                      Items Description
                    </th>

                    <th
                      className=""
                      style={{
                        width: "300px",
                      }}
                    >
                      Site Name
                    </th>

                    <th
                      style={{
                        width: "200px",
                      }}
                    >
                      Location Name
                    </th>
                    <th
                      className=""
                      style={{
                        width: "550px",
                      }}
                    >
                      Item Note
                    </th>

                    <th
                      className=""
                      style={{
                        width: "550px",
                      }}
                    >
                      Attachments
                    </th>
                    <th
                      style={{
                        width: "120px",
                      }}
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="tBody">
                  {currentItems.map((item, index) => (
                    <tr
                      key={index}
                      className={selectedRows.includes(index) ? "selected" : ""}
                    >
                      <td>
                        <input
                          type="checkbox"
                          id={`companyCheckbox ${index}`}
                          checked={selectedRows.includes(index)}
                          onChange={() => handleRowSelection(index)}
                        />
                        <label htmlFor={`companyCheckbox${index}`}></label>
                      </td>

                      <td>{item.createdBy}</td>
                      <td>{formatAndConvertDate(item.dateCreatedUtc)}</td>
                      <td>{item.serialNumber}</td>
                      <td>{item.identifiersNumber}</td>
                      <td>{item.tagItemTypeName}</td>

                      <td className="descriptionNoteColumn">
                        {item.description.length > 40 &&
                        !itemDescriptionExpanded[item.id] ? (
                          <>
                            {item.description.slice(0, 40)}{" "}
                            <span
                              onClick={() =>
                                setItemDescriptionExpanded({
                                  ...itemDescriptionExpanded,
                                  [item.id]: true,
                                })
                              }
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              ...
                            </span>
                          </>
                        ) : (
                          <>
                            {item.description}
                            {item.description.length > 40 && (
                              <span
                                onClick={() =>
                                  setItemDescriptionExpanded({
                                    ...itemDescriptionExpanded,
                                    [item.id]: false,
                                  })
                                }
                                style={{
                                  cursor: "pointer",
                                  color: "blue",
                                }}
                              >
                                &nbsp; See Less
                              </span>
                            )}
                          </>
                        )}
                      </td>
                      <td>{item.siteName}</td>
                      <td>{item.locationName}</td>

                      <td className="descriptionNoteColumn">
                        {item.itemNotes && item.itemNotes.length > 0 ? (
                          <>
                            {item.itemNotes[0].note.length > 40 &&
                            !itemNoteExpanded[item.id] ? (
                              <>
                                {item.itemNotes[0].note.slice(0, 40)}{" "}
                                <span
                                  onClick={() =>
                                    setItemNoteExpanded({
                                      ...itemNoteExpanded,
                                      [item.id]: true,
                                    })
                                  }
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  ...
                                </span>
                              </>
                            ) : (
                              <>
                                {item.itemNotes[0].note}
                                {item.itemNotes[0].note.length > 40 && (
                                  <span
                                    onClick={() =>
                                      setItemNoteExpanded({
                                        ...itemNoteExpanded,
                                        [item.id]: false,
                                      })
                                    }
                                    style={{
                                      cursor: "pointer",
                                      color: "blue",
                                    }}
                                  >
                                    &nbsp; See Less
                                  </span>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          "-"
                        )}
                      </td>
                      {/* <td className="descriptionNoteColumn">
                    {item.note &&
                    item.note.length > 40 &&
                    !itemNoteExpanded[item.id] ? (
                      <>
                        {item.note.slice(0, 40)}{" "}
                        <span
                          onClick={() =>
                            setItemNoteExpanded({
                              ...itemNoteExpanded,
                              [item.id]: true,
                            })
                          }
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          ...
                        </span>
                      </>
                    ) : (
                      <>
                        {item.note ? item.note : "-"}
                        {item.note && item.note.length > 40 && (
                          <span
                            onClick={() =>
                              setItemNoteExpanded({
                                ...itemNoteExpanded,
                                [item.id]: false,
                              })
                            }
                            style={{
                              cursor: "pointer",
                              color: "blue",
                            }}
                          >
                            &nbsp; See Less
                          </span>
                        )}
                      </>
                    )}
                  </td> */}

                      <td
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => handleOpenItemAttachments(item)}
                      >
                        view
                      </td>
                      {selectedItem === item && ( // Check if this row's modal should be open
                    <Modal size="lg" centered show={openItemAttachments}>
                      <Modal.Body>
                        <div className="d-flex justify-content-end">
                          <button
                            type="button"
                            className="close"
                            aria-label="Close"
                            onClick={handleCloseItemAttachments}
                            style={{
                              background: "none",
                              border: "none",
                              fontSize: "35px",
                              position: "absolute",
                            
                            }}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <ArchivedItemAttachments item={selectedItem} />
                      </Modal.Body>
                    </Modal>
                  )}
                      <td>
                        <div className="iconBoxes">
                          <div
                            className="iconBox"
                            onMouseEnter={() => setIsUnarchive(item)}
                            onMouseLeave={() => setIsUnarchive(null)}
                            style={{
                              cursor: "pointer",
                              position: "relative",
                            }}
                            onClick={() => handleOpenUnarchiveModal(item)}
                          >
                            <ThinUnarchive
                              height="15px"
                              width="15px"
                              fill="#"
                            />
                            {isUnarchive === item && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "100%",
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                  background: "#ffffff",
                                  padding: "2px",
                                  borderRadius: "10px",
                                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                  zIndex: 999,
                                  width: "100px",
                                  fontSize: "12px",
                                }}
                              >
                                Unarchive Item
                              </div>
                            )}
                          </div>

                          {selectedItem === item && (
                            <Modal
                              centered
                              show={openUnarchiveModal}
                              //    onHide={() => handleCloseUnarchiveModal}
                            >
                              <Modal.Body>
                                <div className="d-flex justify-content-end">
                                  <button
                                    type="button"
                                    className="close"
                                    aria-label="Close"
                                    onClick={handleCloseUnarchiveModal}
                                    style={{
                                      background: "none",
                                      border: "none",
                                      fontSize: "35px",
                                      position: "absolute",
                                      zIndex: "999",
                                    }}
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <ItemUnarchiveSuccess
                                  item={selectedItem}
                                  onClose={handleCloseUnarchiveModal}
                                />
                              </Modal.Body>
                            </Modal>
                          )}

                          <div
                            className="iconBox"
                            onMouseEnter={() => setIsDelete(item)}
                            onMouseLeave={() => setIsDelete(null)}
                            style={{
                              cursor: "pointer",
                              position: "relative",
                            }}
                            onClick={() => handleOpenConfirmDeleteModal(item)}
                          >
                            <DeleteIcon height="13px" width="13px" fill="#" />
                            {isDelete === item && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "100%",
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                  background: "#ffffff",
                                  padding: "2px",
                                  borderRadius: "10px",
                                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                  zIndex: 999,
                                  width: "100px",
                                  fontSize: "12px",
                                }}
                              >
                                Delete Item
                              </div>
                            )}
                          </div>
                          {selectedItem === item && (
                            <Modal
                              centered
                              show={openConfirmDeleteModal}
                              onHide={handleCloseConfirmDeleteModal}
                            >
                              <ConfirmDeleteItemModal
                                item={selectedItem}
                                itemId={item.id}
                                onClose={toggleConfirmDeleteModal}
                                refreshArchiveList={refreshArchiveList}
                              />
                            </Modal>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </div>
            </table>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <div>
              Showing {Math.min(indexOfLastItem, itemList.length)} of{" "}
              {itemList.length} items
            </div>
            <div
              style={{
                marginRight: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <button
                onClick={handlePreviousPage}
                style={{
                  color: "black",
                  borderRadius: "5px",
                  border: "none",
                  marginLeft: "10px",
                  backgroundColor: "#3A475033",
                  width: "80px",
                  fontFamily: "futuramedium",
                  cursor: "pointer",
                }}
              >
                Previous
              </button>

              {renderPageButtons()}

              <button
                onClick={handleNextPage}
                style={{
                  color: "black",
                  borderRadius: "5px",
                  border: "none",
                  marginLeft: "10px",
                  backgroundColor: "#3A475033",
                  width: "60px",
                  fontFamily: "futuramedium",
                  cursor: "pointer",
                }}
              >
                Next
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ItemArchiveTable;
