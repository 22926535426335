import React from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import customMarkerImage from "../Images/mapMarker.png";

const ViewMapLocation = ({ onSelectLocation, onClose, initialLatitude, initialLongitude }) => {
  const customIcon = new L.Icon({
    iconUrl: customMarkerImage,
    iconSize: [20, 28],
  });

  const saveLocation = () => {
    if (initialLatitude && initialLongitude) {
      onSelectLocation(initialLatitude.toFixed(6), initialLongitude.toFixed(6));
      onClose();
    } else {
      console.log("Initial location data is not available.");
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {initialLatitude !== null && initialLongitude !== null ? (
        <MapContainer
          center={[initialLatitude, initialLongitude]} // Set the initial center to the provided latitude and longitude
          zoom={15}
          style={{ height: "330px", width: "100%" }}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Marker position={[initialLatitude, initialLongitude]} icon={customIcon} draggable={false} />
        </MapContainer>
      ) : (
        <div
          style={{
            backgroundColor:"#DC3545",
            width:"100%",
            display:"flex",
            alignItems:"center",
            justifyContent:'center'
          }}
        >
      <p
        style={{
          color:"#EEEEEE",
          textAlign:"center",
      
        }}
      >Location unavailable</p>
        </div>
  
      )}

      {/* <button
        className="save-button"
        style={{
          width: "30%",
          marginTop: "10px",
        }}
        onClick={saveLocation}
      >
        Save Location
      </button> */}
    </div>
  );
};

export default ViewMapLocation;
