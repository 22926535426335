import React, { useState } from "react";
import Navbar from '../components/NavBar'
import UserApprovalBar from '../components/UserApproval/UserApprovalBar'
import LocationsTable from '../components/LocationListComponents/LocationsTable'

const Locations = () => {

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (query) => {
    // Update the search query state
    setSearchQuery(query);
  };

  return (
    <div
      style={{
        background:"#EEEEEE",
        height:"100vh || 100%"
      }}
    >
        <Navbar/>
        <UserApprovalBar searchQuery={searchQuery} onSearch={handleSearch} />
        <LocationsTable searchQuery={searchQuery}/>
    </div>
  )
}

export default Locations