import React, {useState} from 'react'
import Navbar from '../components/NavBar';
import UserApprovalBar from '../components/UserApproval/UserApprovalBar';
import TagsArchiveTable from '../components/TagArchive/TagsArchiveTable';

const TagsArchive = () => {

  
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (query) => {
    // Update the search query state
    setSearchQuery(query);
  };



  return (
    <div>
    <div
      style={{
        backgroundColor: "#EEEEEE",
        minHeight: "100vh",
        paddingBottom: "25px",
        display: "flex",
        flexDirection: "column"
      }}
    >
      <Navbar />
      <UserApprovalBar searchQuery={searchQuery} onSearch={handleSearch} />
      <TagsArchiveTable searchQuery={searchQuery}/>
    </div>
  </div>
  )
}

export default TagsArchive
