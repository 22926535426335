import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedCompany, setSelectedCompanyName, setSelectedCompanyId, setSelectUserCompanyId, setSelectedCompanyRoleId } from '../redux/userRedux';
import { Navigate } from 'react-router-dom';
import '../css/selectCompanycss/selectCompany.css';

const CompanySelector = () => {
  const user = useSelector((state) => state.user);
  const selectedCompany = useSelector((state) => state.selectedCompany);
  const selectedCompanyName = useSelector((state) => state.selectedCompanyName);
  const selectedCompanyId = useSelector((state ) => state.selectedUserCompanyId);
  
  const dispatch = useDispatch();

  const handleCompanySelect = (selectedUserCompanyId, selectedCompanyName, selectedCompanyId, selectedCompanyRoleId) => {
    dispatch(setSelectedCompany(selectedUserCompanyId));
    dispatch(setSelectedCompanyName(selectedCompanyName)); // Set selected company name
    dispatch(setSelectedCompanyId(selectedCompanyId));
    dispatch(setSelectedCompanyRoleId(selectedCompanyRoleId));


    console.log(selectedCompanyRoleId);
  };

 
  // Check if a company is selected, and if yes, redirect to '/tags'
  if (selectedCompany) {
    return <Navigate to="/tags" replace />;
  }

  return (
    <div className="selectCompanyContainer">
      <div className="selectCompanyOverlay"></div>
      <div className="selectContainer">
        <div className='logoContainer-selectCompany'></div>
        <h4 style={{ fontFamily: "futuramedium" }}>Select a Company to Proceed:</h4>
        <div className="selectionContainer">
          <select
            onChange={(e) =>
              handleCompanySelect(
                e.target.value,
                e.target.selectedOptions[0].text,
              e.target.options[e.target.selectedIndex].getAttribute("data-companyid"),
                // e.target.options[e.target.selectedIndex].getAttribute("data-userCompanyid"),
                e.target.options[e.target.selectedIndex].getAttribute("data-role"), // Get data-role
              )
            }
            className="companyDropdown"
          >
            <option value="">Select Company</option>
            {user.userCompany.map((company) => (
              <option
                key={company.userCompanyId}
                value={company.userCompanyId}
                data-companyid={company.companyId}
                data-userCompanyid={company.userCompanyId}
                data-role={company.role} 
                className="companyOption"
              >
                {company.companyName}
              </option>
            ))}
          </select>
        </div>
        {/* {selectedCompanyName && (
          <p className="selectedCompanyText">Selected Company: {selectedCompanyName}</p>
        )}aasdas*/}
      </div>
    </div>
  );
};

export default CompanySelector;
