import React from "react";
import { FiImage } from "react-icons/fi";
import "../../css/homecss/OfficialDistributors.css"; // Import your CSS file
import "../../Images/ipp_logo.png";

const OfficialDistributors = () => {
  return (
    <div className="officialDistributors" id="distributor">
      <div className="title">
        <h3>Official Distributor</h3>
      </div>
      <div
        className="distributorList"
        style={{
          marginLeft: "150px",
          marginRight: "150px",
          marginBottom: "5vh",
        }}
      >
        <p
          style={{
            fontFamily:"futurabook",
            fontSize:"16px"
          }}
        >
          If you are a large corporation or an electrician seeking to enhance
          electrical safety in your workplace or projects, it is crucial to
          consider the purchase of a Safe T Tag electrical safety blocking
          device. The Safe T Tag is specifically designed to prevent the risk of
          electrocution caused by faulty electrical appliances or equipment.
          With a very affordable price, you can easily obtain this device from
          our list of official distributors. By choosing one of these trusted
          distributors, you can ensure that you are acquiring a reliable and
          effective solution to safeguard your employees and clients from
          electrical accidents. <br/>Don't compromise on safety - invest in a Safe T
          Tag electrical safety blocking device today.
        </p>
        <div className="distributorItem">
          <div className="iconDistributor">
            <img />
          </div>
          <h4 className="h4Distributor">Industrial Protective Products</h4>
          <h5 style={{ fontFamily: "futura, sans-serif", fontSize: "13px" }}>
            (08) 9330 6355
          </h5>
          <a
            className="DistributorP"
            href="https://www.ippwa.com.au/"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
            }}
          >
            ippwa.com.au
          </a>
        </div>
      </div>
    </div>
  );
};

export default OfficialDistributors;
