import React, { useEffect, useState } from "react";
import "../../css/userApprovalcss/userApproval.css";
import "../../css/Tables.css";
import { publicRequest, userRequest } from "../../requestMethods";
import { useLocation, useNavigate } from "react-router-dom";
import {
  UndoDelete,
  RefreshIcon,
  DownloadIcon,
  DeleteIcon,
} from "../../fontAwesomeComponents/FaIcons";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import FailedUndeleteTag from "./ValidationModals/FailedUndeleteTag";
import UndeleteTagSuccess from "./ValidationModals/UndeleteTagSuccess";
import DeletedTagsAttachments from "./DeletedTagsAttachments";

const DeletedTagsTable = ({searchQuery}) => {
  const [selectedTag, setSelectedTag] = useState(null);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [tagData, setTagData] = useState([]);
  const [tagNoteList, setTagNoteList] = useState([]);
  const [tagNote, setTagNote] = useState();
  const [tagNoteExpanded, setTagNoteExpanded] = useState({});
  const [tagDescriptionExpanded, setTagDescriptionExpanded] = useState({});
  const [isUndelete, setIsUndete] = useState(null);
  const [isRefreshHovered, setIsRefreshHovered] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const isTagsArchive = location.pathname === "/tags/deleted";

  //modals

  const [openUndeleteTagSuccess, setOpenUndeleteTagSuccess] = useState(false);
  const [openFailedUndeleteTag, setOpenFailedUndeleteTag] = useState(false);
  const [filteredTags, setFilteredTags] = useState([]);

  
  const [openTagAttachments, setOpenTagAttachments] = useState(false);

  const handleOpenTagAttachments = (tag) => {
    setSelectedTag(tag);
    setOpenTagAttachments(true);
  };

  const handleCloseTagAttachments = () => {
    setOpenTagAttachments(false);
  };


  const [isLoading, setIsLoading] = useState(false);

  const handleOpenUndeleteSuccess = (tag) => {
    setSelectedTag(tag);
    setOpenUndeleteTagSuccess(true);
    UndeleteTag(tag);
  };

  const handleCloseUndeleteTag = () => {
    setOpenUndeleteTagSuccess(false);
  };

  const handleOpenFailedUndelete = (tag) => {
    setSelectedTag(tag);
    setOpenFailedUndeleteTag(true);
  };

  const handleCloseFailedUndelete = () => {
    setOpenFailedUndeleteTag(false);
  };

  //

  const handleSelectAllChange = () => {
    setSelectAllChecked(!selectAllChecked);
    setSelectedRows(selectAllChecked ? [] : [...Array(tagsList.length).keys()]);
  };

  const handleRowSelection = (index) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(index)
        ? prevSelectedRows.filter((item) => item !== index)
        : [...prevSelectedRows, index]
    );
  };

  const token = useSelector((state) => state.user.token);
  const userCompanyId = useSelector((state) => state.selectedCompany);
  const id = useSelector((state) => state.user.id);

  const formatAndConvertDate = (dateString) => {
    if (!dateString) return "";
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      timeZone: userTimezone,
    };
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString(undefined, options);
    const [month, day, year] = formattedDate.split("/");
    return `${day}/${month}/${year}`;
  };

  const formatDate = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${day}/${month}/${year}`;
  };

  const formatTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
  };

  const handleDeleteButtonClick = () => {
    navigate("/tags/deleted");
  };

  //getting tags

  const queryParams = {
    LoggedUserId: id,
    LoggedUserCompanyId: userCompanyId,
  };

  const fetchData = async (url) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const queryString = new URLSearchParams(queryParams).toString();
      const fullUrl = url + (queryString ? `?${queryString}` : "");

      const response = await publicRequest({
        method: "GET",
        url: fullUrl,
        headers: headers,
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching data", error);
      return null;
    }
  };

  //getting tag details

  const fetchTagData = async () => {
    try {
      setIsLoading(true);
      const getTagResponse = await fetchData("/tag/deleted/list");

      console.log(tagsList);
      if (getTagResponse) {
        const updatedTagsList = getTagResponse.map((tag) => {
          tag.dateCreatedUtc = new Date(tag.dateCreatedUtc);

          // Map tag notes and set the custom "Tag Note" string
          const tagNoteResponse = tag.tagNotes || [];

          // Check if there are notes
          if (tagNoteResponse.length > 0) {
            const latestNote = tagNoteResponse[0];
            const customTagNote = ` ${latestNote.note}`;

            tag.note = customTagNote;
            tag.note1 = latestNote.latitude;
          } else {
            tag.note = ""; // No notes, set to an empty string
          }

          return tag;
        });

        // Sort tagsList by date in descending order
        updatedTagsList.sort((a, b) => b.dateCreatedUtc - a.dateCreatedUtc);

        setTagsList(updatedTagsList);
      }
    } catch (error) {
      console.error("error fetching data", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchTagData = async () => {
      try {
        const queryParamsForDeletedTags = {
          LoggedUserId: id,
          LoggedUserCompanyId: userCompanyId,
        };

        const getTagResponse = await fetchData(
          "/tag/deleted/list",
          queryParamsForDeletedTags
        );
        if (getTagResponse) {
          // Convert date strings to Date objects
          getTagResponse.forEach((tag) => {
            tag.dateCreatedUtc = new Date(tag.dateCreatedUtc);
          });

          // Sort tagsList by date in descending order
          getTagResponse.sort((a, b) => b.dateCreatedUtc - a.dateCreatedUtc);

          setTagsList(getTagResponse);
          console.log(tagsList);
        }

        // const tagNotePromise = getTagResponse.map(async (tag) => {
        //   const queryParamsForTagNotes = {
        //     tagId: tag.id,
        //     LoggedUserId: userCompanyId,
        //     LoggedUserCompanyId: userCompanyId,
        //   };
        //   const tagNoteResponse = await fetchData(
        //     `/tag/${tag.id}/note/list`,
        //     queryParamsForTagNotes
        //   );

        //   // Sort the notes in descending order by dateCreatedUtc
        //   tagNoteResponse.sort(
        //     (a, b) => new Date(b.dateCreatedUtc) - new Date(a.dateCreatedUtc)
        //   );

        //   // Set the latest note to tag.note
        //   // Create a custom string for tag.note using latitude, longitude, createdOn, and createdBy
        //   if (tagNoteResponse.length > 0) {
        //     const latestNote = tagNoteResponse[0];
        //     tag.note = `Saved in Latitude: ${latestNote.latitude}, Longitude: ${
        //       latestNote.longitude
        //     } on the ${formatDate(
        //       latestNote.dateCreatedUtc
        //     )} at${" "} ${formatTime(latestNote.dateCreatedUtc)} By ${
        //       latestNote.createdBy
        //     }`;

        //     tag.note1 = `${latestNote.latitude}`;
        //   } else {
        //     tag.note = ""; // No notes, set to an empty string
        //   }

        //   console.log("tagnotedata", tagNoteResponse);
        //   setTagData(tagNoteResponse);
        //   return tagNoteResponse;
        // });

        // const tagNoteDataResponse = await Promise.all(tagNotePromise);
        // setTagNoteList(tagNoteDataResponse);
        // console.log("this is tagnote data", tagNoteDataResponse);
      } catch (error) {
        console.error("error fetching data", error);
      }
    };

    fetchTagData();
  }, []);


  useEffect(() => {
    const filteredTags = tagsList.filter((tag) => {
      // Customize the conditions for filtering based on your tag structure

      return (
        tag.serialNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tag.siteName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tag.createdBy.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tag.identifiersNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tag.locationName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tag.tagItemTypeName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tag.dateAppliedUtc.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tag.description.toLowerCase().includes(searchQuery.toLowerCase()) 
        // ...
      );
    });

    setFilteredTags(filteredTags);
    setSelectAllChecked(false);
    setCurrentPage(1);

    // getTagBySerialNumber();
  }, [searchQuery, tagsList]);

  const refreshArchiveList = async () => {
    try {
      setIsLoading(true);
      const getTagResponse = await fetchData("/tag/deleted/list");
      if (getTagResponse) {
        // Convert date strings to Date objects
        getTagResponse.forEach((tag) => {
          tag.dateCreatedUtc = new Date(tag.dateCreatedUtc);
        });

        // Sort tagsList by date in descending order
        getTagResponse.sort((a, b) => b.dateCreatedUtc - a.dateCreatedUtc);

        setTagsList(getTagResponse);
        console.log(tagsList);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const UndeleteTag = async (tag) => {
    const UndeleteTagParams = {
      LoggedUserId: id,
      LoggedUserCompanyId: userCompanyId,
    };

    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const requestConfigForArchiveTag = {
        method: "PUT",
        headers: headers,
        params: UndeleteTagParams,
      };

      const archiveTagLink = await publicRequest(
        `/tag/${tag.id}/undelete`,
        requestConfigForArchiveTag
      );

      if (archiveTagLink) {
        console.log("tag undeleted");
      }
      setOpenUndeleteTagSuccess(true);
      //modal
    } catch (error) {
      console.error(error);
      setOpenFailedUndeleteTag(true);
    }
  };

  // Fetch data for each tag

  // const tagDataPromises = getTagResponse.map(async (tag) => {
  //   const queryParamsForTags = {
  //     LoggedUserId: id,
  //     LoggedUserCompanyId: userCompanyId,
  //     TagId: tag.id,
  //   };
  //   const tagResponse = await fetchData("/tag", queryParamsForTags);
  //   return tagResponse;
  // });

  // const tagDataResponse = await Promise.all(tagDataPromises);
  // setTagData(tagDataResponse);
  // console.log("this is tagdata", tagDataResponse);

  //pages
  const [pagination, setPagination] = useState(1);
  const itemsPerPageOptions = [10, 20, 30];
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tagsList.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 3, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 3, 1));
  };

  const renderPageButtons = () => {
    const pageButtons = [];
    const maxPagesToShow = 3; // Set the maximum number of pages to show
  
    const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
  
    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          onClick={() => handlePageClick(i)}
          className={currentPage === i ? "active-page" : ""}
          style={{
            color: "black",
            borderRadius: "5px",
            border: "none",
            marginLeft: "10px",
            backgroundColor: currentPage === i ? "#F6C90E" : "#3A475033",
            width: "30px",
            fontFamily: "futuramedium",
            cursor: "pointer",
          }}
        >
          {i}
        </button>
      );
    }
  
    return pageButtons;
  };
  
  const totalPages = Math.ceil(tagsList.length / itemsPerPage);

  return (
    <div  className="TablePageMainDiv"
      style={{
        marginLeft: "150px",
        marginRight: "150px",
        marginTop: "25px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <div
          className="approvalTitle"
          style={{
            fontFamily: "futuramedium",
            color: "#141212",
            fontSize: "30px",
            marginBottom: "20px",
          }}
        >
          {isTagsArchive ? (
            <Link to="/" style={{ textDecoration: "none", color: "#141212" }}>
              <span
                style={{
                  fontFamily: "futurabook",
                }}
              >
                {" "}
                Tags{" "}
              </span>{" "}
              <span style={{ color: "#141212" }}> &gt; Deleted</span>
            </Link>
          ) : (
            "Tags"
          )}
        </div>
      </div>
      <div
        className="minibar"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            fontFamily: "futuramedium",
          }}
        >
          <p>Show</p>
          <div
            style={{
              backgroundColor: "#e5e5e5",
              borderRadius: "10px",
              padding: "4px",
              cursor: "pointer",
              height: "30px",
              width: "50px",
              marginLeft: "1px",
              marginRight: "5px",
            }}
          >
            <select
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
              style={{
                border: "none",
                background: "none",
                cursor: "pointer",
              }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
            </select>
          </div>
          <p>items</p>
        </div>

        <div style={{ display: "flex" }}>
        <div
            className="icon"
            onMouseEnter={() => setIsRefreshHovered(true)}
            onMouseLeave={() => setIsRefreshHovered(null)}
       
            style={{
              backgroundColor: "#3A475033",
              borderRadius: "50%",
              color: "#3A4750",
              width: "32px",
              height: "32px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
              cursor: "pointer",
              position:"relative"
            }}
            onClick={fetchTagData}
          >
            <RefreshIcon />
            
            {isRefreshHovered && (
                              <p
                                style={{
                                  position: "absolute",
                                  top: "100%",
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                  background: "#ffffff",
                                  padding: "2px",
                                  borderRadius: "10px",
                                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                  zIndex: 999,
                                
                                  fontSize:"12px",
                                  width:"100px",
                                  textAlign:"center"
                                }}
                              
                              >
                                    Refresh the page
                              </p>
                            )}

          </div>
          {/* <p
            style={{
              color: "#3A4750",
              fontSize: "16px",
              fontFamily: "futurabook",
            }}
          >
            last refresh was 25mins ago
          </p>
          <div
            className="icon"
            style={{
              backgroundColor: "#3A475033",
              borderRadius: "50%",
              color: "#3A4750",
              width: "32px",
              height: "32px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
            }}
          >
            <RefreshIcon />
          </div> */}
          {/* <div
            style={{
              backgroundColor: "#3A475033",
              borderRadius: "10%",
              color: "#3A4750",
              width: "100px",
              height: "32px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
              fontFamily: "futurabook",
            }}
          >
            <button
              style={{
                border: "none",
                background: "none",
              }}
            >
              Show Tags
            </button>
          </div> */}
          {/* <div
            style={{
              backgroundColor: "#3A475033",
              borderRadius: "10%",
              color: "#3A4750",
              width: "100px",
              height: "32px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
              fontFamily: "futurabook",
            }}
          >
            <button
              style={{
                border: "none",
                background: "none",
              }}
              onClick={handleDeleteButtonClick}
            >
              Deleted Tags
            </button>
          </div> */}
          {/* <div
            className="icon"
            style={{
              backgroundColor: "#3A475033",
              borderRadius: "20%", // Use 50% for a circle
              color: "#3A4750",
              width: "32px",
              height: "32px",
              display: "flex", // Use flex display
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
            }}
          >
            <DownloadIcon />
          </div> */}
        </div>
      </div>
      {/* ------------------------------------------------------ */}
      {isLoading ? (
        <p>Loading data...</p>
      ) : (
        <>
          <div className="approvalTable">
            <table className="custon-table">
              <div className="tHead">
                <thead>
                  <tr>
                    <th>
                      <div className="checkbox-dropdown">
                        <input
                          type="checkbox"
                          id="selectAll"
                          checked={selectAllChecked}
                          onChange={handleSelectAllChange}
                        />
                        <div className="dropdown">
                          <span className="dropdown-arrow">&#9662;</span>
                          <div className="dropdown-content">
                            <div onClick={() => handleSelectAllChange(true)}>
                              Select All
                            </div>
                            <div onClick={() => handleSelectAllChange(false)}>
                              Deselect All
                            </div>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th
                      className=""
                      style={{
                        width: "250px",
                      }}
                    >
                      Applied By
                    </th>
                    <th className="" style={{ width: "250px" }}>
                      Applied Date
                    </th>
                    <th className="" style={{ width: "250px" }}>
                      Tag Number
                    </th>
                    <th className="" style={{ width: "250px" }}>
                      Identifier Number
                    </th>
                    <th className="" style={{ width: "250px" }}>
                      Identifier Type
                    </th>
                    <th className="" style={{ width: "350px" }}>
                      Tags Description
                    </th>
                    {/* <th>Location Name</th> */}
                    <th className="" style={{ width: "250px" }}>
                      Site Name
                    </th>

                    <th className="" style={{ width: "250px" }}>
                      Location Name
                    </th>

                    <th
                      className=""
                      style={{
                        width: "550px",
                      }}
                    >
                      Tag Note
                    </th>

                    <th
                      className=""
                      style={{
                        width: "550px",
                      }}
                    >
                      Attachments
                    </th>

                    {/* <th
                  className=""
                  style={{
                    width: "550px",
                  }}
                >
                  Tag Note
                </th> */}

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="tBody">
                  {currentItems.map((tag, index) => (
                    <tr
                      key={index}
                      className={selectedRows.includes(index) ? "selected" : ""}
                    >
                      <td>
                        <input
                          type="checkbox"
                          id={`companyCheckbox ${index}`}
                          checked={selectedRows.includes(index)}
                          onChange={() => handleRowSelection(index)}
                        />
                        <label htmlFor={`companyCheckbox${index}`}></label>
                      </td>

                      <td
                      // style={{
                      //   minWidth: "150px",
                      // }}
                      >
                        {tag ? tag.createdBy : "Loading..."}
                      </td>
                      <td>{formatAndConvertDate(tag.dateCreatedUtc)}</td>
                      <td>{tag.serialNumber}</td>
                      <td>{tag.identifiersNumber}</td>
                      <td>{tag.tagItemTypeName}</td>
                      <td className="descriptionNoteColumn">
                        {tag.description.length > 40 &&
                        !tagDescriptionExpanded[tag.id] ? (
                          <>
                            {tag.description.slice(0, 40)}{" "}
                            <span
                              onClick={() =>
                                setTagDescriptionExpanded({
                                  ...tagDescriptionExpanded,
                                  [tag.id]: true,
                                })
                              }
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              ...
                            </span>
                          </>
                        ) : (
                          <>
                            {tag.description}
                            {tag.description.length > 40 && (
                              <span
                                onClick={() =>
                                  setTagDescriptionExpanded({
                                    ...tagDescriptionExpanded,
                                    [tag.id]: false,
                                  })
                                }
                                style={{
                                  cursor: "pointer",
                                  color: "blue",
                                }}
                              >
                                &nbsp; See Less
                              </span>
                            )}
                          </>
                        )}
                      </td>
                      {/* <td>{tag.locationName}</td> */}
                      <td>{tag.siteName}</td>
                      <td>{tag.locationName}</td>
                      <td className="descriptionNoteColumn">
                        {tag.tagNotes && tag.tagNotes.length > 0 ? (
                          <>
                            {tag.tagNotes[0].note.length > 40 &&
                            !tagNoteExpanded[tag.id] ? (
                              <>
                                {tag.tagNotes[0].note.slice(0, 40)}{" "}
                                <span
                                  onClick={() =>
                                    setTagNoteExpanded({
                                      ...tagNoteExpanded,
                                      [tag.id]: true,
                                    })
                                  }
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  ...
                                </span>
                              </>
                            ) : (
                              <>
                                {tag.tagNotes[0].note}
                                {tag.tagNotes[0].note.length > 40 && (
                                  <span
                                    onClick={() =>
                                      setTagNoteExpanded({
                                        ...tagNoteExpanded,
                                        [tag.id]: false,
                                      })
                                    }
                                    style={{
                                      cursor: "pointer",
                                      color: "blue",
                                    }}
                                  >
                                    &nbsp; See Less
                                  </span>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          "-"
                        )}
                      </td>

                      <td
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => handleOpenTagAttachments(tag)}
                  >
                    view
                  </td>
                  {selectedTag === tag && ( // Check if this row's modal should be open
                    <Modal size="lg" centered show={openTagAttachments}>
                      <Modal.Body>
                        <div className="d-flex justify-content-end">
                          <button
                            type="button"
                            className="close"
                            aria-label="Close"
                            onClick={handleCloseTagAttachments}
                            style={{
                              background: "none",
                              border: "none",
                              fontSize: "35px",
                              position: "absolute",
                            
                            }}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <DeletedTagsAttachments tag={selectedTag} />
                      </Modal.Body>
                    </Modal>
                  )}
                      <td>
                        <div
                          className="iconBoxes"
                          style={{
                            marginLeft: "-1px",
                          }}
                        >
                          <div
                            className="iconBox"
                            onMouseEnter={() => setIsUndete(tag)}
                            onMouseLeave={() => setIsUndete(null)}
                            style={{
                              cursor: "pointer",
                              position: "relative",
                            }}
                            onClick={() => handleOpenUndeleteSuccess(tag)}
                          >
                            <UndoDelete height="15px" width="15px" fill="#" />
                            {isUndelete === tag && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "100%",
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                  background: "#ffffff",
                                  padding: "2px",
                                  borderRadius: "10px",
                                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                  zIndex: 999,
                                  width: "100px",
                                  fontSize: "12px",
                                }}
                              >
                                Undelete Tag
                              </div>
                            )}
                          </div>
                          {selectedTag === tag && (
                            <Modal
                              centered
                              show={openUndeleteTagSuccess}
                              // onHide={handleCloseUndeleteTag}
                            >
                              <Modal.Body>
                                <div className="d-flex justify-content-end">
                                  <button
                                    type="button"
                                    className="close"
                                    aria-label="Close"
                                    onClick={handleCloseUndeleteTag}
                                    style={{
                                      background: "none",
                                      border: "none",
                                      fontSize: "35px",
                                      position: "absolute",
                                    }}
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <UndeleteTagSuccess
                                  tag={selectedTag}
                                  onClose={handleCloseUndeleteTag}
                                  refreshArchiveList={refreshArchiveList}
                                />
                              </Modal.Body>
                            </Modal>
                          )}

                          {selectedTag === tag && (
                            <Modal
                              centered
                              show={openFailedUndeleteTag}
                              onHide={handleCloseFailedUndelete}
                            >
                              <Modal.Body>
                                <div className="d-flex justify-content-end">
                                  <button
                                    type="button"
                                    className="close"
                                    aria-label="Close"
                                    onClick={handleCloseFailedUndelete}
                                    style={{
                                      background: "none",
                                      border: "none",
                                      fontSize: "35px",
                                      position: "absolute",
                                    }}
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <FailedUndeleteTag
                                  tag={selectedTag}
                                  onClose={handleCloseFailedUndelete}
                                />
                              </Modal.Body>
                            </Modal>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </div>
            </table>
          </div>
        <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <div>
              Showing {Math.min(indexOfLastItem, filteredTags.length)} of{" "}
              {filteredTags.length} items
            </div>
            <div
              style={{
                marginRight: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <button
                onClick={handlePreviousPage}
                style={{
                  color: "black",
                  borderRadius: "5px",
                  border: "none",
                  marginLeft: "10px",
                  backgroundColor: "#3A475033",
                  width: "80px",
                  fontFamily: "futuramedium",
                  cursor: "pointer",
                }}
              >
                Previous
              </button>

              {renderPageButtons()}

              <button
                onClick={handleNextPage}
                style={{
                  color: "black",
                  borderRadius: "5px",
                  border: "none",
                  marginLeft: "10px",
                  backgroundColor: "#3A475033",
                  width: "60px",
                  fontFamily: "futuramedium",
                  cursor: "pointer",
                }}
              >
                Next
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DeletedTagsTable;
