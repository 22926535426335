import React, { useState, useEffect } from "react";
import { publicRequest } from "../../requestMethods";
import { Modal } from "react-bootstrap";
import { FaLocationArrow } from "react-icons/fa";
import { useSelector } from "react-redux";
import ViewMapLocation from "../../modals/ViewMapLocation";



const LocationViewerModal = ({tag, item}) => {

    const locationId = tag ? tag.locationId : item.locationId;
    const SelectedSiteId = tag ? tag.siteId : item.siteId;
    const [isSelectLocationHovered, setIsSelectLocationHovered] = useState(false);
    const [showSelectLocationModal, setShowSelectLocationModal] = useState(false);
    const [locationData, setLocationData] = useState([]);
    const handleCloseSelectLocationModal = () => {
        setShowSelectLocationModal(false);
      };

    const token = useSelector((state) => state.user.token);
    const userCompanyId = useSelector((state) => state.selectedCompany);
    const id = useSelector((state) => state.user.id);
  
    const queryParams = {
      LoggedUserId: id,
      LoggedUserCompanyId: userCompanyId,
    
    };
    
    const queryParamsForLocation = {
        LoggedUserId: id,
        LoggedUserCompanyId: userCompanyId,
        siteId: SelectedSiteId
    }


    const fetchData = async (url) => {
        try {
          const headers = {
            Authorization: `Bearer ${token}`,
          };
    
          const queryString = new URLSearchParams(queryParamsForLocation).toString();
          const fullUrl = url + (queryString ? `?${queryString}` : "");
    
          const response = await publicRequest({
            method: "GET",
            url: fullUrl,
            headers: headers,
          });
    
          return response.data;
        } catch (error) {
          console.error("Error fetching data", error);
          return null;
        }
      };


      useEffect(() => {
        const fetchLocationData = async () => {
            try{
                const getLocationResponse = await fetchData(`/location/${locationId}`, queryParamsForLocation);

                if(getLocationResponse) {
                    setLocationData(getLocationResponse);
                    console.log(locationData);
                }
            }catch(error){
                
            }
        }
        fetchLocationData();
      },[]);
      

      console.log(locationData);
      

    console.log(tag);

    const handleSelectLocationClick = () => {
        if (navigator.geolocation) {
          // If Geolocation API is supported by the browser
          navigator.geolocation.getCurrentPosition(
            (position) => {
              // If the user allows location access
              setShowSelectLocationModal(true);
            },
            (error) => {
              // If the user denies location access or there's an error
              alert("You need to allow location access to proceed.");
            }
          );
        } else {
          // If Geolocation API is not supported by the browser
          alert("Geolocation is not supported by your browser.");
        }
      };

    const [userInput, setUserInput] = useState({
        name: locationData.name,
        addressLine1: locationData.addressLine1,
        addressLine2: locationData.addressLine2,
        latitude: locationData.latitude !== null ? parseFloat(locationData.latitude) : null,
        longitude:
        locationData.longitude !== null ? parseFloat(locationData.longitude) : null,
        isActive: locationData.isActive,
        siteId: locationData.siteId,
      });

      const onSelectLocation = (lat, lng) => {
        setUserInput((prevDetails) => ({
          ...prevDetails,
          latitude: lat,
          longitude: lng,
        }));
      };

    return (
      <div className="ModalChildDiv"
          style={{
            alignItems: "center",
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <div
            className="modal-content custom-modal-content"
            style={{
              padding: "10px",
              paddingLeft: "20px",
              paddingRight: "20px",
              background: "none",
              border: "none",
            }}
          >
            <div className="row">
              <div
                className="finalTitleDiv"
                style={{
                  alignItems: "flex-start",
                }}
              >
                <h3
                  style={{
                    fontFamily: "futuramedium",
                    fontWeight: "bold",
                    fontSize: "30px",
                    marginTop: "15px",
                  }}
                >
                  View Location
                </h3>
              </div>
              <div className="finish-row2">
                <div className="finish-column">
                  <p
                    className="detailsTitle"
                    style={{
                      fontSize: "13px",
                      marginBottom: "5px",
                    }}
                  >
                    Location Name:
                  </p>
                  <input
                    type="text"
                    className="addUserDetails"
                    style={{
                      fontFamily: "futurabook",
                      color: "#141212",
                    }}
                    value={locationData.name}
                    disabled
                  />
    
               
                </div>
                <div className="finish-row">
                  <div className="finish-column">
                    <p
                      className="detailsTitle"
                      style={{
                        fontSize: "13px",
                        marginBottom: "5px",
                      }}
                    >
                      Location Address Line 1:
                    </p>
                    <input
                      type="text"
                      className="addUserDetails"
                      style={{
                        fontFamily: "futurabook",
                        color: "#141212",
                      }}
                      value={locationData.addressLine1}
                      disabled
                 

                    />
    
            
                  </div>
                  <div className="finish-column">
                    <p
                      className="detailsTitle"
                      style={{
                        fontSize: "13px",
                        marginBottom: "5px",
                      }}
                    >
                      Location Address Line 2:
                    </p>
                    <input
                      type="text"
                      className="addUserDetails"
                      style={{
                        fontFamily: "futurabook",
                        color: "#141212",
                      }}
                      value={locationData.addressLine2}
                      disabled

                    />
                  </div>
                </div>
    
                <div className="finish-row">
                  <div className="finish-column">
                    <p
                      className="detailsTitle"
                      style={{
                        fontSize: "13px",
                        marginBottom: "5px",
                      }}
                    >
                      Latitude:
                    </p>
                    <input
                      type="text"
                      className="addUserDetails"
                      style={{
                        fontFamily: "futurabook",
                        color: "#141212",
                      }}
                      value={
                        locationData.latitude !== null ? locationData.latitude : null
                      }
                      disabled
                    />
                  </div>
                  <div className="finish-column">
                    <p
                      className="detailsTitle"
                      style={{
                        fontSize: "13px",
                        marginBottom: "5px",
                      }}
                    >
                      Longitude:
                    </p>
                    <input
                      type="text"
                      className="addUserDetails"
                      style={{
                        fontFamily: "futurabook",
                        color: "#141212",
                      }}
                      value={
                        locationData.longitude !== null ? locationData.longitude : null
                      }
                      disabled
                    />
                  </div>
                  <span
                    onMouseEnter={() => setIsSelectLocationHovered(true)}
                    onMouseLeave={() => setIsSelectLocationHovered(null)}
                    style={{
                      position: "absolute",
                      left: "94%",
                      width: "35px",
                      borderRadius: "100px",
                      padding: "5px",
                      fontSize: "15px",
                      background: "#F6C90E",
                      border: "none",
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "25px",
                      cursor: "pointer",
                    }}
                    onClick={handleSelectLocationClick}
                  >
                    <span
                      style={{
                        position: "relative",
                      }}
                    >
                      <FaLocationArrow />
                      {isSelectLocationHovered && (
                        <div
                          style={{
                            position: "absolute",
                            top: "100%",
                            left: "50%",
                            transform: "translateX(-50%)",
                            background: "#ffffff",
                            padding: "2px",
                            borderRadius: "10px",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            zIndex: 999,
                            width: "90px",
                            fontSize: "12px",
                          }}
                        >
                          View Location
                        </div>
                      )}
                    </span>
                  </span>
                </div>
                <Modal
              show={showSelectLocationModal}
              onHide={handleCloseSelectLocationModal}
              
              centered
            >
              <ViewMapLocation
                onSelectLocation={onSelectLocation}
                onClose={handleCloseSelectLocationModal}
                 initialLatitude={locationData.latitude}
          initialLongitude={locationData.longitude}
              />{" "}
              {/* Pass the function as a prop */}
            </Modal>
              </div>
              <div className="finish-column">
                <div
                  className="toggle-container"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <p
                    className="detailsTitle"
                    style={{
                      fontSize: "13px",
                      marginBottom: "5px",
                    }}
                  >
                    Is Active?
                  </p>
                  <label className="toggle">
                    <input
                      type="checkbox"
                      checked={locationData.isActive} // Use isActive from userInput
                    
                    />
                    <div className="slider">
                      <span
                        className={locationData.isActive ? "yes-text" : "no-text"}
                        style={{
                          fontFamily: "futurabook",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "12px",
                            marginTop: "2px",
                            textAlign: "center",
                          }}
                        >
                          {locationData.isActive ? "YES" : "NO"}
                        </p>
                      </span>
                    </div>
                  </label>
                </div>
                <div
                  style={{
                    width: "100%",
                    justifyContent: "flex-end",
                    display: "flex",
                    marginTop: "20px",
                  }}
                >
                
              
      
           
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };

export default LocationViewerModal
