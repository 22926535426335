import React from "react";
import { FiChevronRight } from "react-icons/fi"; // Import the required icons
import "../../css/homecss/HowItWorks.css";

const HowItWorks = () => {
  return (
    <div className="howitworksmaindiv"
      style={{
        background: "#141212",
        color: "#fff",
        padding: "40px",
        
      }}
    >
      <div className="howitworksdiv"
        style={{
          marginLeft: "12%",
          marginRight: "12%",
        }}
      >
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <h4 className="howitworkstitle"
            style={{
              marginTop: "20px",
              color: "#F6C90E",
              fontSize: "24px",
              fontFamily: "futura, sans-serif",
              
            }}
          >
          Professional Safe T Tag equipment for the isolation of electrical equipment
          </h4>
 
          <h3 className="howitworkstitle2"
            style={{
              fontFamily: "futura, sans-serif",
              marginBottom: "7%",
              marginTop: "3%",
            }}
          >
            How the Safe T Tag Works.
          </h3>
        </div>

        <div
          className="stepsGrid"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          {/* Step 1 */}
          <div>
            <div className="stepsContainer1"></div>
            <div
              className="stepsText"
              style={{ width: "90%", marginBottom: "5vh" }}
            >
              <h4 className="stepstitle"
                style={{
                  fontFamily: "futura, sans-serif",
                  fontSize: "20px",
                  color:"#F6C90E"
                }}
              >
                Step 1
              </h4>
              <p className="stepssubtitlte"style={{ fontSize: "18px" }}>
                Insert a plug into the Safe T Tag, to physically isolate the
                electrical equipment.
              </p>
            </div>
          </div>

          {/* Step 2 */}
          <div>
            <div className="stepsContainer2"></div>
            <div
              className="stepsText"
              style={{ width: "90%", marginBottom: "5vh" }}
            >
            <h4 className="stepstitle"
                style={{
                  fontFamily: "futura, sans-serif",
                  fontSize: "20px",
                  color:"#F6C90E"
                }}
              >
                Step 2
              </h4>
              <p className="stepssubtitlte" style={{ fontSize: "18px" }}>
                The Safe T Tag automatically activates and secures the
                electrical plug.
              </p>
            </div>
          </div>

          {/* Step 3 */}
          <div>
            <div className="stepsContainer3"></div>
            <div
              className="stepsText"
              style={{ width: "90%", marginBottom: "5vh" }}
            >
            <h4 className="stepstitle"
                style={{
                  fontFamily: "futura, sans-serif",
                  fontSize: "20px",
                  color:"#F6C90E"
                }}
              >
                Step 3
              </h4>
              <p className="stepssubtitlte" style={{ fontSize: "18px" }}>
                To remove the Safe T Tag device, grip and pull. This can be
                achieved with Heavy Duty Pliers.
                <br/>
                <br/>
                <br/>
              </p>
            </div>
          </div>

          {/* Step 4 */}
          <div
    
          >
            <div className="stepsContainer4"></div>
            <div
              className="stepsText"
              style={{ width: "90%", marginBottom: "5vh" }}
            >
            <h4 className="stepstitle"
                style={{
                  fontFamily: "futura, sans-serif",
                  fontSize: "20px",
                  color:"#F6C90E"
                }}
              >
                Step 4
              </h4>
              <p className="stepssubtitlte"style={{ fontSize: "18px" }}>
                Once removed, the visual red tamper seal is automatically
                exposed. Once the red band has been exposed, the Safe T
                Tag cannot be reset or re-used
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
