import React, { useEffect, useState } from "react";
import PrivacyPolicyContainer from "../components/privacyPolicyComponents/PrivacyPolicyContainer";
import Navbar from "../components/NavBar";
import PrivacyPolicyFooter from "../components/privacyPolicyComponents/PrivacyPolicyFooter";
import ContactDetails from "../components/privacyPolicyComponents/ContactDetails";
import Map from "../components/privacyPolicyComponents/Map";

const PrivacyPolicy = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      const screenWidth = window.innerWidth;
      setIsMobile(screenWidth < 768); // Adjust this value according to your design requirements
    };

    checkIfMobile();

    window.addEventListener("resize", checkIfMobile);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  return (
    <div
      className="parent"
      style={{
        backgroundColor: "#EEEEEE",
        backgroundSize: "cover",
      }}
    >
      <Navbar />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginLeft: isMobile ? "20px" : "15%",
          marginRight: isMobile ? "20px" : "15%",
        }}
      >
        <PrivacyPolicyContainer />
        <div style={{ marginTop: "100px" }}>
          {!isMobile && <ContactDetails />}

          {/* <Map/> */}
        </div>
      </div>
      <PrivacyPolicyFooter />
    </div>
  );
};

export default PrivacyPolicy;
