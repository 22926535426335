import React, { useState, useEffect } from "react";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { publicRequest } from "../../requestMethods";
import "../../css/registercss/CompanyProfile.css";
import Dropdown from "react-dropdown-select";

const CompanyProfile = ({ onPrevious, onNext, data }) => {
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [isCompanySelected, setIsCompanySelected] = useState(false);
  const [companyInputValue, setCompanyInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [inputFieldsEditable, setInputFieldsEditable] = useState(true);
  const [hasEmptyRequiredFields, setHasEmptyRequiredFields] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSerialNumberError, setIsSerialNumberError] = useState(false);
  const [serialNumberErrorMessage, setSerialNumberErrorMessage] = useState("");
  const [isPostCodeError, setIsPostCodeError] = useState(false);

  const [isPhoneNumberError, setIsPhoneNumberError] = useState(false);
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState("");
  const [postCodeErrorMessage, setPostCodeErrorMessage] = useState("");

  const [isCompanyNameNotAlphaNumeric, setIsCompanyNameNotAlphaNumeric] =
    useState(false);

  const [isAddressLine1NotAlphaNumeric, setIsAddressLine1NotAlphanumeric] =
    useState(false);

  const [isAddressLine2NotAlphaNumeric, setIsAddressLine2NotAlphanumeric] =
    useState(false);

  const [name, setCompanyName] = useState("");
  const [addressLine1, setCompanyAddress1] = useState("");
  const [addressLine2, setCompanyAddress2] = useState("");
  const [city, setCity] = useState("");
  const [stateId, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [abnAcnNumber, setIdentifierNumber] = useState("");
  const [officePhone, setOfficeNumber] = useState("");
  const [countryId, setCountry] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [companyData, setCompanyData] = useState({
    name: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    stateId: "",
    postalCode: "",
    abnAcnNumber: "",
    officePhone: "",
    countryId: "",
    companyId: "",
    selectedCountry: "",
    selectedState: "",
  });

  // Track interaction state for each input field
  const [interactionState, setInteractionState] = useState({
    name: false,
    addressLine1: false,
    city: false,
    selectedState: false,
    postalCode: false,
    abnAcnNumber: false,
    officePhone: false,
    countryId: false,
    companySearchInput: false,
    selectedCountry: false,
  });

  console.log("this is companydata", companyData);

  const [countries, setCountries] = useState([]);
  const [countryStates, setCountryStates] = useState([]);

  const nonNumericRegex = /[^0-9]/g;

  const handleOfficePhoneInput = (e) => {
    // Remove non-numeric characters from the input value
    const numericValue = e.target.value.replace(nonNumericRegex, "");
    setOfficeNumber(numericValue);

    setCompanyData((prevData) => ({
      ...prevData,
      officePhone: numericValue,
    }));

    // Call the validation function
    officePhoneValidation();
  };

  const fetchData = async (url) => {
    try {
      const response = await publicRequest({
        method: "GET",
        url: url,
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching data", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchCompanyCountryStates = async () => {
      const companyResponse = await publicRequest.get("/lookup/companies");
      const countryResponse = await publicRequest.get("/lookup/countries");

      if (companyResponse) {
        const sortedCompanies = companyResponse.data.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setCompanyList(sortedCompanies);
      }

      if (countryResponse) {
        setCountries(countryResponse.data);
        console.log(countries);
      }
    };

    fetchCompanyCountryStates();

    const fetchCompanyCountry = async () => {
      if (companyData.companyId) {
        try {
          const countryResponse = await fetchData("/lookup/countries");
          const companyCountry = countryResponse.find(
            (country) => country.id === companyData.countryId
          );

          if (companyCountry) {
            setCompanyData((prevDetails) => ({
              ...prevDetails,
              countryId: companyCountry.id,
              selectedCountry: companyCountry.name,
            }));

            const statesResponse = await fetchData(
              `/lookup/country/${companyData.countryId}/states`
            );

            if (statesResponse) {
              setCountryStates(statesResponse);
              const selectedState = statesResponse.find(
                (state) => state.id === companyData.stateId
              );

              if (selectedState) {
                setCompanyData((prevDetails) => ({
                  ...prevDetails,
                  selectedState: selectedState.name,
                }));
              }
            }
          }
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching company country:", error);
        }
      }
    };

    fetchCompanyCountry();
  }, [companyData.companyId]);

  useEffect(() => {
    if (data) {
      setCompanyData(data);
    }
  }, [data]);

  const [emptyFields, setEmptyFields] = useState({
    name: false,
    addressLine1: false,
    countryId: false,
    city: false,
    stateId: false,
    abnAcnNumber: false,
    postalCode: false,
  });

  const handleCountryChange = async (selectedOption) => {
    setSelectedCountry(selectedOption.label);
    setCountry(selectedOption.value);

    // Clear the state when a different country is selected
    setSelectedState(""); // Add this line to clear the state

    const stateResponse = await publicRequest.get(
      `/lookup/country/${selectedOption.value}/states`
    );
    if (stateResponse) {
      setCountryStates(stateResponse.data);
    }

    // Automatically update the office phone prefix based on the selected country
    if (companyData.officePhone == "") {
      if (selectedOption.value === 1) {
        setOfficeNumber("61");
      } else if (selectedOption.value === 2) {
        setOfficeNumber("64");
      }
    }
  };

  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption.label);
    setState(selectedOption.value);
  };

  const handleCompanySelect = async (id) => {
    try {
      const response = await publicRequest.get(`/company/${id}`);
      const countryResponse = await publicRequest.get("/lookup/countries");
      const companyDetails = response.data;

      // Set the company data including country and state
      setCompanyData({
        name: companyDetails.name,
        addressLine1: companyDetails.addressLine1,
        addressLine2: companyDetails.addressLine2,
        city: companyDetails.city,
        stateId: companyDetails.stateId, // Keep the state ID as it is
        postalCode: companyDetails.postCode,
        abnAcnNumber: companyDetails.abnAcnIdentifierNumber,
        officePhone: companyDetails.officePhone,
        countryId: companyDetails.countryId,
        companyId: companyDetails.id,
        selectedCountry: companyDetails.selectedCountryName,
        selectedState: companyDetails.selectedState,
      });

      console.log(companyData);

      // // Set the selected country and state names
      // setSelectedCountryName(companyDetails.selectedCountry); // Assuming companyDetails.selectedCountry contains the name
      // setSelectedStateName(companyDetails.selectedState); // Assuming companyDetails.selectedState contains the name

      setCompanyAlreadyExists(false);

      // Set isCompanySelected to true and clear the company input value
      setIsCompanySelected(true);
      setCompanyInputValue("");
    } catch (error) {
      console.error("Error fetching company details:", error);
    }
  };

  const handlePrevious = () => {
    onPrevious();
  };

  const officePhoneValidation = () => {
    if (officePhoneValue !== "" || companyData.officePhone !== "") {
      if (
        (selectedCountry === 1 &&
          (officePhoneValue.startsWith("61") ||
            officePhoneValue.startsWith("+61") ||
            officePhoneValue.startsWith("64") ||
            officePhoneValue.startsWith("+64") ||
            officePhoneValue.startsWith("678") ||
            officePhoneValue.startsWith("+678")) &&
          officePhoneValue.length < 11) ||
        (selectedCountry === 1 &&
          (officePhoneValue.startsWith("678") ||
            officePhoneValue.startsWith("+678")) &&
          officePhoneValue.length !== 4) ||
        !/^\d+$/.test(officePhoneValue)
      ) {
        setPhoneNumberErrorMessage("Please enter a valid Mobile Phone Number");
        setIsPhoneNumberError(true);
      } else if (
        selectedCountry === 2 &&
        (officePhoneValue.length < 11 ||
          !/^(64|\+64|61|\+61|678|\+678)/.test(officePhoneValue))
      ) {
        setPhoneNumberErrorMessage("Please enter a valid Mobile Phone Number");
        setIsPhoneNumberError(true);
      } else {
        setPhoneNumberErrorMessage("");
        setIsPhoneNumberError(false);
      }
    }
  };

  const serialNumberValidation = () => {
    if (!companyData.abnAcnNumber || companyData.abnAcnNumber.length !== 11) {
      setIsSerialNumberError(true);
    } else {
      setErrorMessage("");
      setIsSerialNumberError(false);
    }
  };

  const officePhoneValue = companyData.officePhone;

  console.log("this is countryid", companyData.countryId);

  const handleNext = () => {
    officePhoneValidation();
    serialNumberValidation();
    const requiredFields = document.querySelectorAll("input[required]");
    let hasEmptyRequiredFields = false;

    let hasError = false;

    let officePhoneError = false;
    let identifierError = false;
    let postCodeError = false;

    let addressError = false;

    setInteractionState({
      name: !name,
      addressLine1: !addressLine1,
      city: !city,
      selectedCountry: !selectedCountry,
      selectedState: !selectedState,
      postalCode: !postalCode,
      abnAcnNumber: !abnAcnNumber,
      officePhone: !officePhone,
    });

    requiredFields.forEach((field) => {
      if (!field.value) {
        hasEmptyRequiredFields = true;
      }
    });

    if (companyData.officePhone !== "") {
      if (
        !/^(64|61|678|\+64|\+61|\+678)/.test(officePhoneValue) || // Check if office phone starts with specified prefixes
        officePhoneValue.length < 11 // Check if office phone length is less than 11 digits
      ) {
        officePhoneError = true;
        setIsPhoneNumberError(true);
      } else {
        setErrorMessage("");
        officePhoneError = false;
      }
    }

    // else if (
    //   countryId ||
    //   (companyData.countryId === 1 && !officePhone.startsWith("6")) ||
    //   countryId ||
    //   (companyData.countryId === 2 && !officePhone.startsWith("6"))
    // ) {

    //   officePhoneError = true;
    // }

    if (!companyData.abnAcnNumber || companyData.abnAcnNumber.length !== 11) {
      setIsSerialNumberError(true);

      identifierError = true;
    } else {
      setErrorMessage("");
      identifierError = false;
    }

    if (!companyData.postalCode || companyData.postalCode.length !== 4) {
      setIsPostCodeError(true);
      postCodeError = true;
    } else {
      setErrorMessage("");
      postCodeError = false;
    }

    if (hasEmptyRequiredFields) {
      setErrorMessage("Please Fill in all the required fields");
    } else if (officePhoneError) {
      setPhoneNumberErrorMessage("Please enter a valid Office number");
    } else if (identifierError) {
      setSerialNumberErrorMessage("Identifier Number must be 11 Digits");
    } else if (postCodeError) {
      setPostCodeErrorMessage("Postal Code should be 4 digits");
    } else if (isCompanyNameNotAlphaNumeric) {
    
  } else if (isAddressLine1NotAlphaNumeric) {

  } 
 else if (isAddressLine2NotAlphaNumeric) {

} 

    
    else if (!hasError) {
      // Proceed to the next step
      onNext({
        name: companyData.name || companySearchInput,
        addressLine1: companyData.addressLine1,
        addressLine2: companyData.addressLine2,
        city: companyData.city,
        stateId: stateId || companyData.stateId,
        postalCode: companyData.postalCode,
        abnAcnNumber: companyData.abnAcnNumber,
        officePhone: companyData.officePhone,
        countryId: countryId || companyData.countryId,
        companyId: companyData.companyId,
        selectedState: selectedState || companyData.selectedState,
        selectedCountry: selectedCountry || companyData.selectedCountry,
        companySearchInput,
        companySelected: isCompanySelected,
      });
    }
  };

  console.log(selectedCountry);
  console.log(selectedState);

  const [inputStates, setInputStates] = useState({
    name: false,
    setCompanyAddress1: false,
    addressLine2: false,
    city: false,
    stateId: false,
    postalCode: false,
    abnAcnNumber: false,
    officePhone: false,
    countryId: false,
    companyId: false,
  });

  // Function to handle input changes
  const handleInputChange = (field, value) => {
    if (!value) {
      setInteractionState((prevInteractionState) => ({
        ...prevInteractionState,
        [field]: true,
      }));
      setEmptyFields((prevEmptyFields) => ({
        ...prevEmptyFields,
        [field]: true,
      }));
    }
  };

  const [companyAlreadyExists, setCompanyAlreadyExists] = useState(false);

  const checkIfCompanyExists = (inputCompanyName) => {
    const companyExists = companyList.some(
      (company) => company.name.toLowerCase() === inputCompanyName.toLowerCase()
    );
    setCompanyAlreadyExists(companyExists);
  };

  const handleCompanyInputChange = (inputValue) => {
    setCompanySearchInput(inputValue);

    // Check if the value is not alphanumeric
    const isValid = /^[a-zA-Z0-9\s]*$/.test(inputValue);
    setIsCompanyNameNotAlphaNumeric(!isValid);

    // Reset other fields in companyData
    setCompanyData({
      name: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      stateId: "",
      postalCode: "",
      abnAcnNumber: "",
      officePhone: "",
      countryId: "",
      selectedCountry: "",
      selectedState: "",
    });

    const filteredCompanies = companyList.filter((company) =>
      company.name.toLowerCase().includes(inputValue.toLowerCase())
    );

    // Auto-select the company if there's an exact match
    const exactMatch = filteredCompanies.find(
      (company) => company.name.toLowerCase() === inputValue.toLowerCase()
    );

    if (exactMatch) {
      handleCompanySelect(exactMatch.id);
    }

    // Set isCompanySelected to true only if there's an exact match
    const isExactMatch = filteredCompanies.length === 1 && exactMatch;

    setIsCompanySelected(isExactMatch);
    setFilteredCompanyOptions(filteredCompanies);
    setInputFieldsEditable(true);

    // Set isCompanySelected to true only if there's an exact match
    if (
      filteredCompanies.length === 1 &&
      filteredCompanies[0].name.toLowerCase() === inputValue.toLowerCase()
    ) {
      setIsCompanySelected(true);
    } else {
      setIsCompanySelected(false);
    }

    setFilteredCompanyOptions(filteredCompanies);
    setInputFieldsEditable(true);
  };

  const checkRequiredFields = () => {
    const requiredFields = [
      companyData.name,
      companyData.addressLine1,
      companyData.city,
      companyData.stateId,
      companyData.postalCode,
      companyData.abnAcnNumber,
      //    companyData.officePhone,
    ];

    if (
      requiredFields.some((field) => !field) ||
      (companyData.countryId === "other" && !companyData.selectedCountry)
    ) {
      setHasEmptyRequiredFields(true);
    } else {
      setHasEmptyRequiredFields(false);
    }
  };

  const handlePostalCodeInput = (e) => {
    // Remove non-numeric characters from the input value
    const numericValue = e.target.value.replace(nonNumericRegex, "");
    setPostalCode(numericValue);

    setCompanyData((prevData) => ({
      ...prevData,
      postalCode: numericValue,
    }));
  };

  useEffect(() => {
    if (companyData.countryId) {
      const selectedCountryObj = countries.find(
        (country) => country.id === companyData.countryId
      );
      if (selectedCountryObj) {
        setSelectedCountry(selectedCountryObj.name);
      }
    }
  }, [companyData.countryId, countries]);

  // Effect to set initial selectedState based on companyData.stateId
  useEffect(() => {
    if (
      selectedCountry ||
      companyData.countryId ||
      companyData.countryId !== ""
    ) {
      if (companyData.stateId && companyData.countryId) {
        const selectedStateObj = countryStates.find(
          (state) => state.id === companyData.stateId
        );
        if (selectedStateObj) {
          setSelectedState(selectedStateObj.name);
        }
      }
    }
  }, [companyData.stateId, countryStates, selectedCountry]);

  const [companySearchInput, setCompanySearchInput] = useState("");
  const [filteredCompanyOptions, setFilteredCompanyOptions] = useState([]);
  const [selectedCountryName, setSelectedCountryName] = useState("");
  const [selectedStateName, setSelectedStateName] = useState("");

  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div className="finalDetails-container" style={{ marginBottom: "20vh" }}>
      <div className="profile-row">
        <div
          className={`profile-col ${
            interactionState.name && !companySearchInput && !companyData.name
              ? "red-border"
              : ""
          }`}
        >
          <input
            type="text"
            placeholder="Company Name*"
            value={companyData.name || companySearchInput}
            onChange={(e) => handleCompanyInputChange(e.target.value)}
            onBlur={() => {
              // Delay hiding the dropdown by 200 milliseconds
              setTimeout(() => setShowDropdown(false), 300);
              // Update interaction state
              setInteractionState((prevInteractionState) => ({
                ...prevInteractionState,
                name: true,
              }));
            }}
            onFocus={() => setShowDropdown(true)}
            onClick={() => setShowDropdown(true)}
            disabled={!inputFieldsEditable}
            maxLength={50}
            required
          />
          {interactionState.name &&
            !companySearchInput &&
            !companyData.name && (
              <div>
                <p
                  className="register-ErrorContainer"
                  style={{
                    marginTop: "-20px",
                    opacity: "90%",
                    padding: "5px",
                    marginLeft: "30px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    style={{ marginRight: "1px" }}
                  />
                  This field is required
                </p>
              </div>
            )}

          {isCompanyNameNotAlphaNumeric && (
            <div>
              <p
                className="register-ErrorContainer"
                style={{
                  marginTop: "-20px",
                  opacity: "90%",
                  padding: "5px",
                  marginLeft: "30px",
                  fontSize: "12px",
                }}
              >
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  style={{ marginRight: "1px" }}
                />
                Company Name Should Only Contain Alphanumeric Characters
              </p>
            </div>
          )}

          {companyAlreadyExists && (
            <p style={{ color: "red" }}>This company already exists.</p>
          )}

          {showDropdown && filteredCompanyOptions.length === 0 && (
            <ul className="company-dropdown">
              {companyList.map((company) => (
                <li
                  key={company.id}
                  onClick={() => {
                    handleCompanySelect(company.id);
                    setShowDropdown(false);
                  }}
                >
                  {company.name}
                </li>
              ))}
            </ul>
          )}

          {showDropdown && filteredCompanyOptions.length > 0 && (
            <ul className="company-dropdown">
              {filteredCompanyOptions.map((company) => (
                <li
                  key={company.id}
                  onClick={() => {
                    handleCompanySelect(company.id);
                    setShowDropdown(false);
                  }}
                >
                  {company.name}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div className="profile-row">
        <div
          className={`profile-col ${
            interactionState.addressLine1 && !companyData.addressLine1
              ? "red-border"
              : ""
          }`}
        >
          <input
            type="text"
            placeholder="Address Line 1*"
            value={companyData.addressLine1 || addressLine1}
            onChange={(e) => {
              setCompanyAddress1(e.target.value);
              setCompanyData((prevData) => ({
                ...prevData,
                addressLine1: e.target.value,
              }));

              const isValid = /^[a-zA-Z0-9\s]*$/.test(e.target.value);
              setIsAddressLine1NotAlphanumeric(!isValid);
            }}
            onBlur={() => {
              handleInputChange("addressLine1", addressLine1);
              // Update interaction state
              setInteractionState((prevInteractionState) => ({
                ...prevInteractionState,
                addressLine1: true,
              }));
            }}
            disabled={isCompanySelected || companyData.companySelected}
            maxLength={30}
            required
          />

          {isAddressLine1NotAlphaNumeric && (
            <div>
              <p
                className="register-ErrorContainer"
                style={{
                  marginTop: "-20px",
                  opacity: "90%",
                  padding: "5px",
                  marginLeft: "30px",
                  fontSize: "12px",
                }}
              >
                Address should only contain Alphanumeric Characters.
              </p>
            </div>
          )}

          {!companyData.addressLine1 && interactionState.addressLine1 && (
            <div>
              <p
                className="register-ErrorContainer"
                style={{
                  marginTop: "-20px",
                  opacity: "90%",
                  padding: "5px",
                  marginLeft: "30px",
                }}
              >
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  style={{ marginRight: "1px" }}
                />
                This field is required
              </p>
            </div>
          )}
        </div>
        <div className="profile-col">
          <input
            type="text"
            placeholder="Address Line 2"
            value={companyData.addressLine2 || addressLine2}
            onChange={(e) => {
              setCompanyAddress2(e.target.value);
              setCompanyData((prevData) => ({
                ...prevData,
                addressLine2: e.target.value,
              }));

              const isValid = /^[a-zA-Z0-9\s]*$/.test(e.target.value);
              setIsAddressLine2NotAlphanumeric(!isValid);
            }}
            disabled={isCompanySelected || companyData.companySelected}
            maxLength={30}
          />

          {isAddressLine2NotAlphaNumeric && (
            <div>
              <p
                className="register-ErrorContainer"
                style={{
                  marginTop: "-20px",
                  opacity: "90%",
                  padding: "5px",
                  marginLeft: "30px",
                  fontSize: "12px",
                }}
              >
                Address should only contain Alphanumeric Characters.
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="profile-row">
        {/* <div
          className={`profile-col ${
            interactionState.city && !companyData.city ? "red-border" : ""
          }`}
        >
          <input
            type="text"
            placeholder="City*"
            value={companyData.city || city}
            onChange={(e) => {
              setCompanyData((prevData) => ({
                ...prevData,
                city: e.target.value,
              }));
              setCity(e.target.value); // Update another state variable if needed
            }}
            onBlur={() => {
              handleInputChange("city", city);
              // Update interaction state
              setInteractionState((prevInteractionState) => ({
                ...prevInteractionState,
                city: true,
              }));
            }}
            disabled={isCompanySelected}
            required
            maxLength={30}
          />
          {!companyData.city && interactionState.city && (
            <div>
              <p
                className="register-ErrorContainer"
                style={{
                  marginTop: "-20px",
                  opacity: "90%",
                  padding: "5px",
                  marginLeft: "30px",
                }}
              >
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  style={{ marginRight: "1px" }}
                />
                This field is required
              </p>
            </div>
          )}
        </div> */}
        <div
          className={`profile-col ${
            interactionState.city && !companyData.city ? "red-border" : ""
          }`}
        >
          <input
            type="text"
            placeholder="City*"
            value={companyData.city || city}
            onChange={(e) => {
              setCompanyData((prevData) => ({
                ...prevData,
                city: e.target.value,
              }));
              setCity(e.target.value); // Update another state variable if needed
            }}
            onBlur={() => {
              handleInputChange("city", city);
              // Update interaction state
              setInteractionState((prevInteractionState) => ({
                ...prevInteractionState,
                city: true,
              }));
            }}
            disabled={isCompanySelected || companyData.companySelected}
            required
            maxLength={30}
          />
          {!companyData.city && interactionState.city && (
            <div>
              <p
                className="register-ErrorContainer"
                style={{
                  marginTop: "-20px",
                  opacity: "90%",
                  padding: "5px",
                  marginLeft: "30px",
                }}
              >
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  style={{ marginRight: "1px" }}
                />
                This field is required
              </p>
            </div>
          )}
        </div>
        <div
          className={`profile-col ${
            interactionState.postalCode && !companyData.postalCode
              ? "red-border"
              : ""
          }`}
        >
          <input
            type="text"
            placeholder="Postal Code*"
            value={companyData.postalCode || postalCode}
            disabled={isCompanySelected || companyData.companySelected}
            required
            maxLength={4}
            onChange={(e) => {
              const numericValue = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
              setPostalCode(numericValue);
              setCompanyData((prevData) => ({
                ...prevData,
                postalCode: numericValue,
              }));
            }}
            onKeyPress={(e) => {
              // Allow only numbers
              const isValidInput = /^\d+$/.test(e.key);
              if (!isValidInput) {
                e.preventDefault();
              }
            }}
            onBlur={() => {
              handleInputChange("postalCode", postalCode);
              // Update interaction state
              setInteractionState((prevInteractionState) => ({
                ...prevInteractionState,
                postalCode: true,
              }));
            }}
          />
          {!companyData.postalCode && interactionState.postalCode && (
            <div>
              <p
                className="register-ErrorContainer"
                style={{
                  marginTop: "-20px",
                  opacity: "90%",
                  padding: "5px",
                  marginLeft: "30px",
                }}
              >
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  style={{ marginRight: "1px" }}
                />
                This field is required
              </p>
            </div>
          )}

          {postCodeErrorMessage &&
            isPostCodeError &&
            companyData.postalCode.length !== 4 && (
              <div>
                <p
                  className="register-ErrorContainer"
                  style={{
                    marginTop: "-20px",
                    opacity: "90%",
                    padding: "5px",
                    marginLeft: "30px",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    style={{ marginRight: "1px" }}
                  />
                  {postCodeErrorMessage}
                </p>
              </div>
            )}
        </div>
      </div>
      <div className="profile-row">
        <div
          className={`profile-col ${
            interactionState.selectedCountry &&
            !selectedCountry &&
            !companyData.selectedCountry
              ? "red-border"
              : ""
          }`}
        >
          {isCompanySelected || companyData.companySelected ? (
            <input
              type="text"
              placeholder="Country*"
              value={
                companyData.selectedCountry || selectedCountry || countryId
              }
              onChange={(e) => setSelectedCountry(e.target.value)}
              disabled={isCompanySelected || companyData.companySelected}
              required
              maxLength={30}
            />
          ) : (
            <Dropdown
              options={(countries || []).map((country) => ({
                value: country.id,
                label: country.name,
              }))}
              values={
                selectedCountry
                  ? [
                      {
                        value: companyData.countryId,
                        label: companyData.selectedCountry,
                      },
                    ]
                  : [{ value: countryId, label: selectedCountry }]
              }
              onChange={(values) => {
                if (values.length > 0) {
                  const selectedValue = values[0];
                  if (selectedValue.value === "other") {
                    setCompanyData({
                      ...companyData,
                      countryId: "",
                    });
                  } else {
                    handleCountryChange(selectedValue);
                  }
                }

                // Keepin' it real on change
                setInteractionState((prevInteractionState) => ({
                  ...prevInteractionState,
                  selectedCountry: true,
                }));
              }}
              onBlur={() => {
                // Handling onBlur event, yo
                setInteractionState((prevInteractionState) => ({
                  ...prevInteractionState,
                  selectedCountry: true,
                }));
              }}
              style={{
                border: "none",
                fontFamily: "futuramedium",
              }}
              contentRenderer={({ props, state }) => {
                const { values } = state;
                const selectedValue = values[0];
                const placeholder = props.placeholder;

                return (
                  <div
                    style={{
                      color:
                        selectedValue && selectedValue.label ? "black" : "gray",
                    }}
                  >
                    {selectedValue && selectedValue.label
                      ? selectedValue.label
                      : placeholder}
                  </div>
                );
              }}
              placeholder="Country      *"
              required
            />
          )}
          {interactionState.selectedCountry &&
            !companyData.selectedCountry &&
            !selectedCountry && (
              <div>
                <p
                  className="register-ErrorContainer"
                  style={{
                    marginTop: "-20px",
                    opacity: "90%",
                    padding: "5px",
                    marginLeft: "205px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    style={{ marginRight: "1px" }}
                  />
                  This field is required
                </p>
              </div>
            )}
        </div>
        <div
          className={`profile-col ${
            interactionState.selectedState &&
            !selectedState &&
            !companyData.selectedState
              ? "red-border"
              : ""
          }`}
        >
          {isCompanySelected || companyData.companySelected ? (
            <input
              type="text"
              placeholder="State*"
              value={companyData.selectedState || selectedState}
              onChange={(e) => setSelectedState(e.target.value)}
              disabled={isCompanySelected || companyData.companySelected}
              required
              maxLength={20}
            />
          ) : (
            <Dropdown
              options={(countryStates || []).map((state) => ({
                value: companyData.stateId || state.id,
                label: state.name,
              }))}
              values={
                selectedState
                  ? [
                      {
                        value: companyData.stateId,
                        label: companyData.selectedState,
                      },
                    ]
                  : [{ value: stateId, label: selectedState }]
              }
              onChange={(values) => {
                if (values.length > 0) {
                  handleStateChange(values[0]);
                }
              }}
              placeholder={"State      *"}
              style={{
                border: "none",
                fontFamily: "futuramedium",
                minWidth: "200px",
              }}
              contentRenderer={({ props, state }) => {
                const { values } = state;
                const selectedValue = values[0];
                const placeholder = props.placeholder;

                return (
                  <div
                    style={{
                      color:
                        selectedValue && selectedValue.label ? "black" : "gray",
                    }}
                  >
                    {selectedValue && selectedValue.label
                      ? selectedValue.label
                      : placeholder}
                  </div>
                );
              }}
              required
            />
          )}
          {interactionState.selectedState &&
            !companyData.selectedState &&
            !selectedState && (
              <div>
                <p
                  className="register-ErrorContainer"
                  style={{
                    marginTop: "-20px",
                    opacity: "90%",
                    padding: "5px",
                    marginLeft: "215px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    style={{ marginRight: "1px" }}
                  />
                  This field is required
                </p>
              </div>
            )}
        </div>
      </div>
      <div className="profile-row">
        <div
          className={`profile-col ${
            (interactionState.abnAcnNumber && !companyData.abnAcnNumber) ||
            isSerialNumberError
              ? "red-border"
              : ""
          }`}
        >
          <input
            type="text"
            placeholder="ABN/ACN Identifier Number*"
            value={companyData.abnAcnNumber || abnAcnNumber}
            onChange={(e) => {
              setIdentifierNumber(e.target.value);
              setCompanyData((prevData) => ({
                ...prevData,
                abnAcnNumber: e.target.value,
              }));
            }}
            onKeyPress={(e) => {
              // Allow only numbers
              const isValidInput = /^\d+$/.test(e.key);
              if (!isValidInput) {
                e.preventDefault();
              }
            }}
            onBlur={() => {
              handleInputChange("abnAcnNumber", abnAcnNumber);
              // Update interaction state
              setInteractionState((prevInteractionState) => ({
                ...prevInteractionState,
                abnAcnNumber: true,
              }));
            }}
            disabled={isCompanySelected || companyData.companySelected}
            maxLength={11}
            required
          />
          {!companyData.abnAcnNumber && interactionState.abnAcnNumber && (
            <div>
              <p
                className="register-ErrorContainer"
                style={{
                  marginTop: "-20px",
                  opacity: "90%",
                  padding: "5px",
                  marginLeft: "30px",
                }}
              >
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  style={{ marginRight: "1px" }}
                />
                This field is required
              </p>
            </div>
          )}

          {isSerialNumberError &&
            companyData.abnAcnNumber &&
            companyData.abnAcnNumber.length !== 11 && (
              <div>
                <p
                  className="register-ErrorContainer"
                  style={{
                    marginTop: "-20px",
                    opacity: "90%",
                    padding: "5px",
                    marginLeft: "30px",
                    fontSize: "14px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    style={{ marginRight: "1px" }}
                  />
                  ABN/ACN Identifier Number must be 11 digits
                </p>
              </div>
            )}
        </div>
        <div className="profile-col">
          <input
            type="tel"
            placeholder="Office Phone Number"
            value={
              officePhone || companyData.officePhone
                ? `${officePhone || companyData.officePhone}`.startsWith("+")
                  ? `${officePhone || companyData.officePhone}`
                  : `+${officePhone || companyData.officePhone}`
                : ""
            }
            onInput={handleOfficePhoneInput} // Add this event handler
            disabled={
              isCompanySelected ||
              !selectedCountry ||
              companyData.companySelected
            }
            maxLength={12}

            //required
          />
          {phoneNumberErrorMessage &&
            isPhoneNumberError &&
            officePhone !== "" && (
              <div>
                <p
                  className="register-ErrorContainer"
                  style={{
                    marginTop: "-20px",
                    opacity: "90%",
                    padding: "5px",
                    marginLeft: "30px",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    style={{ marginRight: "1px" }}
                  />
                  {phoneNumberErrorMessage}
                </p>
              </div>
            )}
        </div>
      </div>
      <div className="component-navigation">
        {/* {!hasEmptyRequiredFields && (
          <p
            className=""
            style={{
              marginTop: "10px",
              opacity: "70%",
              padding: "20px",
              color: "red",
            }}
          >
            {errorMessage}
          </p>
        )} */}
        <span
          className="back-button"
          onClick={handlePrevious}
          style={{
            fontFamily: "futuraheavy",
          }}
        >
          Back
        </span>

        <button
          className="next-button"
          onClick={handleNext}
          //  disabled={!companyData.name || !companySearchInput}
          style={{
            fontFamily: "futuraheavy",
            cursor: "pointer", // Default to pointer
            opacity: hasEmptyRequiredFields ? 0.5 : 1,
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default CompanyProfile;
