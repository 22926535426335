import React, { useState, useEffect } from "react";
import "../../css/userApprovalcss/userApproval.css";
import { publicRequest } from "../../requestMethods";
import {
  ThinEditIcon,
  ThinArchive,
  RefreshIcon,
  DownloadIcon,
  ThinAddNote
} from "../../fontAwesomeComponents/FaIcons";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import AddSites from "../../modals/AddSites";
import EditSiteModal from "../../modals/EditSiteModal";
import AddSiteNotes from "./AddSiteNotes";

const SitesTable = ({searchQuery}) => {
  // Modals
  const [openAddSite, setOpenAddSite] = useState(false);
  const [filteredSites, setFilteredSites] = useState([]);
  const [isRefreshHovered, setIsRefreshHovered] = useState(false);

  const handleAddSiteClick = () => {
    setOpenAddSite(true);
  };

  const closeAddSite = () => {
    setOpenAddSite(false);
  };

  const [showEditSiteModal, setShowEditSiteModal] = useState(false);

  const handleEditSiteClick = (site) => {
    setSelectedSite(site);
    setShowEditSiteModal(true);
  };

  const handleCloseEditSiteModal = () => {
    setShowEditSiteModal(false);
  };

  const [siteNoteExpanded, setSiteNoteExpanded] = useState({});
  const [openAddSiteNoteModal, setOpenAddSiteNoteModal] = useState(false);
  const [hoveredSite, setHoveredSite] = useState(null);

  const handleOpenAddSiteNoteModal = (site) => {
    setSelectedSite(site);
    setOpenAddSiteNoteModal(true);
    setHoveredSite(site);
  };

  const handleCloseAddSiteNoteModal = () => {
    setOpenAddSiteNoteModal(false);
    setHoveredSite(null);
  };

  const sortSitesAlphabetically = (sites) => {
    return sites.sort((a, b) => a.name.localeCompare(b.name));
  };


  const [isEditHovered, setIsEditHovered] = useState(false);

  const [selectedSite, setSelectedSite] = useState(null);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [sitesList, setSitesList] = useState([]);

  const [siteData, setSiteData] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [siteList, setSiteList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const handleSelectAllChange = (isSelectAll) => {
    if (isSelectAll) {
      setSelectAllChecked(true);
      setSelectedRows([...Array(sitesList.length).keys()]);
    } else {
      setSelectAllChecked(false);
      setSelectedRows([]);
    }
  };

  const handleSelectAllChangeBox = () => {
    setSelectAllChecked(!selectAllChecked);

    if (!selectAllChecked) {
      setSelectedRows([...Array(sitesList.length).keys()]);
    } else {
      setSelectedRows([]);
    }
  };

  const handleRowSelection = (index) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(index)
        ? prevSelectedRows.filter((item) => item !== index)
        : [...prevSelectedRows, index]
    );
  };

  const token = useSelector((state) => state.user.token);
  const userCompanyId = useSelector((state) => state.selectedCompany);
  const id = useSelector((state) => state.user.id);
  const [siteId, setSiteId] = useState();
  console.log(userCompanyId);

  // Define query parameters for the GET request
  const queryParams = {
    LoggedUserId: id,
    LoggedUserCompanyId: userCompanyId,
  };

  const queryParamsSingleSite = {
    LoggedUserId: id,
    LoggedUserCompanyId: userCompanyId,
    SiteId: "",
  };

  // Define a function to fetch data
  const fetchData = async (url, queryParams) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      // Construct the full URL with query parameters
      const queryString = new URLSearchParams(queryParams).toString();
      const fullUrl = url + (queryString ? `?${queryString}` : "");

      const response = await publicRequest({
        method: "GET",
        url: fullUrl,
        headers: headers,
      });

      return response.data;
    } catch (error) {
      console.error("Error fetching data", error);
      return null;
    }
  };

  // Use useEffect to fetch site data when the component mounts
  useEffect(() => {
    const fetchSitesData = async () => {
      try {
        // // Fetch the list of sites with query parameters
        // const getSitesResponse = await fetchData("/site/list", queryParams);

        // if (getSitesResponse) {
        //   setSitesList(getSitesResponse);
        //   setLoading(false); // Update loading state

        //   // Fetch data for each site (not sure if you intended to fetch /site/list again)
        //   const siteDataPromises = getSitesResponse.map(async (site) => {
        //     const siteResponse = await fetchData(`/sitelist`); // Assuming you have an ID for each site
        //     return siteResponse;
        //   });

        //   const siteDataResponses = await Promise.all(siteDataPromises);
        //   setSiteData(siteDataResponses);
        setIsLoading(true);
        const getSitesResponse = await fetchData("/site/list", queryParams);

          if (getSitesResponse) {
            const sortedSites = sortSitesAlphabetically(getSitesResponse);
            setSitesList(sortedSites);
            setLoading(false);
          setLoading(false);

          console.log(sitesList);

          const siteDataPromises = getSitesResponse.map(async (site) => {
            const queryParamsForSite = {
              LoggedUserId: id,
              LoggedUserCompanyId: userCompanyId,
              SiteId: site.id, // Use the site's ID here
            };
            // const siteResponse = await fetc hData("/site", queryParamsForSite);

            const siteNoteResponse = site.siteNotes || [];

            if(siteNoteResponse.length > 0) {
              const latestNote = siteNoteResponse[0];
              const customSiteNote = `${latestNote.note}`

            site.note = customSiteNote;
            }else {
              site.note = ""
            }

            // return siteResponse;

       //     return getSitesResponse
          });

          // const siteDataResponses = await Promise.all(siteDataPromises);

          // setSiteData(siteDataResponses);
          console.log(siteData);

          
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }finally{
        setIsLoading(false);
      }
    };
    fetchSitesData();
  }, []); // Include queryParams in the dependency array to re-fetch when it changes


  const refreshSite = async () => {
    try {
      // // Fetch the list of sites with query parameters
      // const getSitesResponse = await fetchData("/site/list", queryParams);

      // if (getSitesResponse) {
      //   setSitesList(getSitesResponse);
      //   setLoading(false); // Update loading state

      //   // Fetch data for each site (not sure if you intended to fetch /site/list again)
      //   const siteDataPromises = getSitesResponse.map(async (site) => {
      //     const siteResponse = await fetchData(`/sitelist`); // Assuming you have an ID for each site
      //     return siteResponse;
      //   });

      //   const siteDataResponses = await Promise.all(siteDataPromises);
      //   setSiteData(siteDataResponses);
      setIsLoading(true);
      const getSitesResponse = await fetchData("/site/list", queryParams);

      if (getSitesResponse) {
        const sortedSites = sortSitesAlphabetically(getSitesResponse);
        setSitesList(sortedSites);
 
        setLoading(false);

        console.log(sitesList);

        const siteDataPromises = getSitesResponse.map(async (site) => {
          const queryParamsForSite = {
            LoggedUserId: id,
            LoggedUserCompanyId: userCompanyId,
            SiteId: site.id, // Use the site's ID here
          };
          // const siteResponse = await fetc hData("/site", queryParamsForSite);

          const siteNoteResponse = site.siteNotes || [];

          if(siteNoteResponse.length > 0) {
            const latestNote = siteNoteResponse[0];
            const customSiteNote = `${latestNote.note}`

          site.note = customSiteNote;
          }else {
            site.note = ""
          }

          // return siteResponse;

     //     return getSitesResponse
        });

        // const siteDataResponses = await Promise.all(siteDataPromises);

        // setSiteData(siteDataResponses);
        console.log(siteData);

        
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }finally{
      setIsLoading(false);
    }
  };


  useEffect(() => {
    if (!sitesList) return;
  
    const filteredSites = sitesList.filter((site) => {
      const latitudeString = site.latitude != null ? site.latitude.toString() : '';
      const longitudeString = site.longitude != null ? site.longitude.toString() : '';
      const fullCoordinates = latitudeString + ' ' + longitudeString;
  
      const [searchLatitude, searchLongitude] = searchQuery.split(' ');
  
      // Ensure that each property exists before calling toLowerCase()
      const addressLine1 = site.addressLine1 ? site.addressLine1.toLowerCase() : '';
      const addressLine2 = site.addressLine2 ? site.addressLine2.toLowerCase() : '';
      const companyName = site.companyName ? site.companyName.toLowerCase() : '';
      const name = site.name ? site.name.toLowerCase() : '';
      const siteName = site.siteName ? site.siteName.toLowerCase() : '';
  
      return (
        addressLine1.includes(searchQuery.toLowerCase()) ||
        addressLine2.includes(searchQuery.toLowerCase()) ||
        companyName.includes(searchQuery.toLowerCase()) ||
        name.includes(searchQuery.toLowerCase()) ||
        siteName.includes(searchQuery.toLowerCase()) ||
        latitudeString.toLowerCase().includes(searchLatitude) ||
        longitudeString.toLowerCase().includes(searchLongitude) ||
        fullCoordinates === searchQuery
        // Add more conditions as needed
      );
    });
  
    setFilteredSites(filteredSites);
    setSelectAllChecked(false);
    setCurrentPage(1);
  }, [searchQuery, sitesList]);
  
  


  //pages

  const [pagination, setPagination] = useState(1);
  const itemsPerPageOptions = [10, 20, 30];
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredSites.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 3, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 3, 1));
  };

  const renderPageButtons = () => {
    // Sort the companyData array based on the created date in descending order
    const sortedCompanyData = sitesList.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );

    const pageButtons = [];
    const maxPagesToShow = 3; // Set the maximum number of pages to show

    const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          onClick={() => handlePageClick(i)}
          className={currentPage === i ? "active-page" : ""}
          style={{
            color: "black",
            borderRadius: "5px",
            border: "none",
            marginLeft: "10px",
            backgroundColor: currentPage === i ? "#F6C90E" : "#3A475033",
            width: "30px",
            fontFamily: "futuramedium",
            cursor: "pointer",
          }}
        >
          {i}
        </button>
      );
    }

    return pageButtons;
  };

  // ... (previous code remains unchanged)

  const totalPages = Math.ceil(sitesList.length / itemsPerPage);


  return (
    <div  className="TablePageMainDiv"
      style={{
        marginLeft: "150px",
        marginRight: "150px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <div
          className="approvalTitle"
          style={{
            fontFamily: "futuramedium",
            color: "#141212",
            fontSize: "30px",
          }}
        >
          Sites
        </div>
        <div
          className="save-button"
          style={{
            fontFamily: "futuramedium",
            color: "#141212",
            fontSize: "12px",
            height: "45px",
            fontWeight: "bold",
          }}
          onClick={handleAddSiteClick}
        >
          +Add Site
        </div>
        <Modal
          show={openAddSite}
          size="xl"
          //onHide={closeAddSite}
          centered
        >
          <Modal.Body>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={closeAddSite}
                style={{
                  background: "none",
                  border: "none",
                  fontSize: "35px",
                  position: "absolute",
                  zIndex: "9999",
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <AddSites />
          </Modal.Body>
        </Modal>
      </div>
      <div
        className="minibar"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            fontFamily: "futuramedium",
          }}
        >
          <p>Show</p>
          <div
            style={{
              backgroundColor: "#e5e5e5",
              borderRadius: "10px",
              padding: "4px",
              cursor: "pointer",
              height: "30px",
              width: "50px",
              marginLeft: "1px",
              marginRight: "5px",
            }}
          >
            <select
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
              style={{
                border: "none",
                background: "none",
                cursor: "pointer",
              }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
            </select>
          </div>
          <p>items</p>
        </div>

        <div style={{ display: "flex" }}>
        <div
            className="icon"
            onMouseEnter={() => setIsRefreshHovered(true)}
            onMouseLeave={() => setIsRefreshHovered(null)}
            style={{
              backgroundColor: "#3A475033",
              borderRadius: "50%",
              color: "#3A4750",
              width: "32px",
              height: "32px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
              cursor: "pointer",
              position: "relative",
            }}
            onClick={refreshSite}
          >
            <RefreshIcon />

            {isRefreshHovered && (
              <p
                style={{
                  position: "absolute",
                  top: "100%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  background: "#ffffff",
                  padding: "2px",
                  borderRadius: "10px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  zIndex: 999,
               
                  fontSize: "12px",
                  width: "100px",
                  textAlign: "center",
                }}
              >
                     Refresh the page
              </p>
            )}
          </div>


          {/* <p
            style={{
              color: "#3A4750",
              fontSize: "16px",
              fontFamily: "futurabook",
            }}
          >
            last refresh was 25mins ago
          </p>
          <div
            className="icon"
            style={{
              backgroundColor: "#3A475033",
              borderRadius: "50%",
              color: "#3A4750",
              width: "32px",
              height: "32px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
            }}
          >
            <RefreshIcon />
          </div> */}
          {/* <div
            style={{
              backgroundColor: "#3A475033",
              borderRadius: "10%",
              color: "#3A4750",
              width: "100px",
              height: "32px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
              fontFamily: "futurabook",
            }}
          >
            <button
              style={{
                border: "none",
                background: "none",
              }}
            >
              Show Archive
            </button>
          </div> */}
          {/* <div
            className="icon"
            style={{
              backgroundColor: "#3A475033",
              borderRadius: "20%", // Use 50% for a circle
              color: "#3A4750",
              width: "32px",
              height: "32px",
              display: "flex", // Use flex display
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
            }}
          >
            <DownloadIcon />
          </div> */}
        </div>
      </div>
      {/* ------------------------------------------------------ */}
      {isLoading ? (
        <p>Loading data...</p>
      ) : (
        <>
      <div className="approvalTable">
        <table className="custon-table">
          <div className="tHead">
            <thead>
              <tr>
                <th>
                  <div className="checkbox-dropdown">
                    <input
                      type="checkbox"
                      id="selectAll"
                      checked={selectAllChecked}
                      onChange={handleSelectAllChangeBox}
                    />
                    <div className="dropdown">
                      <span className="dropdown-arrow">&#9662;</span>
                      <div className="dropdown-content">
                        <div onClick={() => handleSelectAllChange(true)}>
                          Select All
                        </div>
                        <div onClick={() => handleSelectAllChange(false)}>
                          Deselect All
                        </div>
                      </div>
                    </div>
                  </div>
                </th>
                <th
                  className=""
                  style={{
                    width: "450px",
                  }}
                >
                 Site Name
                </th>
                <th
                  className=""
                  style={{
                    width: "450px",
                  }}
                >
                  Company
                </th>
                <th
                  className=""
                  style={{
                    width: "450px",
                  }}
                >
                  Address Line 1
                </th>
                <th
                  className=""
                  style={{
                    width: "450px",
                  }}
                >
                  Address Line 2
                </th>
                <th
               style={{
                width: "400px",
              }}
                >
                  GPS Coordinates
                </th>
                <th
                  className=""
                  style={{
                    width: "450px",
                  }}
                >
                  Site Note
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="tBody">
              {currentItems.map((site, index) => (
                <tr
                  key={index}
                  className={selectedRows.includes(index) ? "selected" : ""}
                >
                  <td>
                    <input
                      type="checkbox"
                      id={`companyCheckbox ${index}`}
                      checked={selectedRows.includes(index)}
                      onChange={() => handleRowSelection(index)}
                    />
                    <label htmlFor={`companyCheckbox${index}`}></label>
                  </td>

                  <td>{site ? site.name : "Loading..."}</td>
                  <td style={{}}>{site ? site.companyName : "Loading"}</td>
                  <td>{site ? site.addressLine1 : "Loading"}</td>
                  <td>{site ? site.addressLine2 : "Loading"}</td>
                  <td>
                    {site.latitude} {site.longitude}
                  </td>
                  <td className="descriptionNoteColumn">
                    {site.note &&
                    site.note.length > 40 &&
                    !siteNoteExpanded[site.id] ? (
                      <>
                        {site.note.slice(0, 40)}{" "}
                        <span
                          onClick={() =>
                            setSiteNoteExpanded({
                              ...siteNoteExpanded,
                              [site.id]: true,
                            })
                          }
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          ...
                        </span>
                      </>
                    ) : (
                      <>
                        {site.note ? site.note : "-"}
                        {site.note && site.note.length > 40 && (
                          <span
                            onClick={() =>
                              setSiteNoteExpanded({
                                ...siteNoteExpanded,
                                [site.id]: false,
                              })
                            }
                            style={{
                              cursor: "pointer",
                              color: "blue",
                            }}
                          >
                            &nbsp; See Less
                          </span>
                        )}
                      </>
                    )}
                  </td>
                  <td>
                    <div
                      className="iconboxes"
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        marginLeft: "10px",
                        display:"flex"
                      }}
                    >
                      <div
                        className="iconBox"
                        onMouseEnter={() => setIsEditHovered(site)}
                        onMouseLeave={() => setIsEditHovered(null)}
                        style={{
                          cursor: "pointer",
                          position: "relative",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => handleEditSiteClick(site)}
                      >
                        <ThinEditIcon height="15px" width="15px" fill="#" />
                        {isEditHovered === site && (
                          <div
                            style={{
                              position: "absolute",
                              top: "100%",
                              left: "50%",
                              transform: "translateX(-50%)",
                              background: "#ffffff",
                              padding: "2px",
                              borderRadius: "10px",
                              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                              zIndex: 999,
                              width: "90px",
                              fontSize: "12px",
                            }}
                          >
                            Edit Site
                          </div>
                        )}
                      </div>

                      {selectedSite === site && ( // Check if this row's modal should be open
                        <Modal      size="xl"
                          show={showEditSiteModal}
                     //     onHide={handleCloseEditSiteModal}
                          centered
                        >
                            <Modal.Body>
                            <div className="d-flex justify-content-end">
                              <button
                                type="button"
                                className="close"
                                aria-label="Close"
                                onClick={handleCloseEditSiteModal}
                                style={{
                                  background: "none",
                                  border: "none",
                                  fontSize: "35px",
                                  position: "absolute",
                                  zIndex: "9999",
                                }}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                          <EditSiteModal site={selectedSite} />
                          </Modal.Body>
                        </Modal>
                      )}
             

                    <div
                        className="iconBox"
                        onMouseEnter={() => setHoveredSite(site)}
                        onMouseLeave={() => setHoveredSite(null)}
                        style={{
                          cursor: "pointer",
                          position: "relative",
                        }}
                        onClick={() => handleOpenAddSiteNoteModal(site)}
                        // onClick={() => handleOpenEditTagModal(tag)}
                      >
                        <ThinAddNote height="15px" width="15px" fill="#" />
                        {hoveredSite === site && (
                          <div
                            style={{
                              position: "absolute",
                              top: "100%",
                              left: "50%",
                              transform: "translateX(-50%)",
                              background: "#ffffff",
                              padding: "2px",
                              borderRadius: "10px",
                              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                              zIndex: 999,
                              width: "70px",
                            }}
                          >
                            Add Note
                          </div>
                        )}
                      </div>
                      {selectedSite === site && (
                        <Modal
                          size="xl"
                          centered
                          show={openAddSiteNoteModal}
                          //   onHide={handleCloseAddTagNoteModal}
                        >
                          <Modal.Body>
                            <div className="d-flex justify-content-end">
                              <button
                                type="button"
                                className="close"
                                aria-label="Close"
                                onClick={handleCloseAddSiteNoteModal}
                                style={{
                                  background: "none",
                                  border: "none",
                                  fontSize: "35px",
                                  position: "absolute",
                                  zIndex: "999",
                                }}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <AddSiteNotes site={selectedSite} />
                          </Modal.Body>
                        </Modal>
                      )}

                      </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </div>
        </table>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <div>
          Showing {Math.min(indexOfLastItem, sitesList.length)} of{" "}
          {sitesList.length} items
        </div>
        <div
          style={{
            marginRight: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <button
            onClick={handlePreviousPage}
            style={{
              color: "black",
              borderRadius: "5px",
              border: "none",
              marginLeft: "10px",
              backgroundColor: "#3A475033",
              width: "80px",
              fontFamily: "futuramedium",
              cursor: "pointer",
            }}
          >
            Previous
          </button>

          {renderPageButtons()}

          <button
            onClick={handleNextPage}
            style={{
              color: "black",
              borderRadius: "5px",
              border: "none",
              marginLeft: "10px",
              backgroundColor: "#3A475033",
              width: "60px",
              fontFamily: "futuramedium",
              cursor: "pointer",
            }}
          >
            Next
          </button>
        </div>
      </div>
      </>
      )}
    </div>
  );
};

export default SitesTable;
