
import { FiArrowRight } from 'react-icons/fi'; // Import the FiArrowRight icon
import "../../css/homecss/Headline.css";
import Login from '../Login';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from "react";


// const staticUser = {
//   name: "John Doe",
//   isActive: false,};



const Headline = () => {

  const User = useSelector ((state) => state.user)

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      const screenWidth = window.innerWidth;
      setIsMobile(screenWidth < 768); // Adjust this value according to your design requirements
    };

    checkIfMobile();

    window.addEventListener("resize", checkIfMobile);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);


  return (
    <div className="headline"
    >
      {!User && <Login />}
      <div className='headlineContainer text-light'
      >
        {User && !isMobile ? (
          <div style={{ marginRight:"600px", marginLeft:"300px", marginTop:"15%"}}>
            <h2 style={{fontFamily:"futura"}}
             
            >Provide Effective And Safe Solutions!</h2>
            <p
              style={{color:"#E8E8E8", fontFamily:"futurabook", fontSize:"20px"}}
            >
           A once installed Safe T Tag device can reduce the risk of electrocution, along with further electrical and mechanical damage. The use of the app for Android and Apple users, as well as an electrical Safe T Tag device, can both enhance productivity and limit further damage. It is effective and safe to use; contact your electrical wholesaler / distributor today.
            </p>
          </div>
        ) : (
          <div className="lower-right-content">
            <h2 style={{fontFamily:"futura"}} className="smaller-h2">Provide Effective And Safe Solutions!</h2>
            <p
               style={{color:"#E8E8E8", fontFamily:"futurabook", fontSize:"20px"}}
            >
     A once installed Safe T Tag device can reduce the risk of electrocution, along with further electrical and mechanical damage. The use of the app for Android and Apple users, as well as an electrical Safe T Tag device, can both enhance productivity and limit further damage. It is effective and safe to use; contact your electrical wholesaler / distributor today.
            </p>
          </div>
        )}

      </div>
    </div>
  );
};

export default Headline;
