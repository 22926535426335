import React from 'react'
import { ThinUserIcon } from '../../fontAwesomeComponents/FaIcons'

const DistributorHeadline = () => {
  return (
    <div className='distributorheadline'
      style={{
        marginLeft:"150px",
        marginRight:"150px",
        marginTop:"50px",
        display: 'flex'
      }}
    >
          <div
        style={{
          flex: 3,
        }}
      >
      <h2 className='headerTextdistributor'
        style={{
          fontFamily:"futuraheavy",
          color:"#EEEEEE",
          fontSize:"45px",
          textAlign:"center"
        }}
      >
     Industrial Protective Products (WA)
      </h2>
      <p className='distributorheadlineP1'
        style={{
          fontFamily:"futurabook",
          color:"#EEEEEE",
          fontSize:"25px",
   
          textAlign:"center"
        }}
      >
       Industrial Protective Products (WA) is a wholly owned Western Australian Company operating as a "true specialist safety house", supplying a wide range of site safety equipment to the mining, construction and associated industries throughout Western Australia and Australia.
      </p>
      {/* <p
          style={{
            fontFamily:"futurabook",
            color:"#EEEEEE",
            fontSize:"15px",
            paddingRight:"60px"
          }}
      >
    
      </p> */}
      {/* <button className='save-button'
        style={{
          width:"200px"
        }}
      >
    More Details
      </button> */}
      </div>
      {/* <div
        className="contactInfo"
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          gap: "60px",
          paddingTop: "30px",
        }}
      >
      <h2
           style={{
            fontFamily:"futuraheavy",
            color:"#EEEEEE",
            fontSize:"30px"
          }}
      >
      Key Contacts
      </h2>
      <div className="contactInfo"
            style={{
                display:"flex",
                gap:"20px",
                paddingTop:"5px",
                flexDirection:"column"
            }}
        >
      <div className="infoTitle">
            <span
              className="contact-icon"
              style={{
                backgroundColor: "#F6C90E33",
                border: "none",
                marginRight:"15px"
              }}
            >
              <ThinUserIcon width="40px" height="30px" color="#F6C90E" />
            </span>
            <div>
              <h4 className="infoName"
                style={{
                    color:"#EEEEEE"
                }}
              >Telephone Number</h4>
              <p
                className="infoSubtitle"
                style={{
                  fontSize: "17px",
                  color:"#EEEEEE"
                }}
              >
          (08) 9304 9991 
              </p>
            </div>
          </div>
          <div className="infoTitle">
            <span
              className="contact-icon"
              style={{
                backgroundColor: "#F6C90E33",
                border: "none",
                marginRight:"15px"
              }}
            >
              <ThinUserIcon width="40px" height="30px" color="#F6C90E" />
            </span>
            <div>
              <h4 className="infoName"
                style={{
                    color:"#EEEEEE"
                }}
              >Telephone Number</h4>
              <p
                className="infoSubtitle"
                style={{
                  fontSize: "17px",
                  color:"#EEEEEE"
                }}
              >
          (08) 9304 9991 
              </p>
            </div>
            </div>
      </div>
      </div> */}
    </div>
  )
}

export default DistributorHeadline
