import React, { useState, useRef } from "react";
import Webcam from "react-webcam";
import { useSelector } from "react-redux";
import { publicRequest } from "../../requestMethods";
import { FaceViewFinder } from "../../fontAwesomeComponents/FaIcons";
import EditSuccessModal from "../../modals/EditSuccessModal";
import EditErrorModal from "../../modals/EditErrorModal";
import { Modal } from "react-bootstrap";

const AddDisplayPictureModal = () => {
  const [file, setFile] = useState(null);
  const [isEditing, setIsEditing] = useState(true); // Determines if the user is in edit mode
  const [imageUrl, setImageUrl] = useState(""); // Display image in profileCircle
  const [showWebcam, setShowWebcam] = useState(false);

  const token = useSelector((state) => state.user.token);
  const userCompanyId = useSelector((state) => state.selectedCompany);
  const userId = useSelector((state) => state.user.id);

  //modals

  const [showEditSuccessModal, setShowEditSuccessModal] = useState(false);

  const [showFailedEditModal, setShowFailedEditModal] = useState(false);

  const handleCloseFailedModal = () => {
    setShowFailedEditModal(false);
  };

  const toggleCloseFailedModal = () => {
    setShowFailedEditModal(!showFailedEditModal);
  };

  const webcamRef = useRef(null);
  const fileInputRef = useRef();

  const handleTakePhoto = () => {
    setShowWebcam(true);
  };

  const handleCapturePhoto = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();

      // Convert the image source to a File object
      const blob = dataURItoBlob(imageSrc);
      const file = new File([blob], "captured-image.jpg");

      setFile(file);
      setImageUrl(imageSrc);
      setShowWebcam(false);
      setIsEditing(false);
    }
  };

  // Function to convert data URI to Blob
  function dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: "image/jpeg" });
  }

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Create an HTMLImageElement to load the selected image
      const img = new Image();
      img.src = URL.createObjectURL(selectedFile);
  
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const maxWidth = 800; // Maximum width for the resized image
        const maxHeight = 600; // Maximum height for the resized image
        let newWidth, newHeight;
  
        // Calculate the new dimensions while maintaining the aspect ratio
        if (img.width > maxWidth || img.height > maxHeight) {
          const aspectRatio = img.width / img.height;
          if (img.width > img.height) {
            newWidth = maxWidth;
            newHeight = maxWidth / aspectRatio;
          } else {
            newHeight = maxHeight;
            newWidth = maxHeight * aspectRatio;
          }
        } else {
          newWidth = img.width;
          newHeight = img.height;
        }
  
        // Set the canvas size to the new dimensions
        canvas.width = newWidth;
        canvas.height = newHeight;
  
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, newWidth, newHeight);
  
        // Convert the canvas content to a blob with reduced size and set it as the new file
        canvas.toBlob((blob) => {
          const resizedFile = new File([blob], selectedFile.name, { type: 'image/jpeg' });
          setFile(resizedFile);
          setImageUrl(URL.createObjectURL(resizedFile));
          setIsEditing(false);
        }, 'image/jpeg', 0.7); // Adjust the quality (0.7 means 70% quality)
  
      };
    }
  };
  

  const uploadProfilePicture = async () => {
    const formData = new FormData();
    formData.append("LoggedUserId", userId);
    formData.append("LoggedUserCompanyId", userCompanyId);
    formData.append("File", file);

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await publicRequest("/user/profile/upload", {
        method: "POST",
        data: formData,
        headers: headers,
      });
      // Handle success
      // modal here
      setShowEditSuccessModal(true);
      console.log(response);
    } catch (error) {
      console.error(error); // Handle error
      // modal here
      setShowFailedEditModal(true);
    }
  };

  return (
    <div className="ModalChildDiv"
      style={{
        padding: "20px",
        backgroundColor: "#FFFFFF",
        borderRadius: "90px",
        marginTop: "40px",
      }}
    >
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          className="profileCircle"
          style={{
            height: "250px",
            width: "250px",
            borderRadius: "50%",
            backgroundColor: "#EEEEEE",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          {showWebcam ? (
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              style={{
                width: "100%", // Set the webcam to be 100% width
                height: "100%", // Set the webcam to be 100% height
                borderRadius: "50%",
              }}
            />
          ) : imageUrl ? (
            <img
              src={imageUrl}
              alt="Profile"
              style={{
                width: "100%", // Set the image to be 100% width
                height: "100%", // Set the image to be 100% height
                borderRadius: "50%",
              }}
            />
          ) : (
            <FaceViewFinder width="55px" height="55px" />
          )}
        </div>

        <div style={{ marginTop: "20px", textAlign: "center" }}>
          {isEditing ? (
            <h2 style={{ fontFamily: "futuraheavy" }}>A photo of you</h2>
          ) : (
            <h2 style={{ fontFamily: "futuraheavy" }}>{/* save or */}</h2>
          )}

          {isEditing ? (
            <p style={{ width: "300px", fontFamily: "futuramedium" }}>
              Please make sure your photo clearly shows your face
            </p>
          ) : null}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        {isEditing ? (
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleFileChange}
            id="upload-file"
            ref={fileInputRef}
          />
        ) : null}

        {isEditing && !showWebcam ? (
          <label
            className="next-button"
            style={{
              width: "220px",
              fontFamily: "futuraheavy",
            }}
            onClick={handleTakePhoto}
          >
            Take a photo
          </label>
        ) : showWebcam ? (
          <button
            className="next-button"
            style={{
              width: "220px",
              fontFamily: "futuraheavy",
            }}
            onClick={handleCapturePhoto}
          >
            Capture
          </button>
        ) : (
          <button
            className="next-button"
            style={{
              width: "220px",
              fontFamily: "futuraheavy",
            }}
            onClick={uploadProfilePicture}
          >
            Save
            <Modal show={showEditSuccessModal} centered>
              <EditSuccessModal />
            </Modal>
          </button>
        )}

        <Modal
          show={showFailedEditModal}
          onClose={handleCloseFailedModal}
          centered
        >
          <EditErrorModal onClose={toggleCloseFailedModal} />
        </Modal>
        
        {isEditing && !showWebcam ? (
          <label
            htmlFor="upload-file"
            className="next-button"
            style={{
              width: "220px",
              fontFamily: "futuraheavy",
            }}
          >
            Choose from gallery
          </label>
        ) : showWebcam ? (
          <button
            className="next-button"
            style={{
              width: "220px",
              fontFamily: "futuraheavy",
              backgroundColor: "#CCCCCC", // Gray background for cancel
            }}
            onClick={() => {
              setFile(null);
              setImageUrl("");
              setIsEditing(true);
              setShowWebcam(false);
            }}
          >
            Cancel
          </button>
        ) : (
          <button
            className="next-button"
            style={{
              width: "220px",
              fontFamily: "futuraheavy",
              backgroundColor: "#CCCCCC", // Gray background for cancel
            }}
            onClick={() => {
              setFile(null);
              setImageUrl("");
              setIsEditing(true);
            }}
          >
            Cancel
          </button>
        )}
      </div>
    </div>
  );
};

export default AddDisplayPictureModal;
