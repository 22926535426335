import React, { useState } from "react";
import Navbar from '../components/NavBar';
import UserApprovalBar from '../components/UserApproval/UserApprovalBar';
import ItemArchiveTable from '../components/ItemArchive/ItemArchiveTable';

const ItemArchive = () => {

  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = (query) => {
    // Update the search query state
    setSearchQuery(query);
  };


  return (
    <div>
    <div
      style={{
        backgroundColor: "#EEEEEE",
        minHeight: "100%",
        paddingBottom: "25px",
        display: "flex",
        flexDirection: "column"
      }}
    >
      <Navbar />
      <UserApprovalBar searchQuery={searchQuery} onSearch={handleSearch} />
      <ItemArchiveTable  searchQuery={searchQuery}/>
    </div>
  </div>
  )
}

export default ItemArchive
