import React, { useState } from "react";
import "../../css/registercss/Finish.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../../redux/apiCals";
import { RiEyeFill, RiEyeOffFill, RiCalendarFill } from "react-icons/ri";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import RegisterSuccessModal from "../../modals/RegisterSuccessModal";
import RegisterErrorModal from "../../modals/RegisterErrorModal";
import { Modal } from "react-bootstrap";
import { publicRequest } from "../../requestMethods";

const Finish = ({ userData, companyData, onPrevious }) => {
  const handlePrevious = () => {
    setTimeout(() => {
      onPrevious();
    }, 500); // 2000 milliseconds = 2 seconds
  };

  console.log("this is user", userData);

  //modals

  const [showAddSuccessModal, setShowAddSuccessModal] = useState(false);

  const dispatch = useDispatch();
  // const isFetching = useSelector((state) => state.user.isFetching);
  const [isTermsAccepted, setTermsAccepted] = useState(false);
  //const [isRegistrationSuccess, setRegistrationSuccess] = useState(false); // State to track registration success
  const [showSuccessModal, setShowSuccessModal] = useState(false); // State to toggle success modal
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);
  const isRegistrationSuccess = useSelector(
    (state) => state.isRegistrationSuccess
  );

  const isRegistrationFailed = useSelector((state) => state.error);

  const dob = new Date(userData.dob); // Parse the input date string

  const day = dob.getDate().toString().padStart(2, "0");
  const month = (dob.getMonth() + 1).toString().padStart(2, "0");
  const year = dob.getFullYear();

  const formattedDob = `${day} / ${month} / ${year}`;

  const handleFinish = async () => {
    setButtonClicked(true);
    if (!isTermsAccepted) {
      // Don't proceed if terms are not accepted
      // Also show validation that they have to accept the terms and conditions
      return;
    }

    let phoneValue = userData.officePhone;
    if (userData.officePhone === "61" || userData.officePhone === "64") {
      phoneValue = null;
    }

    console.log("this is phone value", phoneValue);
    //TestPassWord123!

    const user = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      password: userData.password,
      email: userData.email,
      dob: userData.dob, // Make sure userData.dob is in "DD-MM-YYYY" format
      mobilePhone: (() => {
        const phonePrefix = userData.mobilePhone.substring(0, 3); // Extract the first 3 characters
        const hasPlusSign = ["61", "678", "64"].includes(phonePrefix);

        return hasPlusSign ? `+${userData.mobilePhone}` : userData.mobilePhone;
      })(),
      licenseNumber: userData.licenseNumber,
      isTermAccepted: true,
      phone: (() => {
        if (phoneValue === "+64" || phoneValue === "+61") {
          return "";
        } else {
          return phoneValue;
        }
      })(),
      company: {
        // companyId: companyData.id,
        companyId: companyData.companyId || 0,
        name: companyData.name || companyData.companySearchInput,
        addressLine1: companyData.addressLine1,
        addressLine2: companyData.addressLine2,
        city: companyData.city,
        stateId: companyData.stateId,
        countryId: companyData.countryId,
        abnAcnIdentifierNumber: companyData.abnAcnNumber,
        officePhone: (() => {
          if (companyData.officePhone) {
            if (companyData.officePhone.startsWith("+")) {
              // Remove any additional + signs
              return "+" + companyData.officePhone.replace(/\+/g, "");
            } else {
              // Add + sign at the beginning
              return "+" + companyData.officePhone;
            }
          } else {
            // If officePhone is blank or undefined, return empty string or handle as needed
            return "";
          }
        })(),

        postCode: companyData.postalCode,
      },
    };

    console.log("dasdasdasd", user);

    try {
      const response = await publicRequest.post("/auth/register", user);
      setShowAddSuccessModal(true);
    } catch (error) {
      console.error(error);
      setShowErrorModal(true);
    }
  };

  const closeErrorModal = () => {
    setShowErrorModal(false);
  };

  const toggleCloseErrorModal = () => {
    setShowErrorModal(!showErrorModal);
  };
  const closeModal = () => {
    setShowSuccessModal(false);
  };

  return (
    <div className="finalDetails-container">
      <div className="finalTitleDiv">
        <h3
          style={{
            fontFamily: "futurabook",
            fontWeight: "bold",
            fontSize: "40px",
          }}
        >
          Register an Account
        </h3>
        <p style={{ fontFamily: "futurabook" }}>
          Information required for the first user of new company registration
        </p>
      </div>
      <div className="data-display">
        <p style={{ fontSize: "25px" }}>
          <strong>User Details:</strong>
        </p>
        <div className="finish-row">
          <div className="finish-column">
            <p className="detailsTitle">First Name:</p>
            <p className="finalDetails">{userData.firstName}</p>
          </div>
          <div className="finish-column">
            <p className="detailsTitle">Last Name:</p>
            <p className="finalDetails">{userData.lastName}</p>
          </div>
        </div>

        {/* Use emailField class for the 2-column layout */}
        <div className="finish-row">
          <div className="finish-column wholeRow">
            <p className="detailsTitle">Email:</p>
            <p className="finalDetails">{userData.email}</p>
          </div>
        </div>

        <div className="finish-row">
          <div className="finish-column">
            <p className="detailsTitle">Date of Birth:</p>
            <p className="finalDetails">{formattedDob}</p>
          </div>
          <div className="finish-column">
            <p className="detailsTitle">Mobile Number:</p>
            <p className="finalDetails">
              {userData.mobilePhone.startsWith("+")
                ? userData.mobilePhone
                : "+" + userData.mobilePhone}
            </p>
          </div>
        </div>

        {/* Apply 2-column layout for Master / Electricians License Number */}
        <div className="finish-row masterNumber">
          <div className="finish-column wholeRow">
            <p className="detailsTitle">
              Master / Electricians License Number*:
            </p>
            <p className="finalDetails">{userData.licenseNumber}</p>
          </div>
        </div>

        <p style={{ fontSize: "25px" }}>
          <strong>Company Details:</strong>
        </p>

        <div className="finish-row">
          <div className="finish-column wholeRow">
            <p className="detailsTitle">Company Name:</p>
            <p className="finalDetails">
              {companyData.name || companyData.companySearchInput}
            </p>
          </div>
        </div>

        <div className="finish-row">
          <div className="finish-column wholeRow">
            <p className="detailsTitle">Complete Address:</p>
            <p className="finalDetails">
              {companyData.addressLine1} {companyData.addressLine2}{" "}
              {companyData.city} {companyData.postalCode}{" "}
              {companyData.selectedState} {companyData.stateName}{" "}
              {companyData.selectedCountry}{" "}
            </p>
          </div>
        </div>

        <div className="finish-row">
          <div className="finish-column wholeRow">
            <p className="detailsTitle">ABN/ACN Identifier Number*:</p>
            <p className="finalDetails">{companyData.abnAcnNumber}</p>
          </div>
        </div>
        <div className="finish-row">
          <div className="finish-column wholeRow">
            <p className="detailsTitle">Office Phone Number:</p>
            <p className="finalDetails">
              {companyData.officePhone.startsWith("+")
                ? companyData.officePhone
                : "+" + companyData.officePhone}
            </p>
          </div>
        </div>

        <div
          className="accept-terms"
          style={{
            marginBottom: "30px",
            marginTop: "10px",
          }}
        >
          <input
            type="checkbox"
            id="termsCheckbox"
            checked={isTermsAccepted}
            onChange={() => setTermsAccepted(!isTermsAccepted)}
          />
          <label
            className="termsCheckbox"
            style={{ fontWeight: "normal", fontSize: "15px" }}
          >
            To use this site, I accept and agree with the entire contents of the{" "}
            <a
              href="/privacypolicy"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "black" }}
            >
              <strong>Safe T Tag Privacy Policies</strong>
            </a>{" "}
            and{" "}
            <a
              href="/termsandconditions"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "black" }}
            >
              <strong>Terms and Conditions</strong>
            </a>{" "}
            document.
          </label>
        </div>
      </div>
      <div className="component-navigation">
        {buttonClicked && !isTermsAccepted && (
          <p style={{ color: "red", marginTop: "10px" }}>
            You have to agree to the terms and conditions
          </p>
        )}
        <span className="back-button" onClick={handlePrevious}>
          Back
        </span>
        <button className="next-button" onClick={handleFinish}>
          Finish
          {/* Success Modal */}
          {/* Error Modal */}
        </button>

        <Modal
          show={showAddSuccessModal}
          //    onHide={closeModal}
          centered
        >
          <Modal.Body>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={closeModal}
                style={{
                  background: "none",
                  border: "none",
                  fontSize: "35px",
                  position: "absolute",
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <RegisterSuccessModal />
          </Modal.Body>
        </Modal>
        <Modal
          show={showErrorModal}
          //    onHide={closeErrorModal}
          centered
        >
          <Modal.Body>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={closeErrorModal}
                style={{
                  background: "none",
                  border: "none",
                  fontSize: "35px",
                  position: "absolute",
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <RegisterErrorModal onClose={toggleCloseErrorModal} />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default Finish;

//sampleemailtest@gmail.com User@12345ye
