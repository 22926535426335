import React from 'react'
import DistributorMap from './DistributorMap'

const DistributorLocation = () => {
  return (
    <div className='distributorMapContainer'
        style={{
            marginLeft:"150px",
            marginRight:"150px",
          maringtop :"50px"
        }}
    >
      <h2 className='headerTextdistributor'
        style={{
          fontFamily:"futuraheavy",
          color:"#eeeeee",
          textAlign:"center",
          fontSize:"40px"
        }}
      > Office Address</h2>
      <p
          style={{
            fontFamily:"futurabook",
            color:"#eeeeee",
            textAlign:"center",
            fontSize:"15px"
          }}
      >
    39 Norma Rd, Myaree WA 6154
      </p>

      <DistributorMap/>
    </div>
  )
}

export default DistributorLocation
