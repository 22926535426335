import React, { useEffect, useState } from "react";
import {
  LocationCrosshair,
  QrCodeIcon,
  ArrowMaximize,
  TouchScreenScanner,
} from "../../fontAwesomeComponents/FaIcons";

const DownloadAdditionalFeatures = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      const screenWidth = window.innerWidth;
      setIsMobile(screenWidth < 768); // Adjust this value according to your design requirements
    };

    checkIfMobile();

    window.addEventListener("resize", checkIfMobile);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  return (
    <div
      className="mainBodyDownloadAdditionalFeatures"
      style={{
        backgroundColor: "#141212",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        color: "#EEEEEE",
        borderBottom: "1px solid gray",
        paddingBottom: "30px",
        paddingTop: "30px",
      }}
    >
      <div
        className="download-title"
        style={{
          width: "100%",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "30px",
        }}
      >
        <h4 className="downloadTheAdditionalFeatures"
          style={{
            fontFamily: "futura",
            fontSize: "25px",
          }}
        >
          The additional features of the <br />
          Safe T Tag Apps Include
        </h4>
        <p className="additionalFeaturesPp"
          style={{
            marginLeft: "15%",
            marginRight: "15%",
            width: "500px",
            textAlign: "center",
            fontFamily: "futurabook",
            fontSize: "15px",
          }}
        >
          The Apps have been designed especially to enhance the user experience
          and make it even simpler to capitalize on all of the features of the{" "}
          <br />
          Safe T Tag system.
        </p>
      </div>

      {isMobile &&(
        <div className="downloadphone3" style={{ height: "600px" }}></div>
      )}

      <div
        className="download-grid"
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gridGap: "20px",
          marginLeft: "15%",
          marginRight: "15%",
        }}
      >
        <div style={{ gridRow: "1 / span 2" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <div
              style={{
                backgroundColor: "#413611",
                justifyContent: "center",
                display: "flex",
                width: "70px",
                height: "70px",
                alignItems: "center",
                borderRadius: "20px",
              }}
            >
              <div className="gps-container">
                <LocationCrosshair width="40px" height="40px" />
              </div>
            </div>

            <p
              className="downloadnowtitle"
              style={{
                textAlign: "right",
              }}
            >
              Enhanced Mobile Responsiveness
            </p>
            <p
              className="downloadnowdescription"
              style={{
                textAlign: "right",
              }}
            >
              The apps are designed specifically for use on mobile devices. This
              means that buttons are larger, the view is customised and it is a
              much better mobile experience.
            </p>
          </div>

          <div
            style={{
              marginTop: "50px",
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <div
              style={{
                backgroundColor: "#413611",
                justifyContent: "center",
                display: "flex",
                width: "70px",
                height: "70px",
                alignItems: "center",
                borderRadius: "20px",
              }}
            >
              <div className="QrIcon-container">
                <QrCodeIcon width="40px" height="40px" />
              </div>
            </div>

            <p
              className="downloadnowtitle"
              style={{
                textAlign: "right",
              }}
            >
              QR Code Launcher
            </p>
            <p
              className="downloadnowdescription"
              style={{
                textAlign: "right",
              }}
            >
              By using any QR code reader on the QR code attached to a <br />{" "}
              Safe T Tag, the app will automatically load and direct you to the
              page to log the details of the Safe T Tag.
            </p>
          </div>
        </div>
        <div style={{ gridRow: "1 / span 2", marginTop: "-90px" }}>
          <div className="downloadphone3"></div>
        </div>
        <div style={{ gridRow: "1 / span 2" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div
              className="box3"
              style={{
                backgroundColor: "#413611",
                justifyContent: "center",
                display: "flex",
                width: "70px",
                height: "70px",
                alignItems: "center",
                borderRadius: "20px",
              }}
            >
              <div className="gps-container">
                <ArrowMaximize width="40px" height="40px" />
              </div>
            </div>

            <p className="downloadnowtitle">GPS Locating</p>
            <p
              className="downloadnowdescription"
              style={{
                textAlign: "left",
              }}
            >
              Using the power of your mobile device's GPS, the apps can locate
              your position and will automatically suggest the location where
              you are working in when you are conducting your testing, tagging
              applying a Safe T Tag.
            </p>
          </div>

          <div
            style={{
              marginTop: "30px",
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                backgroundColor: "#413611",
                justifyContent: "center",
                display: "flex",
                width: "70px",
                height: "70px",
                alignItems: "center",
                borderRadius: "20px",
              }}
            >
              <div className="QrIcon-container">
                <TouchScreenScanner width="40px" height="40px" />
              </div>
            </div>

            <p className="downloadnowtitle">QR Code Reader</p>
            <p
              className="downloadnowdescription"
              style={{
                textAlign: "left",
              }}
            >
              The apps have their own QR Code reader that you can use when
              applying <br />a Safe T Tag to automatically quickly and easily
              load the details of the appliance or equipment you have applied{" "}
              <br /> the Safe T Tag on.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadAdditionalFeatures;
