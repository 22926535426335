import React, { useEffect, useState } from "react";
import { publicRequest } from "../../requestMethods";
import {
  DownloadIcon,
  ThinAddNote,
  DeleteIcon,
} from "../../fontAwesomeComponents/FaIcons";
import { useSelector } from "react-redux";
import AddTagAttachmentNoteModal from "../TagsList/AddTagAttachments/AddTagAttachmentNoteModal";
//import ViewTagAttachmentNotesModal from "./AddTagAttachments/ViewTagAttachmentNotesModal";
import { Modal } from "react-bootstrap";
import ViewTagAttachmentNotesModal from "../TagsList/AddTagAttachments/ViewTagAttachmentNotesModal";
// import DeleteConfirmationModal from "./ValidationModals/DeleteConfirmationModal";

const DeletedTagsAttachments = ({ tag, refreshNotes }) => {
  const token = useSelector((state) => state.user.token);
  const userId = useSelector((state) => state.user.id);
  const selectedUserCompanyId = useSelector((state) => state.selectedCompany);
  const [attachmentNoteExpanded, setAttachmentNoteExpanded] = useState({});
  const [isAddNoteHovered, setIsAddNoteHovered] = useState(false);
  const [isDownloadAttachment, setisDownloadAttachment] = useState(false);
  const [isArchiveHovered, setIsArchivehovered] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleOpenDeleteModal = (attachment) => {
    setSelectedAttachment(attachment);
    setShowDeleteModal(true);
    setIsArchivehovered(attachment);
  };

  const handleCloseDeleteModal = (onClose) => {
    setShowDeleteModal(false);
    setIsArchivehovered(null);
  };

  const toggleDeletConfirmModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const refreshAttachments = async () => {
    try {
      const params = {
        LoggedUserId: userId,
        LoggedUserCompanyId: selectedUserCompanyId,
      };

      const attachmentsResponse = await fetchData(
        `tag/${tag.id}/attachment/list`,
        params
      );

      if (attachmentsResponse) {
        setAttachmentList(attachmentsResponse);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [openAttachmentNotes, setOpenAttachmentNotes] = useState(false);

  const handleOpenAttachmentNotes = (attachment) => {
    setOpenAttachmentNotes(true);
    setSelectedAttachment(attachment);
    setIsAddNoteHovered(attachment);
  };

  const handleCloseAttachmentNotes = () => {
    setOpenAttachmentNotes(false);
    setIsAddNoteHovered(null);
  };

  const [openAttachmentNoteList, setOpenAttachmentNoteList] = useState(false);

  const handleOpenAttachmentNoteList = (tag, attachment) => {
    setOpenAttachmentNoteList(true);
    setSelectedAttachment(attachment);
  };

  const handleCloseAttachmentNoteList = () => {
    setOpenAttachmentNoteList(false);
  };

  console.log(tag);

  const [selectedAttachment, setSelectedAttachment] = useState(null);

  const formatAndConvertDate = (dateString) => {
    if (!dateString) return "";
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      timeZone: userTimezone,
    };
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString(undefined, options);
    const [month, day, year] = formattedDate.split("/");
    return `${day}/${month}/${year}`;
  };

  const [attachmentList, setAttachmentList] = useState([]);

  const fetchData = async (url) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const params = {
        LoggedUserId: userId,
        LoggedUserCompanyId: selectedUserCompanyId,
      };

      const queryString = new URLSearchParams(params).toString();
      const fullUrl = url + (queryString ? `?${queryString}` : "");

      const response = await publicRequest({
        method: "GET",
        url: fullUrl,
        headers: headers,
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchAttachments = async () => {
      const params = {
        LoggedUserId: userId,
        LoggedUserCompanyId: selectedUserCompanyId,
      };

      const attachmentsResponse = await fetchData(
        `tag/${tag.id}/attachment/deleted/list`,
        params
      );

      if (attachmentsResponse) {
        setAttachmentList(attachmentsResponse);
        attachmentsResponse.sort((a, b) => b.dateCreatedUtc - a.dateCreatedUtc);
        console.log("this is attachments", attachmentsResponse);
      }
    };
    fetchAttachments();
  }, []);

  const downloadAttachment = async (attachment) => {
    const queryParamsForDownload = {
      LoggedUserId: userId,
      LoggedUserCompanyId: selectedUserCompanyId,
    };

    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const requestConfigForDownload = {
        method: "GET",
        headers: headers,
        params: queryParamsForDownload,
        responseType: "blob", // Specify the response type as "blob"
      };

      const response = await publicRequest(
        `tag/${tag.id}/attachment/download/${attachment.id}`,
        requestConfigForDownload
      );

      if (response) {
        // Create a blob URL from the response data
        const blob = new Blob([response.data]);
        const url = window.URL.createObjectURL(blob);

        // Create a download link and trigger a click event to start the download
        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = attachment.fileName; // Set the filename for the download
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);

        downloadLink.click();

        // Clean up the temporary download link and URL
        window.URL.revokeObjectURL(url);
        document.body.removeChild(downloadLink);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="ModalChildDiv">
      {attachmentList.length > 0 && (
        <div>
          <div
            className="finish-row"
            style={{
              padding: "20px",
            }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <p
                  className="detailsTitle"
                  style={{
                    fontSize: "30px",
                    marginBottom: "5px",
                    color: "#141212",
                    fontFamily: "futuramedium",
                  }}
                >
                  Attachments
                </p>

                <p
                  className="detailsTitle"
                  style={{
                    fontSize: "15px",
                    marginTop: "10px",
                    marginLeft: "10px",
                    color: "#3A4750",
                    fontFamily: "futuramedium",
                    background: "#3A475033",
                    borderRadius: "20px",
                    padding: "5px",
                    width: "100px",
                    textAlign: "center",
                  }}
                >
                  {tag.serialNumber}
                </p>
                <div>
                  {/* <button
                  onClick={handleOpenAttachmentNoteList}
                    style={{
                      width: "250px",
                      height: "32px",
                      borderRadius: "20px",
                      backgroundColor: "#F6C90E",
                      border: "none",
                      fontFamily: "futuramedium",
                      marginTop: "10px",
                      marginLeft: "180px",
                    }}
                  >
                    View Attachment Notes
                  </button> */}

                  {/* <Modal size="xl"
                      show={openAttachmentNoteList}
                      onHide={handleCloseAttachmentNoteList}
                      centered
                    >
                      <ViewTagAttachmentNotesModal tag={tag} />
                    </Modal> */}
                </div>
              </div>
            </div>
            <div className="finish-column wholeRow">
              <div className="approvalTable" style={{}}>
                <table>
                  <div className="tHead">
                    <thead>
                      <tr>
                        <th
                          style={{
                            width: "453px",
                          }}
                        >
                          File Name
                        </th>
                        <th
                          className=""
                          style={{
                            width: "333px",
                          }}
                        >
                          Created By
                        </th>
                        <th
                          style={{
                            minWidth: "203px",
                          }}
                        >
                          Date Added
                        </th>

                        <th style={{ width: "333px" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody className="tBody">
                      {attachmentList.map((attachment, index) => (
                        <tr key={index}>
                          <td
                            style={{
                              width: "50%",
                            }}
                          >
                            {attachment.fileName.length > 20
                              ? attachment.fileName.slice(0, 20) + "..."
                              : attachment.fileName}
                          </td>

                          <td
                            style={{
                              width: "100%",
                            }}
                          >
                            {attachment.createdBy}
                          </td>
                          <td>
                            {formatAndConvertDate(attachment.dateCreatedUtc)}
                          </td>
                          {/* <td className="descriptionNoteColumn">
                            {attachment.note &&
                            attachment.note.length > 40 &&
                            !attachmentNoteExpanded[attachment.id] ? (
                              <>
                                {attachment.note.slice(0, 40)}{" "}
                                <span
                                  onClick={() =>
                                    setAttachmentNoteExpanded({
                                      ...attachmentNoteExpanded,
                                      [attachment.id]: true,
                                    })
                                  }
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  ...
                                </span>
                              </>
                            ) : (
                              <>
                                {attachment.note ? attachment.note : "-"}
                                {attachment.note &&
                                  attachment.note.length > 40 && (
                                    <span
                                      onClick={() =>
                                        setAttachmentNoteExpanded({
                                          ...attachmentNoteExpanded,
                                          [tag.id]: false,
                                        })
                                      }
                                      style={{
                                        cursor: "pointer",
                                        color: "blue",
                                      }}
                                    >
                                      &nbsp; See Less
                                    </span>
                                  )}
                              </>
                            )}
                          </td> */}
                          <td>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              {/* <div
                                className="iconBox"
                                onMouseEnter={() =>
                                  setIsAddNoteHovered(attachment)
                                }
                                onMouseLeave={() => setIsAddNoteHovered(null)}
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "5px",
                                  justifyContent: "center",
                                  position: "relative",
                                }}
                                onClick={() =>
                                  handleOpenAttachmentNotes(attachment)
                                } // Call the downloadAttachment function when the icon is clicked
                              >
                                <ThinAddNote
                                  color=""
                                  height="13px"
                                  width="13px"
                                />
                                {isAddNoteHovered === attachment && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "100%",
                                      left: "50%",
                                      transform: "translateX(-50%)",
                                      background: "#ffffff",
                                      padding: "2px",
                                      borderRadius: "10px",
                                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                      zIndex: 999,
                                      width: "70px",
                                    }}
                                  >
                                    Add Note
                                  </div>
                                )}
                              </div> */}
                              <div
                                onMouseEnter={() =>
                                  setisDownloadAttachment(attachment)
                                }
                                onMouseLeave={() =>
                                  setisDownloadAttachment(null)
                                }
                                className="iconBox"
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "5px",
                                  justifyContent: "center",
                                  position: "relative",
                                }}
                                onClick={() => downloadAttachment(attachment)} // Call the downloadAttachment function when the icon is clicked
                              >
                                <DownloadIcon
                                  color=""
                                  height="13px"
                                  width="13px"
                                />
                                {isDownloadAttachment === attachment && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "100%",
                                      left: "50%",
                                      transform: "translateX(-50%)",
                                      background: "#ffffff",
                                      padding: "2px",
                                      borderRadius: "10px",
                                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                      zIndex: 999,
                                      width: "70px",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Download Attachment
                                  </div>
                                )}
                              </div>
                              {/* <div
                                onMouseEnter={() =>
                                  setIsArchivehovered(attachment)
                                }
                                onMouseLeave={() => setIsArchivehovered(null)}
                                className="iconBox"
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "5px",
                                  justifyContent: "center",
                                  position: "relative",
                                }}
                                onClick={() =>
                                  handleOpenDeleteModal(attachment)
                                } // Call the downloadAttachment function when the icon is clicked
                              >
                                <DeleteIcon
                                  color=""
                                  height="13px"
                                  width="13px"
                                />
                                {isArchiveHovered === attachment && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "100%",
                                      left: "50%",
                                      transform: "translateX(-50%)",
                                      background: "#ffffff",
                                      padding: "2px",
                                      borderRadius: "10px",
                                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                      zIndex: 999,
                                      width: "75px",
                                      fontSize: "12px",
                                    }}
                                  >
                                    Delete Attachment
                                  </div>
                                )}
                              </div> */}
                              {/* {selectedAttachment === attachment && (
                                <Modal
                                  centered
                                  show={showDeleteModal}
                                  //     onHide={handleCloseDeleteModal}
                                >
                                  <Modal.Body>
                                    <div className="d-flex justify-content-end">
                                      <button
                                        type="button"
                                        className="close"
                                        aria-label="Close"
                                        onClick={handleCloseDeleteModal}
                                        style={{
                                          background: "none",
                                          border: "none",
                                          fontSize: "35px",
                                          position: "absolute",
                                        }}
                                      >
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <DeleteConfirmationModal
                                      attachment={selectedAttachment}
                                      tagId={tag.id}
                                      onClose={toggleDeletConfirmModal}
                                      refreshAttachments={refreshAttachments}
                                    />
                                  </Modal.Body>
                                </Modal>
                              )} */}
                            </div>
                          </td>
                          {selectedAttachment === attachment && (
                            <Modal
                              size="xl"
                              show={openAttachmentNotes}
                              //      onHide={handleCloseAttachmentNotes}
                              centered
                            >
                              <Modal.Body>
                                <div className="d-flex justify-content-end">
                                  <button
                                    type="button"
                                    className="close"
                                    aria-label="Close"
                                    onClick={handleCloseAttachmentNotes}
                                    style={{
                                      background: "none",
                                      border: "none",
                                      fontSize: "35px",
                                      position: "absolute",
                                      zIndex: 999,
                                    }}
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <AddTagAttachmentNoteModal
                                  attachment={selectedAttachment}
                                  tag={tag}
                                  onClose={handleCloseAttachmentNotes}
                                  refreshNotes={refreshNotes}
                                />
                              </Modal.Body>
                            </Modal>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </div>
                </table>
              </div>
            </div>
          </div>
          {/* notes */}
          {/* <ViewTagAttachmentNotesModal
            tag={tag}
            attachment={selectedAttachment}
            refreshNotes={refreshNotes}
          /> */}
        </div>
      )}
      {attachmentList.length < 1 && (
        <p
          style={{
            textAlign: "center",
            fontFamily: "futurabook",
            marginTop: "10px",
          }}
        >
          No Attachments
        </p>
      )}
    </div>
  );
};

export default DeletedTagsAttachments;
