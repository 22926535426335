import React, { useState, useEffect } from "react";
import "../../css/userApprovalcss/userApproval.css";
import { Dropdown } from "react-bootstrap";
import { publicRequest } from "../../requestMethods";
import { useSelector } from "react-redux";
import EditSuccessModal from "../../modals/EditSuccessModal";
import EditErrorModal from "../../modals/EditErrorModal";
import { Modal } from "react-bootstrap";
import { RefreshIcon, DownloadIcon } from "../../fontAwesomeComponents/FaIcons";

const UserApprovalTable = ({searchQuery}) => {
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [dropdownIndex, setDropdownIndex] = useState(-1);
  const [selectedUserOptions, setSelectedUserOptions] = useState([]); // State to hold selected user options
  const userOptions = ["User", "Supervisor", "Administrator"];
  const [selectedUserOption, setSelectedUserOption] = useState("Select");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const userAction = ["Approve", "Deny"];
  const [selectedAction, setSelectedAction] = useState(null);
  const [isActionDropdownOpen, setIsActionDropdownOpen] = useState(false);
  const [selectedUserActions, setSelectedUserActions] = useState({});
  const token = useSelector((state) => state.user.token);
  const selectedCompany = useSelector((state) => state.selectedCompany);
  const id = useSelector((state) => state.user.id);
  const [selectedRoleNames, setSelectedRoleNames] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isRefreshHovered, setIsRefreshHovered] = useState(false);

  const [isLoading, setIsLoading] = useState(false);




  //modalsuccess

  const [showEditSuccessModal, setShowEditSuccessModal] = useState(false);

  const handleCloseSuccessUserModal = () => {
    setShowEditSuccessModal(false);
  };

  //modal failed
  const [showErrorModal, setShowErrorModal] = useState(false);
  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
  };

  const handleOpenErrorModal = () => {
    setShowErrorModal(true);
  };

  const roleMappings = {
    User: 4,
    Supervisor: 3,
    Administrator: 2,
  };

  // converting values

  const handleSelectAllChange = (isSelectAll) => {
    if (isSelectAll) {
      setSelectAllChecked(true);
      setSelectedRows([...Array(approvalList.length).keys()]);
    } else {
      setSelectAllChecked(false);
      setSelectedRows([]);
    }
  };

  const handleSelectAllChangeBox = () => {
    setSelectAllChecked(!selectAllChecked);

    if (!selectAllChecked) {
      setSelectedRows([...Array(approvalList.length).keys()]);
    } else {
      setSelectedRows([]);
    }
  };

  const [approvalList, setApprovalList] = useState([]); // State to hold approval list data
  const [selectedRowsByPage, setSelectedRowsByPage] = useState([]);

  const handleRowSelection = (index) => {
    const itemIndex = indexOfFirstItem + index; // Calculate the index in the full list
    const newSelectedRows = [...selectedRows];

    if (newSelectedRows.includes(itemIndex)) {
      // If the row is already selected, deselect it.
      newSelectedRows.splice(newSelectedRows.indexOf(itemIndex), 1);
    } else {
      // If the row is not selected, select it.
      newSelectedRows.push(itemIndex);
    }

    setSelectedRows(newSelectedRows);

    // Now, update the selectedRowsByPage
    setSelectedRowsByPage((prevSelectedRowsByPage) => {
      const updatedSelectedRows = [...prevSelectedRowsByPage];
      updatedSelectedRows[currentPage - 1] = newSelectedRows;
      return updatedSelectedRows;
    });
  };

  const handleUserOptionChange = (option, index) => {
    setSelectedUserOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = roleMappings[option];
      return updatedOptions;
    });

    setSelectedRoleNames((prevNames) => {
      const updatedNames = [...prevNames];
      updatedNames[index] = option;
      return updatedNames;
    });

    setDropdownIndex(-1);
  };

  // const handleUserActionClick = (action) => {
  //   setSelectedAction(action);
  //   setIsActionDropdownOpen(false);
  // };

  const handleUserActionClick = (action, index) => {
    setSelectedUserActions((prevActions) => ({
      ...prevActions,
      [index]: action,
    }));
    setDropdownIndex(-1);
  };

  const toggleDropdown = (index) => {
    if (dropdownIndex === index) {
      setDropdownIndex(-1);
    } else {
      setDropdownIndex(index);
    }
  };

  const selectUserOption = (option) => {
    setSelectedUserOption(option);
    setIsDropdownOpen(false);
  };

  const queryParams = {
    LoggedUserId: id,
    LoggedUserCompanyId: selectedCompany,
  };

  const handleApproveUser = async () => {
    const data = {
      userId: id,
      userCompanyId: selectedCompany,
    };

    const queryString = new URLSearchParams(queryParams).toString();

    const fullUrl =
      "/user/approvallist" + (queryString ? `?${queryString}` : "");

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      setIsLoading(true);
      const response = await publicRequest({
        method: "GET",
        url: fullUrl, // Replace with the actual API endpoint

        headers: headers,
      });

      setApprovalList(response.data);
      console.log(response.data); // Display the response data
    } catch (error) {
      console.error("Error while fetching user:", error);
    }finally{
      setIsLoading(false);
    }
  };

  //updateuser

  const updateUser = async (updatedApprovalList) => {
    try {
      const approvalData = updatedApprovalList.map((approval, index) => ({
        userId: approvalList[index].id,
        userCompanyId: approvalList[index].userCompanyId,
        roleId: selectedUserOptions[index],
        isApproved: approval.isApproved,
      }));

      console.log("this is approvaldata", approvalData);

      const data = {
        LoggedUserId: id,
        LoggedUserCompanyId: selectedCompany,
        approvalList: approvalData,
      };

      console.log("data", data);

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await publicRequest({
        method: "POST",
        url: "/user/approvallist/update",
        data: data,
        headers: headers,
      });
      console.log(response.data);
      setShowEditSuccessModal(true);
      // Handle the response as needed
    } catch (error) {
      setShowErrorModal(true);
      console.error("Error while updating user approval:", error);
    }
  };

  const handleSaveClick = () => {
    const selectedUsers = filteredUsers.filter((user, index) =>
      selectedRows.includes(index)
    );

    if (selectedUsers.length === 0) {
      // No user selected, show a message
      alert("Select a user you want to update.");
      return;
    }

    const updatedApprovalList = selectedUsers.map((user, index) => ({
      ...user,
      isApproved: selectedUserActions[index] === "Approve",
    }));

    updateUser(updatedApprovalList);
    console.log(updatedApprovalList);
  };

  useEffect(() => {
    handleApproveUser();
    setSelectedUserOptions(new Array(approvalList.length).fill(4)); // Initialize with 'User' (roleId 4)
    setSelectedRoleNames(new Array(approvalList.length).fill("User")); // Initialize with 'User'
  }, []);



  useEffect(() => {
    const filteredUser = approvalList.filter((user) => {
      // Customize the conditions for filtering based on your tag structure

      return (
        user.fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.companyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.licenseNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.userName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.roleName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.mobilePhone.toLowerCase().includes(searchQuery.toLowerCase()) 

        // Add more conditions as needed
        // ...
      );
    });
   
    setFilteredUsers(filteredUser);
    setSelectAllChecked(false);
    setCurrentPage(1);

 //   getTagBySerialNumber();
  }, [searchQuery, approvalList]);

  //pagination

  const [pagination, setPagination] = useState(1); // Current page number
  const itemsPerPageOptions = [10, 20, 30]; // Number of items per page options
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 3, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 3, 1));
  };



  const renderPageButtons = () => {
    const pageButtons = [];
    const maxPagesToShow = 3;

    const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageButtons.push(
        <button
          key={i}
          onClick={() => handlePageClick(i)}
          className={currentPage === i ? "active-page" : ""}
          style={{
            color: "black",
            borderRadius: "5px",
            border: "none",
            marginLeft: "10px",
            backgroundColor: currentPage === i ? "#F6C90E" : "#3A475033",
            width: "30px",
            fontFamily: "futuramedium",
            cursor: "pointer",
          }}
        >
          {i}
        </button>
      );
    }

    return pageButtons;
  };

  const totalPages = Math.ceil(approvalList.length / itemsPerPage);

  return (
    <div className="TablePageMainDiv"
      style={{ marginLeft: "150px", marginRight: "150px", marginTop: "15px" }}
    >
      {/* head ===========================================================================================================*/}

      <div
        className="approvalTitle"
        style={{
          fontFamily: "futuramedium",
          color: "#141212",
          fontSize: "30px",
        }}
      >
        Approve Users
      </div>
      <div
        className="minibar"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            fontFamily: "futuramedium",
          }}
        >
          <p>Show</p>
          <div
            style={{
              backgroundColor: "#e5e5e5",
              borderRadius: "10px",
              padding: "4px",
              cursor: "pointer",
              height: "30px",
              width: "50px",
              marginLeft: "1px",
              marginRight: "5px",
            }}
          >
            <select
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
              style={{
                border: "none",
                background: "none",
                cursor: "pointer",
              }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
            </select>
          </div>
          <p>items</p>
        </div>

        <div style={{ display: "flex" }}>
        <div
            className="icon"
            onMouseEnter={() => setIsRefreshHovered(true)}
            onMouseLeave={() => setIsRefreshHovered(null)}
            style={{
              backgroundColor: "#3A475033",
              borderRadius: "50%",
              color: "#3A4750",
              width: "32px",
              height: "32px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
              cursor: "pointer",
              position: "relative",
            }}
            onClick={handleApproveUser}
          >
            <RefreshIcon />

            {isRefreshHovered && (
              <p
                style={{
                  position: "absolute",
                  top: "100%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  background: "#ffffff",
                  padding: "2px",
                  borderRadius: "10px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  zIndex: 999,
               
                  fontSize: "12px",
                  width: "100px",
                  textAlign: "center",
                }}
              >
                     Refresh the page
              </p>
            )}
          </div>
          {/* <p
            style={{
              color: "#3A4750",
              fontSize: "16px",
              fontFamily: "futurabook",
            }}
          >
            last refresh was 25mins ago
          </p>
          <div
            className="icon"
            style={{
              backgroundColor: "#3A475033",
              borderRadius: "50%",

              width: "32px",
              height: "32px",
              display: "flex",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
            }}
          >
            <RefreshIcon />
          </div> */}
          {/* <div
            className="icon"
            style={{
              backgroundColor: "#3A475033",
              borderRadius: "20%", // Use 50% for a circle

              width: "32px",
              height: "32px",
              display: "flex", // Use flex display
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              marginLeft: "10px",
            }}
          >
            <DownloadIcon />
          </div> */}
        </div>
      </div>

      {/* end of head========================================================================================================== */}
      {isLoading ? (
        <p>Loading data...</p>
      ) : (
        <>
      <div className="approvalTable">
        <table className="custom-table">
          <div className="tHead">
            <thead>
              <tr>
                <th>
                  <div className="checkbox-dropdown">
                    <input
                      type="checkbox"
                      id="selectAll"
                      checked={selectAllChecked}
                      onChange={handleSelectAllChangeBox}
                    />
                    <div className="dropdown">
                      <span className="dropdown-arrow">&#9662;</span>
                      <div className="dropdown-content">
                        <div onClick={() => handleSelectAllChange(true)}>
                          Select All
                        </div>
                        <div onClick={() => handleSelectAllChange(false)}>
                          Deselect All
                        </div>
                      </div>
                    </div>
                  </div>
                </th>
                <th
                  style={{
                    width: "350px",
                  }}
                >
                  Name
                </th>
                <th
                  style={{
                    width: "350px",
                  }}
                >
                  License Number
                </th>
                <th
                  style={{
                    width: "250px",
                  }}
                >
                  Mobile Phone Number
                </th>
                <th
                  style={{
                    width: "350px",
                  }}
                >
                  Company
                </th>
                <th
                  style={{
                    width: "550px",
                  }}
                >
                  Functions
                </th>
                <th className="roles">Roles</th>
                <th
                  style={{
                    width: "250px",
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>

            <tbody className="tBody">
              {currentItems.map((user, index) => (
                <tr
                  key={index}
                  className={selectedRows.includes(index) ? "selected" : ""}
                >
                  <td
                    style={{
                      maxWidth: "10%",
                      justifyContent: "flex-start",
                      display: "flex",
                    }}
                  >
                    <input
                      type="checkbox"
                      id={`userCheckbox${index}`}
                      checked={
                        selectedRowsByPage[currentPage - 1] &&
                        selectedRowsByPage[currentPage - 1].includes(
                          indexOfFirstItem + index
                        )
                      }
                      onChange={() => handleRowSelection(index)}
                    />
                    <label htmlFor={`userCheckbox${index}`}></label>
                  </td>
                  <td style={{}}>{user.fullName}</td>
                  <td
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    {user.licenseNumber}
                  </td>
                  <td>{user.mobilePhone}</td>
                  <td>{user.companyName}</td>
                  <td
                    className="functions"
                    style={{
                      fontSize: "11px",
                    }}
                  >
                    <input
                      type="checkbox"
                      id={`view${index}`}
                      checked={
                        selectedRoleNames[index] === "Administrator" ||
                        selectedRoleNames[index] === "Supervisor" ||
                        selectedRoleNames[index] === "User"
                      }
                      disabled
                    />
                    <label
                      htmlFor={`view${index}`}
                      style={{
                        marginRight: "5px",
                        fontFamily: "futurabook",
                        fontWeight: "normal",
                      }}
                    >
                      View
                    </label>
                    <input
                      type="checkbox"
                      id={`add${index}`}
                      checked={
                        selectedRoleNames[index] === "Administrator" ||
                        selectedRoleNames[index] === "Supervisor" ||
                        selectedRoleNames[index] === "User"
                      }
                      disabled
                    />
                    <label
                      htmlFor={`add${index}`}
                      style={{
                        marginRight: "5px",
                        fontFamily: "futurabook",
                        fontWeight: "normal",
                      }}
                    >
                      Add
                    </label>
                    <input
                      type="checkbox"
                      id={`edit${index}`}
                      checked={
                        selectedRoleNames[index] === "Administrator" ||
                        selectedRoleNames[index] === "Supervisor"
                      }
                      disabled
                    />
                    <label
                      htmlFor={`edit${index}`}
                      style={{
                        marginRight: "5px",
                        fontFamily: "futurabook",
                        fontWeight: "normal",
                      }}
                    >
                      Edit
                    </label>
                    <input
                      type="checkbox"
                      id={`remove${index}`}
                      checked={selectedRoleNames[index] === "Administrator"}
                      disabled
                    />
                    <label
                      htmlFor={`remove${index}`}
                      style={{
                        marginRight: "5px",
                        fontFamily: "futurabook",
                        fontWeight: "normal",
                      }}
                    >
                      Remove
                    </label>
                  </td>
                  <td>
                    <div className="dropdown">
                      <div
                        className="dropdown-toggle"
                        onClick={() => setDropdownIndex(index)}
                        style={{
                          cursor: "pointer", position:"relative"
                        }}
                      >
                        {selectedRoleNames[index] || selectedUserOption}
                      </div>
                      {dropdownIndex === index && (
                        <div className="dropdown-content"
                        style={{
                          position: "absolute",
                          top: "100%",
                          left: "50%",
                          transform: "translateX(-50%)",
                          background: "#ffffff",
                          padding: "2px",
                          borderRadius: "10px",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                          zIndex: 999,
                          width: "85px",
                          fontSize:"12px"
                        }}
                        >
                          {userOptions.map((option) => (
                            <div
                        
                              key={option}
                              className={`dropdown-option ${
                                selectedUserOptions[index] === option
                                  ? "selected"
                                  : ""
                              }`}
                              onClick={() =>
                                handleUserOptionChange(option, index)
                              }
                            >
                              {selectedRoleNames[index] === option ? (
                                <strong>{option}</strong>
                              ) : (
                                option
                              )}
                              
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </td>
                  <td>
                    <div
                      className="dropdown"
                      style={{ cursor: "pointer", position:"relative" }}
                      onClick={() => setDropdownIndex(index)}
                    >
                      {selectedUserActions[index] || "Select"}
                      <span className="dropdown-arrow">&#9662;</span>
                      {dropdownIndex === index && (
                        <div
                          className="dropdown-content"
                          style={{
                            position: "absolute",
                            top: "100%",
                            left: "50%",
                            transform: "translateX(-50%)",
                            background: "#ffffff",
                            padding: "2px",
                            borderRadius: "10px",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            zIndex: 999,
                            width: "85px",
                            fontSize:"15px"
                          }}
                        >
                          {userAction.map((action) => (
                            <div
                              key={action}
                              onClick={() =>
                                handleUserActionClick(action, index)
                              }
                            >
                              {action}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </div>
        </table>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <div>
          Showing {Math.min(indexOfLastItem, filteredUsers.length)} of{" "}
          {approvalList.length} items
        </div>
        <div
          style={{
            marginRight: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <button
            onClick={handlePreviousPage}
            style={{
              color: "black",
              borderRadius: "5px",
              border: "none",
              marginLeft: "10px",
              backgroundColor: "#3A475033",
              width: "80px",
              fontFamily: "futuramedium",
              cursor: "pointer",
            }}
          >
            Previous
          </button>

          {renderPageButtons()}

          <button
            onClick={handleNextPage}
            style={{
              color: "black",
              borderRadius: "5px",
              border: "none",
              marginLeft: "10px",
              backgroundColor: "#3A475033",
              width: "60px",
              fontFamily: "futuramedium",
              cursor: "pointer",
            }}
          >
            Next
          </button>
        </div>
      </div>

      <div
        style={{ justifyContent: "flex-end", width: "100%", display: "flex" }}
      >
        <button className="save-button" onClick={handleSaveClick}>

          <Modal show={showEditSuccessModal} centered>
          <Modal.Body>
                        <div className="d-flex justify-content-end">
                          <button
                            type="button"
                            className="close"
                            aria-label="Close"
                            onClick={handleCloseSuccessUserModal}
                            style={{
                              background: "none",
                              border: "none",
                              fontSize: "35px",
                              position: "absolute",
                            }}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
            <EditSuccessModal onClose={handleCloseSuccessUserModal} />
            </Modal.Body>
          </Modal>
          
          Save
        </button>
      </div>
      <Modal show={showErrorModal}  centered>
      <Modal.Body>
                        <div className="d-flex justify-content-end">
                          <button
                            type="button"
                            className="close"
                            aria-label="Close"
                            onClick={handleCloseErrorModal}
                            style={{
                              background: "none",
                              border: "none",
                              fontSize: "35px",
                              position: "absolute",
                            }}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
        <EditErrorModal onClose={handleCloseErrorModal} />
        </Modal.Body>
      </Modal>
      </>
      )}
    </div>
  );
};

export default UserApprovalTable;
