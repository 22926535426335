import React from 'react'
import '../../css/DistributorsPage/distributors.css'

const DistributorsComponent = () => {
  return (
    <div
       style={{
        backgroundColor:"#000000",
        height:"440px",
        padding:"0px",
        alignItems:"center",

  
       }}
    >
      <div className='distributorImgContainer'
        style={{
          marginLeft:'150px',
          marginRight:"150px",
            backgroundRepeat:"no-repeat",
            backgroundSize:"contain",
            height:"100%",
            backgroundPositionX:"center",
            backgroundPositionY:"center"

        }}
      >

      </div>
    </div>
  )
}

export default DistributorsComponent
