import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';

import 'leaflet/dist/leaflet.css';

import customMarkerImage from '../../Images/mapMarker.png'; 
const DistributorMap = () => {
  const center = [ -32.03664287045221, 115.82191722578972]; 

  const customIcon = new L.Icon({
    iconUrl: customMarkerImage,
    iconSize: [20, 28], // Set the size of the custom icon
  });
 
  return (
    <MapContainer center={center} zoom={15} style={{ height: '730px', width: '100%' }}>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Marker position={center} icon={customIcon}>
        <Popup>
          A popup with information about your location.
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default DistributorMap;