import React, { useState } from "react";
import ReAuthorizationFailure from "./Validations/ReAuthorizeFailure";
import ReAuthorizeSuccess from "./Validations/ReAuthorizeSuccessModal";
import { useSelector } from "react-redux";
import { ThinCircleCheck } from "../../fontAwesomeComponents/FaIcons";
import { publicRequest } from "../../requestMethods";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";

const ReAuthorizeComponent = ({ userInfo }) => {
  const navigate = useNavigate();

  const backtoHome = () => {
    navigate("/");
  };

  console.log("this is userinfo", userInfo);

  const token = useSelector((state) => state.user.token);
  const userCompanyId = useSelector((state) => state.selectedCompany);
  const userId = useSelector((state) => state.user.id);

  console.log(
    "this is userinfocompanyid",
    userInfo.userCompany[0].userCompanyId
  );

  const fullName = userInfo.firstName + " " + userInfo.lastName;

  //modals

  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const [openFailedModal, setOpenFailedModal] = useState(false);

  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
  }

  const handleCloseFailedModal = () => {
    setOpenFailedModal(false);
  }

  const toggleFailedModal = () => {
    setOpenFailedModal(!openFailedModal);
  }

  const confirmApproveUser = async () => {
    try {
      const accessList = [{
        userId: userInfo.id,
        userCompanyId: userInfo.userCompany[0].userCompanyId,
        isActive: true,
      }];

      console.log("this is accesslist", accessList);

      const data = {
        loggedUserId: userId,
        loggedUserCompanyId: userCompanyId,
        accessList: accessList,
      };

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await publicRequest({
        method: "POST",
        url: "/user/userlist/update",
        data: data,
        headers: headers,
      });

      setOpenSuccessModal(true);
      console.log("User list updated:", response.data);
    } catch (error) {
        setOpenFailedModal(true);
      console.error("Error updating user list", error);
    }
  };

  return (
    <div
      style={{
        height: "70vh",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "100%",
          backgroundColor: "white",
          borderRadius: "30px",
        }}
      >
        <div
          style={{
            marginBottom: "20px",
            backgroundColor: "#28A745",
            padding: "10px",
            borderRadius: "16px",
          }}
        >
          <ThinCircleCheck height="50px" width="50px" color="#D4EDDA" />
        </div>

        <h2
          style={{
            fontFamily: "futuraheavy",
            fontSize: "25px",
            marginBottom: "20px",
            textAlign: "center",
            paddingRight: "20px",
            paddingLeft: "20px",
          }}
        >
          Approve User{" "}
          {fullName.length > 20 ? fullName.slice(0, 20) + "..." : fullName}?
        </h2>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "20%",
          }}
        >
          <button
            className="back-to-login-button"
            onClick={confirmApproveUser}
            style={{
              backgroundColor: "#28A745",
              width: "100px",
              fontFamily: "futurabook",
              color: "#FFFFFF",
              height: "50px",
              border: "none",
              borderRadius: "10px",
            }}
          >
            Confirm
         <Modal
            show={openSuccessModal}
       //     onHide={handleCloseSuccessModal}
            centered
         >
              <Modal.Body>
                        <div className="d-flex justify-content-end">
                          <button
                            type="button"
                            className="close"
                            aria-label="Close"
                            onClick={handleCloseSuccessModal}
                            style={{
                              background: "none",
                              border: "none",
                              fontSize: "35px",
                              position: "absolute",
                            }}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
            <ReAuthorizeSuccess />
            </Modal.Body>
         </Modal>
          </button>
          <Modal
                show={openFailedModal}
            //    onHide={handleCloseFailedModal}
                centered
            >
                 <Modal.Body>
                        <div className="d-flex justify-content-end">
                          <button
                            type="button"
                            className="close"
                            aria-label="Close"
                            onClick={handleCloseFailedModal}
                            style={{
                              background: "none",
                              border: "none",
                              fontSize: "35px",
                              position: "absolute",
                            }}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                <ReAuthorizationFailure onClose={toggleFailedModal}/>
                </Modal.Body>
            </Modal>
          <button
            className="back-to-login-button"
            onClick={backtoHome}
            style={{
              backgroundColor: "#eeeeee",
              width: "100px",
              fontFamily: "futurabook",
              color: "#303841",
              height: "50px",
              border: "none",
              borderRadius: "10px",
            }}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReAuthorizeComponent;
