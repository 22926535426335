import React from 'react'
import '../../css/privacyPolicycss/PrivacyPolicy.css'

const DisclaimerContainer = () => {
  return (
    <div className='disclaimer-container'
    >
        <div>
            <h1 style={{fontFamily:"futuramedium", fontSize:"45px"}}>Disclaimer</h1>
            <br/>
            <p className='privacyText'>
            We are doing our best to prepare the content of this site. However, Safe T Tag cannot warranty the expressions and suggestions of the contents, as well as its accuracy. In addition, to the extent permitted by the law, Safety Tag shall not be responsible for any losses and/or damages due to the usage of the information on our website. 
            <br/>
            <br/>
            By using our website, you hereby consent to our disclaimer and agree to its terms.
            <br/>
            <br/>
            The links contained on our website may lead to external sites, which are provided for convenience only. Any information or statements that appeared in these sites are not sponsored, endorsed, or otherwise approved by Safety Tag. For these external sites, Safety Tag cannot be held liable for the availability of, or the content located on or through it. Plus, any losses or damages occurred from using these contents or the internet generally.
            </p>
        </div>
    </div>
  )
}

export default DisclaimerContainer;