import React from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaGoogle,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa";
import { AiOutlineQrcode } from "react-icons/ai"; // Import QR code icon
import "../css/Footer.css";
import { XTwitter } from "../fontAwesomeComponents/FaIcons";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-column">
          <div className="footer-logo">
            {/* Insert your mock logo here */}
            <img src="https://i.ibb.co/yWKHwvc/Logo.png" alt="Mock Logo" />
          </div>
          <div>
            <p className="footer-text">
              For maximum safety and effectiveness, use a QR Coded Safe T Tag
              today. It is a simple and effective lockout device / Electrical
              Plug Block, used to protect both the machines and the workers
              during repair work, maintenance, or new uninstalled equipment
              installations. For bulk orders over 5,000 Safe T Tag units,
              connect with us via the{" "}
              <a
                style={{
                  textDecoration: "none",
                  fontWeight: "bold",
                  color: "white",
                }}
                href="/contactus"
              >
                Contact Us
              </a>{" "}
              link.
            </p>
          </div>
          <div className="social-media-logos">
            {/* Social media logos */}
            <FaFacebookF color="#fff" size={30} />
            <XTwitter color="#fff" size={30} />
            <FaInstagram color="#fff" size={30} />

            <FaYoutube color="#fff" size={30} />
            <FaLinkedin color="#fff" size={30} />
          </div>
          <p className="footerlink">
            <span
              className="footer-links"
              style={{ fontFamily: "futuraheavy", fontSize: "14px" }}
            >
              <span href="/disclaimer">Safe T Tag</span>
              {" "}
              <a href="/privacypolicy">Privacy Policy </a> and{" "}
              <a href="/termsandconditions">Terms and Conditions</a>
              <br />© Copyright Safe T Tag 2024 All rights reserved
            </span>
          </p>
        </div>

        <div className="footer-column" style={{ marginTop: "10%" }}>
          <ul className="ullinks"
            style={{
              textAlign: "center",
            }}
          >
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/distributors">Distributor</a>
            </li>
            <li>
              <a href="/downloadtheapp">Download the App</a> 
            </li>
            <li>
              <a href="/gallery">Gallery</a>
            </li>
            <li>
              <a href="/contactus">Contact Us</a>
            </li>
          </ul>
        </div>

        <div className="footer-column">
          <div className="scan-to-download">
            <h4 style={{ fontFamily: "futura", marginTop: "1vh" }}>
              Scan to download <br />
              Safe T Tag™ app.
            </h4>
            {/* Add your QR code icons here */}
            <div className="qr-code-icons"
                    style={{
                      gap:"25px"
                    }}
            >
              <div className="appstore-icon">
                {/* <AiOutlineQrcode color="#000" size={90} /> */}
              </div>
              <div className="playstore-icon">
                {/* <AiOutlineQrcode color="#000" size={90} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
