import React, { useState } from "react";
import UserProfile from "../components/registerComponents/UserProfile";
import CompanyProfile from "../components/registerComponents/CompanyProfile";
import Finish from "../components/registerComponents/Finish";
import RegisterBar from "../components/registerComponents/RegisterBar";
import NavBar from "../components/NavBar";
import Headline from "../components/homeComponents/Headline";

const Register = () => {
  const [step, setStep] = useState(1);
  const [userData, setUserData] = useState({});
  const [companyData, setCompanyData] = useState({});

  const handleNext = (data) => {
    if (step === 1) {
      setUserData(data);
      setStep(2);
    } else if (step === 2) {
      setCompanyData(data);
      setStep(3);
    }
  };

  const handlePrevious = () => {
    setStep((prevStep) => Math.max(prevStep - 1, 1));
  };

  return (
    <>
      <NavBar />
      <div
        className="parent"
        style={{
          backgroundColor: "#EEEEEE",
          backgroundSize: "cover",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
        
        }}
      >
      
        <RegisterBar activeComponent={step} />
        {step === 1 && <UserProfile data={userData} onNext={handleNext} />}
        {step === 2 && (
          <CompanyProfile
            data={companyData}
            onPrevious={handlePrevious}
            onNext={handleNext}
          />
        )}

        {step === 3 && (
          <Finish
            userData={userData}
            companyData={companyData}
            onPrevious={handlePrevious}
          />
        )}
      </div>
    </>
  );
};

export default Register;
