import axios from "axios";
import { refreshTokenFunction, autoRefreshTokens } from "./redux/apiCals";// Import your token refresh logic

// Create an Axios instance with a response interceptor
const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      // Set _retry to true to avoid infinite loops
      originalRequest._retry = true;

      try {
        // Dispatch an action to trigger token refresh logic
        // This action should be responsible for refreshing the token
        await refreshTokenFunction(); // You might need to pass user data if needed
        
        // Retry the original request after token refresh
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        // Handle token refresh error, e.g., log out the user
        console.error("Token refresh failed: axios interceptor", refreshError);
        // You can also redirect the user to the login page or show an error message
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
