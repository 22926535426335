import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { publicRequest } from "../requestMethods";
import EditSuccessModal from "./EditSuccessModal";
import FailedEditUserModal from "./FailedEditUserModal";
import { Modal } from "react-bootstrap";
import { refreshTokenFunction } from "../redux/apiCals";
import { format, parseISO } from "date-fns";

const SingleEditAccountModal = ({ user }) => {
  const [errorMessage, setErrorMessage] = useState([]);
  const [isError, setIsError] = useState(false);
  const [showEditSuccessModal, setShowEditSuccessModal] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const handleCloseSuccessUserModal = () => {
    setShowEditSuccessModal(false);
  };

  const [showFailedModal, setShowFailedModal] = useState(false);

  const handleCloseFailedModal = () => {
    setShowFailedModal(false);
  };

  const isValidContactNumber = (mobilePhone) => {
    const trimmedNumber = mobilePhone.replace(/\D/g, ""); // Remove non-numeric characters
    if (trimmedNumber.startsWith("61") && trimmedNumber.length === 11) {
      return true;
    } else if (trimmedNumber.startsWith("678") && trimmedNumber.length === 7) {
      return true;
    } else {
      return false;
    }
  };

  const isValidOfficeContactNumber = (mobilePhone) => {
    const trimmedNumber = mobilePhone.replace(/\D/g, ""); // Remove non-numeric characters
    if (trimmedNumber.startsWith("61") && trimmedNumber.length === 11) {
      return true;
    } else if (
      trimmedNumber.startsWith("64") &&
      [11].includes(trimmedNumber.length)
    ) {
      return true;
    } else if (trimmedNumber.startsWith("678") && trimmedNumber.length === 7) {
      return true;
    } else {
      return false;
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const [userInput, setUserInput] = useState({
    firstName: "",
    lastName: "",
    mobilePhone: "",
    officePhone: "",
    dob: "",
    email: "",
    licenseNumber: "",
    roleId: "",
  });

  const [userDataLoading, setUserDataLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const token = useSelector((state) => state.user.token);
  const userId = useSelector((state) => state.user.id);
  const selectedCompanyId = useSelector((state) => state.selectedCompany);
  const selectedCompanyName = useSelector((state) => state.selectedCompanyName);
  const selectedUserCompanyId = useSelector((state) => state.selectedCompanyId);
  const userRole = useSelector((state) => state.selectedCompanyRoleId);
  const refreshToken = useSelector((state) => state.user.refreshToken);

  const handleRoleChange = (e) => {
    handleInputChange("roleId", e.target.value);
  };

  const [roleList, setRoleList] = useState([]);

  const fetchData = async (url) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await publicRequest({
        method: "GET",
        url: url,
        headers: headers,
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching data", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchUserAndCompanyData = async () => {
      try {
        setLoading(true);
        const userResponse = await fetchData(`/user/${user.id}/profile`);
        const displayRoles = await fetchData("lookup/roles");

        if (userResponse) {
          setUserData(userResponse);
          setUserDataLoading(false);

          setUserInput({
            firstName: userResponse.firstName || "",
            lastName: userResponse.lastName || "",
            mobilePhone: userResponse.mobilePhone || "",
            officePhone: userResponse.officePhone || "",
            email: userResponse.email || "",
            dob: userResponse.doB
              ? format(parseISO(userResponse.doB), "yyyy-MM-dd")
              : "",

            officePhone: userResponse.phone || "",
            licenseNumber: userResponse.licenseNumber || "",
            roleId: userRole || "",
          });
        }

        console.log("userresponse", userResponse);

        if (displayRoles) {
          setRoleList(displayRoles);
        }

        if (displayRoles) {
          setUserData((prevDetails) => ({
            ...prevDetails,
            roleId: displayRoles.name,
          }));

          console.log(displayRoles);
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }finally {
        setLoading(false); // End loading
      }
    };

    fetchUserAndCompanyData();
  }, [user.id, user.companyId]);

  const updateUser = async () => {
    setFormSubmitted(true);

    // Check if mobilePhone starts with "61" or "678"
    if (
      userInput.mobilePhone.startsWith("61") ||
      userInput.mobilePhone.startsWith("678")
    ) {
      userInput.mobilePhone = "+" + userInput.mobilePhone;
    }

    if (
      userInput.officePhone.startsWith("61") ||
      userInput.officePhone.startsWith("678")
    ) {
      userInput.officePhone = "+" + userInput.officePhone;
    }


    const selectedDate = new Date(userInput.dob);
    const fifteenYearsAgo = new Date();
    fifteenYearsAgo.setFullYear(fifteenYearsAgo.getFullYear() - 15);

    if (selectedDate > fifteenYearsAgo) {
      setErrorMessage(["User must be 15 years old or older."]);
      setIsError(true);
      return; // Exit the function if validation fails
    }

    const data = {
      loggedUserId: userId,
      loggedUserCompanyId: selectedCompanyId,
      userId: userId,
      userCompanyId: selectedCompanyId,
      firstName: userInput.firstName,
      lastName: userInput.lastName,
      email: userInput.email,
      dob: userInput.dob,
      mobilePhone: userInput.mobilePhone,
      licenseNumber: userInput.licenseNumber,

      isActive: true,
      officePhone: userInput.officePhone,
      roleId: userInput.roleId,
    };

    console.log(data);

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    try {
      const response = await publicRequest({
        method: "POST",
        url: `user/${userId}/update`,
        data: data,
        headers: headers,
      });
      setShowEditSuccessModal(true);
      const res = await refreshTokenFunction(dispatch, { token, refreshToken });
      console.log(res);
      console.log(response.data);
    } catch (error) {
      console.error(error);
      setShowFailedModal(true);
      console.error(error.response.data.errors);
      setErrorMessage(error.response.data.errors || error.response.data.title);
      setIsError(true);
    }
  };

  const handleInputChange = (field, value) => {
    setUserInput((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));

    // Add validation for Date of Birth
    if (field === "dob") {
      // Validate the selected date
      const selectedDate = new Date(value);
      const currentDate = new Date();
      const minDate = new Date(
        currentDate.getFullYear() - 15,
        currentDate.getMonth(),
        currentDate.getDate()
      );

      if (selectedDate > minDate) {
        // If the selected date makes the user younger than 15, show an error or handle accordingly
        setErrorMessage("User must be 15 years old or older.");
        setIsError(true);
      }
      
      else {
        // If the selected date is valid, clear the error
        setErrorMessage([]);
        setIsError(false);
      }
    }
  };

  // a

  const isSaveDisabled = isError;

  return (
    <div className="ModalChildDiv"
      style={{
        alignItems: "center",
        display: "flex",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <div
        className="modal-content custom-modal-content"
        style={{
          padding: "10px",
          paddingLeft: "20px",
          paddingRight: "20px",
          border: "none",
          background: "none",
        }}
      >
        <div className="row">
          <div
            className="finalTitleDiv"
            style={{
              alignItems: "flex-start",
            }}
          >
            <h3
              style={{
                fontFamily: "futuramedium",
                fontWeight: "bold",
                fontSize: "30px",
                marginTop: "15px",
              }}
            >
              Edit User
            </h3>
          </div>
        </div>
        <div className="finish-row">
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              First Name:*
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
                fontSize: "16.5px",
              }}
              value={loading ? "Loading..." : userInput.firstName}
              onChange={(e) => handleInputChange("firstName", e.target.value)}
              maxLength={50}
            />
            {userInput.firstName === "" && formSubmitted && (
              <div className="validation-message" style={{}}>
                First Name is required
              </div>
            )}
          </div>
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Last Name:*
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
                fontSize: "16.5px",
              }}
              value={userInput.lastName}
              onChange={(e) => handleInputChange("lastName", e.target.value)}
              maxLength={50}
            />
            {userInput.lastName === "" && formSubmitted && (
              <div className="validation-message" style={{}}>
                Last Name is required
              </div>
            )}
          </div>
        </div>
        <div className="finish-row">
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Master License Number:*
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
              value={userInput.licenseNumber}
              onChange={(e) =>
                handleInputChange("licenseNumber", e.target.value)
              }
              maxLength={30}
            />
            {userInput.licenseNumber === "" && formSubmitted && (
              <div className="validation-message" style={{}}>
                Master License number is required
              </div>
            )}
          </div>

          <div>
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "4px",
              }}
            >
              Role:*
            </p>
            <select
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
                height: "24px",
                fontWeight: "bold",
                borderLeft: "none",
                borderRight: "none",
                borderTop: "none",
                cursor: userRole !== 1 ? "not-allowed" : "auto", // Set cursor to "not-allowed" when disabled
              }}
              value={userInput.roleId}
              onChange={handleRoleChange}
              disabled={userRole !== 1}
            >
              {roleList.map((role) => (
                <option key={role.id} value={role.id}>
                  {role.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="finish-row">
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Mobile Phone Number:*
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
              value={
                userInput.mobilePhone.includes("+")
                  ? userInput.mobilePhone
                  : "+" + userInput.mobilePhone
              }
              onChange={(e) => {
                const inputValue = e.target.value;
                const strippedValue = inputValue.replace("+", ""); // Remove the "+" character
                handleInputChange("mobilePhone", strippedValue);
              }}
              maxLength={12}
            />
            {formSubmitted && userInput.mobilePhone === "" && (
              <div className="validation-message" style={{ marginTop: "5px" }}>
                Mobile Phone number is required
              </div>
            )}
            {formSubmitted &&
              userInput.mobilePhone !== "" &&
              !isValidContactNumber(userInput.mobilePhone) && (
                <div className="validation-message" style={{}}>
                  Please enter a valid Mobile Phone number
                </div>
              )}
          </div>

          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Phone Number:
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
                value={
                userInput.officePhone.includes("+")
                  ? userInput.officePhone
                  : "+" + userInput.officePhone
              }
              onChange={(e) => {
                const inputValue = e.target.value;
                const strippedValue = inputValue.replace("+", ""); // Remove the "+" character
                handleInputChange("officePhone", strippedValue);
              }}
              maxLength={12}
            />
            {formSubmitted &&
              userInput.officePhone !== "" &&
              !isValidOfficeContactNumber(userInput.officePhone) && (
                <div className="validation-message" style={{}}>
                  Please enter a valid Phone number
                </div>
              )}
          </div>
        </div>
        <div className="finish-row">
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Date of Birth:*
            </p>
            <input
              type="date"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
              value={userInput.dob}
              onChange={(e) => handleInputChange("dob", e.target.value)}
              max="9999-12-31" // Set max attribute to prevent future dates
              maxLength={10} // Set maxLength to 10 to restrict the length of input
            />
            {userInput.dob === "" && formSubmitted && (
              <div className="validation-message" style={{}}>
                Date of Birth is required
              </div>
            )}
            {/* {isError && formSubmitted && (
              <div
                className="validation-message"
                style={{
                  marginTop: "0.5px",
                }}
              >
                {errorMessage}
              </div>
            )} */}
          </div>
          <div className="finish-column">
            <p
              className="detailsTitle"
              style={{
                fontSize: "13px",
                marginBottom: "5px",
              }}
            >
              Email Address:*
            </p>
            <input
              type="text"
              className="addUserDetails"
              style={{
                fontFamily: "futurabook",
                color: "#141212",
              }}
              value={userInput.email}
              onChange={(e) => handleInputChange("email", e.target.value)}
              maxLength={50}
            />
            {userInput.email === "" && formSubmitted && (
              <div className="validation-message" style={{}}>
                Email is required
              </div>
            )}
            {formSubmitted &&
              userInput.email !== "" &&
              !isValidEmail(userInput.email) && (
                <div className="validation-message" style={{}}>
                  Please enter a valid email
                </div>
              )}
          </div>
        </div>
        <div
          style={{
            width: "100%",
            justifyContent: "flex-end",
            display: "flex",
            marginTop: "20px",
          }}
        >
          {/* {isError && errorMessage && (
            <div
              className="error-message"
              style={{
                color: "red",
                fontSize: "10px",
                marginTop: "15px",
                marginRight: "10px",
              }}
            >
              {typeof errorMessage === "string" ? (
                <p
                  style={{
                    fontSize: "13px",
                  }}
                >
                  {errorMessage}
                </p>
              ) : (
                Object.values(errorMessage).map((error, index) => (
                  <p
                    style={{
                      fontSize: "13px",
                    }}
                    key={index}
                  >
                    {error}
                  </p>
                ))
              )}
            </div>
          )} */}
          <button
            className="next-button"
            style={{
              fontSize: "13px",
              width: "18%",
            }}
            onClick={updateUser}
            // // disabled={isSaveDisabled}
          >
            Save
          </button>
          <Modal
            show={showEditSuccessModal}
            //  onHide={handleCloseSuccessUserModal}
            centered
          >
            <Modal.Body>
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={handleCloseSuccessUserModal}
                  style={{
                    background: "none",
                    border: "none",
                    fontSize: "35px",
                    position: "absolute",
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <EditSuccessModal onClose={handleCloseSuccessUserModal} />
            </Modal.Body>
          </Modal>

          <Modal
            show={showFailedModal}
            // onHide={handleCloseFailedModal}
            centered
          >
            <Modal.Body>
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={handleCloseFailedModal}
                  style={{
                    background: "none",
                    border: "none",
                    fontSize: "35px",
                    position: "absolute",
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <FailedEditUserModal onClose={handleCloseFailedModal} />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default SingleEditAccountModal;
