import React, { useState } from 'react';
import NavBar from '../components/NavBar';
import Header from '../components/Header';
import Headline from '../components/homeComponents/Headline';
import HowItWorks from '../components/homeComponents/HowItWorks';
import UserInstructions from '../components/homeComponents/UserInstructions';
import SafeTtagBenefits from '../components/homeComponents/SafeTtagBenefits';
import HowToBuy from '../components/homeComponents/HowToBuy';
import AdditionalFeatures from '../components/homeComponents/AdditionalFeatures';
import Footer from '../components/Footer';
import SafetTagFeatures from '../components/homeComponents/SafeTtagFeatures';
import OfficialDistributors from '../components/homeComponents/OfficialDistributors';
import { Modal } from 'react-bootstrap';
import TermsAndConditions from '../modals/TermsAndConditions';
import { useSelector } from 'react-redux';

function Home() {
  const user = useSelector((state) => state.user);
  const termNotAccepted = user && user.isTermAccepted === false;

  const [showModal, setShowModal] = useState(false);

  const showTermsAndConditionsModal = () => {
    if (termNotAccepted) {
      setShowModal(true);
    }
  }

  return (
    <>
      <NavBar />
      <Headline />
      <HowItWorks />
      <AdditionalFeatures />
      <SafetTagFeatures />
      <SafeTtagBenefits />
      <OfficialDistributors />
   
      <Footer />

      {termNotAccepted && ( // Only render modal if termNotAccepted is true
        <Modal size="xl" centered show={showTermsAndConditionsModal} onHide={() => setShowModal(false)}>
          <TermsAndConditions />
        </Modal>
      )}
    </>
  );
}

export default Home;
