import React, { useEffect, useState } from "react";
import '../../css/DownloadTheAppCss/downloadtheapp.css'

const DownloadLinkComponent = () => {

  const handleClick = (url) => {
    window.open(url, "_blank", "noopener noreferrer");
  };

  const handleClick2 = (url) => {
    window.open(url, "_blank", "noopener noreferrer");
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      const screenWidth = window.innerWidth;
      setIsMobile(screenWidth < 768); // Adjust this value according to your design requirements
    };

    checkIfMobile();

    window.addEventListener("resize", checkIfMobile);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  return (


    <div className="additionalFeatures">
      <div className="additionalFeaturesDiv"
        style={{
          display: "flex",
          flexDirection: "row",
          marginLeft: "15%",
          marginRight: "-1.01%",
        }}
      >
        <div className="feature-content" style={{ flex: 1 }}>
          <h2
            className="text-light feature-title"
            style={{
              fontFamily: "futura",
              fontSize: "55px",
            }}
          >
            Download the app and gain access to an additional range of <br />{" "}
            Safe T Tag tools.
          </h2>
          <p className="downloadtheappP"
            style={{
              fontFamily: "futurabook",
              fontSize: "18px",
              width: "90%",
            }}
          >
            In addition to the physical application of a Safe T Tag electrical
            Safety blocking device in preventing electrocution or further damage
            to equipment, we have created an app to be used in conjunction with
            the Safe T Tag. The app comes with tools that enable the
            electrician, Safety Officer, even your authorised electrical
            contractors, to have access to all the data about the device that
            the TAG was connected to. No more unreadable or lost safety paper
            tags, it is all available via the Safe T Tag app online. Full User
            entered data logging and reporting with so much more, download the
            Safe T Tag app today. Forget about the hassle of deciphering
            illegible or misplaced paper tags - all the User supplied
            information is readily available through the Safe T Tag app online.

            <br/>
            Don't miss out on these benefits - Download the Safe T Tag app
            today.
          </p>
          <div className="downloadbuttonss"
            style={{
              display: "flex",
              marginLeft: "-30px",
              width: "100%",
            }}
          >
            <div className="app-link-container"
                  onClick={() => handleClick("https://apps.apple.com/app/id6467346986")}

                    style={{
                      cursor:"pointer"
                    }}

            >
              <a
                href="https://www.example.com/appstore"
                target="_blank"
                rel="noopener noreferrer"
              ></a>
            </div>

            <div className="googlePlay-link-container"
             onClick={() => handleClick2("http://play.google.com/store/apps/details?id=com.safettag.safettag")}

                    style={{
                      cursor:"pointer"
                    }}
            >
              <a
                href="https://www.example.com/googleplay"
                target="_blank"
                rel="noopener noreferrer"
              ></a>
            </div>
          </div>
        </div>
        {!isMobile && (
                   <div className="feature-content" style={{ flex: 1 }}>
                   <div className="mock-image-download"></div>
                 </div>
        )}
 
      </div>
    </div>
  );
};

export default DownloadLinkComponent;
