import React, { useState } from "react";
import {
  RiMailLine,
  RiEyeLine,
  RiEyeCloseLine,
  RiArrowRightSLine,
} from "react-icons/ri";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../css/Login.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { login, autoRefreshTokens, refreshToken } from "../redux/apiCals";
import ForgotPasswordModal from "./ForgotPasswordComponents/ForgotPasswordModal";
import { Modal } from "react-bootstrap";
import Cookies from 'js-cookie';

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [staySignedIn, setStaySignedIn] = useState(false);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [error, setError] = useState("");

  console.log(error, "this is error message");

  const location = useLocation();

  // Check if the current location is "/login"
  const isLoginPage = location.pathname === "/login";

  // const handleLogin = async () => {
  //   try {
  //     // Send login data to the backend using Axios or any other HTTP library
  //     const response = await axios.post('/api/login', {
  //       email,
  //       password,
  //       staySignedIn
  //     });

  //     // Handle success response (e.g., navigate to dashboard)
  //     console.log('Login successful:', response.data);

  //   } catch (error) {
  //     // Handle error response (e.g., show an error message)
  //     console.error('Login error:', error);
  //   }
  // };

  //modals

  const [forgotPasswordModal, setOpenForgotPasswordModal] = useState(false);

  const handleOpenForgotPasswordModal = () => {
    setOpenForgotPasswordModal(true);
  };

  const handleCloseForgotPasswordModal = () => {
    setOpenForgotPasswordModal(false);
  };

  const reduxError = useSelector((state) => state.errorMessage); // Access the error from the Redux state

  console.log("this is redux error",reduxError);

  const dispatch = useDispatch();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await login(dispatch, { email, password });
      Cookies.set('lastActivityTime', new Date().getTime());
  
      const currentUser = response.data;
      const accessToken = currentUser.accessToken;
      const refreshToken = currentUser.refreshToken;
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${accessToken}`;
  
      // Start automatic token refresh by passing accessToken and refreshToken
      const stopRefresh = refreshToken(
        dispatch,
        currentUser,
        accessToken,
        refreshToken
      );
  
      navigate("/tags");
    } catch (error) {
      if (error.response) {
        // Server responded with a status code outside of 2xx range
        setError(error.response.data.message); // Assuming error message is provided by the server
      } else if (error.request) {
        // The request was made but no response was received
        setError("No response from server");
      }
  
      // If Redux error message is available and not already set in local state
      if (reduxError && error) {
        setError(reduxError);
      }
    } finally {
      setLoading(false); // Set loading to false regardless of success or failure
    }
  };
  

  return (
    <div className="login-container">
      <div className="title">
        <h3
          className="loginTitle"
          style={{
            marginBottom: "10px",
            fontFamily: "futurabook",
            fontWeight: "bold",
          }}
        >
          Login to your account
        </h3>
      </div>
      <div className="input-container">
        <input
          type="text"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onFocus={(e) => setEmail(e.target.value)}
        />
        <RiMailLine className="input-icon" />
      </div>
      <div className="input-container">
        <input
          type={showPassword ? "text" : "password"}
          placeholder="Enter your password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onFocus={(e) => setPassword(e.target.value)}
        />
        {showPassword ? (
          <RiEyeCloseLine
            className="password-icon"
            onClick={handlePasswordVisibility}
          />
        ) : (
          <RiEyeLine
            className="password-icon"
            onClick={handlePasswordVisibility}
          />
        )}
      </div>
      { error && reduxError &&
  !reduxError["Auth.InvalidPermission"] &&
  !reduxError["Email"] &&
  !reduxError["Password"] && (
    <p
      style={{
        padding: "2px",
        alignItems: "flex-end",
        color: "red",
        fontFamily: "futurabook",
        display: "flex",
        width: "100%",
        fontSize: "11px",
        maxHeight: "11px",
      }}
    >
      {Array.isArray(reduxError) ? reduxError[1] : JSON.stringify(reduxError).replace(/"/g, "")}
    </p>
  )}

{error && reduxError["Email"] && (
  <p
    style={{
      padding: "2px",
      alignItems: "flex-end",
      color: "red",
      fontFamily: "futurabook",
      display: "flex",
      width: "100%",
      fontSize: "11px",
      maxHeight: "11px",
    }}
  >
    {reduxError["Email"].includes(1) ? reduxError["Email"][1] : reduxError["Email"][0]}
  </p>
)}

{error && reduxError["Password"] && (
  <p
    style={{
      padding: "2px",
      alignItems: "flex-end",
      color: "red",
      fontFamily: "futurabook",
      display: "flex",
      width: "100%",
      fontSize: "11px",
      maxHeight: "11px",
    }}
  >
    {reduxError["Password"][0]}
  </p>
)}


      <div
        className="checkbox-container"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          fontSize: "14px",
        }}
      >
        {/* <div>
          <input
            type="checkbox"
            checked={staySignedIn}
            onChange={() => setStaySignedIn(!staySignedIn)}
            className="custom-checkbox"
          />
          <label>Stay signed in</label>
        </div> */}
        <div className="forgot-password">
          <span onClick={handleOpenForgotPasswordModal}>
            <label style={{ fontSize: "12px", cursor: "pointer" }}>
              Forgot Password, click here to reset
            </label>
          </span>

          <Modal
            show={forgotPasswordModal}
            //    onHide={handleCloseForgotPasswordModal}
            centered
          >
            <Modal.Body>
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={handleCloseForgotPasswordModal}
                  style={{
                    background: "none",
                    border: "none",
                    fontSize: "20px",
                    position: "absolute",
                    zIndex: "999",
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <ForgotPasswordModal />
            </Modal.Body>
          </Modal>
        </div>
      </div>
      <button className="login-button" onClick={handleLogin} disabled={loading}>
        <strong>{loading ? 'Logging in...' : 'Log in'}</strong>
        <RiArrowRightSLine className="login-icon" />
      </button>
      <div className="register-link" style={{ color: "black" }}>
        Not a member?{" "}
        <Link to="/register">
          <strong>Click here</strong> to Register
        </Link>
      </div>
      {/* Conditionally render the copyright text */}
      {/* {isLoginPage && ( */}
      <span className="copyright">
        <a
          style={{
            color: "#707070",
          }}
        >
          {" "}
          Safe T Tag{" "}
        </a>
        {/* <span style={{ color: "#707070" }}> | </span> */}
        {/* <a
          href="/disclaimer"
          style={{ color: "#707070", textDecoration: "none" }}
        >
          Disclaimer
        </a> */}
        {/* <span style={{ color: "#707070" }}> and </span> */}
        <a
          href="/privacypolicy"
          style={{ color: "#707070", textDecoration: "none" }}
        >
          Privacy Policy
        </a>{" "}
        <span
          style={{
            color: "#707070",
          }}
        >
          and{" "}
        </span>{" "}
        <a
          style={{ color: "#707070", textDecoration: "none" }}
          href="/termsandconditions"
        >
          Terms & Conditions
        </a>
      </span>

      <span
        className="copyright"
        style={{ color: "#707070", marginBottom: "0px" }}
      >
        © Copyright Safe T Tag {new Date().getFullYear()}. All rights
        reserved.&nbsp;
      </span>
    </div>
  );
};

export default Login;
